import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import ProtectedRoute from "./scenes/SessionManagement/ProtectedRoutes";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Calendar from "./scenes/calendar/calendar";
import FacilityForm from "./scenes/facilityForm";
import ViewClient from "./scenes/viewClient";
import FacilityInfo from "./scenes/viewFacility";
import ManageStaff from "./scenes/ManageStaff";
import Contacts from "./scenes/viewStaff";
import { useState } from "react";
import Dashboard from "./scenes/dashboard";
import ChooseSessionNotePage from "./scenes/ChooseSessionNote";
import ClientForm from "./scenes/AddClient";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Ssidebar from "./scenes/global/Ssidebar";
import ManageFacility from "./scenes/ManageFacility";
import ManageClient from "./scenes/ManageClient";
import Login from "./scenes/Login/login";
import SessionNote from "./scenes/SessionNote97153";
import UpdateSessionNote from "./scenes/updateSessionNote97153";
import ViewSessionnote from "./scenes/viewSessionNote";
import { AuthProvider } from "./scenes/SessionManagement/authProvider";
import PasswordChange from "./scenes/PasswordChange";
import SessionTimeout from "./scenes/SessionTimeOut";
import { useDispatch } from "react-redux";
import { fetchClients } from "./Redux/ActionCreators/ClientActionCreator";
import {
  fetchPermissions,
  fetchStaff,
  fetchRoles,
  fetchBcbaAndAbove,
} from "./Redux/ActionCreators/StaffActionCreator";
import { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import AddGoal from "./Phase2/Goal/AddGoal";
import ManageGoal from "./Phase2/Goal/ManageGoal";
import FolderComponent from "./Phase2/Goal/ManageGoal/FolderComponent";
import AddProgramBook from "./Phase2/ProgramBook/AddProgramBook";
import ManageProgramBookTemplate from "./Phase2/ProgramBookTemplate/ManageProgramBookTemplate";

//=======
import Topbar from "./scenes/global/Topbar";
import SessionNote9156 from "./scenes/sessionNote9156";
import UpdateSessionNote9156 from "./scenes/UpdateSessionNote97156";
import SessionNote97155 from "./scenes/sessionnote97155";
import UpdateSessionNote97155 from "./scenes/UpdateSessionNote97155";
import SessionNote97151 from "./scenes/SessionNote97151";
import SessionNoteH0032 from "./scenes/SessionNoteH0032";
import UpdateSessionNoteH0032 from "./scenes/UpdateSessionNoteH0032";
import UpdateSessionNote97151 from "./scenes/UpdateSessionNote97151";
import AddProgramBookTemplate from "./Phase2/ProgramBookTemplate/AddProgramBookTemplate";
import ManageProgramBook from "./Phase2/ProgramBook/ManageProgramBook";
import UpdateProgramBook from "./Phase2/ProgramBook/UpdateProgramBook";
import UpdateProgramBookTemplate from "./Phase2/ProgramBookTemplate/UpdateProgramBookTemplate";
import CustomizedTreeView from "./Phase2/TreeView/customizedTreeView";

import { SnackbarProvider } from "./components/SnackBarProvider";
import "./App.css";
import Scheduler from "./Phase2/Scheduler";
import GoalsLineGraphs from "./Phase2/GoalLineGraph";
import StaffForm from "./scenes/Add Staff";

import ManagePriorAuthorization from "./Phase2/PriorAuthorization/ManagePriorAuthorization";
import AuthorizationClient from "./Phase2/PriorAuthorization/AuthorizationClient";
import UploadFile from "./Phase2/File/UploadFile";
import ManageFile from "./Phase2/File/ManageFile";
import AddSchedule from "./Phase2/AddSchedule";
import ManageSchedule from "./Phase2/ManageSchedule";

import TypePassword from "./Phase2/TypePassword";

import ChangePassword from "./Phase2/ChangePassword";

import GoogleAddIcon from "./Phase3/Calender/Icon/index";

// import Icon from "./Phase3/Calender/Icon";

import CalenderDates from "./Phase3/Calender/CalenderDates";
import TokenHandler from "./Phase3/TokenHandler";

// >>>>>>> snapshot-1
function MainApp() {
  const location = useLocation();
  console.log("location", location);
  const [isSidebar, setIsSidebar] = useState(true);

  const path = location.pathname.split("/")[1];
  console.log("path", path);
  return (
    <div style={{ display: "flex" }}>
      <SessionTimeout />

      <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
        {location.pathname !== "/" && <Topbar setIsSidebar={setIsSidebar} />}
        <div style={{ flex: 1, overflowY: "auto" }}>
          <AuthProvider>
            <Routes>
              <Route path="/" element={<Login />} />

              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/addClient"
                element={
                  <ProtectedRoute>
                    <ClientForm />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/authorizationClient"
                element={<AuthorizationClient />}
              />

              <Route
                path="/managePriorAuthorization"
                element={<ManagePriorAuthorization />}
              />

              <Route path="/uploadFile" element={<UploadFile />} />

              <Route path="/manageFile" element={<ManageFile />} />

              <Route path="/addSchedule" element={<AddSchedule />} />
              <Route path="/manageSchedule" element={<ManageSchedule />} />
              <Route path="/typePassword" element={<TypePassword />} />
              <Route path="/reset-password" element={<ChangePassword />} />

              {/* <Route path="/icon" element={<Icon />} /> */}
              <Route path="/calenderDates" element={<CalenderDates />} />
              <Route path="/googleaddIcon" element={<GoogleAddIcon />} />

              <Route
                path="/addFacility"
                element={
                  <ProtectedRoute>
                    <FacilityForm mode="add" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/UpdateFacility"
                element={
                  <ProtectedRoute>
                    <FacilityForm mode="update" />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/addStaff"
                element={
                  <ProtectedRoute>
                    <StaffForm mode="add" />
                  </ProtectedRoute>
                }
              />

              <Route path="/viewClient" element={<ViewClient />} />

              <Route
                path="/viewFacility"
                element={
                  <ProtectedRoute>
                    <FacilityInfo />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/viewStaff"
                element={
                  <ProtectedRoute>
                    <Contacts />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/updateGoal"
                element={
                  <ProtectedRoute>
                    <AddGoal mode="update" />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/manageStaff"
                element={
                  <ProtectedRoute>
                    <ManageStaff />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/manageFacility"
                element={
                  <ProtectedRoute>
                    <ManageFacility />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/manageClient"
                element={
                  <ProtectedRoute>
                    <ManageClient />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/updateClient"
                element={
                  <ProtectedRoute>
                    <ClientForm mode="update" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/updateStaff"
                element={
                  <ProtectedRoute>
                    <StaffForm mode="update" />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/calendar"
                element={
                  <ProtectedRoute>
                    <CalenderDates />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/sessionNote"
                element={
                  <ProtectedRoute path="/sessionNote">
                    <SessionNote />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/updateSessionNote97153"
                element={
                  <ProtectedRoute>
                    <UpdateSessionNote />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/sessionNote97156"
                element={
                  <ProtectedRoute>
                    <SessionNote9156 />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/sessionNote97155"
                element={
                  <ProtectedRoute>
                    <SessionNote97155 />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/sessionNote97151"
                element={
                  <ProtectedRoute>
                    <SessionNote97151 />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/sessionNoteH0032"
                element={
                  <ProtectedRoute>
                    <SessionNoteH0032 />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/updateSessionNote97156"
                element={
                  <ProtectedRoute>
                    <UpdateSessionNote9156 />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/updateSessionNote97155"
                element={
                  <ProtectedRoute>
                    <UpdateSessionNote97155 />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/updateSessionNoteH0032"
                element={
                  <ProtectedRoute>
                    <UpdateSessionNoteH0032 />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/updateSessionNote97151"
                element={
                  <ProtectedRoute>
                    <UpdateSessionNote97151 />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/viewSessionNotes"
                element={
                  <ProtectedRoute>
                    <ViewSessionnote />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/passwordChange"
                element={
                  <ProtectedRoute>
                    <PasswordChange />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/addGoal"
                element={
                  <ProtectedRoute>
                    <AddGoal mode="add" />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/manageGoal"
                element={
                  <ProtectedRoute>
                    <ManageGoal />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path={`${path}/folder/:folderId`}
                element={
                  <ProtectedRoute>
                    <FolderComponent />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manageProgramBookTemplate"
                element={
                  <ProtectedRoute>
                    <ManageProgramBookTemplate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/addProgramBookTemplate"
                element={
                  <ProtectedRoute>
                    <AddProgramBookTemplate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/updateProgramBookTemplate"
                element={
                  <ProtectedRoute>
                    <UpdateProgramBookTemplate />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/addProgramBook"
                element={
                  <ProtectedRoute>
                    <AddProgramBook />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manageProgramBook"
                element={
                  <ProtectedRoute>
                    <ManageProgramBook />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/UpdateprogramBook"
                element={
                  <ProtectedRoute>
                    <UpdateProgramBook />
                  </ProtectedRoute>
                }
              />
              <Route
                path="*"
                element={
                  <ProtectedRoute>
                    <h1>Not Found</h1>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/tree"
                element={
                  // <ProtectedRoute>
                  <CustomizedTreeView />
                  // </ProtectedRoute>
                }
              />
              <Route
                path="/scheduler"
                element={
                  // <ProtectedRoute>
                  <Scheduler />
                  // </ProtectedRoute>
                }
              />
              <Route
                path="/chooseSessionNote"
                element={
                  <ProtectedRoute>
                    <ChooseSessionNotePage />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/lineGraph"
                element={
                  <ProtectedRoute>
                    <GoalsLineGraphs />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </AuthProvider>
        </div>
      </Box>
    </div>
  );
}

function App() {
  const [theme, colorMode] = useMode();
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  ChartJS.defaults.animation = {
    duration: 2000, // Duration of the animation in milliseconds
    easing: "easeOutBounce", // Easing function to use
  };
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch permissions once when the app initializes,
    // commented as it is not working in the current setup
    dispatch(fetchBcbaAndAbove());
    dispatch(fetchPermissions());
    dispatch(fetchStaff());
    dispatch(fetchClients());
    dispatch(fetchRoles());
  }, [dispatch]);
  return (
    <SnackbarProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />

            <Router>
              <TokenHandler>
                {" "}
                <MainApp />
              </TokenHandler>
            </Router>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </LocalizationProvider>
    </SnackbarProvider>
  );
}

export default App;
