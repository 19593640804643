import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom"; // If you're using react-router

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SessionTimeout = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate(); // If you're using react-router for navigation

  // Function to clear the session and redirect to login
  const clearSessionAndRedirect = () => {
    localStorage.clear();
    navigate("/"); // Direct to the root which is the login route
  };

  const handleOpen = () => {
    localStorage.clear();
    setOpen(true);
  };
  const handleClose = () => {
    localStorage.clear();
    setOpen(false);
    clearSessionAndRedirect();
  };

  const resetTimer = () => {
    if (open) {
      // If the modal is open, close it
      handleClose();
    }
  };

  useEffect(() => {
    // Set a timer for 30 minutes
    let timer = setTimeout(() => {
      handleOpen();
    }, 1800000); // 30 minutes in milliseconds

    // Event listeners for user activity
    const events = ["click", "load", "keydown"];
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    // Reset the timer on user activity
    const resetTimeout = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        handleOpen();
      }, 1800000); // Reset the timer to 30 minutes
    };

    // Add resetTimeout as the callback for user activity
    events.forEach((event) => {
      window.addEventListener(event, resetTimeout);
    });

    // Clear the timer and event listeners on component unmount
    return () => {
      clearTimeout(timer);
      events.forEach((event) => {
        window.removeEventListener(event, resetTimeout);
      });
    };
  }, []);

  // Handle the case where the user refreshes the page

  return (
    <div>
      {/* Session timeout modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="session-timeout-title"
        aria-describedby="session-timeout-description">
        <Box sx={style}>
          <Typography id="session-timeout-title" variant="h6" component="h2">
            Session Timeout
          </Typography>
          <Typography id="session-timeout-description" sx={{ mt: 2 }}>
            Your session has expired due to inactivity. Please log in again.
          </Typography>
          <Box textAlign="center" mt={4}>
            <Button variant="contained" onClick={handleClose}>
              Okay
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SessionTimeout;
