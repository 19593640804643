import React, { useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Button,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddProgramBookGoalHandler from "../AddProgramBookGoal";

import { themeStyles, buttonStyle } from "../../../constants";
import StyledTextArea from "../../../components/StyledTextArea";
// ShortTermGoal Component
const ShortTermGoal = ({
  iid,
  onRemove,
  toggleVisibility,
  visible,
  type,
  updateShortTermGoal,
  InitialShortTermGoalName,
  InitialShortTermGoalDescription,
  InitialProgramBookGoals,
}) => {
  console.log(
    "ShortTermGoal -> InitialProgramBookGoals",
    InitialProgramBookGoals
  );
  console.log(
    "ShortTermGoal -> InitialShortTermGoalName",
    InitialShortTermGoalName
  );
  console.log(
    "ShortTermGoal -> InitialShortTermGoalDescription",
    InitialShortTermGoalDescription
  );
  console.log("ShortTermGoal -> visible", visible);
  const [programBookGoals, setProgramBookGoals] = useState(
    InitialProgramBookGoals
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [shortTermGoalName, setShortTermGoalName] = useState(
    InitialShortTermGoalName
  );
  const [shortTermGoalDescription, setShortTermGoalDescription] = useState(
    InitialShortTermGoalDescription
  );
  const handleGoalNameChange = (event) => {
    setShortTermGoalName(event.target.value);
    updateShortTermGoal(
      iid,
      event.target.value,
      shortTermGoalDescription,
      programBookGoals
    );
  };

  const handleGoalDescriptionChange = (event) => {
    setShortTermGoalDescription(event.target.value);
    updateShortTermGoal(
      iid,
      shortTermGoalName,
      event.target.value,
      programBookGoals
    );
  };
  const handleGoalChange = (res) => {
    // setGoal(event.target.value);
    updateShortTermGoal(iid, shortTermGoalName, shortTermGoalDescription, res);
  };
  return (
    <Box marginLeft={2}>
      <Box display="flex" alignItems="end">
        <IconButton onClick={() => toggleVisibility(iid)} size="large">
          {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
        <IconButton onClick={() => onRemove(iid)} size="large">
          <DeleteOutlineIcon />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="start">
        {visible && (
          <Stack spacing={2} flexGrow={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5.8}>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Treatment Plan Objective Name"
                  name={`treatmentplanObjectiveName-${iid}`}
                  required
                  onChange={handleGoalNameChange}
                  value={shortTermGoalName}
                  sx={{ ...themeStyles.textField }}
                />
              </Grid>
              <Grid item xs={12} md={6.2}>
                <StyledTextArea
                  fullWidth
                  variant="filled"
                  multiline
                  rows={3}
                  label="Treatment Plan Objective Description"
                  name={`treatmentplanObjectiveDescription-${iid}`}
                  onChange={handleGoalDescriptionChange}
                  required
                  value={shortTermGoalDescription}
                  sx={{ ...themeStyles.textField }}
                />
              </Grid>
            </Grid>
            <AddProgramBookGoalHandler
              programBookGoals={programBookGoals}
              setProgramBookGoals={setProgramBookGoals}
              handleGoalChange={handleGoalChange}
              type={type}
            />
          </Stack>
        )}
      </Box>
    </Box>
  );
};

// ShortTermGoalHandler Component
const ShortTermGoalHandler = ({
  shortTermGoals,
  setShortTermGoals,
  handleShortTermGoalChange,
}) => {
  const addShortTermGoal = () => {
    setShortTermGoals([
      ...shortTermGoals,
      {
        iid: shortTermGoals.length,
        visible: true,
        treatmentplanObjectiveDescription: "",
        treatmentplanObjectiveName: "",
        treatmentplanObjectiveStatus: "Active",

        shortTermObjectiveTemplates: [],
      },
    ]);
  };

  const removeShortTermGoal = (iid) => {
    setShortTermGoals(shortTermGoals.filter((goal) => goal.iid !== iid));
    handleShortTermGoalChange(
      shortTermGoals.filter((goal) => goal.iid !== iid)
    );
  };
  const updateShortTermGoal = (iid, name, description, goals) => {
    console.log("updateShortTermGoal", iid, name, description, goals);
    const res = shortTermGoals.map((goal) => {
      if (goal.iid === iid) {
        return {
          ...goal,
          treatmentplanObjectiveName: name,
          treatmentplanObjectiveDescription: description,

          shortTermObjectiveTemplates: goals,
        };
      }
      return goal;
    });
    setShortTermGoals(res);
    handleShortTermGoalChange(res);
  };

  const toggleVisibility = (iid) => {
    setShortTermGoals(
      shortTermGoals.map((goal) =>
        goal.iid === iid ? { ...goal, visible: !goal.visible } : goal
      )
    );
    handleShortTermGoalChange(
      shortTermGoals.map((goal) =>
        goal.iid === iid ? { ...goal, visible: !goal.visible } : goal
      )
    );
  };

  return (
    <Box>
      <Button
        startIcon={<AddCircleOutlineIcon />}
        variant="contained"
        color="primary"
        onClick={addShortTermGoal}
        sx={{ ...buttonStyle, marginBottom: "25px" }}>
        Treatment Plan Objective
      </Button>
      {shortTermGoals?.map((goal, index) => (
        <ShortTermGoal
          key={index}
          iid={goal.iid}
          onRemove={removeShortTermGoal}
          toggleVisibility={toggleVisibility}
          InitialProgramBookGoals={goal.shortTermObjectiveTemplates}
          InitialShortTermGoalName={goal.treatmentplanObjectiveName}
          InitialShortTermGoalDescription={
            goal.treatmentplanObjectiveDescription
          }
          visible={goal.visible}
          updateShortTermGoal={updateShortTermGoal}
        />
      ))}
    </Box>
  );
};

export default ShortTermGoalHandler;
