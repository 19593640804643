import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  IconButton,
  Snackbar,
  Grid,
  Alert,
  InputAdornment,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Domain from "../Domain";
import Header from "../../../components/Header";
import useSharedLink from "../../../scenes/SharedLink";
import { useMediaQuery } from "@mui/material";
import axiosInstance from "../../../components/AxiosInstance";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { themeStyles, buttonStyle } from "../../../constants";
import StyledTextArea from "../../../components/StyledTextArea";
import ScrollToBottomButton from "../../../components/ScrollToBottom";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import { useSnackbar } from "../../../components/SnackBarProvider";

const AddProgramBookTemplate = () => {
  const sharedLink = useSharedLink();
  const { openSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [domains, setDomains] = useState([]);
  const [status, setStatus] = useState("Active");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [programBookName, setProgramBookName] = useState("");
  const [programBookDescription, setProgramBookDescription] = useState("");
  const descriptionRef = useRef(null);

  const transformData = (inputData) => {
    const processItem = (item) => {
      if (Array.isArray(item)) {
        item.forEach((child) => processItem(child));
      } else if (typeof item === "object" && item !== null) {
        delete item["iid"];
        delete item["visible"];
        delete item["modified"];
        delete item["enteredBy"];
        delete item["lastUpdatedBy"];
        delete item["lastUpdatedTime"];
        delete item["enteredTime"];
        delete item["type"];
        if ("goaltype" in item) {
          if (
            typeof item["goaltype"] === "object" &&
            item["goaltype"] !== null
          ) {
            item["goalAreaName"] = item["goaltype"].goalAreaName;
            item["goalAreaId"] = item["goaltype"].goalAreaId;
          }
          delete item["goaltype"];
        }
        Object.keys(item).forEach((key) => processItem(item[key]));
      }
    };
    processItem(inputData);
    return inputData;
  };

  const transformDataToaddIndex = (inputData) => {
    const processItem = (item, parentKey = null, index = null) => {
      if (Array.isArray(item)) {
        item.forEach((child, i) => {
          processItem(child, parentKey, i);
        });
      } else if (typeof item === "object" && item !== null) {
        item["iid"] = index;
        item["visible"] = true;
        Object.keys(item).forEach((key) => {
          processItem(item[key], key);
        });
      }
    };
    const dataCopy = JSON.parse(JSON.stringify(inputData));
    dataCopy.forEach((item, index) => processItem(item, null, index));
    return dataCopy;
  };

  useEffect(() => {}, []);

  function cleanPayload(data) {
    function cleanObject(obj) {
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (!["id", "visible", "goaltype"].includes(key)) {
          if (Array.isArray(value)) {
            newObj[key] = value.map((item) => cleanObject(item));
          } else if (typeof value === "object" && value !== null) {
            newObj[key] = cleanObject(value);
          } else {
            newObj[key] = value;
          }
        }
        if (key === "goaltype" && typeof value === "object" && value !== null) {
          newObj["goalAreaId"] = value.goalAreaId;
          newObj["goalAreaName"] = value.goalAreaName;
        }
      });
      return newObj;
    }
    return Array.isArray(data)
      ? data.map((item) => cleanObject(item))
      : cleanObject(data);
  }

  const handleCopy = () => {
    if (descriptionRef.current) {
      descriptionRef.current.select();
      document.execCommand("copy");
      setSnackbar({
        open: true,
        message: "Description copied to clipboard",
        severity: "success",
      });
    }
  };

  const handleSubmit = () => {
    const domainsCopy = JSON.parse(JSON.stringify(domains));
    const payload = {
      programBookName: programBookName,
      programBookDescription: programBookDescription,
      dataStrategyTemplates: [...domainsCopy],
      lastUpdatedBy: localStorage.getItem("username"),
      enteredBy: localStorage.getItem("username"),
      status: status,
    };

    const cleanedPayload = transformData(payload);

    const strinifiedpayload = {
      programBookName: programBookName,
      dataStrategyTemplates: JSON.stringify(
        cleanedPayload.dataStrategyTemplates
      ),
      programBookDescription: programBookDescription,
      lastUpdatedBy: localStorage.getItem("username"),
      enteredBy: localStorage.getItem("username"),
      status: "Active",
    };
    console.log(cleanedPayload, "cleaned");
    try {
      axiosInstance 
        .post(sharedLink + "/addprogrambooktemplate", strinifiedpayload)
        .then((response) => {
          if (response.status === 200) {
            setSnackbar({
              open: true,
              message: "Program Book Added Successfully",
              severity: "success",
            });
            setProgramBookName("");
            setProgramBookDescription("");
            setDomains([]);
          } else {
            setSnackbar({
              open: true,
              message: "Failed to Add Program Book Template",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          openSnackbar(error, "error");
        });
    } catch (e) {
      console.log(e);
    }
  };

  const addDomain = () => {
    const newId = domains.length;
    setDomains([
      ...domains,
      {
        iid: newId,
        visible: true,
        strategyName: "",
        strategyDescription: "",
        strategyStatus: "Active",
        developmentalAreaTemplates: [],
      },
    ]);
  };

  const updateDomain = (iid, domainName, domainDescription, longtermgoals) => {
    setDomains(
      domains.map((domain) => {
        if (domain.iid === iid) {
          return {
            ...domain,
            strategyName: domainName,
            strategyDescription: domainDescription,
            strategyStatus: "Active",
            developmentalAreaTemplates: longtermgoals,
          };
        }
        return domain;
      })
    );
  };

  const removeDomain = (iid) => {
    setDomains(domains.filter((domain) => domain.iid !== iid));
  };

  const toggleVisibility = (iid) => {
    setDomains(
      domains.map((domain) => {
        if (domain.iid === iid) {
          return { ...domain, visible: !domain.visible };
        }
        return domain;
      })
    );
  };

  return (
    <>
      <Box m="20px" sx={{ marginBottom: "20px" }}>
        <Header title="Add Program Book Template" subenteredby="" />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="filled"
              label="Program Book Name"
              value={programBookName}
              onChange={(e) => setProgramBookName(e.target.value)}
              name="programBookName"
              required
              sx={{ ...themeStyles.textField }}
              inputProps={{
                maxLength: 120,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextArea
              fullWidth
              variant="filled"
              label="Program Book Description"
              multiline
              value={programBookDescription}
              onChange={(e) => setProgramBookDescription(e.target.value)}
              // rows={3}
              name="programBookDescription"
              maxLength={512}
              sx={{ ...themeStyles.textField }}
              inputRef={descriptionRef}
            />
          </Grid>
        </Grid>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          color="primary"
          onClick={addDomain}
          sx={{ ...buttonStyle, marginTop: "8px", marginBottom: "25px" }}>
          Data Strategy
        </Button>
        {domains.map((domain) => (
          <Domain
            key={domain.iid}
            iid={domain.iid}
            InitialDomainDescription={domain.strategyDescription}
            InitialDomainName={domain.strategyName}
            InitialLongTermGoals={domain.developmentalAreaTemplates}
            onRemove={removeDomain}
            visible={domain.visible}
            toggleVisibility={toggleVisibility}
            updateDomain={updateDomain}
            themeStyles={themeStyles}
            buttonStyle={buttonStyle}
            type="template"
          />
        ))}
      </Box>
      <ScrollToTopButton />
      <ScrollToBottomButton />
      <Box
        display="flex"
        justifyContent="end"
        sx={{
          marginTop: "20px",
        }}>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          sx={{
            ...buttonStyle,
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}>
          Save
        </Button>
      </Box>
    </>
  );
};

export default AddProgramBookTemplate;
