import {
  FETCH_CLIENTS,
  ADD_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  FETCH_CLIENTS_FOR_STAFF,
} from "../../Actions/actionTypes";

const initialState = {
  assignedClients: [],
  allClients: [],
  ownClients: [],
  clientsForStaff: [],
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CLIENTS:
      return {
        ...state,
        assignedClients: action.payload.assignedClients,
        allClients: action.payload.allClients,
        ownClients: action.payload.ownClients,
      };
    case ADD_CLIENT:
      return {
        ...state,
        assignedClients: [...state.assignedClients, action.payload],
        allClients: [...state.allClients, action.payload],
        ownClients: [...state.ownClients, action.payload],
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        assignedClients: state.assignedClients.map((client) =>
          client.id === action.payload.id ? action.payload : client
        ),
        allClients: state.allClients.map((client) =>
          client.id === action.payload.id ? action.payload : client
        ),
        ownClients: state.ownClients.map((client) =>
          client.id === action.payload.id ? action.payload : client
        ),
      };
    case DELETE_CLIENT:
      return {
        ...state,
        assignedClients: state.assignedClients.map((client) =>
          client.id === action.payload
            ? { ...client, status: "Inactive" }
            : client
        ),
        allClients: state.allClients.map((client) =>
          client.id === action.payload
            ? { ...client, status: "Inactive" }
            : client
        ),
        ownClients: state.ownClients.map((client) =>
          client.id === action.payload
            ? { ...client, status: "Inactive" }
            : client
        ),
      };
    case FETCH_CLIENTS_FOR_STAFF:
      return {
        ...state,
        clientsForStaff: [...action.payload],
      };
    default:
      return state;
  }
};

export default clientReducer;
