import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Autocomplete,
  Checkbox,
  FormGroup,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { TimePicker } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { usePermissions } from "../../CheckPermission";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the DeleteIcon
import { useSelector } from "react-redux";
import { useEffect } from "react";
import EventUpdatePopUp from "../../../components/EventUpdateOption";
import {
  addEvent,
  updateEvent,
  deleteEvent,
  fetchEventById,
} from "../../../Redux/ActionCreators/CalenderActionCreator";
import { useSnackbar } from "../../../components/SnackBarProvider";
import { CALENDAR, READ } from "../../../constants";
import { fetchClientsForStaff } from "../../../Redux/ActionCreators/ClientActionCreator";
// Styled Ok button
const OkButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#4285f4",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#357ae8",
  },
}));

// Styled Cancel button
const CancelButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#f44336",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#d32f2f",
  },
}));

const EventPopup = ({
  open,
  onClose,
  handleSave,
  event,
  selectedEvent,
  staffids,
  clientids,
  selectedDate,
  updatePermission,
  deletePermission,
}) => {
  // Dialog form state
  console.log("new event", event);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false); // State to control the EventUpdatePopUp
  const [updateOption, setUpdateOption] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const { giveClients, giveStaff } = usePermissions();
  const parseDate = (dateString) => (dateString ? dayjs(dateString) : null);

  const [eventDate, setEventDate] = useState(dayjs(selectedDate));
  const [staff, setStaff] = useState(
    !selectedEvent
      ? event?.staff
      : {
          firstName: selectedEvent.staffName,
          lastName: "",
          id: selectedEvent.staffId,
        }
  ); // Staff selection state
  const [client, setClient] = useState(null); // Patient selection state
  const [eventTitle, setEventTitle] = useState(""); // Event title
  const [startTime, setStartTime] = useState(dayjs(event?.startTime)); // Start time
  const [endTime, setEndTime] = useState(dayjs(event?.endTime)); // End time
  const [repeatEvent, setRepeatEvent] = useState(false); // State for repeat event checkbox
  const [frequency, setFrequency] = useState(""); // New state for frequency dropdown
  const [repeatInterval, setRepeatInterval] = useState(1); // State for repeat interval
  const [endType, setEndType] = useState("on"); // State for end type
  const [endDate, setEndDate] = useState(null); // State for end date
  const [endAfter, setEndAfter] = useState(1); // State for end after appointments
  const [staffInstruction, setStaffInstruction] = useState("");
  const [clientInstruction, setClientInstruction] = useState("");
  const clients = useSelector((state) => state?.clients?.clientsForStaff) || [];
  const isDateInPast = dayjs(selectedDate).isBefore(dayjs(), "day");
  console.log(clients, "clients in popup");

  useEffect(() => {
    dispatch(
      fetchClientsForStaff(
        selectedEvent ? selectedEvent.staffId : event?.staff?.id
      )
    );
  }, [startTime, endTime, staff]);
  const [daysOfWeek, setDaysOfWeek] = useState({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  });

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    setDaysOfWeek({ ...daysOfWeek, [event.target.name]: event.target.checked });
  };

  const handleResetClick = () => {
    setStaff(null);
    setClient(null);
    setEventTitle("");
    setStartTime(null);
    setEndTime(null);
    setRepeatEvent(false);
    setFrequency("");
    setRepeatInterval(1);
    setEndType("on");
    setEndDate(null);
    setEndAfter(1);
    setStaffInstruction("");
    setClientInstruction("");
    setDaysOfWeek({
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
    });
    // onClose();
  };
  useEffect(() => {
    // if (selectedEvent) {
    //   dispatch(fetchEventById(selectedEvent?.id));
    // }
  }, [selectedEvent]);

  // Update the form fields when the selected event changes
  useEffect(() => {
    if (selectedEvent) {
      setStaff({
        firstName: selectedEvent.staffName,
        lastName: "",
        id: selectedEvent.staffId,
      });
      console.log(staff, "staff");
      setClient({
        firstName: selectedEvent.clientName,
        lastName: "",
        id: selectedEvent.clientId,
      });
      setEventTitle(selectedEvent.title);
      // setEventDate(dayjs(`${selectedEvent.startDate}`));
      setStartTime(dayjs(`${selectedEvent.startTime}`, "hh:mm A"));
      setEndTime(dayjs(`${selectedEvent.endTime}`, "hh:mm A"));
      setRepeatEvent(!!selectedEvent.repetitionRule);
      setFrequency(selectedEvent.repetitionRule?.type?.toLowerCase() || "");
      setRepeatInterval(selectedEvent.repetitionRule?.interval || 1);
      setEndType(selectedEvent.repetitionRule?.neverEnds ? "never" : "on");
      setEndDate(dayjs(selectedEvent.repetitionRule?.endDate || null));

      const preSelectedDays = selectedEvent.repetitionRule?.daysOfWeek || [];
      const dayMapReverse = {
        1: "sunday",
        2: "monday",
        3: "tuesday",
        4: "wednesday",
        5: "thursday",
        6: "friday",
        7: "saturday",
      };
      const updatedDaysOfWeek = {
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      };
      preSelectedDays.forEach((day) => {
        const dayName = dayMapReverse[day];
        if (dayName) {
          updatedDaysOfWeek[dayName] = true;
        }
      });
      setDaysOfWeek(updatedDaysOfWeek);
      setStaffInstruction(selectedEvent.staffInstruction || "");
      setClientInstruction(selectedEvent.clientInstruction || "");
    } else if (event) {
      setStaff(event.staff);
      // setEventDate(event.selectedDate);
      setClient(null);
      setEventTitle("");
      setStartTime(dayjs(event.startTime));
      setEndTime(dayjs(event.endTime));
      setRepeatEvent(false);
      setFrequency("");
      setRepeatInterval(1);
      setEndType("on");
      setEndDate(null);
      setEndAfter(1);
      setStaffInstruction("");
      setClientInstruction("");
      setDaysOfWeek({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      });
    }
  }, [selectedEvent, event]);

  //week days number
  const getDaysOfWeekArray = () => {
    const dayMap = {
      sunday: 1,
      monday: 2,
      tuesday: 3,
      wednesday: 4,
      thursday: 5,
      friday: 6,
      saturday: 7,
    };

    return Object.keys(daysOfWeek)
      .filter((day) => daysOfWeek[day])
      .map((day) => dayMap[day]);
  };
  const handleUpdateConfirm = (updateOption) => {
    console.log(updateOption);

    if (updateOption === "thisEvent") {
      if (showDeletePopup) handleDeleteClick(true);
      else handleSaveClick(true);
    } else if (updateOption === "thisAndFollowingEvents") {
      if (showDeletePopup) handleDeleteClick(false);
      else handleSaveClick(false);
    }
    setShowDeletePopup(false);
    setShowUpdatePopup(false); // Close the popup after saving
  };
  const handleSaveClick = (updateSingleEvent = null) => {
    // Validate the "Select a client" field
    if (!client) {
      openSnackbar("Please select a client");
      return; // Exit the function early if validation fails
    }

    // Validate the "Event title" field
    if (!eventTitle.trim()) {
      openSnackbar("Please enter an event title");
      return; // Exit the function early if validation fails
    }

    // Check if end time is greater than start time
    if (!endTime || !startTime || endTime.isBefore(startTime)) {
      openSnackbar(
        "End date and time must be greater than start date and time"
      );
      return; // Exit the function early if the validation fails
    }

    // Check if end time is the same as start time
    if (endTime.isSame(startTime)) {
      openSnackbar("End time cannot be the same as start time");
      return; // Exit the function early if the validation fails
    }

    // Validate frequency and end date fields if repeat event is checked
    if (repeatEvent) {
      if (!frequency) {
        openSnackbar("Please select a frequency");
        return;
      }
      if (endType === "on" && !endDate) {
        openSnackbar("Please select an end date");
        return;
      }
    }

    // Generate daysOfWeek array from selected checkboxes
    const selectedDaysOfWeek = getDaysOfWeekArray();
    console.log("Selected days of the week:", selectedDaysOfWeek);

    let eventData = {
      title: eventTitle,
      clientInstruction: clientInstruction, // Assuming this is another field you may want to add
      staffInstruction: staffInstruction,
      startDate: eventDate ? eventDate.format("YYYY-MM-DD") : "", // Format startDate
      endDate: eventDate ? eventDate.format("YYYY-MM-DD") : "", // Format endDate
      startTime: startTime ? startTime.format("hh:mm A") : "", // Format startTime
      endTime: endTime ? endTime.format("hh:mm A") : "", // Format endTime
      staffId: staff?.id,
      clientId: client?.id,

      lastUpdatedBy: localStorage.getItem("username"),
      enteredBy: localStorage.getItem("username"),
    };

    if (repeatEvent) {
      eventData.repetitionRule = {
        type: frequency.toUpperCase(),
        endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : null,
        neverEnds: endType === "never",
      };
      if (eventData.repetitionRule.endDate === "Invalid Date")
        eventData.repetitionRule.endDate = null;
      if (selectedDaysOfWeek.length > 0)
        eventData.repetitionRule.daysOfWeek = selectedDaysOfWeek;
    }

    if (selectedEvent) {
      eventData.id = selectedEvent.id || null;

      // Only access repetitionRule if it exists
      if (selectedEvent.repetitionRule) {
        if (selectedEvent.repetitionRule.id) {
          eventData.repetitionRule.id = selectedEvent.repetitionRule.id;
        } else if (updateSingleEvent) {
          delete eventData.repetitionRule;
        }
      }
      console.log(updateSingleEvent, "update");
      if (
        updateSingleEvent !== true &&
        updateSingleEvent !== false &&
        repeatEvent
      ) {
        setShowUpdatePopup(true);
      } else {
        if (updateSingleEvent == true || updateSingleEvent == null)
          delete eventData["repetitionRule"];
        dispatch(
          updateEvent(
            eventData,
            openSnackbar,
            onClose,
            handleResetClick,
            selectedDate
          )
        );
      }
    } else {
      dispatch(
        addEvent(
          eventData,
          openSnackbar,
          onClose,
          handleResetClick,
          staffids,
          clientids || [],
          selectedDate
        )
      );
    }
  };

  const handleDeleteClick = (singleEvent) => {
    console.log(singleEvent, "single");
    if (
      selectedEvent &&
      (!repeatEvent || singleEvent == true || singleEvent == false)
    ) {
      const confirmed = window.confirm(
        "Are you sure you want to delete this event?"
      );

      if (!confirmed) {
        return; // Exit the function if the user clicks 'Cancel'
      }
      dispatch(
        deleteEvent(
          {
            ...selectedEvent,
            startDate: dayjs(selectedDate).format("YYYY-MM-DD"),
          },
          openSnackbar,
          onClose,
          singleEvent
        )
      );
    } else setShowDeletePopup(true);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Create Event{" "}
        {selectedEvent && deletePermission && !isDateInPast && (
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleDeleteClick}
            style={{ position: "absolute", right: 16, top: 16 }}>
            <DeleteIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        {/* Select staff dropdown */}
        <Autocomplete
          options={giveStaff(CALENDAR, READ) || []} // Use giveStaff for staff options
          getOptionLabel={(option) => option.firstName + " " + option.lastName} // Assuming each option has a 'name' property
          value={staff}
          disabled
          onChange={(event, newValue) => setStaff(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a staff"
              margin="normal"
              fullWidth
            />
          )}
        />
        <TextField
          label="Staff Instruction"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={staffInstruction}
          onChange={(e) => setStaffInstruction(e.target.value)}
        />
        {/* Select patient dropdown */}
        <Autocomplete
          options={clients || []} // Use giveClient for client options
          getOptionLabel={(option) => option.firstName + " " + option.lastName} // Assuming each option has a 'name' property
          value={client}
          onChange={(event, newValue) => setClient(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                <>
                  Select a client <span style={{ color: "red" }}>*</span>
                </>
              }
              margin="normal"
              fullWidth
            />
          )}
        />
        <TextField
          label="Client Instruction"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          value={clientInstruction}
          onChange={(e) => setClientInstruction(e.target.value)}
        />

        {/* Appointment type input */}

        {/* Event title input */}
        <TextField
          label={
            <>
              Event title <span style={{ color: "red" }}>*</span>
            </>
          }
          fullWidth
          // required
          margin="normal"
          value={eventTitle}
          onChange={(e) => setEventTitle(e.target.value)}
        />

        {/* Start time and end time pickers with gap */}
        <Grid container spacing={2} marginTop={1}>
          <Grid item xs={4}>
            <DatePicker
              label="Event Date"
              value={eventDate}
              disabled
              onChange={(newValue) => setEventDate(newValue)}
              renderInput={(params) => (
                <TextField fullWidth margin="normal" {...params} />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TimePicker
              label="Start Time"
              value={startTime}
              onChange={(newValue) => setStartTime(newValue)}
              renderInput={(params) => (
                <TextField fullWidth margin="normal" {...params} />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TimePicker
              label="End Time"
              value={endTime}
              onChange={(newValue) => setEndTime(newValue)}
              renderInput={(params) => (
                <TextField fullWidth margin="normal" {...params} />
              )}
            />
          </Grid>
        </Grid>

        {/* Repeat event checkbox */}
        <FormControlLabel
          control={
            <Checkbox
              checked={repeatEvent}
              onChange={(e) => setRepeatEvent(e.target.checked)}
              name="repeatEvent"
            />
          }
          label="Repeat event"
        />

        {/* Conditionally render frequency and additional fields if 'Repeat event' is checked */}
        {repeatEvent && (
          <>
            {/* Frequency dropdown */}
            <TextField
              select
              label={
                <>
                  Frequency <span style={{ color: "red" }}>*</span>{" "}
                  {/* Add red star */}
                </>
              }
              fullWidth
              margin="normal"
              value={frequency}
              // error={!frequency}  // Show error if frequency is empty
              onChange={(e) => {
                setFrequency(e.target.value);
                if (e.target.value === "weekdays") {
                  setDaysOfWeek({
                    sunday: false,
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: false,
                  });
                } else if (e.target.value === "custom") {
                  setDaysOfWeek({
                    sunday: false,
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                  });
                }
              }}>
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="custom">Custom</MenuItem>
              {/* <MenuItem value="weekdays">
                Every weekday (Monday to Friday)
              </MenuItem> */}
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </TextField>

            {/* Conditionally render additional fields if 'Daily', 'Every weekday (Monday to Friday)', 'custom', 'Monthly', or 'Yearly' is selected */}
            {(frequency === "daily" ||
              frequency === "weekdays" ||
              frequency === "custom" ||
              frequency === "monthly" ||
              frequency === "yearly") && (
              <>
                {(frequency === "weekdays" || frequency === "custom") && (
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={daysOfWeek.sunday}
                          onChange={handleCheckboxChange}
                          name="sunday"
                        />
                      }
                      label="S"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={daysOfWeek.monday}
                          onChange={handleCheckboxChange}
                          name="monday"
                        />
                      }
                      label="M"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={daysOfWeek.tuesday}
                          onChange={handleCheckboxChange}
                          name="tuesday"
                        />
                      }
                      label="T"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={daysOfWeek.wednesday}
                          onChange={handleCheckboxChange}
                          name="wednesday"
                        />
                      }
                      label="W"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={daysOfWeek.thursday}
                          onChange={handleCheckboxChange}
                          name="thursday"
                        />
                      }
                      label="T"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={daysOfWeek.friday}
                          onChange={handleCheckboxChange}
                          name="friday"
                        />
                      }
                      label="F"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={daysOfWeek.saturday}
                          onChange={handleCheckboxChange}
                          name="saturday"
                        />
                      }
                      label="S"
                    />
                  </FormGroup>
                )}
                <Typography>Ends</Typography>
                <RadioGroup
                  value={endType}
                  onChange={(e) => setEndType(e.target.value)}>
                  {/* <FormControlLabel
                    value="never"
                    control={<Radio />}
                    label="Never ends"
                  /> */}
                  <FormControlLabel value="on" control={<Radio />} label="On" />
                  {endType === "on" && (
                    <DatePicker
                      label={
                        <>
                          Ends on <span style={{ color: "red" }}>*</span>{" "}
                          {/* Add red star */}
                        </>
                      }
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      minDate={eventDate} // Prevent selecting an "Ends on" date before the "Event Date"
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          margin="normal"
                          {...params}
                          error={!endDate} // Show error if endDate is empty
                        />
                      )}
                    />
                  )}
                  {/* <FormControlLabel
                    value="after"
                    control={<Radio />}
                    label="After"
                  />
                  {endType === "after" && (
                    <TextField
                      type="number"
                      fullWidth
                      margin="normal"
                      value={endAfter}
                      onChange={(e) => setEndAfter(e.target.value)}
                      InputProps={{
                        endAdornment: <Typography>appointment(s)</Typography>,
                      }}
                    />
                  )} */}
                </RadioGroup>
              </>
            )}
          </>
        )}
      </DialogContent>

      {/* Dialog actions */}
      <DialogActions>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
        <OkButton
          onClick={handleSaveClick}
          disabled={!updatePermission || isDateInPast}>
          Save
        </OkButton>
      </DialogActions>
      {repeatEvent && (showUpdatePopup || showDeletePopup) && (
        <EventUpdatePopUp
          open={showUpdatePopup || showDeletePopup}
          onClose={() => {
            setShowUpdatePopup(false);
            setShowDeletePopup(false);
          }}
          action={showDeletePopup ? "Delete" : "Edit"}
          onConfirm={handleUpdateConfirm}
          onOptionChange={(option) => setUpdateOption(option)}
        />
      )}
    </Dialog>
  );
};

export default EventPopup;
