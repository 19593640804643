import React, { useState, useEffect } from "react";
import axiosInstance from "../../components/AxiosInstance";
import ForbiddenErrorModal from "../../components/ForbiddenErrorModal";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  fetchPermissions,
  fetchStaff,
  fetchRoles,
  fetchBcbaAndAbove,
} from "../../Redux/ActionCreators/StaffActionCreator";

import { fetchClients } from "../../Redux/ActionCreators/ClientActionCreator";
import { API_BASE_URL } from "../../constants";
import axios from "axios";
const TokenHandler = ({ children }) => {
  const [isForbiddenModalOpen, setIsForbiddenModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    // Initialize the axios response interceptor
    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        const username = localStorage.getItem("username");
        const password = localStorage.getItem("ABApassword");
        console.log("user", username);
        if (
          error.response &&
          error.response.status === 403 &&
          username &&
          password
        ) {
          // Open the Forbidden Error Modal if both username and password are in localStorage
          setIsForbiddenModalOpen(true);
        }
        return Promise.reject(error);
      }
    );

    // Cleanup the interceptor when the component unmounts
    return () => {
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  // Modal handlers
  const handleModalCancel = () => {
    setIsForbiddenModalOpen(false);
  };

  const handleModalOk = async () => {
    setIsForbiddenModalOpen(false);
    setIsLoading(true); // Start loading indicator

    const username = localStorage.getItem("username");
    const password = localStorage.getItem("ABApassword"); // You should store this securely or use another method

    if (!username || !password) {
      console.log("Username or password not found in localStorage");
      setIsLoading(false);
      navigate("/login");
      return;
    }

    try {
      const encodedCredentials = btoa(`${password}`);

      const data = {
        username: username,
        password: password,
      };

      const response = await axios.post(
        API_BASE_URL + "/api/authenticate",
        data // Sending the form data
      );

      const token = response.data.jwt;
      localStorage.setItem("ABAtoken", token);
      console.log("Login response received:", response);

      if (response.status === 200) {
        await Promise.all([
          dispatch(fetchPermissions()),
          dispatch(fetchStaff()),
          dispatch(fetchClients()),
          dispatch(fetchRoles()),
          dispatch(fetchBcbaAndAbove()),
        ]);
      } else {
        console.error("Login failed with status:", response.status);
        localStorage.clear();
        navigate("/login");
      }
    } catch (error) {
      console.error("Error during login:", error);
      localStorage.clear();
      navigate("/login");
    } finally {
      setIsLoading(false); // Stop loading indicator
    }
  };

  return (
    <>
      {/* Render the Forbidden Error Modal */}
      <ForbiddenErrorModal
        open={isForbiddenModalOpen}
        handleCancel={handleModalCancel}
        handleOk={handleModalOk}
      />
      {/* Render the children passed to this component */}
      {children}
    </>
  );
};

export default TokenHandler;
