import { Box, Snackbar } from "@mui/material";
import { tokens } from "../../../../theme";
import { useTheme } from "@mui/material";
import axiosInstance from "../../../../components/AxiosInstance";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { useState, useEffect, useRef } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../../components/Header";
import Alert from "@mui/material/Alert";
import { useNavigate, useLocation } from "react-router-dom";
import { Select, MenuItem } from "@mui/material";
import LoadingIndicator from "../../../../scenes/Loader";
import useSharedLink from "../../../../scenes/SharedLink";
import { useSnackbar } from "../../../../components/SnackBarProvider";
import { FormControlLabel, Checkbox } from "@mui/material";
import { ROOT_FOLDER } from "../../../../constants";
const CustomToolbar = ({ moveToOptions, onMoveToChange }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />

      <Select
        onChange={onMoveToChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        renderValue={(selected) => {
          // if (!selected) {
          return <em>Move To</em>;
          // }
          // return selected;
        }}>
        <MenuItem disabled value="">
          <em>Move To</em>
        </MenuItem>
        {moveToOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </GridToolbarContainer>
  );
};
const ViewGoal = ({ parent }) => {
  console.log("parent", parent);
  const theme = useTheme();
  const sharedLink = useSharedLink();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const location = useLocation();
  const [checked, setChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const { openSnackbar } = useSnackbar();
  const handleRowSelection = (newSelectionModel) => {
    setRowSelectionModel(newSelectionModel);

    // Filter out the full row data for the selected rows
    const newSelectedRows = rows.filter((row) =>
      newSelectionModel.includes(row.id)
    );
    setSelectedRows(newSelectedRows);
  };

  const [moveToOptions, setMoveToOptions] = useState([]);
  function extractGoalAreas(data) {
    let result = [];

    function traverse(node) {
      // Check if the node itself has an id and goalBankAreaName to be included
      if (
        node.id &&
        node.goalBankAreaName &&
        parseInt(node.id) !== parseInt(parent) &&
        node.goalBankAreaStatus === "Active" &&
        node.goalBankAreaName !== ROOT_FOLDER
      ) {
        result.push({
          value: node.id,
          label: node.goalBankAreaName,
        });
      }

      // If the node has children, process each one recursively
      if (node.children && node.children.length) {
        node.children.forEach((child) => {
          traverse(child);
        });
      }
    }

    // Start the traversal from the root node
    traverse(data);

    return result;
  }

  useEffect(() => {
    setIsLoading(true);
    console.log("parent", parent);
    axiosInstance
      .post(`${sharedLink}/viewgoals`, {
        goalareaId: parent,
      })
      .then((goalsResponse) => {
        console.log("Goals", goalsResponse);
        if (
          Array.isArray(goalsResponse?.data) &&
          goalsResponse &&
          goalsResponse.status === 200
        ) {
          // Assuming the response contains an array of goals
          setRows(goalsResponse.data);
        } else if (goalsResponse) {
          // openSnackbar(goalsResponse.data, "info");
          console.log("Failed to fetch goals.");
        }
      })
      .catch((error) => {
        setSnackbar({
          open: true,
          message: "Error occurred during API calls.",
          severity: "error",
        });
        console.error("An error occurred during the requests:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
      axiosInstance
      .post(`${sharedLink}/viewgoalbankarea`, { withCredentials: true })
      .then((goalAreaResponse) => {
        console.log("goal area response", goalAreaResponse);
        if (goalAreaResponse && goalAreaResponse.status === 200) {
          // Assuming the response contains an array of goals
          // Assuming `goalAreaResponse.data.data` is the data you've received
          setMoveToOptions(extractGoalAreas(goalAreaResponse.data.data));

          // You can now use moveToOptions as needed
          console.log(moveToOptions);
        } else if (goalAreaResponse) {
          setSnackbar({
            open: true,
            message: "Failed to fetch goal areas.",
            severity: "error",
          });
          console.log("Failed to fetch goal areas.");
        }
      });
  }, [parent]);
  useEffect(() => {
    if (!checked)
      setFilteredRows(rows.filter((row) => row.goalStatus == "Active"));
    else setFilteredRows(rows);
  }, [rows, checked]);
  const handleMoveToChange = (event) => {
    // Handle the move to action here
    if (selectedRows.length < 1) {
      setSnackbar({
        open: true,
        message: "No rows selected.",
        severity: "error",
      });

      return;
    }
    const moveToValue = event.target.value;
    console.log("move to value", moveToValue);
    console.log("rowselectionmodel", rowSelectionModel);
    console.log("selected rows", selectedRows);
    const updatedvalue = selectedRows.map((row) => {
      return {
        ...row,
        id: row.id,
        goalAreaId: moveToValue,
        goalAreaName: moveToOptions.find(
          (option) => option.value === moveToValue
        ).label,
        lastUpdatedBy: localStorage.getItem("username"),
      };
    });
    console.log("updated value", updatedvalue);
    console.log("shared link", `${sharedLink}/updateGoal`);
    console.log("updated value", JSON.stringify(updatedvalue));
    try {
      axiosInstance
        .post(sharedLink + "/updategoal", updatedvalue)
        .then((res) => {
          console.log("response", res);

          if (res.status === 200) {
            console.log("success");

            setRows(rows.filter((row) => !rowSelectionModel.includes(row.id)));
            setSnackbar({
              open: true,
              message: "Goal moved successfully.",
              severity: "success",
            });
          } else {
            setSnackbar({
              open: true,
              message: "Failed to move goal.",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          console.error("An error occurred during the requests:", error);
          setSnackbar({
            open: true,
            message: "Error occurred during API calls.",
            severity: "error",
          });
        });
    } catch (error) {
      console.log(error);
    }
    console.log(moveToValue);
  };
  const handleEditClick = (row) => () => {
    navigate(`/updategoal?id=${row.id}&from=${parent}`);
  };

  const dateTimeFormatter = (value, locale = "en-US") => {
    if (!value) return "";

    const date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() is zero-based
    const day = date.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    const timeFormatter = new Intl.DateTimeFormat(locale, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // true for AM/PM notation
    });

    const formattedTime = timeFormatter.format(date);

    return `${formattedDate} ${formattedTime}`;
  };

  const handleDeleteClick = (id) => () => {
    if (window.confirm("Sure want to delete?")) {
      const idToDelete = { id: id };
      console.log("id to delete", idToDelete);
      try {
        axiosInstance
          .post(sharedLink + "/removegoal", idToDelete)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              console.log("succss");
              setRows((prevRows) =>
                prevRows.map((row) =>
                  row.id === id ? { ...row, goalStatus: "Inactive" } : row
                )
              );
              setSnackbar({
                open: true,
                message: "Goal deleted successfully.",
                severity: "success",
              });
            } else {
              setSnackbar({
                open: true,
                message: "Failed to delete goal.",
                severity: "error",
              });
            }
          })
          .catch((error) => {
            setSnackbar({
              open: true,
              message: "Error occurred during API calls.",
              severity: "error",
            });
            console.error("Error while deleting item:", error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "goalName", headerName: "Goal Name", width: 200 },
    {
      field: "goalStatus",
      headerName: "Goal Status",
      width: 200,
    },
    { field: "programType", headerName: "Program Type", width: 200 },

    {
      field: "lastUpdatedBy",
      headerName: "Last Updated By",
      width: 200,
    },
    {
      field: "lastUpdatedTime",
      headerName: "Last Updated Time",
      width: 200,
      type: "dateTime",
      editable: false,
      valueGetter: ({ row }) =>
        row.lastUpdatedTime
          ? new Date(row.lastUpdatedTime).toISOString()
          : null,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },

    {
      field: "enteredBy",
      headerName: "Entered By",
      width: 100,
      editable: false,
    },

    {
      field: "enteredTime",
      headerName: "Entered Time",
      width: 200,
      type: "dateTime",
      editable: false,
      valueGetter: ({ row }) =>
        row.enteredTime ? new Date(row.enteredTime).toISOString() : null,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (params) => {
        const id = params.row.id;

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            disabled={
              (params.row.sessionStatus === "Approved" &&
                localStorage.getItem("role") === "Staff") ||
              params.row.goalStatus == "Inactive"
            }
            color="inherit"
          />,
        ];
      },
    },
  ];

  if (isloading) {
    return <LoadingIndicator />;
  } else if (rows.length < 1) {
    return <></>;
  } else {
    return (
      <Box m="20px">
        <Box m="20px">
          <Header title="" subtitle="" />
          <Snackbar
            open={snackbar.open}
            autoHideDuration={3000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              severity={snackbar.severity}>
              {snackbar.message}
            </Alert>
          </Snackbar>
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.grey[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#3A57E8",
                borderBottom: "white",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "#3A57E8",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.grey[200]} !important`,
              },
            }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  name="includeInactive"
                />
              }
              label="Show Inactive Goals"
            />
            <DataGridPro
              initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
                columns: {
                  columnVisibilityModel: {
                    // Hide columns status and traderName, the other columns will remain visible
                    id: false,

                    lastUpdatedBy: false,
                    lastUpdatedTime: false,
                    enteredTime: false,
                    enteredBy: false,
                  },
                },
              }}
              checkboxSelection
              onRowSelectionModelChange={handleRowSelection}
              rowSelectionModel={rowSelectionModel}
              components={{
                Toolbar: CustomToolbar,
              }}
              componentsProps={{
                toolbar: {
                  moveToOptions: moveToOptions,
                  onMoveToChange: handleMoveToChange,
                },
              }}
              sx={{
                boxShadow: 5,
                border: 5,
                borderColor: "primary.light",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold !important",
                },
                "& .MuiDataGrid-footerContainer": {
                  color: "white",
                  fontWeight: "bold !important",
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              rows={filteredRows}
              columns={columns}
              onRowDoubleClick={(params) => {
                // Call the handleEditClick function or directly navigate
                handleEditClick(params.row)();
              }}
              // components={{ Toolbar: GridToolbar }}
            />

            {/* <InitialFilters row={row} /> */}
          </Box>
        </Box>
      </Box>
    );
  }
};

export default ViewGoal;
