import {
  FETCH_ROLES,
  FETCH_STAFF,
  FETCH_PERMISSIONS,
  ADD_STAFF,
  UPDATE_STAFF,
  FETCH_SUPERVISOR_BY_ROLE,
  FETCH_BCBA_AND_ABOVE,
} from "../../Actions/actionTypes";
import { API_BASE_URL } from "../../../constants";
import { type } from "@testing-library/user-event/dist/type";
import axiosInstance from "../../../components/AxiosInstance";

// Action Creators
const fetchRolesSuccess = (roles) => ({
  type: FETCH_ROLES,
  payload: roles,
});

const fetchStaffSuccess = (staff) => ({
  type: FETCH_STAFF,
  payload: staff,
});

const fetchPermissionsSuccess = (permissions) => ({
  type: FETCH_PERMISSIONS,
  payload: permissions,
});

const addStaffSuccess = (staff) => ({
  type: ADD_STAFF,
  payload: staff,
});

const updateStaffSuccess = (staff) => ({
  type: UPDATE_STAFF,
  payload: staff,
});

const fetchSupervisorByRoleSuccess = (staff) => ({
  type: FETCH_SUPERVISOR_BY_ROLE,
  payload: staff,
});

const fetchBcbaAndAboveSuccess = (staff) => ({
  type: FETCH_BCBA_AND_ABOVE,
  payload: staff,
});
// Thunk Action Creators for async API calls using axiosInstance
export const fetchRoles = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        API_BASE_URL + "/api/roles/getallroles",
        { withCredentials: true }
      );
      dispatch(fetchRolesSuccess(response.data));
    } catch (error) {
      console.error("Failed to fetch roles:", error);
    }
  };
};

export const fetchStaff = () => {
  return async (dispatch) => {
    try {
      const userId = localStorage.getItem("userId");
      const userRole = localStorage.getItem("role");

      const allStaffRequest = axiosInstance.post(
        API_BASE_URL + "/api/viewallstaff",
        {},
        { withCredentials: true }
      );

      let assignedStaffRequest;
      if (userRole === "admin" && userId === 1) {
        assignedStaffRequest = axiosInstance.post(
          API_BASE_URL + "/api/viewallstaff",
          {},
          { withCredentials: true }
        );
      } else {
        assignedStaffRequest = axiosInstance.post(
          API_BASE_URL + "/api/getsubordinates",
          { id: userId },
          { withCredentials: true }
        );
      }

      const [allStaffResponse, assignedStaffResponse] = await Promise.all([
        allStaffRequest,
        assignedStaffRequest,
      ]);

      const staff = {
        allStaff: allStaffResponse?.data,
        assignedStaff: assignedStaffResponse?.data,
      };
      console.log("staffs in action creator", staff);
      dispatch(fetchStaffSuccess(staff));
    } catch (error) {
      console.error("Failed to fetch staff:", error);
    }
  };
};

export const fetchPermissions = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        API_BASE_URL + "/api/roles/getrole",
        {
          role: localStorage.getItem("role"),
        },
        { withCredentials: true }
      );
      dispatch(fetchPermissionsSuccess(response?.data?.screenActions));
    } catch (error) {
      console.error("Failed to fetch permissions:", error);
    }
  };
};

export const addOrUpdateStaff = (payload, mode) => {
  return async (dispatch) => {
    try {
      const url = mode === "add" ? "/api/addstaff" : "/api/updatestaff";
      const response = await axiosInstance.post(API_BASE_URL + url, payload, {
        withCredentials: true,
      });
      if (response.status === 200) {
        if (mode === "add") {
          dispatch(addStaffSuccess(response.data));
        } else {
          dispatch(updateStaffSuccess(response.data));
        }
        // dispatch(fetchStaff());
        return null; // Indicating success
      }
    } catch (error) {
      console.error("Error during the request:", error);
      return error.message; // Return error message
    }
  };
};

export const fetchSupervisorByRole = (role) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        API_BASE_URL + "/api/roles/supervisorofrole",
        {
          role,
        },
        { withCredentials: true }
      );
      dispatch(fetchSupervisorByRoleSuccess(response.data));
    } catch (error) {
      console.error("Error fetching higher role staff:", error);
    }
  };
};
export const fetchBcbaAndAbove = () => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        API_BASE_URL + "/api/getbcbaandabovestaff",
        { withCredentials: true }
      );
      dispatch(fetchBcbaAndAboveSuccess(response.data));
    } catch (error) {
      console.error("Error fetching higher role staff:", error);
    }
  };
};
