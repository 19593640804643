import React, { useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Stack,
  useMediaQuery,
  Grid,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LongTermGoalHandler from "../LongTermGoal";
import StyledTextArea from "../../../components/StyledTextArea";

const Domain = ({
  iid,
  onRemove,
  visible,
  toggleVisibility,
  updateDomain,
  InitialDomainName,
  InitialDomainDescription,
  InitialLongTermGoals,
  themeStyles,
  buttonStyle,
  type,
}) => {
  const [longTermGoals, setLongTermGoals] = useState(InitialLongTermGoals);
  const [domainName, setDomainName] = useState(InitialDomainName);
  const [domainDescription, setDomainDescription] = useState(
    InitialDomainDescription
  );

  const handleDomainNameChange = (event) => {
    setDomainName(event.target.value);
    updateDomain(iid, event.target.value, domainDescription, longTermGoals);
  };

  const handleDomainDescriptionChange = (event) => {
    setDomainDescription(event.target.value);
    updateDomain(iid, domainName, event.target.value, longTermGoals);
  };
  const handleLongtermGoalChange = (res) => {
    updateDomain(iid, domainName, domainDescription, res);
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Box margin={2}>
      <Box display="flex" alignItems="end">
        <IconButton onClick={() => toggleVisibility(iid)} size="large">
          {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
        <IconButton onClick={() => onRemove(iid)} size="large">
          <DeleteOutlineIcon />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="start">
        <Stack spacing={2} flexGrow={1}>
          {visible && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5.8}>
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Strategy Name"
                    onChange={handleDomainNameChange}
                    name={`devlopmentalAreaName-${iid}`}
                    value={domainName}
                    required
                    sx={{
                      ...themeStyles.textField,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6.2}>
                  <StyledTextArea
                    fullWidth
                    variant="filled"
                    multiline
                    rows={3}
                    label="Strategy Description"
                    onChange={handleDomainDescriptionChange}
                    name={`devlopmentalAreaDescription-${iid}`}
                    value={domainDescription}
                    required
                    sx={{ ...themeStyles.textField }}
                  />
                </Grid>
              </Grid>
              <LongTermGoalHandler
                longTermGoals={longTermGoals}
                setLongTermGoals={setLongTermGoals}
                handleLongtermGoalChange={handleLongtermGoalChange}
                type={type}
              />
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default Domain;
