import {
  FETCH_STAFF,
  ADD_STAFF,
  UPDATE_STAFF,
  FETCH_SUPERVISOR_BY_ROLE,
  DELETE_STAFF,
  FETCH_BCBA_AND_ABOVE,
} from "../../Actions/actionTypes";

const initialState = {
  assignedStaff: [],
  supervisors: [],
  allStaff: [],
  bcbaAndAbove: [],
};

const staffReducer = (state = initialState, action) => {
  console.log(action.payload, " staff reducer");
  switch (action.type) {
    case FETCH_STAFF:
      return {
        ...state,
        allStaff: action.payload.allStaff,
        assignedStaff: action.payload.assignedStaff,
      };
    case ADD_STAFF:
      return {
        ...state,
        allStaff: [...state.allStaff, action.payload],
      };
    case UPDATE_STAFF:
      const updateStaffList = (list, updatedStaff) => {
        return list.map((staff) =>
          staff.id === updatedStaff.id ? updatedStaff : staff
        );
      };
      return {
        ...state,
        allStaff: updateStaffList(state.allStaff, action.payload),
        supervisors: updateStaffList(state.supervisors, action.payload),
      };
    case FETCH_SUPERVISOR_BY_ROLE:
      return {
        ...state,
        supervisors: action.payload,
      };
    case DELETE_STAFF:
      const markStaffAsInactive = (list, staffId) => {
        return list.map((staff) =>
          staff.id === staffId ? { ...staff, status: "Inactive" } : staff
        );
      };
      return {
        ...state,
        allStaff: markStaffAsInactive(state.allStaff, action.payload),
        supervisors: markStaffAsInactive(state.supervisors, action.payload),
        assignedStaff: markStaffAsInactive(state.assignedStaff, action.payload),
      };
    case FETCH_BCBA_AND_ABOVE:
      return {
        ...state,
        bcbaAndAbove: action.payload,
      };
    default:
      return state;
  }
};

export default staffReducer;
