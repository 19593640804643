import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import axiosInstance from "../../components/AxiosInstance";
import { useNavigate } from "react-router-dom";
import useSharedLink from "../../scenes/SharedLink";
import { themeStyles } from "../../constants";

const ManageSchedule = () => {
  const sharedLink = useSharedLink();
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]); // State to keep all rows
  const [showInactive, setShowInactive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchClientSchedules();
  }, []);

  useEffect(() => {
    if (showInactive) {
      setRows(allRows);
    } else {
      setRows(allRows.filter((row) => row.status === "Active"));
    }
  }, [showInactive, allRows]);

  const fetchClientSchedules = () => {
    axiosInstance
      .post(sharedLink + "/getallclientschedules", {})
      .then((response) => {
        setAllRows(response.data);
        setRows(response.data.filter((row) => row.status === "Active"));
      })
      .catch((error) => {
        console.error("Error fetching client schedules:", error);
        setErrorMessage(
          "Error fetching client schedules: " + error.message
        );
      });
  };

  const handleEdit = (row) => {
    navigate("/addSchedule", { state: { isEdit: true, id: row.id } });
  };

  const handleDelete = (id) => {
    axiosInstance
      .post(sharedLink + "/deactivateclientschedule", { id })
      .then((response) => {
        console.log(response);
        fetchClientSchedules(); // Refresh the list after deletion
      })
      .catch((error) => {
        console.error("Error deleting schedule:", error);
        setErrorMessage("Error deleting schedule: " + error.message);
      });
  };

  const handleShowInactiveChange = (event) => {
    setShowInactive(event.target.checked);
  };

  const columns = [
    { field: "clientName", headerName: "Client Name", width: 200 },
    { field: "insuranceName", headerName: "Insurance Name", width: 200 },
    { field: "clientDateOfBirth", headerName: "Date of Birth", width: 130 },
    { field: "policyNumber", headerName: "Policy Number", width: 200 },
    { field: "status", headerName: "Status", width: 200 },

    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.id)} color="error">
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ width: "80%", mt: 2, ml: "auto", mr: "auto" }}>
      <Typography
        variant="h4"
        sx={{
          mb: 10,
          textAlign: "center",
          fontWeight: "bold",
          fontSize: 35,
          ...themeStyles.textField,
        }}
      >
        Manage Client Schedule
      </Typography>
      {errorMessage && (
        <Typography
          variant="body1"
          sx={{ color: "red", textAlign: "center" }}
        >
          {errorMessage}
        </Typography>
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={showInactive}
            onChange={handleShowInactiveChange}
          />
        }
        label="Show Inactive Schedule"
        sx={{ mb: 2 }}
      />
      <Box sx={{ height: "calc(90vh - 270px)", width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pageSize={5}
          checkboxSelection
          onRowDoubleClick={(params) => handleEdit(params.row)}
          className="animated-input"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#3A57E8",
              color: "white",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "nowrap",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-virtualScroller": {
              overflow: "auto",
            },
            "& .MuiDataGrid-footerContainer": {
              display: "none",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
          backgroundColor: "#3A57E8",
          color: "white",
          borderTop: "1px solid #fff",
        }}
      >
        <Typography variant="subtitle1">
          Total Rows: {rows.length}
        </Typography>
      </Box>
    </Box>
  );
};

export default ManageSchedule;
