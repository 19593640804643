import {
  FETCH_EVENTS,
  ADD_EVENT,
  UPDATE_EVENT,
  EVENT_FAILURE,
  DELETE_EVENT,
  FETCH_EVENT_BY_ID,
} from "../../Actions/actionTypes";
import { API_BASE_URL } from "../../../constants";
import dayjs from "dayjs";
import axiosInstance from "../../../components/AxiosInstance";
// Action Creators
const fetchEventsSuccess = (events, staffs) => ({
  type: FETCH_EVENTS,
  payload: {
    staffIds: staffs, // Example staff IDs
    events: events, // Array of events fetched from API
  },
});

const addEventSuccess = (event) => ({
  type: ADD_EVENT,
  payload: event,
});

const updateEventSuccess = (event) => ({
  type: UPDATE_EVENT,
  payload: event,
});

const deleteEventSuccess = (eventId) => ({
  type: DELETE_EVENT,
  payload: eventId,
});

export const fetchEvents = (clients = [], staff = [], selectedDate) => {
  return async (dispatch) => {
    try {
      const payload = {};

      // Only include non-empty client IDs and staff IDs in the payload
      let flag = false;
      if (clients.length > 0) {
        payload.clientids = clients.map((client) => client.id.toString());

        flag = true;
      }
      if (staff.length > 0) {
        payload.staffids = staff.map((staff) => staff.id.toString());

        flag = true;
      }
      if (!flag && localStorage.getItem("userId") !== null) {
        payload.staffids = [localStorage.getItem("userId")];
        console.log("userid", localStorage.getItem("userId"));
      }
      payload.startDate = dayjs(selectedDate).format("YYYY-MM-DD");
      const response = await axiosInstance.post(
        API_BASE_URL + "/api/calendar/getevents",
        payload,
        {
          withCredentials: true,
        }
      );
      let staffids = [];
      if (staff.length === 0 && response.data && Array.isArray(response.data)) {
        const uniqueStaffIds = new Set();
        staffids = response.data
          .filter((event) => {
            if (!uniqueStaffIds.has(event.staffId)) {
              uniqueStaffIds.add(event.staffId);
              return true;
            }
            return false;
          })
          .map((event) => ({
            id: event.staffId,
            firstName: event.staffName,
            lastName: "",
          }));

        dispatch(fetchEventsSuccess(response.data, staffids));
      } else dispatch(fetchEventsSuccess(response.data, staff));
    } catch (error) {
      dispatch({
        type: EVENT_FAILURE,
        payload: {},
      });
      console.error("Failed to fetch events:", error);
    }
  };
};

export const addEvent = (
  event,
  openSnackbar,
  onClose,
  handleResetClick,
  staffids,
  clientids,
  selectedDate
) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.post(
        API_BASE_URL + "/api/calendar/addevent",
        event,
        {
          withCredentials: true,
        }
      );
      const isClientIdValid = clientids.some(
        (client) => client.id === event.clientId
      );
      const isStaffIdValid = staffids.some(
        (staff) => staff.id === event.staffId
      );
      console.log(selectedDate, "  dates ", event.startDate);
      const sselectedDate = dayjs(selectedDate); // Convert if it's a regular date string or object
      const eventStartDate = dayjs(event.startDate);
      console.log(
        "selected date",
        sselectedDate,
        " start date",
        eventStartDate
      );
      const isDateValid = sselectedDate.isSame(eventStartDate, "day");

      console.log(isClientIdValid, "valid client valid staff", isStaffIdValid);
      console.log(staffids, "staffs");
      console.log(event, "event");
      console.log(clientids, "clients");
      console.log(isDateValid, "date validity");
      if ((isClientIdValid || isStaffIdValid) && isDateValid) {
        dispatch(addEventSuccess(response.data[0]));
      }
      openSnackbar("Successfully Added", "success");
      onClose();
      // handleResetClick();
    } catch (error) {
      console.error("Failed to add event:", error);
      openSnackbar(error, "error");
    }
  };
};

export const updateEvent = (
  event,
  openSnackbar,
  onClose,
  handleResetClick,
  selectedDate
) => {
  return async (dispatch) => {
    console.log("reached");
    try {
      const response = await axiosInstance.post(
        API_BASE_URL + "/api/calendar/updateevent ",
        event,
        { withCredentials: true }
      );
      const isDateValid =
        new Date(selectedDate).toDateString() ===
        new Date(event.startDate).toDateString();
      console.log(isDateValid);
      if (isDateValid) dispatch(updateEventSuccess(response.data));
      openSnackbar("Successfully Updated", "success");
      onClose();
      //     handleResetClick();
    } catch (error) {
      console.error("Failed to update event:", error);
      openSnackbar(error, "error");
    }
  };
};

export const deleteEvent = (
  event,
  openSnackbar,
  onClose,
  singleEvent = false
) => {
  console.log("selected for delte", event);
  let payload = { eventReferenceId: "" + event.eventReferenceId };
  if (!singleEvent) {
    payload.includeFutureEvents = "true";
    payload.startDate = event.startDate;
  }

  return async (dispatch) => {
    try {
      await axiosInstance.post(
        API_BASE_URL + `/api/calendar/deleteevent`,
        payload,
        {
          withCredentials: true,
        }
      );
      dispatch(deleteEventSuccess(event?.id));
      openSnackbar("Successfully Deleted", "success");
      onClose();
    } catch (error) {
      console.error("Failed to delete event:", error);
      openSnackbar(error, "error");
    }
  };
};

export const fetchEventById = (eventId) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_EVENT_BY_ID,
      payload: eventId,
    });
  };
};
