import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  TextField,
  IconButton,
  Snackbar,
  Stack,
  Typography,
  Grid,
  Alert,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CommonDialog from "../../../components/CommonDialog";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Domain from "../../UpdateTreeComponent/Level1";
import Header from "../../../components/Header";
import useSharedLink from "../../../scenes/SharedLink";
import { useMediaQuery } from "@mui/material";
import axiosInstance from "../../../components/AxiosInstance";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../../../scenes/Loader";
import { themeStyles, buttonStyle } from "../../../constants";
import StyledTextArea from "../../../components/StyledTextArea";
import ScrollToBottomButton from "../../../components/ScrollToBottom";
import ScrollToTopButton from "../../../components/ScrollToTopButton";

import isEqual from "lodash/isEqual";
import { useSnackbar } from "../../../components/SnackBarProvider";
const UpdateProgramBookTemplate = () => {
  const sharedLink = useSharedLink();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const from = location.state?.from || "Unknown";
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [domains, setDomains] = useState([]);
  const [status, setStatus] = useState();
  const [activeAreas, setActiveAreas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [initialState, setInitialState] = useState(null);
  const { openSnackbar } = useSnackbar();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [programBookName, setProgramBookName] = useState("");
  const [programBookDescription, setProgramBookDescription] = useState("");
  const checkForUnsavedChanges = useCallback(() => {
    const currentState = {
      programBookName,
      programBookDescription,
      domains,
    };
    if (initialState && !isEqual(initialState, currentState)) {
      setUnsavedChanges(true);
    } else {
      setUnsavedChanges(false);
    }
  }, [initialState, programBookName, programBookDescription, domains]);

  useEffect(() => {
    checkForUnsavedChanges();
  }, [
    programBookName,
    programBookDescription,
    domains,
    checkForUnsavedChanges,
  ]);

  const transformData = (inputData) => {
    let isValid = true;

    const isNonEmptyString = (str) =>
      typeof str === "string" && str.trim().length > 0;

    const processItem = (item, path = "") => {
      if (Array.isArray(item)) {
        item.forEach((child, index) => processItem(child, `${path}[${index}]`));
      } else if (typeof item === "object" && item !== null) {
        // Remove unwanted properties
        delete item["iid"];
        delete item["visible"];
        delete item["modified"];
        delete item["enteredBy"];
        delete item["lastUpdatedBy"];
        delete item["lastUpdatedTime"];
        delete item["enteredTime"];
        delete item["type"];
        if ("goaltype" in item) {
          if (
            typeof item["goaltype"] === "object" &&
            item["goaltype"] !== null
          ) {
            item["goalAreaName"] = item["goaltype"].goalAreaName;
            item["goalAreaId"] = item["goaltype"].goalAreaId;
          }
          delete item["goaltype"];
        }

        // Check for empty name or description fields
        if (
          ("strategyName" in item && !isNonEmptyString(item["strategyName"])) ||
          ("strategyDescription" in item &&
            !isNonEmptyString(item["strategyDescription"])) ||
          ("developmentalAreaTemplates" in item &&
            item["developmentalAreaTemplates"].some((devArea, index) => {
              if (
                !isNonEmptyString(devArea.devlopmentalAreaName) ||
                !isNonEmptyString(devArea.devlopmentalAreaDescription)
              ) {
                console.log(
                  `Empty field found in developmentalAreaTemplates[${index}] at path: ${path}`
                );
                return true;
              }
              return false;
            })) ||
          ("treatmentPlanObjectiveTemplates" in item &&
            item["treatmentPlanObjectiveTemplates"].some((treatPlan, index) => {
              if (
                !isNonEmptyString(treatPlan.treatmentplanObjectiveName) ||
                !isNonEmptyString(treatPlan.treatmentplanObjectiveDescription)
              ) {
                console.log(
                  `Empty field found in treatmentPlanObjectiveTemplates[${index}] at path: ${path}`
                );
                return true;
              }
              return false;
            })) ||
          ("shortTermObjectiveTemplates" in item &&
            item["shortTermObjectiveTemplates"].some((shortTerm, index) => {
              if (
                !isNonEmptyString(shortTerm.shortTermObjectiveName) ||
                !isNonEmptyString(shortTerm.shortTermObjectiveDescription)
              ) {
                console.log(
                  `Empty field found in shortTermObjectiveTemplates[${index}] at path: ${path}`
                );
                return true;
              }
              return false;
            }))
        ) {
          console.log(`Empty field found in item at path: ${path}`, item);
          isValid = false;
        }

        // Recursively process each property
        Object.keys(item).forEach((key) =>
          processItem(item[key], `${path}.${key}`)
        );
      }
    };

    processItem(inputData);
    if (!isValid) return null;
    return inputData;
  };

  const transformDataToaddIndex = (inputData) => {
    const processItem = (item, parentKey = null, index = null) => {
      if (Array.isArray(item)) {
        item.forEach((child, i) => {
          processItem(child, parentKey, i); // Pass the current index as 'id' for children
        });
      } else if (typeof item === "object" && item !== null) {
        // For objects inside 'goalTemplates', replace 'id' with 'goalid' (if present)

        item["iid"] = index; // Set 'id' to index if inside an array

        // Add 'visible': true to every object
        item["visible"] = true;

        // Recursively process all properties, skipping 'id' to prevent overwriting
        Object.keys(item).forEach((key) => {
          processItem(item[key], key);
        });
      }
    };

    // Deep copy to avoid mutating original data
    const dataCopy = JSON.parse(JSON.stringify(inputData));
    dataCopy.forEach((item, index) => processItem(item, null, index)); // Initial call without parentKey
    return dataCopy;
  };

  useEffect(() => {
    try {
      axiosInstance
        .post(sharedLink + "/viewprogrambooktemplate", { id: id })
        .then((res) => {
          console.log(res, "response");
          if (res.status == 200) {
            //  console
            if (Array.isArray(res.data)) {
              const transformedData = transformDataToaddIndex(
                res.data[0].dataStrategyTemplates
              );
              setStatus(res.data[0].status);
              setDomains(transformedData);
              setActiveAreas(
                transformedData.filter((val) => val.strategyStatus === "Active")
              );
              console.log("initial domain", transformedData);
              setInitialState({
                programBookName: res.data[0].programBookName,
                programBookDescription: res.data[0].programBookDescription,
                domains: transformedData,
              });
              console.log(transformedData, "transf");
              setProgramBookDescription(res.data[0].programBookDescription);
              setProgramBookName(res.data[0].programBookName);
              console.log("okio name", programBookName);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {}
  }, []);

  const onActivate = () => {
    setStatus("Active");
    handleSubmit(null);
  };

  const onShowInactiveAreasChange = (isChecked) => {
    // console.log(`Show inactive areas for ${iid}: ${isChecked}`);

    console.log(isChecked);
    if (isChecked) {
      setActiveAreas(domains);
    } else {
      setActiveAreas(domains?.filter((val) => val.strategyStatus === "Active"));
    }
  };

  const handleSubmit = (data) => {
    function isNotEvent(data) {
      return !("_reactName" in data || "type" in data || "nativeEvent" in data);
    }
    setLoading(true);
    let domainsCopy;
    console.log(data);
    if (data === undefined || data === null || !isNotEvent(data)) {
      // Handle the case where data is not passed
      // For example, you can assign some default value to domainsCopy
      domainsCopy = JSON.parse(JSON.stringify(domains));
    } else {
      // Handle the case where data is passed
      domainsCopy = JSON.parse(JSON.stringify(data));
    }
    console.log("Domain start", domainsCopy);
    const payload = {
      programBookName: programBookName,
      programBookDescription: programBookDescription,
      dataStrategyTemplates: [...domainsCopy],
      lastUpdatedBy: localStorage.getItem("username"),
      enteredBy: localStorage.getItem("username"),
      status: status,
    };

    console.log("payload ", payload);
    const cleanedPayload = transformData(payload);
    if (cleanedPayload == null) {
      setLoading(false);
      openSnackbar("Please fill all the fields properly with *", "error");
      return;
    }

    const strinifiedpayload = {
      id: id,
      programBookName: programBookName,
      programBookDescription: programBookDescription,
      dataStrategyTemplates: JSON.stringify(
        cleanedPayload.dataStrategyTemplates
      ),
      lastUpdatedBy: localStorage.getItem("username"),
      enteredBy: localStorage.getItem("username"),
      status: "Active",
    };

    console.log("cleanedPayload", { id: id, ...cleanedPayload });

    try {
      axiosInstance
        .post(sharedLink + "/updateprogrambooktemplate", strinifiedpayload)
        .then((res) => {
          console.log(res, "res");
          if (res.status === 200) {
            console.log("Domains", domains);
            if (Array.isArray(res.data)) {
              const transformedData = transformDataToaddIndex(
                res.data[0].dataStrategyTemplates
              );
              setStatus(res.data[0].status);
              setDomains(transformedData);
              setActiveAreas(
                transformedData.filter((val) => val.strategyStatus === "Active")
              );
              console.log("initial domain", transformedData);

              console.log(transformedData, "transf");
              setProgramBookDescription(res.data[0].programBookDescription);
              setProgramBookName(res.data[0].programBookName);
              console.log("okio name", programBookName);
              setInitialState({
                programBookName: res.data[0].programBookName,
                programBookDescription: res.data[0].programBookDescription,
                domains: transformedData,
              });
              setLoading(false);
              setSnackbar({
                open: true,
                message: "Program Book Updated Successfully",
                severity: "success",
              });
            }
          } else {
            setLoading(false);
            openSnackbar("Failed to Update Program Book", "error");
          }
        })
        .catch((error) => {
          setSnackbar({
            open: true,
            message: "Failed to Update Program Book",
            severity: "error",
          });
        })
        .finally(() => {
          // Set loading to false here
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
    return;
  };
  const addDomain = () => {
    if (!(status.toLowerCase() === "active")) {
      alert("Please make the parent area active");
      return;
    }
    const newId = domains.length;

    setDomains([
      ...domains,
      {
        iid: newId,
        visible: true,
        strategyName: "",
        strategyDescription: "",

        strategyStatus: "Active",
        developmentalAreaTemplates: [],
      },
    ]);
    setActiveAreas([
      ...activeAreas,
      {
        iid: newId,
        visible: true,
        strategyName: "",
        strategyDescription: "",

        strategyStatus: "Active",
        developmentalAreaTemplates: [],
      },
    ]);
  };

  const updateDomain = (iid, domainName, domainDescription, longtermgoals) => {
    console.log("updating in top", longtermgoals);
    setDomains(
      domains.map((domain) => {
        if (domain.iid === iid) {
          return {
            ...domain,
            strategyName: domainName,
            strategyDescription: domainDescription,

            developmentalAreaTemplates: longtermgoals,
          };
        }
        return domain;
      })
    );
    setActiveAreas(
      activeAreas.map((domain) => {
        if (domain.iid === iid) {
          return {
            ...domain,
            strategyName: domainName,
            strategyDescription: domainDescription,

            developmentalAreaTemplates: longtermgoals,
          };
        }
        return domain;
      })
    );
  };

  const removeDomain = (iid) => {
    // Remove the domain with the given iid from both domains and activeAreas
    const updatedDomains = domains.filter((domain) => domain.iid !== iid);
    const updatedActiveAreas = activeAreas.filter((area) => area.iid !== iid);
    console.log(updateDomain, "updated");
    // Update the state
    setDomains(updatedDomains);
    setActiveAreas(updatedActiveAreas);
  };
  const updateStatus = (iid, status, data) => {
    console.log("data at top", data);
    const res = domains.map((domain) => {
      if (domain.iid === iid) {
        return {
          ...domain,
          strategyStatus: status,
          developmentalAreaTemplates: data,
        };
      }
      return domain;
    });
    console.log("at top", res);
    setDomains(res);
    const active = activeAreas.map((a) => {
      if (a.iid === iid) {
        return {
          ...a,
          strategyStatus: status,
          developmentalAreaTemplates: data,
        };
      }
      return a;
    });
    setActiveAreas(active);
  };

  const toggleVisibility = (iid) => {
    console.log("id", iid);
    console.log("Previous domains", domains);
    let updatedDomains = activeAreas.map((domain) => {
      if (domain.iid === iid) {
        console.log("Toggling visibility for:", domain);
        return { ...domain, visible: !domain.visible };
      }
      return domain;
    });

    console.log("Updated domains", updatedDomains);
    setActiveAreas(updatedDomains);
    updatedDomains = domains.map((domain) => {
      if (domain.iid === iid) {
        console.log("Toggling visibility for:", domain);
        return { ...domain, visible: !domain.visible };
      }
      return domain;
    });
    setDomains(updatedDomains);
  };
  const handleGoBack = () => {
    if (unsavedChanges) {
      setOpenDialog(true);
    } else {
      navigate("/manageProgramBookTemplate");
    }
  };

  const handleDialogClose = (confirm) => {
    setOpenDialog(false);
    if (confirm) {
      navigate("/manageProgramBookTemplate");
    }
  };
  if (loading) {
    return <LoadingIndicator />;
  } else
    return (
      <>
        <Box m="20px">
          {id ? (
            <Header title="Manage Program Book Template" subenteredby="" />
          ) : (
            <Header title="Add Program Book Template" subenteredby="" />
          )}

          {status && (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="20px">
              <Button
                variant="contained"
                color="primary"
                onClick={onActivate}
                sx={{ borderRadius: 50, ...buttonStyle }}
                disabled={status === "Active"}>
                Activate
              </Button>
            </Box>
          )}
          <Snackbar
            open={snackbar.open}
            autoHideDuration={3000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}>
            <Alert
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              severity={snackbar.severity}>
              {snackbar.message}
            </Alert>
          </Snackbar>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                variant="filled"
                label="Program Book Name"
                value={programBookName}
                onChange={(e) => setProgramBookName(e.target.value)}
                name="programBookName"
                required
                inputProps={{
                  maxLength: 120,
                }}
                sx={{ ...themeStyles.textField }}
                disabled={id ? status !== "Active" : false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledTextArea
                fullWidth
                variant="filled"
                label="Program Book Description"
                multiline
                value={programBookDescription}
                onChange={(e) => setProgramBookDescription(e.target.value)}
                rows={3}
                name="programBookDescription"
                inputProps={{
                  maxLength: 512,
                }}
                disabled={id ? status !== "Active" : false}
                sx={{ ...themeStyles.textField }}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="space-between">
            <Button
              startIcon={<AddCircleOutlineIcon />}
              variant="contained"
              color="primary"
              onClick={addDomain}
              sx={{ ...buttonStyle }}>
              Data Strategy
            </Button>

            <FormControlLabel
              control={
                <Checkbox
                  onChange={(event) =>
                    onShowInactiveAreasChange(event.target.checked)
                  }
                  name="showInactiveAreas"
                  color="primary"
                />
              }
              label={
                <Typography variant="body1" sx={{ marginRight: 3.3 }}>
                  Show Inactive
                </Typography>
              }
              style={{ marginBottom: 0, marginRight: 4 }} // This style might be unnecessary with flex alignment
            />
          </Box>
          <Box key={activeAreas}>
            {activeAreas.map(
              (domain) => (
                console.log("domain", status, " visible", domain.visible),
                (
                  <Domain
                    parentStatus={status === "Active"}
                    status={domain.strategyStatus}
                    key={domain}
                    iid={domain.iid}
                    id={domain.id ? domain.id : null}
                    InitialDomainDescription={domain.strategyDescription}
                    InitialDomainName={domain.strategyName}
                    InitialLongTermGoals={domain.developmentalAreaTemplates}
                    onRemove={removeDomain}
                    visible={domain.visible}
                    toggleVisibility={toggleVisibility}
                    updateDomain={updateDomain}
                    updateStatus={updateStatus}
                    themeStyles={themeStyles}
                    buttonStyle={buttonStyle}
                    type="template"
                  />
                )
              )
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="end"
          sx={{
            marginTop: "20px",

            bottom: 0,
            right: 0,
            p: 2,
          }}>
          {id && (
            <Button
              onClick={() => {
                handleGoBack();
              }}
              color="secondary"
              variant="contained"
              sx={{
                ...buttonStyle,
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}>
              Go Back
            </Button>
          )}
          <Button
            onClick={handleSubmit}
            color="secondary"
            variant="contained"
            disabled={id ? status !== "Active" : false}
            sx={{
              ...buttonStyle,
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}>
            Save
          </Button>
        </Box>
        <ScrollToTopButton />
        <ScrollToBottomButton />
        <CommonDialog
          open={openDialog}
          heading="Unsaved Changes"
          message="There are unsaved changes, are you sure you want to go back?"
          onClose={handleDialogClose}
          onConfirm={() => handleDialogClose(true)}
        />
      </>
    );
};

export default UpdateProgramBookTemplate;
