import React, { useEffect } from "react";
import { Breadcrumb, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const BreadCrum = ({ currentFolder }) => {
  console.log("currentFolder", currentFolder);
  const navigate = useNavigate();
  const [patharray, setPathArray] = useState([]);

  useEffect(() => {
    console.log("currentFolder", currentFolder);
    const path = currentFolder.data.path
      .split("/")
      .filter((item) => item !== "");

    console.log("path", path);
    setPathArray(
      path.map((item) => {
        return {
          name: item.split(":")[0],
          id: item.split(":")[1],
        };
      })
    );
    console.log("patharray", patharray);
  }, [currentFolder]);
  return (
    <Breadcrumb>
      {currentFolder && patharray.length > 0 ? (
        <>
          {patharray.map((folder) => (
            <Breadcrumb.Item
              key={folder.id}
              linkAs={Button}
              linkProps={{
                variant: "white",
                className: "text-primary",
              }}
              onClick={() => navigate(`/manageGoal/folder/${folder.id}`)}
            >
              {folder.name}
            </Breadcrumb.Item>
          ))}
          <Breadcrumb.Item as={Button} disabled variant="white" active>
            {currentFolder.data.name}
          </Breadcrumb.Item>
        </>
      ) : (
        <>
          <Breadcrumb.Item as={Button} variant="white" disabled active>
            {currentFolder.data.name}
          </Breadcrumb.Item>
        </>
      )}
    </Breadcrumb>
  );
};

export default BreadCrum;
