import React, { useState } from "react";
import { TextField, Button, Box, Typography, Paper } from "@mui/material";
import axiosInstance from "../../components/AxiosInstance";
import useSharedLink from "../../scenes/SharedLink";
import {useSnackbar} from "../../components/SnackBarProvider"; // Import useSnackbar hook



const TypePassword = () => {
  const [email, setEmail] = useState("");
  const { openSnackbar } = useSnackbar(); // Use the useSnackbar hook

  const sharedLink = useSharedLink();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleGoClick = () => {
    axiosInstance
      .post(`${sharedLink}/reset-password`, { email })
      .then((response) => {
        openSnackbar("Password reset email sent successfully!", "success"); // Show success snackbar on successful request
      })
      .catch((error) => {
        console.error("Error resetting password:", error);
        openSnackbar("Error resetting password: " + error.message, "error"); // Show error snackbar on failure
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
        backgroundColor: "#f0f4f8",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          width: "500px",
          borderRadius: "10px",
          backgroundColor: "#87CEFA",
          height: "350px",
        }}
      >
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          align="center"
          marginBottom={5}
          sx={{ fontWeight: "bold" }}
        >
          Forgot Password
        </Typography>

        <Box component="form" noValidate autoComplete="off">
          <Typography
            variant="h5"
            gutterBottom
            sx={{ letterSpacing: "0.35em" }}
          >
            Email
          </Typography>

          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
            InputProps={{
              style: {
                backgroundColor: "#ffffff",
                borderRadius: "5px",
              },
            }}
            sx={{ marginBottom: 5 }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleGoClick}
            sx={{
              backgroundColor: "#007BFF",
              fontWeight: "bold",
              fontSize: "15px",
              height: "40px",
              "&:hover": {
                backgroundColor: "#0056b3",
              },
            }}
          >
            GO
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default TypePassword;
