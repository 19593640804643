import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  Alert,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import axiosInstance from "../../../components/AxiosInstance";
import { useNavigate } from "react-router-dom";
import useSharedLink from "../../../scenes/SharedLink";

const ManagePriorAuthorization = () => {
  const sharedLink = useSharedLink();
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]); // State to keep all rows
  const [showInactive, setShowInactive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [alertDanger, setAlertDanger] = useState(false);
  const [alertContentDanger, setAlertContentDanger] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchPriorAuthorizations();
  }, []);

  useEffect(() => {
    if (showInactive) {
      setRows(allRows);
    } else {
      setRows(allRows.filter((row) => row.status === "Active"));
    }
  }, [showInactive, allRows]);

  const fetchPriorAuthorizations = () => {
    axiosInstance
      .post(sharedLink + "/getallpriorauthorizations", {})
      .then((response) => {
        setAllRows(response.data);
        setRows(response.data.filter((row) => row.status === "Active"));
      })
      .catch((error) => {
        console.error("Error fetching prior authorizations:", error);
        setErrorMessage(
          "Error fetching prior authorizations: " + error.message
        );
      });
  };

  const handleEdit = (row) => {
    navigate("/authorizationClient", { state: { isEdit: true, id: row.id } });
  };

  const handleDelete = (row) => {
    // Confirmation before delete
    if (window.confirm("Sure want to delete?")) {
      const id = row.id;
      axiosInstance
        .post(sharedLink + "/deactivatepriorauthorization", { id })
        .then((response) => {
          console.log(response);
          // Update row status to Inactive
          setRows((prevRows) =>
            prevRows.map((r) => (r.id === id ? { ...r, status: "Inactive" } : r))
          );
          //comment it if you want to show the inactive row on deletion before reloading and without checkbox
           setAllRows((prevRows) =>
             prevRows.map((r) => (r.id === id ? { ...r, status: "Inactive" } : r))
           );
        })
        .catch((error) => {
          console.error("Error deleting authorization:", error);
          setErrorMessage("Error deleting authorization: " + error.message);
        });
    }
  };

  const handleShowInactiveChange = (event) => {
    setShowInactive(event.target.checked);
  };

  const handleClose = () => {
    setAlertDanger(false);
  };

  const columns = [
    { field: "clientName", headerName: "Client Name", width: 200 },
    { field: "clientDateOfBirth", headerName: "Date of Birth", width: 130 },
    { field: "insuranceName", headerName: "Insurance Name", width: 200 },
    { field: "policyNumber", headerName: "Policy Number", width: 200 },
    { field: "groupName", headerName: "Group Name", width: 200 },
    { field: "oonRate", headerName: "Out-of-network-rate", width: 200 },
    {
      field: "oonRateType",
      headerName: "Out-of-network-rate-Type",
      width: 250,
    },
    { field: "status", headerName: "Status", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(params.row)}
            sx={{ color: "#000000" }} // Set color to black
            disabled={params.row.status === "Inactive"} // Disable delete button if status is Inactive
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ width: "80%", mt: 2, ml: "auto", mr: "auto" }}>
      <Typography
        variant="h4"
        sx={{ mb: 10, textAlign: "center", fontWeight: "bold", fontSize: 35 }}
      >
        Manage Prior Authorization
      </Typography>
      {errorMessage && (
        <Typography variant="body1" sx={{ color: "red", textAlign: "center" }}>
          {errorMessage}
        </Typography>
      )}
      {alertDanger && (
        <Alert severity="error" onClose={handleClose}>
          {alertContentDanger}
        </Alert>
      )}
      <FormControlLabel
        control={
          <Checkbox
            checked={showInactive}
            onChange={handleShowInactiveChange}
          />
        }
        label="Show Inactive Authorization"
        sx={{ mb: 2 }}
      />
      <Box sx={{ height: "calc(90vh - 270px)", width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pageSize={5}
          checkboxSelection
          onRowDoubleClick={(params) => handleEdit(params.row)}
          className="animated-input"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#3A57E8",
              color: "white",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "nowrap",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-virtualScroller": {
              overflow: "auto",
            },
            "& .MuiDataGrid-footerContainer": {
              display: "none",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
          backgroundColor: "#3A57E8",
          color: "white",
          borderTop: "1px solid #fff",
        }}
      >
        <Typography variant="subtitle1">Total Rows: {rows.length}</Typography>
      </Box>
    </Box>
  );
};

export default ManagePriorAuthorization;
