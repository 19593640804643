const useSharedLink = () => {
  // Quality ABA Production Server
  const sharedLink = "https://lucidnotes.azurewebsites.net/api"; // Client's shared link
  
  // Lucid Dev Server
  //Another links in constant, login and topbar files
  // const sharedLink = "https://lucidsoftgen.azurewebsites.net/api"; // Your shared link
  
  return sharedLink;
};

export default useSharedLink;
