import React, { useState, useRef } from "react";
import {
  Box,
  styled,
  useTheme,
  IconButton,
  InputAdornment,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "./SnackBarProvider";
const Container = styled(Box)(({ theme }) => ({
  position: "relative",
  gridColumn: "span 2",
  marginBottom: "16px",
  outline: "none",
}));

const Label = styled("label")(({ theme, isFocused, hasValue }) => ({
  position: "absolute",
  top: isFocused || hasValue ? "40px" : "20%",
  left: "14px",
  transform:
    isFocused || hasValue
      ? "translateY(-150%) scale(0.75)"
      : "translateY(-50%)",
  transformOrigin: "top left",
  fontSize: isFocused ? "12px" : "14px",
  color: isFocused ? theme.palette.primary.main : "#000",
  fontWeight: "normal",
  backgroundColor: isFocused ? "transparent" : "#fff",
  padding: "0 4px",
  transition: "all 0.2s",
  zIndex: 1,
  pointerEvents: "none",
  outline: "none",
}));

const Textarea = styled("textarea")(({ theme }) => ({
  width: "100%",
  padding: "27px 14px 10px",
  borderRadius: "5px",
  border: "1px solid #c4c4c4",
  fontFamily: "inherit",
  fontSize: "16px",
  transition: "border-color 0.2s, box-shadow 0.2s, background-color 0.2s",
  resize: "vertical",
  backgroundColor: "#fff",
  boxShadow: "0px 3px 6px #00000029", // Added drop shadow
  "&:focus": {
    borderColor: theme.palette.primary.main,
    boxShadow: `0px 3px 6px ${theme.palette.primary.main}33`,
    outline: "none",
    backgroundColor: "transparent",
  },
  "&:hover": {
    borderColor: theme.palette.primary.main,
    backgroundColor: "#fff",
    outline: "none",
  },
  "&::placeholder": {
    color: "transparent",
  },
}));

const HelperText = styled("div")(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: "5px",
}));

const StyledTextArea = ({
  value,
  onChange,
  onBlur,
  error,
  helperText,
  name,
  label,
  maxLength,
  disabled,
}) => {
  const { openSnackbar } = useSnackbar();

  const [isFocused, setIsFocused] = useState(false);
  const theme = useTheme();
  const textareaRef = useRef(null);

  const handleCopy = () => {
    if (textareaRef.current) {
      textareaRef.current.select();
      document.execCommand("copy");
      // Display a snackbar or alert to inform the user that the text has been copied
      openSnackbar("Text copied to clipboard", "info");
    }
  };

  return (
    <Container theme={theme}>
      <Label theme={theme} hasValue={value}>
        {label}
      </Label>
      <Textarea
        theme={theme}
        name={name}
        rows={2}
        value={value}
        onChange={onChange}
        onBlur={(e) => {
          setIsFocused(false);
          onBlur && onBlur(e);
        }}
        onFocus={() => setIsFocused(true)}
        maxLength={maxLength}
        ref={textareaRef}
        disabled={disabled ? disabled : false}
      />
      {error && <HelperText theme={theme}>{helperText}</HelperText>}
      <IconButton
        onClick={handleCopy}
        sx={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          zIndex: 2,
        }}>
        <ContentCopyIcon />
      </IconButton>
    </Container>
  );
};

export default StyledTextArea;
