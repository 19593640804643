import React, { createContext, useState, useContext } from 'react';

const CheckboxContext = createContext();

export const CheckboxProvider = ({ children }) => {
  const [showInactive, setShowInactive] = useState(false);

  return (
    <CheckboxContext.Provider value={{ showInactive, setShowInactive }}>
      {children}
    </CheckboxContext.Provider>
  );
};

export const useCheckbox = () => useContext(CheckboxContext);