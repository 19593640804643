export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("username", action.payload.username);
      localStorage.setItem("role", action.payload.role);
    case "LOGOUT":
      localStorage.clear();
    default:
      return state;
  }
};
