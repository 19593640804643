// src/components/ForbiddenErrorModal.jsx
import React from "react";
import { Modal, Box, Typography, Button } from "@mui/material";

const ForbiddenErrorModal = ({ open, handleCancel, handleOk }) => (
  <Modal open={open}>
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        borderRadius: "8px",
        boxShadow: 24,
        p: 4,
        textAlign: "center",
      }}>
      <Typography variant="h6" gutterBottom>
        Timed Out
      </Typography>
      <Typography variant="body1" gutterBottom>
        Do you wish to continue with the current session ?
      </Typography>
      <Typography variant="body2" gutterBottom>
        Please choose an action .
      </Typography>
      <Box display="flex" justifyContent="center" mt={3}>
        <Button
          onClick={handleCancel}
          variant="outlined"
          sx={{ marginRight: 2, color: "black", borderColor: "black" }}>
          Cancel
        </Button>
        <Button
          onClick={handleOk}
          variant="contained"
          sx={{ backgroundColor: "#222", color: "#fff" }}>
          Continue
        </Button>
      </Box>
    </Box>
  </Modal>
);

export default ForbiddenErrorModal;
