// ProtectedRoutes.js
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./authContext";
import { useLocation } from "react-router-dom";
const ProtectedRoute = ({ children }) => {
  const username = localStorage.getItem("username");
  console.log("oi hoi", children);
  const location = useLocation();
  const path = location.pathname;
  if (username === undefined || username === null) {
    // Redirect to the login page if not authenticated
    return <Navigate to="/" />;
  }
  // if (username === "admin") {
  return children;
  // }

  if (
    path === "/sessionNote" ||
    path === "/viewSessionNotes" ||
    path == "/updateSessionNote97153" ||
    path == "/chooseSessionNote" ||
    path == "/dashboard" ||
    path == "/passwordChange" ||
    path == "/calendar" ||
    path == "/addProgramBook" ||
    path == "/addProgramBookTemplate" ||
    path == "/manageProgramBook" ||
    path == "/manageProgramBookTemplate" ||
    path == "/addGoal" ||
    path == "/manageGoal" ||
    path == "/updategoal" ||
    path == "/updateGoal" ||
    path.match(/\/folder\/[^/]+$/)
  ) {
    return children;
  } else {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

export default ProtectedRoute;
