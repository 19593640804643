import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useCheckbox } from "./InactiveCheckbox";

const ToggleCheckbox = () => {
  const { showInactive, setShowInactive } = useCheckbox();

  const role = localStorage.getItem("role");

  // Check if the role is either 'admin' or 'executive director'
  const isAuthorized = role === "admin" || role === "Executive Director";

  const handleChange = (event) => {
    setShowInactive(event.target.checked);
  };

  return (
    isAuthorized && (
      <FormControlLabel
        control={
          <Checkbox
            checked={showInactive}
            onChange={handleChange}
            name="showInactive"
            color="primary"
          />
        }
        label="Include Inactive"
      />
    )
  );
};

export default ToggleCheckbox;
