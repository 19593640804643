import { Box, FormControlLabel, Checkbox } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { useTheme } from "@mui/material";
import axiosInstance from "../../components/AxiosInstance";
import { useState, useEffect } from "react";
import LoadingIndicator from "../Loader";
import useSharedLink from "../SharedLink";
import { Alert } from "@mui/material";
const ViewClient = () => {
  const theme = useTheme();
  const sharedLink = useSharedLink();
  const colors = tokens(theme.palette.mode);
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertDanger, setAlertDanger] = useState(false);
  const [alertContentDanger, setAlertContentDanger] = useState("");

  const [allusers, setAllusers] = useState([]);

  const [includeInactive, setIncludeInactive] = useState(false);
  const handleClose = () => {
    setAlertDanger(false);
  };
  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };

  // Helper function to format the time into "HH:MM AM/PM" (if needed)
  const dateTimeFormatter = (value, locale = "en-US") => {
    if (!value) return "";

    const date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() is zero-based
    const day = date.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    const timeFormatter = new Intl.DateTimeFormat(locale, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // true for AM/PM notation
    });

    const formattedTime = timeFormatter.format(date);

    return `${formattedDate} ${formattedTime}`;
  };

  useEffect(() => {
    async function fetchData(retryDelay = 2000, retryCount = 0) {
      const maxRetries = 5; // Define the maximum number of retries

      try {
        const res = await axiosInstance.post(sharedLink + "/viewallclient", {withCredentials: true});
        console.log(res.data);
        const formattedRows = res.data.map((row) => {
          const combinedAssignedUsers =
            Array.isArray(row.assignedUserName) &&
            Array.isArray(row.assignedUser)
              ? row.assignedUserName
                  .map((name, index) => {
                    // Check if the corresponding userId exists to avoid undefined values
                    const userId = row.assignedUser[index] || "unknown";
                    return `${name} (${userId})`;
                  })
                  .join(", ")
              : ""; // You can customize this fallback message as needed

          return {
            ...row,
            facilityName: `${row.facilityName} (${row.facilityId})`,
            assignedUser: combinedAssignedUsers,
          };
        });
        const activeUsers = formattedRows.filter(
          (row) => row.status === "Active"
        );
        setRow(activeUsers);
        setAllusers(formattedRows);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);

        if (retryCount < maxRetries) {
          console.log(
            `Retrying in ${retryDelay / 1000} seconds... (${
              retryCount + 1
            }/${maxRetries})`
          );
          setTimeout(() => fetchData(retryDelay, retryCount + 1), retryDelay);
        } else {
          console.error("Max retries reached. Aborting fetch.");
          setAlertContentDanger(
            "Failed to fetch data after multiple attempts."
          );
          setAlertDanger(true);
          setLoading(false);
        }
      }
    }

    fetchData();
  }, []);
  const handleCheckboxChange = (event) => {
    setIncludeInactive(event.target.checked);
    if (event.target.checked) {
      setRow(allusers);
    } else {
      setRow(allusers.filter((row) => row.status === "Active"));
    }
  };
  const columns = [
    {
      field: "id",
      headerName: "Client ID",
      type: "number",
      hideable: "true",
      width: 150,
    },

    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      hide: false,
    },
    {
      field: "dateOfBirth",
      headerName: "Date of Birth",
      type: "date",
      valueGetter: ({ row }) =>
        row.dateOfBirth ? new Date(row.dateOfBirth) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      field: "country",
      headerName: "Country",
      width: 150,
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
    },
    {
      field: "zipCode",
      headerName: "Zip Code",
      width: 150,
    },
    {
      field: "city",
      headerName: "City",
      width: 150,
    },

    {
      field: "streetAddress",
      headerName: "Street Address",
      width: 150,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 150,
    },
    {
      field: "lastUpdatedBy",
      headerName: "Last Updated By",
      width: 150,
    },
    {
      field: "lastUpdatedTime",
      headerName: "Last Updated Time",
      width: 200,
      type: "dateTime",
      editable: false,
      valueGetter: ({ row }) =>
        row.lastUpdatedTime
          ? new Date(row.lastUpdatedTime).toISOString()
          : null,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },

    {
      field: "enteredBy",
      headerName: "Entered By",
      width: 100,
      editable: false,
    },

    {
      field: "enteredTime",
      headerName: "Entered Time",
      width: 200,
      type: "dateTime",
      editable: false,
      valueGetter: ({ row }) =>
        row.enteredTime ? new Date(row.enteredTime).toISOString() : null,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },

    {
      field: "assignedUser",
      width: 200,
      headerName: "Assigned User ID",
      hideable: true,
    },
    {
      field: "assignedUserName",
      width: 200,
      headerName: "Assigned User Name",
      hideable: true,
    },
    {
      field: "dateOfAdmission",
      headerName: "Date of Admission",

      valueGetter: ({ row }) =>
        row.dateOfAdmission ? new Date(row.dateOfAdmission) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },

    {
      field: "dateOfDischarge",
      headerName: "Date of Discharge",

      valueGetter: ({ row }) =>
        row.dateOfDischarge ? new Date(row.dateOfDischarge) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },

    {
      field: "diagnosisCode",
      headerName: "Diagnosis Code",
    },
    {
      field: "initialComment",
      headerName: "Initial Comment",
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 200,
    },
    {
      field: "policyEffectiveDate",
      headerName: "Policy Effective Date",
      width: 200,
      valueGetter: ({ row }) =>
        row.policyEffectiveDate ? new Date(row.policyEffectiveDate) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },

    {
      field: "insuranceName",
      headerName: "Insurance Name",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
    },

    {
      field: "groupName",
      headerName: "Group Name",
    },

    {
      field: "maritalStatus",
      headerName: "Marital Status",
    },
    {
      field: "facilityName",
      headerName: "Facility Name",
      width: 200,
    },

    {
      field: "facilityId",
      headerName: "Facility ID",
    },
  ];

  function isValidDate(dateString) {
    const date = new Date(dateString);
    return !isNaN(date);
  }

  // Common date formatter for the grid
  // function dateFormatter({ value }) {
  //   if (value instanceof Date) {
  //     return value.toISOString().slice(0, 10); // Format as "yyyy-mm-dd"
  //   }
  //   return ""; // Return empty string for null or invalid values
  // }

  if (loading) return <LoadingIndicator />;
  else {
    return (
      <div  className="staff-background">
      <Box m="20px">
        {alertDanger && (
          <Alert severity="error" onClose={handleClose}>
            {alertContentDanger}
          </Alert>
        )}
        <Header title="View Client" subtitle="" />
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
               "& .name-column--cell": {
              color: colors.grey[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor:"#3A57E8",
              borderBottom: "white",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#3A57E8",
            },
            "& .MuiCheckbox-root": {
              color: `${colors.grey[200]} !important`,
            },
          }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={includeInactive}
                onChange={handleCheckboxChange}
                name="includeInactive"
              />
            }
            label="Include Inactive Clients"
          />

          <DataGridPro
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                  facilityId: false,
                  lastUpdatedBy: false,
                  lastUpdatedTime: false,
                  assignedUser: false,
                  assignedUserName: false,
                  enteredTime: false,
                  enteredBy: false,
                },
              },
            }}
            sx={{
              boxShadow: 5,
              border: 5,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
               "& .MuiDataGrid-columnHeaderTitle": {
                color:"white",
                fontWeight: "bold !important",
              },
               "& .MuiDataGrid-footerContainer": {
                color:"white",
                fontWeight: "bold !important",
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            rows={row}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
          {/* <InitialFilters row={row} /> */}
        </Box>
      </Box>
      </div>
    );
  }
};

export default ViewClient;
