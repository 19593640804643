import { Box, FormControlLabel } from "@mui/material";
import {
  DataGridPro,
  GridToolbar,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import { tokens } from "../../theme";
import {
  useTheme,
  Autocomplete,
  TextField,
  Button,
  Alert,
  Checkbox,
} from "@mui/material";
import axiosInstance from "../../components/AxiosInstance";
import { useState, useEffect, useRef } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import LoadingIndicator from "../Loader";
import useSharedLink from "../SharedLink";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import {
  themeStyles,
  buttonStyle,
  SESSION_NOTE_97151,
  SESSION_NOTE_97155,
  SESSION_NOTE_97156,
  SESSION_NOTE_H0032,
  SESSION_NOTE_97153,
  SESSION_NOTE_PAGE,
  DELETE,
  READ,
} from "../../constants";
import { useSnackbar } from "../../components/SnackBarProvider";
import { usePermissions } from "../../Phase3/CheckPermission";
import Delete from "@mui/icons-material/Delete";
import ToggleCheckbox from "../../components/ToggleCheckbox";

const ViewSessionnote = () => {
  const { checkPermission, giveClients, giveStaff } = usePermissions();
  const clients = giveClients(SESSION_NOTE_97153, READ);
  const staffs = giveStaff(SESSION_NOTE_97153, READ);
  console.log(clients, " ", staffs, "viewses");
  const [selectedClient, setSelectedClient] = useState(null);
  const { openSnackbar } = useSnackbar();
  const theme = useTheme();
  const sharedLink = useSharedLink();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();
  const [rows, setRows] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const [alertDanger, setAlertDanger] = useState(false);
  const [alertContentDanger, setAlertContentDanger] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertInfo, setAlertInfo] = useState(false);
  const [alertContentInfo, setAlertContentInfo] = useState("");
  const isSubmittingRef = useRef(false);
  const [includeInactive, setIncludeInactive] = useState(false);
  const [filteredStaffs, setFilteredStaffs] = useState([]);
  const [allStaffs, setAllStaffs] = useState([]);

  const previousPageValues = location.state?.previouspagevalues || {};

  const satus = ["Pending Review", "Draft", "Approved"];
  const procedureCodes = [];

  if (checkPermission(SESSION_NOTE_97151, "r")) procedureCodes.push("97151");
  if (checkPermission(SESSION_NOTE_97155, "r")) procedureCodes.push("97155");
  if (checkPermission(SESSION_NOTE_97153, "r")) procedureCodes.push("97153");
  if (checkPermission(SESSION_NOTE_97156, "r")) procedureCodes.push("97156");
  if (checkPermission(SESSION_NOTE_H0032, "r")) procedureCodes.push("H0032");

  const [initialValues, setInitialValues] = useState({
    staffid: "",
    clientid: "",
    sessionstartdate: null,
    sessionenddate: null,
    sessionstatus: "",
    procedurecode: "",
  });
  useEffect(() => {
    if (previousPageValues.formValues) {
      setInitialValues({
        ...previousPageValues.formValues,
        sessionenddate: previousPageValues.formValues.sessionenddate
          ? dayjs(previousPageValues.formValues.sessionenddate)
          : null,
        sessionstartdate: previousPageValues.formValues.sessionstartdate
          ? dayjs(previousPageValues.formValues.sessionstartdate)
          : null,
      });
    }
    if (previousPageValues.rows) {
      setRows(previousPageValues?.rows);
    }
  }, [previousPageValues]);
  useEffect(() => {
    console.log("view", previousPageValues);
    async function fetchAllData() {
      if (previousPageValues.formValues) {
        try {
          setFilteredStaffs(previousPageValues.rows || []); // Ensure rows is defined
        } catch (err) {
          console.log(err);
        }

        setIsLoading(false);
        return;
      }
    }

    fetchAllData();
  }, [previousPageValues]);

  const handleCheckboxChange = (event) => {
    setIncludeInactive(event.target.checked);
    if (event.target.checked) {
      setFilteredStaffs(allStaffs);
    } else {
      const activeStaffs = allStaffs.filter(
        (staff) => staff.status === "Active"
      );
      setFilteredStaffs(activeStaffs);
    }
  };

  const handleDownloadClick = (row) => async () => {
    const id = row.sessionId;

    try {
      const res = await axiosInstance.post(
        sharedLink + "/generatepdf",
        { id, procedurecode: row.procedureCode },
        { responseType: "blob" },
        { withCredentials: true }
      );
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "download.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setAlertContent("Downloaded.");
        setAlert(true);
      } else {
        handleDownloadError();
      }
    } catch (error) {
      handleDownloadError();
    }

    function handleDownloadError() {
      setAlertContentDanger("Failed to download.");
      setAlertDanger(true);
    }
  };

  const handleEditClick = (row) => () => {
    console.log("row", row);
    const stateToSave = {
      formValues: {
        ...initialValues,
        sessionstartdate: initialValues.sessionstartdate
          ? dayjs(initialValues.sessionstartdate).format("YYYY-MM-DD")
          : null,
        sessionenddate: initialValues.sessionenddate
          ? dayjs(initialValues.sessionenddate).format("YYYY-MM-DD")
          : null,
      },
      rows: rows,
      staffs: staffs,
      clients: clients,
    };
    navigate(
      `/updateSessionNote${row.procedureCode}?sessionId=${row.sessionId} `,
      {
        state: {
          previouspagevalues: stateToSave,
          from: "viewSessionNotes",
          owner:
            row.staffId == localStorage.getItem("userId") ? "Self" : "Other",
        },
      }
    );
  };

  const handleDeleteClick = (row) => () => {
    if (window.confirm("Sure want to delete?")) {
      const id = row.sessionId;
      const idToDelete = { id: id, procedurecode: row.procedureCode };
      axiosInstance
        .post(sharedLink + "/removesessionnote", idToDelete)
        .then((res) => {
          if (res.status === 200) {
            setRows((prevRows) =>
              prevRows.filter((row) => row.sessionId !== id)
            );
            setAlert(true);
            setAlertContent("Information deleted.");
          } else {
            setAlertContentDanger("Failed to delete.");
            setAlertDanger(true);
          }
        })
        .catch((error) => {
          setAlertContentDanger("Failed to delete.");
          setAlertDanger(true);
        });
    }
  };

  const handleFormSubmit = (values) => {
    setInitialValues(values);
    if (!values.procedurecode || values.procedurecode === "") {
      openSnackbar("Please select a procedure code", "error");

      return;
    }

    if (!values.sessionstatus || values.sessionstatus === "") {
      openSnackbar("Please select Session Status", "error");

      return;
    }
    if (!values.clientid && !values.staffid) {
      openSnackbar("Please select either staff or client", "error");
      return;
    }

    const formatsessionstartdate = values.sessionstartdate
      ? dayjs(values.sessionstartdate).format("YYYY-MM-DD")
      : null;
    const formatsessionenddate = values.sessionenddate
      ? dayjs(values.sessionenddate).format("YYYY-MM-DD")
      : null;

    const startDate = dayjs(formatsessionstartdate);
    const endDate = dayjs(formatsessionenddate);
    const differenceInDays = endDate.diff(startDate, "day");

    const today = endDate.toDate();
    const sevenDaysAgo = new Date(today.setDate(today.getDate() - 7));
    const sevenDaysAgoDayjs = dayjs(sevenDaysAgo);

    if (differenceInDays < 0 || differenceInDays > 8) {
      setAlertContentDanger(
        "The difference between start and end date must not exceed 7 days."
      );
      setAlertDanger(true);
      return;
    }

    const payload = {
      ...values,
      sessionstartdate: formatsessionstartdate,
      sessionenddate: formatsessionenddate,
      isfullsessiondetails: false,
    };

    setAlert(false);
    setAlertDanger(false);
    setAlertInfo(true);
    setAlertContentInfo("Please wait while we are fetching data");

    async function sendRequest(payload) {
      try {
        const res = await axiosInstance.post(
          sharedLink + "/viewasessionnote",
          payload,
          { withCredentials: true }
          // console.log("res", res)
        );
        if (res.status === 200) {
          setAlertDanger(false);
          setAlert(false);
          setAlertInfo(false);
          if (Array.isArray(res.data) && res.data.length > 0) {
            setRows(res.data);
            setAlertInfo(false);
          } else {
            setRows([]);
            setAlertContentInfo("No data found.");
            setAlertInfo(true);
          }
        } else {
          setRows([]);
          setAlertInfo(false);
          setAlert(false);
          setAlertContentDanger(
            "An error has occurred, Please contact Support."
          );
          setAlertDanger(true);
        }
      } catch (error) {
        setAlertInfo(false);
        setAlert(false);
        setAlertContentDanger("An error has occurred, Please contact Support.");
        setAlertDanger(true);
      } finally {
        setIsLoading(false);
      }
    }

    sendRequest(payload);
  };

  const handleClose = () => setAlert(false);
  const handle1Close = () => setAlertDanger(false);

  const columns = [
    { field: "sessionId", headerName: "ID", hide: true },
    { field: "clientFirstName", headerName: "Client First Name", width: 100 },
    { field: "clientLastName", headerName: "Client Last Name", width: 200 },
    { field: "staffFirstName", headerName: "Staff First Name", width: 200 },
    { field: "staffLastName", headerName: "Staff Last Name", width: 200 },
    {
      field: "supervisorFirstName",
      headerName: "Supervisor First Name",
      width: 200,
    },
    {
      field: "supervisorLastName",
      headerName: "Supervisor Last Name",
      width: 200,
    },
    { field: "procedureCode", headerName: "Procedure Code", width: 200 },
    {
      field: "sessionDate",
      headerName: "Session Date",
      width: 150,
      type: "date",
      valueGetter: ({ row }) =>
        row.sessionDate ? new Date(row.sessionDate) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },
    { field: "sessionStatus", headerName: "Session Status", width: 200 },
    {
      field: "lastUpdatedBy",
      headerName: "Last Updated By",
      width: 200,
      hide: true,
    },
    {
      field: "lastUpdatedTime",
      headerName: "Last Updated Time",
      width: 200,
      type: "dateTime",
      hide: true,
      valueGetter: ({ row }) =>
        row.lastUpdatedTime
          ? new Date(row.lastUpdatedTime).toISOString()
          : null,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },
    { field: "enteredBy", headerName: "Entered By", width: 100, hide: true },
    {
      field: "enteredTime",
      headerName: "Entered Time",
      width: 200,
      type: "dateTime",
      hide: true,
      valueGetter: ({ row }) =>
        row.enteredTime ? new Date(row.enteredTime).toISOString() : null,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (params) => {
        const id = params.row.sessionId;

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={handleDeleteClick(params.row)}
            disabled={
              !checkPermission(
                "sessionNote" + params.row.procedureCode,
                DELETE,
                params.row.staffId == localStorage.getItem("userId")
                  ? "Self"
                  : "Other"
              )
            }
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DownloadIcon />}
            label="Download"
            onClick={handleDownloadClick(params.row)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  if (isLoading) {
    return <LoadingIndicator />;
  } else {
    return (
      <div className="staff-background">
        <Box m="20px">
          {alertDanger && (
            <Alert severity="error" onClose={handle1Close}>
              {alertContentDanger}
            </Alert>
          )}
          {alert && (
            <Alert severity="success" onClose={handleClose}>
              {alertContent}
            </Alert>
          )}
          {alertInfo && (
            <Alert
              severity="info"
              onClose={() => {
                setAlertInfo(false);
                setAlertContentInfo("");
              }}>
              {alertContentInfo}
            </Alert>
          )}
          <Header title="Session Note" subtitle="" />
          <br />
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            enableReinitialize>
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <ToggleCheckbox />
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}>
                  <Autocomplete
                    fullWidth
                    options={staffs}
                    getOptionLabel={(option) =>
                      `${option?.firstName} ${option?.lastName} [${option?.email}]`
                    }
                    value={
                      (staffs || []).find(
                        (staff) =>
                          parseInt(staff?.id) === parseInt(values.staffid)
                      ) || null
                    }
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Staff Name"
                        variant="filled"
                        className="animated-input"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select Staff Name",
                        }}
                      />
                    )}
                    onChange={(event, newValue) =>
                      setFieldValue("staffid", newValue ? newValue.id : "")
                    }
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                  <Autocomplete
                    fullWidth
                    options={clients}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName} [${option.email}]`
                    }
                    value={
                      (clients || []).find(
                        (client) => client.id === values.clientid
                      ) || null
                    }
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client Name"
                        variant="filled"
                        className="animated-input"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select Client Name",
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setSelectedClient(newValue);
                      setFieldValue("clientid", newValue ? newValue.id : "");
                    }}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      fullWidth
                      type="date"
                      label="Start Date"
                      name="sessionstartdate"
                      value={values.sessionstartdate}
                      onChange={(value) => {
                        setFieldValue("sessionstartdate", value);
                        if (value) {
                          const today = dayjs();
                          const endDate = dayjs(value).add(7, "day");
                          const finalEndDate = endDate.isAfter(today)
                            ? today
                            : endDate;

                          setFieldValue("sessionenddate", finalEndDate);
                        }
                      }}
                      shouldDisableDate={(date) => date.isAfter(dayjs())}
                      InputLabelProps={{ shrink: true }}
                      variant="filled"
                      className="animated-input"
                      sx={themeStyles.textField}
                    />
                    <DatePicker
                      fullWidth
                      type="date"
                      label="End Date"
                      name="sessionenddate"
                      value={values.sessionenddate}
                      onChange={(value) =>
                        setFieldValue("sessionenddate", value)
                      }
                      InputLabelProps={{ shrink: true }}
                      variant="filled"
                      className="animated-input"
                      shouldDisableDate={(date) => date.isAfter(dayjs())}
                      sx={themeStyles.textField}
                    />
                  </LocalizationProvider>

                  <Autocomplete
                    fullWidth
                    options={satus}
                    autoHighlight
                    value={values.sessionstatus}
                    onChange={(event, newValue) =>
                      handleChange({
                        target: { name: "sessionstatus", value: newValue },
                      })
                    }
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Session Status"
                        variant="filled"
                        className="animated-input"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select status",
                        }}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                  <Autocomplete
                    fullWidth
                    options={procedureCodes}
                    autoHighlight
                    value={values.procedurecode}
                    onChange={(event, newValue) =>
                      handleChange({
                        target: { name: "procedurecode", value: newValue },
                      })
                    }
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Procedure Code"
                        variant="filled"
                        className="animated-input"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select procedure code",
                        }}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </Box>
                <Box display="flex" justifyContent="right" mt="20px">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ ...buttonStyle }}>
                    Submit
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <Box m="20px">
            <Header title="" subtitle="" />
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.grey[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#3A57E8",
                  borderBottom: "white",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: "#3A57E8",
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.grey[200]} !important`,
                },
              }}>
              <DataGridPro
                sx={{
                  boxShadow: 5,
                  border: 5,
                  borderColor: "primary.light",
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white !important",
                    fontWeight: "bold !important",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#3A57E8 !important",
                    borderBottom: "white !important",
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                    height: "100%",
                  },
                  "& .MuiDataGrid-columnSeparator": {
                    visibility: "hidden",
                  },
                  "& .MuiDataGrid-toolbarContainer": {
                    color: "white !important",
                    justifyContent: "space-between",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    color: "white !important",
                    fontWeight: "bold !important",
                    backgroundColor: "#3A57E8 !important",
                  },
                  "& .MuiTablePagination-root": {
                    color: "white !important",
                  },
                  "& .MuiIconButton-root": {
                    color: "black !important",
                  },
                  "& .MuiDataGrid-selectedRowCount": {
                    color: "white !important",
                  },
                }}
                pagination
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                  columns: {
                    columnVisibilityModel: {
                      sessionId: false,
                      lastUpdatedBy: false,
                      lastUpdatedTime: false,
                      enteredTime: false,
                      enteredBy: false,
                    },
                  },
                }}
                pageSizeOptions={[25, 50, 100]}
                rows={rows}
                getRowId={(row) => row.sessionId}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
                onRowDoubleClick={(params) => {
                  handleEditClick(params.row)();
                }}
              />
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
};

const formatDate = (date) => {
  if (!date) return "";
  const d = new Date(date);
  return d.toISOString().split("T")[0];
};

const dateTimeFormatter = (value, locale = "en-US") => {
  if (!value) return "";

  const date = new Date(value);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  const timeFormatter = new Intl.DateTimeFormat(locale, {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  const formattedTime = timeFormatter.format(date);

  return `${formattedDate} ${formattedTime}`;
};

export default ViewSessionnote;
