export const SET_LOADING = "SET_LOADING";

export const SET_FOLDERS = "SET_FOLDERS";
export const ADD_FOLDER = "ADD_FOLDER";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const UPDATE_FOLDER = "UPDATE_FOLDER";
export const UPDATE_FOLDER_NAME = "UPDATE_FOLDER_NAME";
export const SHIFT_FOLDER = "SHIFT_FOLDER";

export const RESET_FOLDERS_FILES = "RESET_FOLDERS_FILES";

export const SET_DATA = "SET_DATA";
export const ADD_DATA = "ADD_DATA";
export const UPDATE_DATA = "UPDATE_DATA";
export const DELETE_DATA = "DELETE_DATA";
export const SHIFT_DATA = "SHIFT_DATA";

//permission of screen
export const FETCH_PERMISSIONS = "FETCH_PERMISSIONS";
//staff
export const FETCH_ROLES = "FETCH_ROLES";
export const FETCH_STAFF = "FETCH_STAFF";
export const ADD_STAFF = "ADD_STAFF";
export const UPDATE_STAFF = "UPDATE_STAFF";
export const FETCH_SUPERVISOR_BY_ROLE = "FETCH_SUPERVISOR_BY_ROLE";
export const FETCH_BCBA_AND_ABOVE = "FETCH_BCBA_AND_ABOVE";
export const DELETE_STAFF = "DELETE_STAFF";

//client
export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const FETCH_CLIENTS_FOR_STAFF = "FETCH_CLIENTS_FOR_STAFF";

export const FETCH_EVENTS = "FETCH_EVENTS";
export const ADD_EVENT = "ADD_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const FETCH_EVENT_BY_ID = "FETCH_EVENT_BY_ID";
export const EVENT_FAILURE = "EVENT_FAILURE";
