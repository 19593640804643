import React, { useRef } from "react"; // Import useRef
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const typeColors = {
  Baseline: "blue",
  Practicing: "red",
  Mastery: "green",
  Maintenance: "purple",
};

const CustomDot = (props) => {
  const { cx, cy, payload, dataLength } = props;
  const color = typeColors[payload.type] || "grey";
  let radius;

  if (dataLength > 100) {
    radius = 1;
  } else if (dataLength > 50) {
    radius = 3;
  } else {
    radius = 5;
  }

  return <circle cx={cx} cy={cy} r={radius} fill={color} />;
};

const CustomLegend = () => (
  <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
    {Object.entries(typeColors).map(([key, color]) => (
      <div
        key={key}
        style={{ display: "flex", alignItems: "center", marginRight: 15 }}
      >
        <div
          style={{
            width: 20,
            height: 10,
            backgroundColor: color,
            marginRight: 5,
          }}
        />
        <strong>{key}</strong>
      </div>
    ))}
  </div>
);

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          background: "white",
          padding: "5px",
          border: "1px solid #ccc",
        }}
      >
        <p>Date: {payload[0].payload.date}</p>
        <p>Type: {payload[0].payload.type}</p>
        <p>Value: {payload[0].value}</p>
        <p>Last Updated By: {payload[0].payload.lastUpdatedBy}</p>
      </div>
    );
  }
  return null;
};

const MyLineChart = ({ goalValues }) => {
  return (
    <div style={{ position: "relative" }}>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={goalValues.map((item) => ({
            ...item,
            date: dayjs.utc(item.sessionDate).format("YYYY-MM-DD"),
            value: item.result,
            lastUpdatedBy: item.lastUpdatedBy,
          }))}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
            dot={<CustomDot dataLength={goalValues.length} />}
            animationDuration={150}
          />
        </LineChart>
      </ResponsiveContainer>
      <CustomLegend />
    </div>
  );
};

export default MyLineChart;
