import {
  SET_LOADING,
  SET_DATA,
  ADD_DATA,
  DELETE_DATA,
  UPDATE_DATA,
  SET_FOLDERS,
  ADD_FOLDER,
  DELETE_FOLDER,
  UPDATE_FOLDER,
  RESET_FOLDERS_FILES,
  SHIFT_FOLDER,
  UPDATE_FOLDER_NAME,
} from "../../Actions/actionTypes";

const initialState = {
  isLoading: true,
  folder: "root",
  folders: [],
  data: null,
};

const filefolderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      state = { ...state, isLoading: payload };
      return state;
    case SET_DATA:
      state = { ...state, data: payload };
      return state;
    case SET_FOLDERS:
      state = { ...state, folders: payload };
      return state;

    case ADD_FOLDER:
      state = { ...state, folders: [...state.folders, payload] };
      console.log("state", state.folders);
      return state;

    case ADD_DATA:
      state = { ...state, data: [...state.data, payload] };
      return state;

    case UPDATE_DATA:
      const currentData = state.data.find(
        (file) => file.docId === payload.docId
      );
      currentData.data.data = payload.data;
      state = {
        ...state,
        data: state.data.map((file) =>
          file.docId === payload.docId ? currentData : file
        ),
      };
      return state;
    case UPDATE_FOLDER_NAME:
      console.log("payload", payload);

      return {
        ...state,
        folders: state.folders.map((folder) =>
          folder.docId === payload.data.docId
            ? { ...folder, data: { ...folder.data, name: payload.data.data } }
            : folder
        ),
      };

    case UPDATE_FOLDER:
      console.log("payload", payload);
      state = {
        ...state,
        folders: state.folders.map((folder) =>
          folder.docId === payload.docId
            ? { data: payload.data, docId: payload.docId }
            : folder
        ),
      };
      console.log(state.folders);
      return state;

    case DELETE_DATA:
      state = {
        ...state,
        data: state.data.filter((file) => file.docId !== payload),
      };
      return state;
    case DELETE_FOLDER:
      return {
        ...state,
        folders: state.folders
          .map((folder) =>
            folder.docId === payload
              ? { ...folder, data: { ...folder.data, status: "Inactive" } }
              : folder
          )
          .filter((folder) => folder.docId !== payload),
      };

    case SHIFT_FOLDER:
      state = {
        ...state,
        folders: state.folders.filter((folder) => folder.docId !== payload),
      };
      return state;
    case RESET_FOLDERS_FILES:
      state = initialState;
      return state;

    default:
      return state;
  }
};
export default filefolderReducer;
