import { FETCH_PERMISSIONS } from "../../Actions/actionTypes";

const initialState = {
  permissions: [],
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    default:
      return state;
  }
};

export default permissionsReducer;
