import React, { useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Box,
  TextField,
  IconButton,
  Stack,
  Grid,
  Button,
  useMediaQuery,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ShortTermGoalHandler from "../ShortTermGoal";
import { themeStyles, buttonStyle } from "../../../constants";
import StyledTextArea from "../../../components/StyledTextArea";

const LongTermGoal = ({
  iid,
  onRemove,
  toggleVisibility,
  visible,
  type,
  updateLongTermGoal,
  InitialLongTermGoalName,
  InitialLongTermGoalDescription,
  InitialShortTermGoals,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [shortTermGoals, setShortTermGoals] = useState(InitialShortTermGoals);
  const [longTermGoalName, setLongTermGoalName] = useState(
    InitialLongTermGoalName
  );
  const [longTermGoalDescription, setLongTermGoalDescription] = useState(
    InitialLongTermGoalDescription
  );

  const handleLongTermGoalNameChange = (event) => {
    setLongTermGoalName(event.target.value);
    updateLongTermGoal(
      iid,
      event.target.value,
      longTermGoalDescription,
      shortTermGoals
    );
  };

  const handleLongTermGoalDescriptionChange = (event) => {
    setLongTermGoalDescription(event.target.value);
    updateLongTermGoal(
      iid,
      longTermGoalName,
      event.target.value,
      shortTermGoals
    );
  };

  const handleShortTermGoalChange = (res) => {
    updateLongTermGoal(iid, longTermGoalName, longTermGoalDescription, res);
  };

  return (
    <Box marginLeft={2}>
      <Box display="flex" alignItems="end">
        <IconButton onClick={() => toggleVisibility(iid)} size="large">
          {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
        <IconButton onClick={() => onRemove(iid)} size="large">
          <DeleteOutlineIcon />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="start">
        <Stack spacing={2} flexGrow={1}>
          {visible && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5.8}>
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Development Area Name"
                    onChange={handleLongTermGoalNameChange}
                    name={`strategyName-${iid}`}
                    value={longTermGoalName}
                    required
                    sx={{ ...themeStyles.textField }}
                  />
                </Grid>
                <Grid item xs={12} md={6.2}>
                  <StyledTextArea
                    fullWidth
                    variant="filled"
                    label="Development Area Description"
                    multiline
                    onChange={handleLongTermGoalDescriptionChange}
                    rows={3}
                    name={`strategyDescription-${iid}`}
                    value={longTermGoalDescription}
                    required
                    sx={{ ...themeStyles.textField }}
                  />
                </Grid>
              </Grid>
              <ShortTermGoalHandler
                shortTermGoals={shortTermGoals}
                setShortTermGoals={setShortTermGoals}
                handleShortTermGoalChange={handleShortTermGoalChange}
                type={type}
              />
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

const LongTermGoalHandler = ({
  longTermGoals,
  setLongTermGoals,
  handleLongtermGoalChange,
  visible,
  type,
}) => {
  const addLongTermGoal = () => {
    setLongTermGoals([
      ...longTermGoals,
      {
        iid: longTermGoals.length,
        visible: true,
        treatmentPlanObjectiveTemplates: [],
        devlopmentalAreaName: "",
        devlopmentalAreaDescription: "",
        devlopmentalAreaStatus: "Active",
      },
    ]);
  };

  const removeLongTermGoal = (iid) => {
    setLongTermGoals(longTermGoals.filter((goal) => goal.iid !== iid));
    handleLongtermGoalChange(longTermGoals.filter((goal) => goal.iid !== iid));
  };

  const updateLongTermGoal = (
    iid,
    longTermGoalName,
    longTermGoalDescription,
    shortTermGoals
  ) => {
    const res = longTermGoals.map((goal) => {
      if (goal.iid === iid) {
        return {
          ...goal,
          devlopmentalAreaName: longTermGoalName,
          devlopmentalAreaDescription: longTermGoalDescription,
          treatmentPlanObjectiveTemplates: shortTermGoals,
        };
      }
      return goal;
    });
    setLongTermGoals(res);
    handleLongtermGoalChange(res);
  };

  const toggleVisibility = (iid) => {
    setLongTermGoals(
      longTermGoals.map((goal) =>
        goal.iid === iid ? { ...goal, visible: !goal.visible } : goal
      )
    );
    handleLongtermGoalChange(
      longTermGoals.map((goal) =>
        goal.iid === iid ? { ...goal, visible: !goal.visible } : goal
      )
    );
  };

  return (
    <Box>
      <Button
        startIcon={<AddCircleOutlineIcon />}
        variant="contained"
        color="primary"
        onClick={addLongTermGoal}
        sx={{ ...buttonStyle, marginBottom: "25px" }}>
        Development Strategy
      </Button>
      {longTermGoals?.map((goal) => (
        <LongTermGoal
          key={goal.iid}
          iid={goal.iid}
          InitialLongTermGoalName={goal.devlopmentalAreaName}
          InitialLongTermGoalDescription={goal.devlopmentalAreaDescription}
          InitialShortTermGoals={goal.treatmentPlanObjectiveTemplates}
          onRemove={removeLongTermGoal}
          toggleVisibility={toggleVisibility}
          visible={goal.visible}
          updateLongTermGoal={updateLongTermGoal}
          type={type}
        />
      ))}
    </Box>
  );
};

export default LongTermGoalHandler;
