import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Snackbar,
  FormLabel,
  Divider,
  FormControl,
  InputLabel,
  Autocomplete,
  Typography,
  autocompleteClasses,
  InputAdornment,
  Tooltip,
  Dialog,
  DialogContent,
  TextareaAutosize,
  Menu,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import {
  themeStyles,
  buttonStyle,
  SESSION_NOTE_97153,
  DATA_ENTRY_PAGE,
  CREATE,
} from "../../constants";

import "./index.css";
import { Alert } from "@mui/material";
import { tokens } from "../../theme";
import SignaturePad from "react-signature-canvas";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axiosInstance from "../../components/AxiosInstance";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { useState, useEffect, useRef } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik, Field } from "formik";
import SampleModal from "../SamplePdFModal/index";
import dayjs from "dayjs";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import LoadingIndicator from "../Loader";
import { formatDate } from "@fullcalendar/core";
import { useTheme } from "@emotion/react";
import useSharedLink from "../SharedLink";
import { useSnackbar } from "../../components/SnackBarProvider";
import { usePermissions } from "../../Phase3/CheckPermission";
const SessionNote = () => {
  const location = useLocation();
  const {
    checkPermission,
    giveClients,
    giveStaff,
    checkApprover,
    fetchSupervisor,
    checkStaffPermission,
  } = usePermissions();
  const [canApprove, setCanApprove] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const clientId = queryParams.get("clientid");
  const date = queryParams.get("date");
  const from = queryParams.get("from");

  const navigate = useNavigate();
  const theme = useTheme();
  const sharedLink = useSharedLink();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [facilities, setFacilities] = useState([]);
  const clients = giveClients(SESSION_NOTE_97153, CREATE);
  const client = clientId
    ? (clients || []).find(
        (client) => parseFloat(client.id) === parseFloat(clientId)
      )
    : null;

  const staffs = giveStaff(SESSION_NOTE_97153, CREATE);
  const [supervisor, setSupervisor] = useState([]);
  const [selectedSupervisors, setSelectedSupervisors] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [alertDanger, setAlertDanger] = useState(false);
  const [alertContentDanger, setAlertContentDanger] = useState("");
  const [initialValues, setInitialValues] = useState({});
  const [isSignaturePresent, setIsSignaturePresent] = useState(false);
  const isDisabled = !checkStaffPermission(SESSION_NOTE_97153, CREATE);
  console.log("isdisabled", isDisabled);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const handleCloseImageModal = () => setIsImageModalOpen(false);

  const sessionType = ["Adaptive Behaviour Treatment", "Other"];
  const placeOfService = ["Home", "School", "Other"];
  const diagnosisCode = ["F84.0", "Other"];
  const procedureCode = ["97151", "97153", "97156", "97155", "Other"];
  const moDifier = ["HM", "HN", "HO", "Other"];
  const parentOrGurdian = ["Parent", "Gurdian", "Other"];

  const handleClose = () => {
    setAlertDanger(false);
  };
  const [sessionparticipantsState, setSessionParticipants] = useState({
    child: false,
    parent: false,
    bcba: false,
    behaviourtechnician: false,
    other: false,
  });

  const [rbtworkstyleState, setRbtWorkStyle] = useState({
    optiona: false,
    optionb: false,
    otherRbt: false,
  });

  const [skillStratergiesState, setSkillStratergies] = useState({
    rolePlaying: false,
    discreteTrialTraining: false,
    behaviourmomentum: false,
    generalizationtraining: false,
    shapingchaining: false,
    naturalenvironmentteaching: false,
    providingsupport: false,
    na: false,
    otherSkill: false,
  });

  const [behaviorStratergiesState, setBehaviorStratergies] = useState({
    modeling: false,
    guidedcompliance: false,
    differntialreinforcement: false,
    verbalremindersprovided: false,
    firststatements: false,
    contigentrewards: false,
    visualsupports: false,
    nabehavior: false,
    otherbehavior: false,
  });

  const {
    modeling,
    guidedcompliance,
    differntialreinforcement,
    verbalremindersprovided,
    firststatements,
    contigentrewards,
    visualsupports,
    nabehavior,
    otherbehavior,
  } = behaviorStratergiesState;

  const { child, parent, bcba, behaviourtechnician, other } =
    sessionparticipantsState;

  const { optiona, optionb, otherRbt } = rbtworkstyleState;

  const {
    rolePlaying,
    discreteTrialTraining,
    behaviourmomentum,
    generalizationtraining,
    shapingchaining,
    naturalenvironmentteaching,
    providingsupport,

    na,
    otherSkill,
  } = skillStratergiesState;
  const [modalSize, setModalSize] = useState("small");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPdf, setSelectedPdf] = useState("");

  const CustomLabel = (title, pdfName) => {
    // console.log(pdfName, " ", title);

    <InputLabel htmlFor={title.title}>
      Reinforcer Used During Session
      <Tooltip title="Help">
        <IconButton>
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
    </InputLabel>;
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = (pdf) => {
    setSelectedPdf(pdf);
    // setModalSize(size);

    setIsModalOpen(true);

    handleMenuClose();
  };
  const handleImageModal = (pdf, size = "small") => {
    setSelectedPdf(pdf);
    setModalSize(size);
    setIsImageModalOpen(true);
  };
  const disableFutureDates = (date) => {
    return date > new Date();
    // const today = new Date();
    // today.setHours(0, 0, 0, 0);

    // Disable dates that are before today
    // return date < today;
  };
  const sigParent = useRef(null);
  const sigSupervisor = useRef(null);
  const sigStaff = useRef(null);
  const clearParentSignature = () => {
    sigParent.current.clear();
  };

  const saveParentSign = () => {
    const signature = sigParent.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    console.log(signature);
    // This will log the PNG data URL of the signature
  };
  const clearSupervisorSignature = () => {
    setIsSignaturePresent(false);
    sigSupervisor.current.clear();
  };
  const saveSupervisorSignature = () => {
    const signature = sigSupervisor.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    console.log(signature); // This will log the PNG data URL of the signature
  };
  const clearStaffSignature = () => {
    sigStaff.current.clear();
  };
  const saveStaffSignature = () => {
    const signature = sigStaff.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    console.log(signature); // This will log the PNG data URL of the signature
  };
  const handleChange1 = (event) => {
    setSessionParticipants({
      ...sessionparticipantsState,
      [event.target.name]: event.target.checked,
    });
  };
  const handleSkillStratergies = (event) => {
    setSkillStratergies({
      ...skillStratergiesState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleBehaviorStratergies = (event) => {
    setBehaviorStratergies({
      ...behaviorStratergiesState,
      [event.target.name]: event.target.checked,
    });
  };
  const handleChangeRbtWorkStyle = (event) => {
    setRbtWorkStyle({
      ...rbtworkstyleState,
      [event.target.name]: event.target.checked,
    });
  };
  const handleSupervisorSignature = (event) => {
    console.log("hye");
    console.log(event.target.value);
    console.log(sigSupervisor.current.isEmpty());
    if (sigSupervisor.current.isEmpty()) {
      setIsSignaturePresent(false);
    } else {
      setIsSignaturePresent(true);
    }
  };

  useEffect(() => {
    console.log(date, " ", clientId, " ", from);

    async function fetchData(retryCount = 0) {
      const maxRetries = 5; // Define a maximum number of retries to avoid infinite loops
      const retryDelay = 2000; // Delay between retries, in milliseconds (e.g., 2 seconds)
      const userRole = localStorage.getItem("role");
      try {
        const [supervisorResponse, facilitiesResponse] = await Promise.all([
          axiosInstance.post(
            sharedLink + "/roles/supervisorofrole",
            { role: userRole },
            { withCredentials: true }
          ),
          axiosInstance.post(sharedLink + "/viewallfacility", {
            withCredentials: true,
          }),
        ]);

        setSupervisor(supervisorResponse.data);
        setFacilities(facilitiesResponse.data);
        console.log(client, "in session note");
        const initialValues = {
          facilityid: "",
          clientid: clientId ? clientId : "",
          insurancename: client?.insuranceName,
          insurancepolicynumber: client?.policyNumber,
          staffid: localStorage.getItem("userId"),

          supervisorid: "",
          sessiondate: date ? dayjs(date) : null,
          sessionstarttime: null,
          sessionendtime: "",
          sessiontime: "",
          sessiontype: "Adaptive Behaviour Treatment",
          placeofservicecode: "Home",
          otherplaceofservice: "",
          modifier: "HN",
          diagnosiscode1: "F84.0",
          otherdiagnosiscode: "",
          procedurecode: "97153",
          sessionparticipants: "",
          othersessionparticipants: "",
          rbtworkstyle: "",
          otherrbtworkstyle: "",
          skillstrategiesapplied: "",
          otherskillstrategiesapplied: "",
          behaviorstrategiesapplied: "",
          otherbehaviorstrategiesapplied: "",
          maladaptivebehaviors: "",
          reinforcerusedduringsession: "",
          primarytargetareasaddressed: "",
          sessionsummary: "",
          clientmoodresponse: "",
          planfornextvisit: "",
          othersessionparticipants: "",
          otherrbtworkstyle: "",
          parentorgurdianname: "Parent",
          parentorgurdiansignaturefilename: "",
          parentorgurdiansignaturedate: null,
          staffsignaturefilename: "",
          staffsignaturedate: null,
          supervisorsignaturefilename: "",
          supervisorsignaturedate: null,
          sessionstatus: "",
          parentsignatureexist: false,
          staffsignatureexist: false,
          supervisorsignatureexist: false,
        };
        fetchEligibleSupervisors(localStorage.getItem("userId"));
        setInitialValues(initialValues);
        setIsloading(false);
      } catch (error) {
        console.error("Error fetching data:", error);

        if (retryCount < maxRetries) {
          console.log(`Retrying (${retryCount + 1}/${maxRetries})...`);
          setTimeout(() => fetchData(retryCount + 1), retryDelay);
        } else {
          console.error("Max retries reached. Aborting fetch.");
          setAlertDanger(true);
          setIsloading(false);
          setAlertContentDanger(
            "Failed to fetch data after multiple attempts."
          );
        }
      }
    }

    fetchData();
  }, [client]);
  useEffect(() => {}, [isloading]);
  const handleData = (formData) => {
    const formattedSessionDate = dayjs(formData.sessiondate).format(
      "YYYY-MM-DD"
    );

    var today = new Date();
    var currdate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1) +
      "-" +
      (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());

    console.log(currdate);

    var sessionpart =
      (child ? "Child;" : "") +
      (parent ? "Parent;" : "") +
      (bcba ? "BCBA;" : "") +
      (behaviourtechnician ? "Behaviour Technician;" : "") +
      (other ? "Other;" : "");

    console.log("sessionparticipants", sessionpart);

    if (sessionpart === "") {
      setAlertDanger(true);
      setAlertContentDanger("Please select session participants");
      return null;
    }

    var rbtwork =
      (optiona ? "RBT/BT worked on goals in treatment plan;" : "") +
      (optionb ? "RBT/BT worked on pairing with reinforcers;" : "") +
      (otherRbt ? "Other;" : "");
    if (rbtwork === "") {
      setAlertDanger(true);
      setAlertContentDanger("Please select RBT work style");
      return null;
    }

    var skillstratergies =
      (rolePlaying ? "Role playing or modeling;" : "") +
      (discreteTrialTraining ? "Discrete trial training;" : "") +
      (behaviourmomentum ? "Behavior Momentum;" : "") +
      (generalizationtraining ? "Generalization training;" : "") +
      (shapingchaining ? "Shaping/Chaining;" : "") +
      (naturalenvironmentteaching ? "Natural environment teaching;" : "") +
      (providingsupport ? "Providing support with prompt fading;" : "") +
      (na ? "N/A;" : "") +
      (otherSkill ? "Other;" : "");

    if (skillstratergies === "") {
      setAlertContentDanger("Please Choose Skill Stratergies");
      setAlertDanger(true);
      return null;
    }

    var behaviorstratergies =
      (modeling ? "Modeling;" : "") +
      (guidedcompliance ? "Guided Compliance;" : "") +
      (differntialreinforcement ? "Differential Reinforcement;" : "") +
      (verbalremindersprovided ? "Verbal reminders provided;" : "") +
      (firststatements ? "First/Then statements;" : "") +
      (contigentrewards ? "Contingent rewards/reinforcers;" : "") +
      (visualsupports
        ? "Visual supports (timer, schedule, token board etc.);"
        : "") +
      (nabehavior ? "N/A;" : "") +
      (otherbehavior ? "Other;" : "");
    if (behaviorstratergies === "") {
      setAlertContentDanger("Please Choose Behavior Stratergies");
      setAlertDanger(true);
      return null;
    }
    if (otherbehavior) {
      if (formData.otherbehaviorstrategiesapplied === "") {
        setAlertContentDanger(
          "Please fill in other behavior strategies applied"
        );
        setAlertDanger(true);
        return null;
      }
    }
    if (otherSkill) {
      if (formData.otherskillstrategiesapplied === "") {
        setAlertContentDanger("Please fill in other skill strategies applied");
        setAlertDanger(true);
        return null;
      }
    }
    if (otherRbt) {
      if (formData.otherrbtworkstyle === "") {
        setAlertContentDanger("Please fill in other RBT work style");
        setAlertDanger(true);
        return null;
      }
    }
    if (other) {
      if (formData.othersessionparticipants === "") {
        setAlertContentDanger("Please fill in other session participants");
        setAlertDanger(true);
        return null;
      }
    }

    const { sessiontime, ...otherValues } = formData;

    if (canApprove) {
      if (sigSupervisor.current.isEmpty()) {
        delete otherValues.supervisorsignaturefilename;
        delete otherValues.supervisorsignaturedate;
      } else {
        otherValues.supervisorsignaturefilename = sigSupervisor.current
          .getTrimmedCanvas()
          .toDataURL("image/png");
        otherValues.supervisorsignaturedate = dayjs(
          formData.supervisorsignaturedate
        ).format("YYYY-MM-DD");
      }
    }
    if (sigParent.current.isEmpty()) {
      delete otherValues.parentorgurdiansignaturefilename;
      delete otherValues.parentorgurdiansignaturedate;
    } else {
      otherValues.parentorgurdiansignaturefilename = sigParent.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      otherValues.parentorgurdiansignaturedate = currdate;
    }
    if (sigStaff.current.isEmpty()) {
      delete otherValues.staffsignaturefilename;
      delete otherValues.staffsignaturedate;
    } else {
      otherValues.staffsignaturefilename = sigStaff.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      otherValues.staffsignaturedate = currdate;
    }

    const payload = {
      ...otherValues,
      sessiondate: formattedSessionDate,
      sessionparticipants: sessionpart,
      rbtworkstyle: rbtwork,
      otherdiagnosiscode:
        formData.diagnosisCode1 === "Other" ? formData.otherdiagnosiscode : "",

      skillstrategiesapplied: skillstratergies,
      behaviorstrategiesapplied: behaviorstratergies,
      sessionendtime: formData.sessiontime[1].format("HH:mm:a"),
      sessionstarttime: formData.sessiontime[0].format("HH:mm:a"),
      enteredby: localStorage.getItem("username"),
    };
    console.log("sending", payload);

    return payload;
  };
  const handleErrors = (formData) => {
    if (!formData.sessiondate) {
      setSnackbar({
        open: true,
        message: "Please fill in the session date",
        severity: "error",
      });
      return true;
    }
    if (!formData.sessiontype) {
      setSnackbar({
        open: true,
        message: "Please fill in the session type",
        severity: "error",
      });
      return true;
    } else if (!formData.facilityid) {
      setSnackbar({
        open: true,
        message: "Please fill in the facility name",
        severity: "error",
      });
      return true;
    } else if (!formData.clientid) {
      setSnackbar({
        open: true,
        message: "Please fill in the client name",
        severity: "error",
      });
      return true;
    } else if (!formData.staffid) {
      setSnackbar({
        open: true,
        message: "Please fill in the staff name",
        severity: "error",
      });
      return true;
    } else if (!formData.supervisorid) {
      setSnackbar({
        open: true,
        message: "Please fill in the supervisor name",
        severity: "error",
      });
      return true;
    } else if (!formData.parentorgurdianname) {
      setSnackbar({
        open: true,
        message: "Please fill in the parent or guardian name",
        severity: "error",
      });
      return true;
    } else if (!formData.placeofservicecode) {
      setSnackbar({
        open: true,
        message: "Please fill in the place of service",
        severity: "error",
      });
      return true;
    } else if (!formData.modifier) {
      setSnackbar({
        open: true,
        message: "Please fill in the modifier",
        severity: "error",
      });
      return true;
    } else if (!formData.procedurecode) {
      setSnackbar({
        open: true,
        message: "Please fill in the procedure code",
        severity: "error",
      });
      return true;
    } else if (!formData.diagnosiscode1) {
      setSnackbar({
        open: true,
        message: "Please fill in the diagnosis code",
        severity: "error",
      });
      return true;
    } else if (
      !formData.sessiontime ||
      !formData.sessiontime[0] ||
      !formData.sessiontime[1] === null
    ) {
      setSnackbar({
        open: true,
        message: "Please fill in the session time",
        severity: "error",
      });
      return true;
    } else if (
      formData.sessiontime[0].format("HH:mm:a") == "Invalid Date" ||
      formData.sessiontime[1].format("HH:mm:a") == "Invalid Date" ||
      formData.sessiontime[0].format("HH:mm:a") >
        formData.sessiontime[1].format("HH:mm:a")
    ) {
      setSnackbar({
        open: true,
        message: "Please fill session time correctly",
        severity: "error",
      });
      return true;
    } else if (formData.placeofservicecode === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the place of service",
        severity: "error",
      });
      return true;
    } else if (
      formData.placeofservicecode == "Other" &&
      formData.otherplaceofservice == ""
    ) {
      setSnackbar({
        open: true,
        message: "Please fill in the other place of service",
        severity: "error",
      });
      return true;
    } else if (
      formData.diagnosiscode1 == "Other" &&
      formData.otherdiagnosiscode == ""
    ) {
      setSnackbar({
        open: true,
        message: "Please fill in the other diagnosis code",
        severity: "error",
      });
      return true;
    } else if (formData.modifier === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the modifier",
        severity: "error",
      });
      return true;
    } else if (formData.procedurecode === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the procedure code",
        severity: "error",
      });
      return true;
    } else if (formData.diagnosiscode1 === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the diagnosis code",
        severity: "error",
      });
      return true;
    } else if (formData.sessionsummary === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the session summary",
        severity: "error",
      });
      return true;
    } else if (formData.reinforcerusedduringsession === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the reinforcer used during session",
        severity: "error",
      });
      return true;
    } else if (formData.primarytargetareasaddressed === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the primary target areas addressed",
        severity: "error",
      });
      return true;
    } else if (formData.maladaptivebehaviors === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the maladaptive behaviors",
        severity: "error",
      });
      return true;
    } else if (formData.clientmoodresponse === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the client mood response",
        severity: "error",
      });
      return true;
    } else if (formData.planfornextvisit === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the plan for next visit",
        severity: "error",
      });
      return true;
    }
    if (
      canApprove &&
      !sigSupervisor.current.isEmpty() &&
      formData.supervisorsignaturedate === null
    ) {
      setSnackbar({
        open: true,
        message: "Please fill in the supervisor signature date",
        severity: "error",
      });
      return true;
    }

    return false;
  };
  const handleSaveAsDraft = (formData, { resetForm }) => {
    if (handleErrors(formData)) {
      return;
    }
    const payload = handleData(formData);
    if (payload === null) {
      return;
    }
    console.log(payload, "sent");
    // console.log(values);
    setSnackbar({
      open: true,
      message: "Saving...",
      severity: "info",
    });
    payload.sessionstatus = "Draft";
    console.log(payload);
    try {
      axiosInstance
        .post(sharedLink + "/addsessionnote", payload)
        .then((res) => {
          if (res.status === 200) {
            resetForm();
            resetOtherValues(formData);
            setSnackbar({
              open: true,
              message: "Saved Successfully",
              severity: "success",
            });
          } else {
            setSnackbar({
              open: true,
              message:
                typeof res.data === "string" && res.data !== ""
                  ? res.data
                  : "An error has occurred failed to save, Please contact support",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          setSnackbar({
            open: true,
            message:
              typeof error.response.data === "string" &&
              error.response.data !== ""
                ? error.response.data
                : "An error has occurred, Please contact support",
            severity: "error",
          });
          console.error("An error occurred:", error);
        });
    } catch (error) {
      setSnackbar({
        open: true,
        message:
          typeof error.response.data === "string" && error.response.data !== ""
            ? error.response.data
            : "An error has occurred failed to save, Please contact support",
        severity: "error",
      });
    }

    // Add your logic for saving as draft
  };

  const handleSaveAsReview = (formData, { resetForm }) => {
    if (handleErrors(formData)) {
      return;
    }

    if (sigParent.current.isEmpty() || sigStaff.current.isEmpty()) {
      setSnackbar({
        open: true,
        message: "Please fill in the parent and staff signature",
        severity: "error",
      });
      return;
    }

    const payload = handleData(formData);
    if (payload === null) {
      return;
    }

    setSnackbar({
      open: true,
      message: "Saving...",
      severity: "info",
    });

    console.log(payload, "sent");
    try {
      axiosInstance
        .post(sharedLink + "/addsessionnote", {
          ...payload,

          sessionstatus: "Pending Review",
        })

        .then((res) => {
          if (res.status === 200) {
            setSnackbar({
              open: true,
              message: "Saved Successfully",
              severity: "success",
            });
            resetForm();
            resetOtherValues(formData);
          } else {
            setSnackbar({
              open: true,

              message:
                typeof res.data === "string" && res.data !== ""
                  ? res.data
                  : "An error has occurred failed to save, Please contact support",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          setSnackbar({
            open: true,
            message:
              typeof error.response.data === "string" &&
              error.response.data !== ""
                ? error.response.data
                : "An error has occurred failed to save, Please contact support",
            severity: "error",
          });
          console.error("An error occurred:", error);
        });
    } catch (error) {
      setSnackbar({
        open: true,
        message:
          typeof error.response.data === "string" && error.response.data !== ""
            ? error.response.data
            : "An error has occurred failed to save, Please contact support",
        severity: "error",
      });
    }

    console.log("values sent");
  };

  const handleApprove = (formData, { resetForm }) => {
    if (handleErrors(formData)) {
      return;
    }
    const payload = handleData(formData);
    if (payload === null) {
      return;
    }
    if (
      sigParent.current.isEmpty() ||
      sigStaff.current.isEmpty() ||
      (canApprove && sigSupervisor.current.isEmpty())
    ) {
      setSnackbar({
        open: true,
        message: "Please fill in the parent, staff and supervisor signature",
        severity: "error",
      });
      return;
    }

    const current = {
      ...payload,

      sessionstatus: "Approved",
    };
    setSnackbar({
      open: true,
      message: "Saving...",
      severity: "info",
    });
    console.log(current, "sent");
    // console.log(values);
    try {
      axiosInstance
        .post(sharedLink + "/addsessionnote", current)
        .then((res) => {
          console.log(res.data, " ");
          if (res.status === 200) {
            console.log("Success");

            resetForm();
            resetOtherValues(formData);
            setSnackbar({
              open: true,
              message: "Saved Successfully",
              severity: "success",
            });
          } else {
            setSnackbar({
              open: true,
              message:
                typeof res.data === "string" && res.data !== ""
                  ? res.data
                  : "An error has occurred failed to save, Please contact support",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          setSnackbar({
            open: true,
            message:
              typeof error.response.data === "string" &&
              error.response.data !== ""
                ? error.response.data
                : "An error has occurred failed to save, Please contact support",
            severity: "error",
          });
          console.error("An error occurred:", error);
        });
    } catch (error) {
      setSnackbar({
        open: true,
        message:
          typeof error.response.data === "string" && error.response.data !== ""
            ? error.response.data
            : "An error has occurred failed to save, Please contact support",
        severity: "error",
      });
    }

    console.log("values sent");

    // Add your logic for approving
  };
  const resetOtherValues = (values) => {
    values.clientid = "";
    clearParentSignature();
    clearStaffSignature();
    if (canApprove) clearSupervisorSignature();
    setRbtWorkStyle({
      optiona: false,
      optionb: false,
      otherRbt: false,
    });
    setSessionParticipants({
      child: false,
      parent: false,
      bcba: false,
      behaviourtechnician: false,
      other: false,
    });
    setSkillStratergies({
      rolePlaying: false,
      discreteTrialTraining: false,
      behaviourmomentum: false,
      generalizationtraining: false,
      shapingchaining: false,
      naturalenvironmentteaching: false,
      providingsupport: false,
      na: false,
      otherSkill: false,
    });
    setBehaviorStratergies({
      modeling: false,
      guidedcompliance: false,
      differntialreinforcement: false,
      verbalremindersprovided: false,
      firststatements: false,
      contigentrewards: false,
      visualsupports: false,
      nabehavior: false,
      otherbehavior: false,
    });
  };
  const fetchEligibleSupervisors = async (id) => {
    try {
      const approval = await checkApprover(id);
      console.log("app", approval);
      setCanApprove(approval);
      const supervisorData = await fetchSupervisor(id);
      console.log("Fetched Supervisors:", supervisorData);
      setSelectedSupervisors(supervisorData);
      // if (supervisorData.length > 0) {
      //   setFieldValue("supervisorid", supervisorData[0].id);
      // } else {
      //   setFieldValue("supervisorid", "");
      // }
    } catch (error) {
      console.error("Error checking approver:", error);
      setCanApprove(false);
      setSelectedSupervisors([]);
    }
  };

  const handleFormSubmit = (values) => {};
  if (isloading) {
    return <LoadingIndicator />;
  } else {
    return (
      <Box m="20px">
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Header
            title="Create Session Note (97153) "
            subtitle=""
            style={{ flexGrow: 1, textAlign: "center" }}
          />
          <br />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1" sx={{ marginRight: "10px" }}>
              Sample
            </Typography>
            <IconButton onClick={handleMenuOpen}>
              <VisibilityIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}>
              <MenuItem onClick={() => handleOpenModal("Sample 1.pdf")}>
                Sample 1
              </MenuItem>
              <MenuItem onClick={() => handleOpenModal("Sample 2.pdf")}>
                Sample 2
              </MenuItem>
              <MenuItem onClick={() => handleOpenModal("Sample 3.pdf")}>
                Sample 3
              </MenuItem>
              <MenuItem onClick={() => handleOpenModal("Sample 4.pdf")}>
                Sample 4
              </MenuItem>
              <MenuItem onClick={() => handleOpenModal("Sample 5.pdf")}>
                Sample 5
              </MenuItem>
              <MenuItem onClick={() => handleOpenModal("Sample 6.pdf")}>
                Sample 6
              </MenuItem>
              <MenuItem onClick={() => handleOpenModal("Sample 7.pdf")}>
                Sample 7
              </MenuItem>
              <MenuItem onClick={() => handleOpenModal("Sample 8.pdf")}>
                Sample 8
              </MenuItem>
              {/* <MenuItem onClick={() => handleOpenModal("Sample 9.pdf")}>
                Sample 9
              </MenuItem> */}
              {/* <MenuItem onClick={() => handleOpenModal("Sample 10.pdf")}>
                Sample 10
              </MenuItem> */}
            </Menu>
            <SampleModal
              open={isModalOpen}
              handleClose={() => setIsModalOpen(false)}
              pdfUrl={selectedPdf}
            />
          </div>
        </Box>
        <Formik
          enableReinitialize
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}>
          {({
            values,
            setFieldValue,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}>
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={facilities}
                    getOptionLabel={(option) =>
                      `${option.facilityName} ${option.facilityNPI}`
                    }
                    value={
                      (facilities || []).find(
                        (facility) => facility.id == values.facilityid
                      ) || null
                    }
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Facility Name"
                        variant="filled"
                        className="animated-input"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select Facility",
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setFieldValue("facilityid", newValue ? newValue.id : "");
                    }}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    options={clients}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName} (${option.email})`
                    }
                    autoHighlight
                    value={
                      (clients || []).find(
                        (client) =>
                          parseFloat(client.id) === parseFloat(values.clientid)
                      ) || null
                    }
                    // disabled={from === "data collection"}
                    onChange={(event, newValue) => {
                      setFieldValue("clientid", newValue ? newValue.id : "");
                      setFieldValue(
                        "insurancename",
                        newValue ? newValue.insuranceName : ""
                      );
                      setFieldValue(
                        "insurancepolicynumber",
                        newValue ? newValue.policyNumber : ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client Name"
                        variant="filled"
                        className="animated-input"
                        required
                        // disabled={from === "data collection"}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={staffs}
                    disabled={isDisabled}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName} [${option.email}]`
                    }
                    value={
                      (staffs || []).find((staff) => {
                        // console.log(staff.id, " ", values.staffid);
                        return staff.id == values.staffid;
                      }) || null
                    }
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Staff Name"
                        variant="filled"
                        required
                        className="animated-input"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select Staff Name",
                        }}
                      />
                    )}
                    onChange={async (event, newValue) => {
                      // Handle the change
                      if (newValue) {
                        fetchEligibleSupervisors(newValue.id);
                      } else {
                        setCanApprove(false);
                      }

                      setFieldValue("staffid", newValue ? newValue.id : "");
                    }}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    options={selectedSupervisors || []}
                    getOptionLabel={(option) => {
                      console.log("Option object:", option); // Log the option object to see its structure
                      return ` ${option.firstName} ${option.lastName}, [${option.role}]`;
                    }}
                    autoHighlight
                    // value={
                    //   (supervisor || []).find((supervisor) => {
                    //     console.log(supervisor.id, " ", values.supervisorid);
                    //     return supervisor.id == values.supervisorid;
                    //   }) || null
                    // }
                    value={
                      (selectedSupervisors || []).find((supervisor) => {
                        console.log(supervisor.id, " hi", values.supervisorid);
                        return supervisor.id == values.supervisorid;
                      }) || null
                    }
                    onChange={(event, newValue) => {
                      console.log(newValue, "new value", selectedSupervisors);
                      setFieldValue(
                        "supervisorid",
                        newValue ? newValue.id : ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Supervisor Name"
                        variant="filled"
                        className="animated-input"
                        required
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <TextField
                  fullWidth
                  variant="filled"
                  className="animated-input"
                  label="Insurance Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={values.insurancename}
                  name="insurancename"
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  className="animated-input"
                  label="Insurance Policy Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={values.insurancepolicynumber}
                  name="insurancepolicynumber"
                  error={
                    !!touched.insurancepolicynumber &&
                    !!errors.insurancepolicynumber
                  }
                  helperText={
                    touched.insurancepolicynumber &&
                    errors.insurancepolicynumber
                  }
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <Autocomplete
                  fullWidth
                  autoHighlight
                  options={parentOrGurdian} // Array of parent or guardian names
                  getOptionLabel={(option) => option} // Assuming each option has a 'name' property
                  value={values.parentorgurdianname || ""}
                  onChange={(event, newValue) => {
                    setFieldValue(
                      "parentorgurdianname",
                      newValue ? newValue : ""
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parent or Guardian"
                      variant="filled"
                      className="animated-input"
                      required
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={sessionType}
                    getOptionLabel={(option) => option} // Since options are strings
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Session Type"
                        variant="filled"
                        className="animated-input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select Session Type",
                        }}
                      />
                    )}
                    value={values.sessiontype || null} // Fallback to null if no value is set
                    onChange={(event, newValue) => {
                      setFieldValue("sessiontype", newValue);
                    }}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>
                <DatePicker
                  fullWidth
                  label="Session Date"
                  variant="filled"
                  value={values.sessiondate || null}
                  onChange={(value) => setFieldValue("sessiondate", value)}
                  name="sessiondate"
                  InputLabelProps={{ shrink: true }}
                  disableFuture
                  disabled={from === "data collection"}
                  slotProps={{
                    textField: {
                      error: touched.sessiondate && Boolean(errors.sessiondate),
                      helperText: touched.sessiondate && errors.sessiondate,
                      sx: { gridColumn: "span 2", ...themeStyles.textField },
                    },
                  }}
                />

                <MultiInputTimeRangeField
                  name="sessiontime"
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  fullWidth
                  value={values.sessiontime}
                  onChange={(newValue) => {
                    handleChange({
                      target: {
                        name: "sessiontime",
                        value: newValue,
                      },
                    });
                  }}
                  slotProps={{
                    textField: ({ position }) => ({
                      label:
                        position === "start"
                          ? "Session start time"
                          : "Session end time",
                      required: true,
                    }),
                  }}
                />

                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={placeOfService} // Assuming 'placeOfService' is the array of options
                    autoHighlight
                    getOptionLabel={(option) => option.toString()} // Assuming 'option' is a string or object that can be converted to string
                    value={values.placeofservicecode}
                    onChange={(event, newValue) => {
                      // Create a synthetic event to pass to handleChange
                      handleChange({
                        target: {
                          name: "placeofservicecode",
                          value: newValue || "", // Use an empty string if newValue is null
                        },
                      });
                    }}
                    onBlur={handleBlur} // Assuming you have a handleBlur function for onBlur event
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Place of service"
                        variant="filled"
                        className="animated-input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select a place of service",
                        }}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <TextField
                  fullWidth
                  variant="filled"
                  className="animated-input"
                  label="Other Place of Service (Maxlength: 30)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required={values.placeofservicecode === "Other"}
                  value={values.otherplaceofservice}
                  name="otherplaceofservice"
                  disabled={values.placeofservicecode !== "Other"}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={moDifier} // Assuming 'moDifier' is the array of options
                    autoHighlight
                    getOptionLabel={(option) => option} // Assuming 'option' is a string you want to display
                    value={values.modifier || ""} // Fallback to an empty string if values.modifier is undefined
                    onChange={(event, newValue) => {
                      // Create a synthetic event to pass to handleChange
                      handleChange({
                        target: {
                          name: "modifier",
                          value: newValue || "", // Use an empty string if newValue is null
                        },
                      });
                    }}
                    onBlur={handleBlur} // Assuming you have a handleBlur function for onBlur event
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Modifier"
                        variant="filled"
                        className="animated-input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select a modifier",
                        }}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option} value={option}>
                        {option}
                      </MenuItem>
                    )}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={procedureCode} // Assuming 'procedureCode' is the array of options
                    autoHighlight
                    getOptionLabel={(option) => option} // Assuming 'option' is a string you want to display
                    value={values.procedurecode || ""} // Fallback to an empty string if values.procedurecode is undefined
                    onChange={(event, newValue) => {
                      // Create a synthetic event to pass to handleChange
                      handleChange({
                        target: {
                          name: "procedurecode",
                          value: newValue || null, // Use an empty string if newValue is null
                        },
                      });
                    }}
                    onBlur={handleBlur} // Assuming you have a handleBlur function for onBlur event
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Procedure Code"
                        variant="filled"
                        className="animated-input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select a procedure code",
                        }}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                    disabled={true}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option} value={option}>
                        {option}
                      </MenuItem>
                    )}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={diagnosisCode} // Assuming 'diagnosisCode' is the array of options
                    autoHighlight
                    getOptionLabel={(option) => option} // Assuming 'option' is a string you want to display
                    value={values.diagnosiscode1 || ""} // Fallback to an empty string if values.diagnosiscode1 is undefined
                    onChange={(event, newValue) => {
                      // Create a synthetic event to pass to handleChange
                      handleChange({
                        target: {
                          name: "diagnosiscode1",
                          value: newValue || "", // Use an empty string if newValue is null
                        },
                      });
                    }}
                    onBlur={handleBlur} // Assuming you have a handleBlur function for onBlur event
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Diagnosis Code 1"
                        variant="filled"
                        className="animated-input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select a diagnosis code",
                        }}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                    // disabled={localStorage.getItem("role") === "Staff"}
                  />
                </FormControl>

                <TextField
                  fullWidth
                  variant="filled"
                  className="animated-input"
                  label="Other Diagnosis Code (Maxlength: 30)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="otherdiagnosiscode"
                  required={values.diagnosiscode1 === "Other"}
                  disabled={values.diagnosiscode1 !== "Other"}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  inputProps={{
                    maxLength: 30,
                  }}
                />

                <FormGroup
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <FormLabel component="legend">Session Participant</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={child}
                        onChange={handleChange1}
                        name="child"
                      />
                    }
                    label="Child"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={parent}
                        onChange={handleChange1}
                        name="parent"
                      />
                    }
                    label="Parent"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bcba}
                        onChange={handleChange1}
                        name="bcba"
                      />
                    }
                    label="BCBA"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={behaviourtechnician}
                        onChange={handleChange1}
                        name="behaviourtechnician"
                      />
                    }
                    label="Behaviour Technician"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={other}
                        onChange={handleChange1}
                        name="other"
                      />
                    }
                    label="Other"
                  />
                  {other && (
                    <TextareaAutosize
                      fullWidth
                      variant="filled"
                      className="animated-input"
                      placeholder="Type in here… (Maxlength: 30)"
                      name="othersessionparticipants"
                      value={values.othersessionparticipants}
                      onChange={handleChange}
                      minRows={2}
                      maxRows={2}
                      required={other}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                  )}
                </FormGroup>
                <FormGroup
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <FormLabel component="legend">Work Style</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={optiona}
                        onChange={handleChangeRbtWorkStyle}
                        name="optiona"
                      />
                    }
                    label="RBT/BT worked on goals in the treatment plan"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={optionb}
                        onChange={handleChangeRbtWorkStyle}
                        name="optionb"
                      />
                    }
                    label="RBT/BT worked on
                  pairing self with reinforcers"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={otherRbt}
                        onChange={handleChangeRbtWorkStyle}
                        name="otherRbt"
                      />
                    }
                    label="Other"
                  />
                  {otherRbt && (
                    <TextareaAutosize
                      fullWidth
                      variant="filled"
                      className="animated-input"
                      placeholder="Type in here… (Maxlength: 30)"
                      name="otherrbtworkstyle"
                      value={values.otherrbtworkstyle}
                      onChange={handleChange}
                      minRows={2}
                      maxRows={3}
                      required={otherRbt}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                  )}
                </FormGroup>
                <FormGroup
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <FormLabel component="legend">Skill Stratergies</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rolePlaying}
                        onChange={handleSkillStratergies}
                        name="rolePlaying"
                      />
                    }
                    label="Role playing or modeling 
                  "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={discreteTrialTraining}
                        onChange={handleSkillStratergies}
                        name="discreteTrialTraining"
                      />
                    }
                    label="Discrete trial training 
                  "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={behaviourmomentum}
                        onChange={handleSkillStratergies}
                        name="behaviourmomentum"
                      />
                    }
                    label="Behaviour momentum
                  "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={generalizationtraining}
                        onChange={handleSkillStratergies}
                        name="generalizationtraining"
                      />
                    }
                    label="Gereralization training 
                  "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={shapingchaining}
                        onChange={handleSkillStratergies}
                        name="shapingchaining"
                      />
                    }
                    label="Shaping/Chaining"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={naturalenvironmentteaching}
                        onChange={handleSkillStratergies}
                        name="naturalenvironmentteaching"
                      />
                    }
                    label="Natural environment teaching
                  "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={providingsupport}
                        onChange={handleSkillStratergies}
                        name="providingsupport"
                      />
                    }
                    label="Providing support with prompt fading
                  "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={na}
                        onChange={handleSkillStratergies}
                        name="na"
                      />
                    }
                    label="N/A
                  "
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={otherSkill}
                        onChange={handleSkillStratergies}
                        name="otherSkill"
                      />
                    }
                    label="Others"
                  />
                  {otherSkill && (
                    <TextareaAutosize
                      fullWidth
                      variant="filled"
                      className="animated-input"
                      placeholder="Type in here… (Maxlength: 30)"
                      name="otherskillstrategiesapplied"
                      value={values.otherskillstrategiesapplied}
                      onChange={handleChange}
                      minRows={2}
                      maxRows={4}
                      required={otherSkill}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                  )}
                </FormGroup>
                <FormGroup
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <FormLabel component="legend">
                    Behavioral Stratergies
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={modeling}
                        onChange={handleBehaviorStratergies}
                        name="modeling"
                      />
                    }
                    label="Modeling
                  "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={guidedcompliance}
                        onChange={handleBehaviorStratergies}
                        name="guidedcompliance"
                      />
                    }
                    label="Guided Compliance 
                  "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={differntialreinforcement}
                        onChange={handleBehaviorStratergies}
                        name="differntialreinforcement"
                      />
                    }
                    label="Differential Reinforcement 
                  "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={verbalremindersprovided}
                        onChange={handleBehaviorStratergies}
                        name="verbalremindersprovided"
                      />
                    }
                    label="Verbal reminders provided 
                  "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={firststatements}
                        onChange={handleBehaviorStratergies}
                        name="firststatements"
                      />
                    }
                    label="First/Then statements
                  "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={contigentrewards}
                        onChange={handleBehaviorStratergies}
                        name="contigentrewards"
                      />
                    }
                    label="Contingent rewards/reinforcer
                  "
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={visualsupports}
                        onChange={handleBehaviorStratergies}
                        name="visualsupports"
                      />
                    }
                    label="Visual supports (timer, schedule, 
                    token board etc.)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={nabehavior}
                        onChange={handleBehaviorStratergies}
                        name="nabehavior"
                      />
                    }
                    label="N/A"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={otherbehavior}
                        onChange={handleBehaviorStratergies}
                        name="otherbehavior"
                      />
                    }
                    label="Others"
                  />
                  {otherbehavior && (
                    <TextareaAutosize
                      fullWidth
                      variant="filled"
                      className="animated-input"
                      placeholder="Type in here… (Maxlength: 30)"
                      name="otherbehaviorstrategiesapplied"
                      value={values.otherbehaviorstrategiesapplied}
                      onChange={handleChange}
                      minRows={2}
                      maxRows={4}
                      required={otherbehavior}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                  )}
                </FormGroup>
                <FormGroup
                  fullWidth
                  sx={{ gridColumn: "span 4", ...themeStyles.textField }}>
                  <FormLabel component="legend">
                    Reinforcers Used (Maxlength: 1000)
                    <Tooltip title="Help">
                      <IconButton
                        onClick={() =>
                          handleImageModal("reinforcersused.jpeg")
                        }>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </FormLabel>
                  <Dialog
                    open={isImageModalOpen}
                    onClose={handleCloseImageModal}
                    maxWidth="sm">
                    <DialogContent>
                      {/* Close button */}
                      <IconButton
                        aria-label="close"
                        onClick={handleCloseImageModal}
                        style={{ position: "absolute", right: 8, top: 8 }}>
                        <CloseIcon />
                      </IconButton>

                      {/* Image content */}
                      <img
                        src={selectedPdf}
                        alt="Popup Content"
                        style={{ width: "100%" }}
                      />
                    </DialogContent>
                  </Dialog>

                  <TextField
                    fullWidth
                    required
                    variant="filled"
                    className="animated-input"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.reinforcerusedduringsession}
                    name="reinforcerusedduringsession"
                    // sx={{ gridColumn: "span 4",...themeStyles.textField }}
                    rows={5}
                    inputProps={{ maxLength: 1000 }}
                    multiline
                  />
                </FormGroup>
                <FormGroup
                  fullWidth
                  sx={{ gridColumn: "span 4", ...themeStyles.textField }}>
                  <FormLabel component="legend">
                    Primary Target Areas Addressed (Maxlength: 1000)
                    <Tooltip title="Help">
                      <IconButton
                        onClick={() =>
                          handleImageModal("primarytargetareasaddressed.png")
                        }>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </FormLabel>
                  <TextField
                    fullWidth
                    variant="filled"
                    className="animated-input"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.primarytargetareasaddressed}
                    name="primarytargetareasaddressed"
                    multiline
                    inputProps={{ maxLength: 1000 }}
                    sx={{ gridColumn: "span 4", ...themeStyles.textField }}
                    rows={5}
                  />
                </FormGroup>
                <FormGroup
                  fullWidth
                  sx={{ gridColumn: "span 4", ...themeStyles.textField }}>
                  <FormLabel component="legend">
                    Describe Maladaptive Behaviour (Maxlength: 1000)
                    <Tooltip title="Help">
                      <IconButton>
                        <HelpOutlineIcon
                          onClick={() =>
                            handleImageModal("maladaptivebehaviors.jpeg")
                          }
                        />
                      </IconButton>
                    </Tooltip>
                  </FormLabel>
                  <TextField
                    fullWidth
                    variant="filled"
                    className="animated-input"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.maladaptivebehaviors}
                    name="maladaptivebehaviors"
                    sx={{ gridColumn: "span 4", ...themeStyles.textField }}
                    rows={5}
                    multiline
                    inputProps={{ maxLength: 1000 }}
                    // inputProps={{ maxLength: 50 }}
                  />
                </FormGroup>
                <FormGroup
                  fullWidth
                  sx={{ gridColumn: "span 4", ...themeStyles.textField }}>
                  <FormLabel component="legend">
                    Session Summary (Maxlength: 2500)
                    <Tooltip title="Help">
                      <IconButton
                        onClick={() => handleImageModal("sessionsummary.png")}>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </FormLabel>
                  <TextField
                    fullWidth
                    variant="filled"
                    className="animated-input"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.sessionsummary}
                    name="sessionsummary"
                    sx={{ gridColumn: "span 4", ...themeStyles.textField }}
                    rows={5}
                    multiline
                    inputProps={{ maxLength: 2500 }}
                    required
                  />
                </FormGroup>
                <FormGroup
                  fullWidth
                  sx={{ gridColumn: "span 4", ...themeStyles.textField }}>
                  <FormLabel component="legend">
                    Client Mood Response (Maxlength: 1000)
                    <Tooltip title="Help">
                      <IconButton
                        onClick={() =>
                          handleImageModal("clientmoodresponse.jpeg")
                        }>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </FormLabel>
                  <TextField
                    fullWidth
                    variant="filled"
                    className="animated-input"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.clientmoodresponse}
                    required
                    name="clientmoodresponse"
                    sx={{ gridColumn: "span 4", ...themeStyles.textField }}
                    rows={5}
                    multiline
                    inputProps={{ maxLength: 1000 }}
                  />
                </FormGroup>
                <FormGroup
                  fullWidth
                  sx={{ gridColumn: "span 4", ...themeStyles.textField }}>
                  <FormLabel component="legend">
                    Plan for Next Visit (Maxlength: 1000)
                    <Tooltip title="Help">
                      <IconButton
                        onClick={() =>
                          handleImageModal("planfornextvisit.png")
                        }>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </FormLabel>
                  <TextField
                    fullWidth
                    variant="filled"
                    className="animated-input"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.planfornextvisit}
                    name="planfornextvisit"
                    sx={{ gridColumn: "span 4", ...themeStyles.textField }}
                    rows={5}
                    inputProps={{ maxLength: 1000 }}
                    required
                    multiline
                  />
                </FormGroup>

                <FormGroup
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <FormLabel component="legend">Staff's signature</FormLabel>
                  <FormControlLabel
                    control={
                      <SignaturePad
                        ref={sigStaff}
                        canvasProps={{
                          height: 200,
                          className: "sigCanvas",
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    control={
                      <div>
                        <Button
                          onClick={clearStaffSignature}
                          variant="contained"
                          color="primary"
                          sx={{ ...buttonStyle }}>
                          Clear
                        </Button>
                      </div>
                    }
                  />
                </FormGroup>
                <FormGroup
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <FormLabel component="legend">Parent's signature</FormLabel>
                  <FormControlLabel
                    control={
                      <SignaturePad
                        ref={sigParent}
                        canvasProps={{
                          height: 200,
                          className: "sigCanvas",
                        }}
                      />
                    }
                  />

                  <FormControlLabel
                    control={
                      <div>
                        <Button
                          onClick={clearParentSignature}
                          // fullWidth
                          variant="contained"
                          sx={{ ...buttonStyle }}
                          color="primary">
                          Clear
                        </Button>
                      </div>
                    }
                  />
                </FormGroup>

                {canApprove && (
                  <>
                    <FormGroup
                      sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                      <FormLabel component="legend">
                        supervisor's signature
                      </FormLabel>
                      <FormControlLabel
                        control={
                          <SignaturePad
                            ref={sigSupervisor}
                            onEnd={handleSupervisorSignature}
                            canvasProps={{
                              height: 200,
                              className: "sigCanvas",
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        control={
                          <div>
                            <Button
                              onClick={clearSupervisorSignature}
                              // fullWidth
                              //  fullWidth
                              variant="contained"
                              color="primary"
                              margin="10px"
                              sx={{ gridColumn: "span 1", ...buttonStyle }}>
                              Clear
                            </Button>
                          </div>
                        }
                      />
                    </FormGroup>
                    <DatePicker
                      fullWidth
                      label="Supervisor Signature Date"
                      variant="filled"
                      value={values.supervisorsignaturedate || null}
                      onChange={(value) =>
                        setFieldValue("supervisorsignaturedate", value)
                      }
                      name="supervisorsignaturedate"
                      InputLabelProps={{ shrink: true }}
                      disabled={!isSignaturePresent}
                      slotProps={{
                        textField: {
                          error:
                            touched.supervisorsignaturedate &&
                            Boolean(errors.supervisorsignaturedate),
                          helperText:
                            touched.supervisorsignaturedate &&
                            errors.supervisorsignaturedate,
                          required: isSignaturePresent,
                          sx: {
                            gridColumn: "span 2",
                            ...themeStyles.textField,
                          },
                        },
                      }}
                    />
                  </>
                )}
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="button"
                  color="secondary"
                  variant="contained"
                  sx={{ ...buttonStyle }}
                  style={{ marginRight: "10px" }}
                  onClick={() => navigate(-1)}
                  // pass the values directly
                >
                  Go Back
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  variant="contained"
                  sx={{ ...buttonStyle }}
                  style={{ marginRight: "10px" }}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default form submission
                    handleSaveAsDraft(values, { resetForm });
                  }} // pass the values directly
                >
                  Save as draft
                </Button>
                <Button
                  type="button"
                  sx={{ ...buttonStyle }}
                  color="secondary"
                  variant="contained"
                  style={{ marginRight: "10px" }}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default form submission
                    handleSaveAsReview(values, { resetForm });
                  }} // pass the values directly
                >
                  Save as review
                </Button>
                {canApprove && (
                  <Button
                    type="submit"
                    color="secondary"
                    sx={{ ...buttonStyle }}
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default form submission
                      handleApprove(values, { resetForm });
                    }} // pass the values directly // pass the values directly
                  >
                    Approve
                  </Button>
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    );
  }
};

const checkoutSchema = yup.object().shape({});

export default SessionNote;
