import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Button,
  Stack,
  useMediaQuery,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import GoalModal from "../../UpdateTreeComponent/GoalModal";
import { themeStyles, buttonStyle } from "../../../constants";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";

const GoalHandler = ({ handleGoalChange, goals, setGoals, type }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [goalvalues, setGoalValues] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const columns = [
    { field: "goalName", headerName: "Name", width: 250 },
    { field: "goalDescription", headerName: "Description", width: 300 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <strong>
          {/* Edit Button */}
          <IconButton
            onClick={() => {
              setGoalValues(params.row);
              setModalOpen(true);
            }}>
            <EditIcon />
          </IconButton>
          {/* Delete Button */}
          <IconButton onClick={() => removeGoal(params.row.iid)}>
            <DeleteIcon />
          </IconButton>
        </strong>
      ),
    },
  ];

  const addGoal = () => {
    const len = goals ? goals.length : 0;

    setGoals([
      ...goals,
      {
        iid: len,
        visible: true,
        programType: "",
        goalName: "",
        goalDescription: "",
        masteryCriteria: "",
        masteryDirection: "",
        masteryDuration: "",
        prompt: "",
        noOfAttempts: "",
        trialList: [],
        goaltype: {
          goalAreaName: "",
          goalAreaId: "",
        },
        goalStatus: "Active",
        modified: true,
      },
    ]);
    setGoalValues({
      iid: len,
      visible: true,
      programType: "",
      goalName: "",
      goalDescription: "",
      masteryCriteria: "",
      masteryDirection: "",
      masteryDuration: "",
      prompt: "",
      noOfAttempts: "",
      trialList: [],
      goaltype: {
        goalAreaName: "",
        goalAreaId: "",
      },
      goalStatus: "Active",
      modified: true,
    });

    setModalOpen(true);
  };

  const removeGoal = (iid) => {
    setGoals(goals.filter((goal) => goal.iid !== iid));
    handleGoalChange(goals.filter((goal) => goal.iid !== iid));
  };
  const updateGoal = (payload) => {
    const res = goals.map((goal) => {
      if (goal.iid === payload.iid) {
        return { ...payload, visible: goal.visible, goalStatus: "Active" };
      }
      return goal;
    });
    setGoals(res);
    handleGoalChange(res);
  };

  return (
    <Box>
      <Box
        marginLeft={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Button
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          color="primary"
          onClick={() => {
            addGoal();
          }}
          sx={{ ...buttonStyle, marginBottom: "10px" }}>
          Goal
        </Button>
      </Box>
      <GoalModal
        key={goalvalues?.iid}
        open={modalOpen}
        handleClose={handleClose}
        updateGoal={updateGoal}
        removeGoal={removeGoal}
        type={type}
        goalvalues={goalvalues}
      />
      {goals && goals.length > 0 && (
        <Box
          marginLeft={5}
          height={52 * 8}
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.grey[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#3A57E8",
              borderBottom: "white",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#3A57E8",
            },
            "& .MuiCheckbox-root": {
              color: `${colors.grey[200]} !important`,
            },
            "& .MuiDataGrid-row--red": {
              backgroundColor: "red !important",
            },
          }}>
          <DataGridPro
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            sx={{
              boxShadow: 5,
              border: 5,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold !important",
              },
              "& .MuiDataGrid-footerContainer": {
                color: "white",
                fontWeight: "bold !important",
              },
            }}
            rows={goals}
            columns={columns}
            getRowId={(row) => row.iid}
            // getRowClassName={(params) =>
            //   params.row.modified ? "MuiDataGrid-row--red" : ""
            // }
          />
        </Box>
      )}
    </Box>
  );
};

export default GoalHandler;
