import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Button,
  Stack,
  useMediaQuery,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import DeleteIcon from "@mui/icons-material/Delete";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import GoalModal from "../GoalModal";
import { themeStyles, buttonStyle } from "../../../constants";
import { useTheme } from "@mui/material";
import { tokens } from "../../../theme";

const GoalHandler = ({
  handleGoalChange,
  goals,
  setGoals,
  handleActivation,
  parentStatus,
  type,
}) => {
  const [activeAreas, setActiveAreas] = useState(
    goals?.filter((val) => val.goalStatus === "Active")
  );
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showInactiveAreas, setShowInactiveAreas] = useState(false);
  const [goalvalues, setGoalValues] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const columns = [
    { field: "goalName", headerName: "Name", width: 180 },
    { field: "goalDescription", headerName: "Description", width: 230 },
    { field: "goalStatus", headerName: "Status", width: 120 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,

      renderCell: (params) => (
        <strong>
          <IconButton
            onClick={() => {
              setGoalValues(params.row);
              setModalOpen(true);
            }}
            disabled={params.row.goalStatus == "Inactive" || !parentStatus}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              if (params.row.goalStatus === "Active") {
                deactivateGoal(params.row.iid);
              } else {
                activateGoal(params.row.iid);
              }
            }}
            disabled={!parentStatus}
            color={params.row.goalStatus === "Active" ? "error" : "primary"}>
            {params.row.goalStatus === "Active" && parentStatus ? (
              <RemoveCircleOutlineIcon />
            ) : (
              <CheckCircleOutlineIcon />
            )}
          </IconButton>
          <Typography variant="body2" display="inline">
            {params.row.goalStatus === "Active" && parentStatus
              ? "Deactivate"
              : "Activate"}
          </Typography>
        </strong>
      ),
    },
  ];

  const deactivateGoal = (iid) => {
    const updatedGoals = goals.map((goal) => {
      if (goal.iid === iid) {
        return { ...goal, goalStatus: "Inactive", modified: true };
      }
      return goal;
    });
    setGoals(updatedGoals);
    if (!showInactiveAreas)
      setActiveAreas(
        updatedGoals.filter((goal) => goal.goalStatus === "Active")
      );
    else setActiveAreas(updatedGoals);
    handleGoalChange(updatedGoals);
  };

  const activateGoal = (iid) => {
    const updatedGoals = goals.map((goal) => {
      if (goal.iid === iid) {
        return { ...goal, goalStatus: "Active", modified: true };
      }
      return goal;
    });
    setGoals(updatedGoals);
    if (!showInactiveAreas)
      setActiveAreas(
        updatedGoals.filter((goal) => goal.goalStatus === "Active")
      );
    else setActiveAreas(updatedGoals);
    handleGoalChange(updatedGoals);
  };

  const onShowInactiveAreasChange = (isChecked) => {
    setShowInactiveAreas(isChecked);
    if (isChecked) {
      setActiveAreas(goals);
    } else {
      setActiveAreas(goals?.filter((val) => val.goalStatus == "Active"));
    }
  };

  const addGoal = () => {
    if (!parentStatus) {
      alert("First Activate the parent Area ");
      return;
    }
    const len = goals ? goals.length : 0;

    setGoals([
      ...goals,
      {
        iid: len,
        visible: true,
        programType: "",
        goalName: "",
        goalDescription: "",
        masteryCriteria: "",
        masteryDirection: "",
        masteryDuration: "",
        masteryTargetDate: null,
        prompt: "",
        noOfAttempts: "",
        trialList: [],
        goaltype: null,
        goalStatus: "Active",
      },
    ]);
    setGoalValues({
      iid: len,
      visible: true,
      programType: "",
      goalName: "",
      goalDescription: "",
      masteryCriteria: "",
      masteryDirection: "",
      masteryDuration: "",
      masteryTargetDate: null,
      prompt: "",
      noOfAttempts: "",
      trialList: [],
      goaltype: null,
      goalStatus: "Active",
    });
    setActiveAreas([
      ...activeAreas,
      {
        iid: len,
        visible: true,
        programType: "",
        goalName: "",
        goalDescription: "",
        masteryCriteria: "",
        masteryDirection: "",
        masteryDuration: "",
        masteryTargetDate: null,
        prompt: "",
        noOfAttempts: "",
        trialList: [],
        goaltype: null,
        goalStatus: "Active",
      },
    ]);
    setModalOpen(true);
  };

  const removeGoal = (iid) => {
    const currentgoals = goals.filter((goal) => !(goal.iid == iid && !goal.id));
    const updatedGoals = currentgoals.map((goal) => {
      if (goal.iid === iid) {
        return { ...goal, goalStatus: "Inactive", modified: true };
      }
      return goal;
    });

    console.log(updatedGoals, "level5");
    setActiveAreas(activeAreas.filter((goal) => goal.iid !== iid));
    handleGoalChange(updatedGoals);
  };

  const updateGoal = (payload) => {
    const res = goals.map((goal) => {
      if (goal.iid === payload.iid) {
        return { ...payload, visible: goal.visible, goalStatus: "Active" };
      }
      return goal;
    });

    if (showInactiveAreas) {
      setActiveAreas(res);
    } else {
      setActiveAreas(res?.filter((val) => val.goalStatus === "Active"));
    }
    handleGoalChange(res);
  };

  const updateStatus = (iid, status) => {
    const res = goals.map((goal) => {
      if (goal.iid === iid) {
        return {
          ...goal,
          goalStatus: status,
        };
      }
      return goal;
    });
    handleActivation(res);
  };

  const toggleVisibility = (iid) => {
    setActiveAreas(
      activeAreas.map((goal) =>
        goal.iid === iid ? { ...goal, visible: !goal.visible } : goal
      )
    );
    handleGoalChange(
      goals.map((goal) =>
        goal.iid === iid ? { ...goal, visible: !goal.visible } : goal
      )
    );
  };

  return (
    <Box marginLeft={3}>
      <Box
        margin={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Button
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          color="primary"
          onClick={addGoal}
          sx={{ ...buttonStyle }}>
          Goal
        </Button>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event) =>
                onShowInactiveAreasChange(event.target.checked)
              }
              name="showInactiveAreas"
              color="primary"
            />
          }
          label={<Typography variant="body1">Show Inactive </Typography>}
          style={{ marginBottom: 0 }} // Adjust as needed for alignment
        />
      </Box>
      <GoalModal
        key={goalvalues}
        open={modalOpen}
        handleClose={handleClose}
        updateGoal={updateGoal}
        removeGoal={removeGoal}
        type={type}
        goalvalues={goalvalues}
      />
      {activeAreas && activeAreas.length > 0 && (
        <Box
          marginLeft={5}
          height={52 * 8}
          sx={{
            overflowX: "auto", // Enable horizontal scrolling
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.grey[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#3A57E8",
              borderBottom: "white",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#3A57E8",
            },
            "& .MuiCheckbox-root": {
              color: `${colors.grey[200]} !important`,
            },
          }}>
          <DataGridPro
            autoHeight={false} // Make DataGrid take full height of the container
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            sx={{
              boxShadow: 5,
              border: 5,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold !important",
              },
              "& .MuiDataGrid-footerContainer": {
                color: "white",
                fontWeight: "bold !important",
              },
              "& .red-row": {
                backgroundColor: "red !important",
              },
            }}
            rows={activeAreas}
            columns={columns}
            getRowId={(row) => row.iid} // Custom function to specify the row identifier using `iid`
            // getRowClassName={(params) => (params.row.modified ? "red-row" : "")} // Apply 'red-row' class if there is no 'id'
          />
        </Box>
      )}
    </Box>
  );
};

export default GoalHandler;
