import React, { useState, useEffect } from "react";
import { Fab, Zoom } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const ScrollToBottomButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.pageYOffset + window.innerHeight;
    const threshold = document.body.scrollHeight - 100;
    if (scrollPosition < threshold) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Zoom in={isVisible}>
      <Fab
        color="primary"
        aria-label="scroll to bottom"
        onClick={scrollToBottom}
        size="small"
        sx={{
          position: "fixed",
          bottom: 16,
          left: "50%",
          transform: "translateX(-50%)",
        }}>
        <ArrowDownwardIcon />
      </Fab>
    </Zoom>
  );
};

export default ScrollToBottomButton;
