import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import axiosInstance from "../../components/AxiosInstance";
import { Alert } from "@mui/material";
import useSharedLink from "../SharedLink";
const PasswordChange = () => {
  const sharedLink = useSharedLink();
  const [input, setInput] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
    setError(""); // Clear error when user starts typing
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (input.newPassword !== input.confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    // if (!input.newPassword || input.newPassword.length < 8) {
    //   setError("Password must be at least 8 characters long.");
    //   return;
    // }
    // Here you would handle the password change logic
    const fetchStaffDetails = async () => {
      try {
        const userId = localStorage.getItem("username"); // Ensure the key is correct
        if (!userId) {
          setError("No user ID found in local storage.");
          return;
        }
        const payload = {
          username: userId,
          password: input.newPassword,
        };
        console.log(payload);

        axiosInstance
          .post(
            sharedLink + "/updateuser",
            {
              username: userId,
              password: input.newPassword,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Expect: undefined,
              },
            }
          )
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              setAlert(true);
              setInput({
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
              });
              setAlertContent("Password Changed successfully");
            } else {
              setError("An error occurred while Chaning Password.");
            }
          })
          .catch((err) => {
            console.error(err);
            setError("An error occurred while Chaning Password.");
          });

        // Clear the input after successful password change
      } catch (err) {
        setError("An error occurred while Chaning Password.");
        console.error(err);
      }
    };

    fetchStaffDetails();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh">
      {error && (
        <Alert severity="error" onClose={() => setError("")} dismissible>
          {error}
        </Alert>
      )}
      {alert && (
        <Alert variant="success" onClose={() => setAlert(false)} dismissible>
          {alertContent}
        </Alert>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "grid",
          gap: 2, // spacing between items
          gridTemplateColumns: "repeat(2, 1fr)", // 2 columns of equal width
        }}>
        <h1>Change Password</h1>

        <TextField
          label="New Password"
          type="password"
          name="newPassword"
          value={input.newPassword}
          onChange={handleChange}
          required
          fullWidth
          variant="outlined"
          sx={{ gridRow: "3", gridColumn: "span 2" }}
        />
        <TextField
          label="Confirm New Password"
          type="password"
          name="confirmPassword"
          value={input.confirmPassword}
          onChange={handleChange}
          required
          fullWidth
          variant="outlined"
          sx={{ gridRow: "4", gridColumn: "span 2" }}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{
            gridColumn: "span 2",
            gridRow: "5", // This will place the button below the text fields
            fontSize: "0.8rem", // larger text
          }}>
          Change Password
        </Button>
      </Box>
    </Box>
  );
};

export default PasswordChange;
