import React, { useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Button,
  Stack,
  useMediaQuery,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddProgramBookGoalHandler from "../Level4";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { themeStyles, buttonStyle } from "../../../constants";
import StyledTextArea from "../../../components/StyledTextArea";

// ShortTermGoal Component
const ShortTermGoal = ({
  id,
  iid,
  onRemove,
  toggleVisibility,
  visible,
  updateShortTermGoal,
  InitialShortTermGoalName,
  InitialShortTermGoalDescription,
  InitialProgramBookGoals,
  status,
  updateStatus,
  parentStatus,
  type,
}) => {
  const [programBookGoals, setProgramBookGoals] = useState(
    InitialProgramBookGoals
  );
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [shortTermGoalName, setShortTermGoalName] = useState(
    InitialShortTermGoalName
  );
  const [shortTermGoalDescription, setShortTermGoalDescription] = useState(
    InitialShortTermGoalDescription
  );
  const [isActive, setIsActive] = useState(status === "Active");

  const toggleActivationStatus = () => {
    if (!parentStatus) {
      alert("Please make the parent area active");
      return;
    }
    updateStatus(iid, isActive ? "Inactive" : "Active", programBookGoals);
    setIsActive((prev) => !prev);
  };

  const handleActivation = (data) => {
    updateStatus(iid, isActive ? "Active" : "Inactive", data);
  };

  const handleGoalNameChange = (event) => {
    setShortTermGoalName(event.target.value);
    updateShortTermGoal(
      iid,
      event.target.value,
      shortTermGoalDescription,
      programBookGoals
    );
  };

  const handleGoalDescriptionChange = (event) => {
    setShortTermGoalDescription(event.target.value);
    updateShortTermGoal(
      iid,
      shortTermGoalName,
      event.target.value,
      programBookGoals
    );
  };

  const handleGoalChange = (res) => {
    console.log("level3 ", res);
    setProgramBookGoals(res);
    updateShortTermGoal(iid, shortTermGoalName, shortTermGoalDescription, res);
  };

  return (
    <Box marginLeft={2}>
      <Box
        margin={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => toggleVisibility(iid)} size="large">
            {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
          {id == null ? (
            <>
              <IconButton onClick={() => onRemove(iid)} size="large">
                <DeleteOutlineIcon />
              </IconButton>
            </>
          ) : (
            <Box display="flex" alignItems="center" mr={2}>
              <IconButton
                onClick={toggleActivationStatus}
                size="large"
                disabled={!parentStatus}>
                {isActive && parentStatus ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <CheckCircleOutlineIcon />
                )}
              </IconButton>
              <Typography variant="body1">
                {isActive && parentStatus ? "Deactivate" : "Activate"}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="start">
        {visible && (
          <Stack spacing={2} flexGrow={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5.8}>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Treatment Plan Objective Name"
                  name={`treatmentplanObjectiveName-${iid}`}
                  required
                  onChange={handleGoalNameChange}
                  disabled={!isActive || !parentStatus}
                  value={shortTermGoalName}
                  sx={{ ...themeStyles.textField }}
                  inputProps={{
                    maxLength: 120,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6.2}>
                <StyledTextArea
                  fullWidth
                  variant="filled"
                  multiline
                  rows={3}
                  disabled={!isActive || !parentStatus}
                  label="Treatment Plan Objective Description"
                  name={`treatmentplanObjectiveDescription-${iid}`}
                  onChange={handleGoalDescriptionChange}
                  inputProps={{
                    maxLength: 512,
                  }}
                  value={shortTermGoalDescription}
                  sx={{ ...themeStyles.textField }}
                />
              </Grid>
            </Grid>
            <AddProgramBookGoalHandler
              programBookGoals={programBookGoals}
              setProgramBookGoals={setProgramBookGoals}
              handleGoalChange={handleGoalChange}
              handleActivation={handleActivation}
              parentStatus={parentStatus && isActive}
              type={type}
            />
          </Stack>
        )}
      </Box>
    </Box>
  );
};

// ShortTermGoalHandler Component
const ShortTermGoalHandler = ({
  shortTermGoals,
  setShortTermGoals,
  handleShortTermGoalChange,
  handleActivation,
  parentStatus,
  type,
}) => {
  const [activeAreas, setActiveAreas] = useState(
    shortTermGoals?.filter(
      (val) => val.treatmentplanObjectiveStatus === "Active"
    )
  );
  const [showInactiveAreas, setShowInactiveAreas] = useState(false);

  const onShowInactiveAreasChange = (isChecked) => {
    if (isChecked) {
      setActiveAreas(shortTermGoals);
    } else {
      setActiveAreas(
        shortTermGoals?.filter(
          (val) => val.treatmentplanObjectiveStatus === "Active"
        )
      );
    }
    setShowInactiveAreas(isChecked);
  };

  const addShortTermGoal = () => {
    if (!parentStatus) {
      alert("Please make the parent area active");
      return;
    }
    const len = shortTermGoals.length;
    setShortTermGoals([
      ...shortTermGoals,
      {
        iid: len,
        visible: true,
        treatmentplanObjectiveDescription: "",
        treatmentplanObjectiveName: "",
        treatmentplanObjectiveStatus: "Active",
        shortTermObjectiveTemplates: [],
      },
    ]);
    setActiveAreas([
      ...activeAreas,
      {
        iid: len,
        visible: true,
        treatmentplanObjectiveDescription: "",
        treatmentplanObjectiveName: "",
        treatmentplanObjectiveStatus: "Active",
        shortTermObjectiveTemplates: [],
      },
    ]);
  };

  const removeShortTermGoal = (iid) => {
    setActiveAreas(activeAreas.filter((goal) => goal.iid !== iid));
    handleShortTermGoalChange(
      shortTermGoals.filter((goal) => goal.iid !== iid)
    );
  };

  const updateShortTermGoal = (iid, name, description, goals) => {
    const res = shortTermGoals.map((goal) => {
      if (goal.iid === iid) {
        return {
          ...goal,
          treatmentplanObjectiveName: name,
          treatmentplanObjectiveDescription: description,
          shortTermObjectiveTemplates: goals,
        };
      }
      return goal;
    });
    setShortTermGoals(res);
    if (showInactiveAreas) {
      setActiveAreas(res);
    } else {
      setActiveAreas(
        res?.filter((val) => val.treatmentplanObjectiveStatus === "Active")
      );
    }

    handleShortTermGoalChange(res);
  };

  const toggleVisibility = (iid) => {
    setActiveAreas(
      activeAreas.map((goal) =>
        goal.iid === iid ? { ...goal, visible: !goal.visible } : goal
      )
    );
    handleShortTermGoalChange(
      shortTermGoals.map((goal) =>
        goal.iid === iid ? { ...goal, visible: !goal.visible } : goal
      )
    );
  };

  const updateStatus = (iid, status, child) => {
    const res = shortTermGoals.map((goal) => {
      if (goal.iid === iid) {
        return {
          ...goal,
          treatmentplanObjectiveStatus: status,
          shortTermObjectiveTemplates: child,
        };
      }
      return goal;
    });
    console.log("at level 3", res);
    handleShortTermGoalChange(res);
  };

  return (
    <Box>
      <Box
        margin={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Button
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          color="primary"
          onClick={addShortTermGoal}
          sx={{ ...buttonStyle }}>
          Treatment Plan Objective
        </Button>
        <Box flexGrow={1} display="flex" justifyContent="flex-end">
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) =>
                  onShowInactiveAreasChange(event.target.checked)
                }
                name="showInactiveAreas"
                color="primary"
              />
            }
            label={<Typography variant="body1">Show Inactive</Typography>}
            style={{ marginBottom: 0, marginRight: 0 }} // Adjust as needed for alignment
          />
        </Box>
      </Box>
      <Box key={activeAreas}>
        {activeAreas?.map((goal, index) => (
          <ShortTermGoal
            key={index}
            iid={goal.iid}
            id={goal.id ? goal.id : null}
            onRemove={removeShortTermGoal}
            toggleVisibility={toggleVisibility}
            InitialProgramBookGoals={goal.shortTermObjectiveTemplates}
            InitialShortTermGoalName={goal.treatmentplanObjectiveName}
            InitialShortTermGoalDescription={
              goal.treatmentplanObjectiveDescription
            }
            visible={goal.visible}
            updateShortTermGoal={updateShortTermGoal}
            status={goal.treatmentplanObjectiveStatus}
            updateStatus={updateStatus}
            parentStatus={parentStatus}
            type={type}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ShortTermGoalHandler;
