import React, { createContext, useReducer } from "react";
import { authReducer } from "./authReducer";
import { AuthContext } from "./authContext";

const initialState = {
  isAuthenticated: false,
  username: "",
  role: "",
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
