import React, { createContext, useContext, useState } from "react";
import { Snackbar, Alert } from "@mui/material";

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const openSnackbar = (error, severity = "info") => {
    console.log(error, severity);
    let message;
    if (error && error.response && error.response.data) {
      if (typeof error.response.data === "string") {
        message = error.response.data;
      } else if (
        error.response.data.message &&
        typeof error.response.data.message === "string"
      ) {
        message = error.response.data.message;
      } else {
        message =
          "There is a technical problem, kindly consult technical support";
      }
    } else if (severity == "error" && typeof error !== "string") {
      message =
        "There is a technical problem, kindly consult technical support";
    } else message = error;

    setSnackbar({ open: true, message, severity });
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert
          onClose={closeSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}>
          {snackbar.message ||
            (snackbar.severity === "error" ? "Something went wrong" : "")}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
