import { Box } from "@mui/material";
import {
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { useTheme, Autocomplete } from "@mui/material";
import axiosInstance from "../../components/AxiosInstance";
import { DataGridPro } from "@mui/x-data-grid-pro";

import { useState, useEffect, useRef } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DownloadIcon from "@mui/icons-material/Download";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import dayjs from "dayjs";
import { Button } from "@mui/material";
import { DATA_COLLECTION_TYPES, EVENT_STATUS } from "../../constants";
import { useNavigate, useLocation } from "react-router-dom";
import { TextField } from "@mui/material";
import { Formik } from "formik";
import LoadingIndicator from "../../scenes/Loader";
import useSharedLink from "../../scenes/SharedLink";
import { themeStyles, buttonStyle } from "../../constants";
import { useSnackbar } from "../../components/SnackBarProvider";
import { hide } from "@floating-ui/react-dom";
const Scheduler = () => {
  const theme = useTheme();
  const sharedLink = useSharedLink();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [schedulerStatus, setSchedulerStatus] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [rows, setRows] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const { openSnackbar } = useSnackbar();
  const handleCloseLogoutDialog = () => {
    setOpenLogoutDialog(false);
  };
  const handleOpenLogoutDialog = () => {
    setOpenLogoutDialog(true);
  };
  const handleToggleScheduler = () => {
    // Toggle the scheduler status
    setOpenConfirmationDialog(true); // Open confirmation dialog
  };
  const handleConfirmToggle = () => {
    let endpoint = sharedLink;
    if (!schedulerStatus) {
      endpoint = endpoint + "/startrawdatamonitoring";
    } else {
      endpoint = endpoint + "/stoprawdatamonitoring";
    }
    console.log("endp", endpoint);
    try {
      axiosInstance
        .post(endpoint, { forcestop: true })
        .then((res) => {
          if (res.status == 200) {
            setSchedulerStatus(!schedulerStatus);
            openSnackbar("Success!", "success");
            console.log("response", res);
          } else {
            openSnackbar("Failed!", "error");
          }
        })
        .catch((err) => {
          console.log(err);
          openSnackbar("Failed!", "error");
        });
    } catch (error) {}
    setOpenConfirmationDialog(false); // Close confirmation dialog
  };
  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false); // Close confirmation dialog without changing the scheduler status
  };

  const initialValues = {
    clientid: "",
    sessionstartdate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
    sessionenddate: dayjs().format("YYYY-MM-DD"),
    eventstatus: "",
    datacollectiontype: "",
  };

  useEffect(() => {
    try {
      axiosInstance
        .get(sharedLink + "/healthcheck/monitor")
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            setSchedulerStatus(res.data == "False" ? false : true);
          }
        })
        .catch((err) => {
          openSnackbar(err, "error");
        });
    } catch (error) {}
    const fetchClients = async () => {
      setIsLoading(true);
      try {
        const userId = localStorage.getItem("userId");
        const userRole = localStorage.getItem("role");

        let response;
        if (userRole === "admin" && userId === 1) {
          response = await axiosInstance.post(`${sharedLink}/viewallclient` , { withCredentials: true });
        } else {
          response = await axiosInstance.post(`${sharedLink}/viewclient`, {
            staffid: userId}, { withCredentials: true });
        }
        // const response = await axios.post(
        //   localStorage.getItem("role") !== "admin"
        //     ? `${sharedLink}/viewclient`
        //     : `${sharedLink}/viewallclient`,
        //   { staffid: localStorage.getItem("userId") }
        // );
        setClients(response.data);
      } catch (error) {
        openSnackbar(
          "Failed to fetch client data. Please try again later.",
          "error"
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchClients();
  }, [sharedLink]);

  const preparePayload = (values) => {
    if (
      (values.sessionstartdate && !values.sessionenddate) ||
      (!values.sessionstartdate && values.sessionenddate)
    ) {
      openSnackbar(
        "If selecting dates, both Start Date and End Date must be selected.",
        "error"
      );
      return null;
    }

    // Data Collection Type Selection Validation
    if (
      values.datacollectiontype &&
      (!values.clientid ||
        !values.sessionstartdate ||
        !values.sessionenddate ||
        !values.eventstatus)
    ) {
      openSnackbar(
        "If selecting Data Collection Type, all fields must be selected.",
        "error"
      );
      return null;
    }

    // Ensure at least one of the required fields is present
    if (
      !values.clientid &&
      !values.sessionstartdate &&
      !values.sessionenddate &&
      !values.eventstatus
    ) {
      openSnackbar(
        "Please provide at least one of the required fields.",
        "error"
      );
      return null;
    }
    let payload = {
      ...(values.clientid && { clientid: values.clientid }),
      ...(values.sessionstartdate && {
        sessionstartdate: dayjs(values.sessionstartdate).format("YYYY-MM-DD"),
      }),
      ...(values.sessionenddate && {
        sessionenddate: dayjs(values.sessionenddate).format("YYYY-MM-DD"),
      }),
      ...(values.datacollectiontype && {
        datacollectiontype: values.datacollectiontype,
      }),
      ...(values.eventstatus && { eventstatus: values.eventstatus }),
    };

    return payload;
  };

  const dateTimeFormatter = (value, locale = "en-US") => {
    if (!value) return "";

    const date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() is zero-based
    const day = date.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    const timeFormatter = new Intl.DateTimeFormat(locale, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // true for AM/PM notation
    });

    const formattedTime = timeFormatter.format(date);

    return `${formattedDate} ${formattedTime}`;
  };

  const handleFormSubmit = async (values) => {
    const payload = preparePayload(values);
    console.log("payload ", payload);
    if (!payload) return; // Stop if the payload is invalid

    try {
      // setIsLoading(true);
      const response = await axiosInstance.post(
        `${sharedLink}/getrawdataevent`,
        payload, { withCredentials: true }
      );
      if (response.status === 200) {
        console.log("response", response);
        setRows(response.data.length > 0 ? response.data : []);
        if (response.data.length === 0) {
          openSnackbar("No data found.", "info");
        }
      } else {
        openSnackbar("An error has occurred. Please contact support.", "error");
      }
    } catch (error) {
      openSnackbar("An error has occurred. Please try again later.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },

    // Additional fields as specified
    { field: "sessionDate", headerName: "Session Date", width: 100 },
    {
      field: "clientName",
      headerName: "Client Name",
      width: 200,
      valueGetter: (params) => {
        // Assuming `clients` is an array of objects with `id`, `firstName`, and `lastName`
        const client = clients.find(
          (client) => parseInt(client.id) === parseInt(params.row.clientId)
        );
        return client ? `${client.firstName} ${client.lastName}` : "";
      },
    },
    {
      field: "dataCollectionType",
      headerName: "Data Collection Type",
      width: 180,
    },
    {
      field: "eventDateTime",
      headerName: "Event Date Time",
      width: 180,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },
    { field: "eventExecutedBy", headerName: "Event Executed By", width: 150 },
    {
      field: "eventExecutionDateTime",
      headerName: "Event Execution Date Time",
      width: 200,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },
    { field: "eventSource", headerName: "Event Source", width: 200 },
    // { field: "eventSourceId", headerName: "Event Source ID", width: 120 },
    { field: "eventStatus", headerName: "Event Status", width: 130 },
    { field: "eventType", headerName: "Event Type", width: 120 },
    { field: "programType", headerName: "Program Type", width: 150 },
  ];

  if (isloading) {
    return <LoadingIndicator />;
  } else {
    return (
      <div className="staff-background">
        <Box m="20px">
          <Header title="Event Scheduler" subtitle="" m="10px" />
          <br />
          <br />
          <Typography variant="h5" component="h5" fontWeight="bold">
            Toggle Schedular
          </Typography>
          <Box display="flex" alignItems="center" gap="10px">
            <Switch
              checked={schedulerStatus}
              onChange={handleToggleScheduler}
            />
            {schedulerStatus && (
              <div className="heartbeat-icon" style={{ color: "green" }}></div>
            )}
          </Box>
          <br />
          <br />

          <Formik onSubmit={handleFormSubmit} initialValues={initialValues}>
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                    ...themeStyles.textField,
                  }}>
                  <Autocomplete
                    fullWidth
                    options={clients}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName} [${option.email}]`
                    }
                    value={
                      clients.find((client) => client.id == values.clientid) ||
                      null
                    }
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client Name"
                        variant="filled"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select Client Name",
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      // Handle the change

                      setFieldValue("clientid", newValue ? newValue.id : "");
                    }}
                    //     disabled={localStorage.getItem("role") !== "admin"}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                  <Autocomplete
                    fullWidth
                    options={EVENT_STATUS}
                    autoHighlight
                    value={values.eventstatus}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: "eventstatus",
                          value: newValue, // This should be the new value from the Autocomplete, not event.target.value
                        },
                      });
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Event Status"
                        variant="filled"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Event status",
                        }}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="date" // Adjust the input type to "date" for picking dates
                    label="Start Date"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="animated-input"
                    value={values.sessionstartdate} // Adjust the value to correspond to a date property
                    name="sessionstartdate" // Change the name to reflect the date selection
                    InputLabelProps={{
                      shrink: true, // This property ensures the label doesn't overlap with the date value
                    }}
                    sx={themeStyles.textField}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="date" // Adjust the input type to "date" for picking dates
                    label="End Date"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="animated-input"
                    value={values.sessionenddate} // Adjust the value to correspond to a date property
                    name="sessionenddate" // Change the name to reflect the date selection
                    InputLabelProps={{
                      shrink: true, // This property ensures the label doesn't overlap with the date value
                    }}
                    sx={themeStyles.textField}
                  />

                  <Autocomplete
                    fullWidth
                    options={DATA_COLLECTION_TYPES}
                    autoHighlight
                    value={values.datacollectiontype}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: "datacollectiontype",
                          value: newValue, // This should be the new value from the Autocomplete, not event.target.value
                        },
                      });
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Data Collection Code"
                        variant="filled"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select Data Collection Type",
                        }}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </Box>
                <Box display="flex" justifyContent="end" mt="20px" mr="10px">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ ...buttonStyle }}>
                    Submit
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <Box m="20px">
            <Header title="" subtitle="" />
            <Box
              m="40px 0 0 0"
              height="75vh"
              sx={{
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .name-column--cell": {
                  color: colors.grey[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#3A57E8",
                  borderBottom: "white",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: "#3A57E8",
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.grey[200]} !important`,
                },
              }}>
              <DataGridPro
                sx={{
                  boxShadow: 5,
                  border: 5,
                  borderColor: "primary.light",
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: "white !important",
                    fontWeight: "bold !important",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#3A57E8 !important",
                    borderBottom: "white !important",
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                    height: "100%", // Ensuring the header takes full height of the column header container
                  },
                  "& .MuiDataGrid-columnSeparator": {
                    visibility: "hidden",
                  },
                  "& .MuiDataGrid-toolbarContainer": {
                    color: "white !important",
                    justifyContent: "space-between", // This should align the tools on either end of the toolbar
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                    color: "white !important",
                    fontWeight: "bold !important",
                    backgroundColor: "#3A57E8 !important",
                  },
                  "& .MuiTablePagination-root": {
                    color: "white !important",
                  },
                  "& .MuiIconButton-root": {
                    color: "black !important",
                  },
                  "& .MuiDataGrid-selectedRowCount": {
                    color: "white !important",
                  },
                }}
                pagination
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns status and traderName, the other columns will remain visible
                      id: false,
                    },
                  },
                }}
                pageSizeOptions={[25, 50, 100]}
                rows={rows}
                columns={columns}
                components={{ Toolbar: GridToolbar }}
              />
              {/* <InitialFilters row={row} /> */}
            </Box>
          </Box>
        </Box>
        <Dialog
          open={openLogoutDialog}
          onClose={handleCloseLogoutDialog}></Dialog>
        <Dialog
          open={openConfirmationDialog}
          onClose={handleCloseConfirmationDialog}>
          <DialogTitle>{"Confirm Action"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {schedulerStatus
                ? "Turn off the scheduler?"
                : "Turn on the scheduler?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmationDialog}>Cancel</Button>
            <Button onClick={handleConfirmToggle} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
};

export default Scheduler;
