import axios from "axios";
import { API_BASE_URL } from "../constants";
import { useNavigate } from "react-router-dom";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true, // Add credentials for CORS requests if necessary
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor to add the token to headers (if applicable)
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("ABAtoken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      console.log("hi");
    } else if (error.response && error.response.status === 401) {
      // Handle unauthorized errors, e.g., redirect to login
      localStorage.removeItem("ABAtoken");
      const navigate = useNavigate(); // This only works inside React components
      navigate("/login");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
