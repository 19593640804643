import React, { useState, useEffect } from "react";
import { TextField, Button, Box, Typography, Paper } from "@mui/material";
import { API_BASE_URL } from "../../constants";
import axiosInstance from "../../components/AxiosInstance";
import { useLocation } from 'react-router-dom';
import {useSnackbar} from "../../components/SnackBarProvider"; // Import useSnackbar hook





const ChangePassword = () => {
  const query = useQuery();
  const token = query.get('token');

  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { openSnackbar } = useSnackbar(); // Use the useSnackbar hook

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    axiosInstance.get(API_BASE_URL + `/api/reset-password/verify?token=${token}`)
      .then(response => {
        setEmail(response.data.email);
      })
      .catch(error => {
        console.log("invalid token");
      });
  }, [token]);

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleGoClick = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      openSnackbar("Passwords do not match", "error"); // Show error snackbar if passwords don't match
      return;
    }

    axiosInstance.post(API_BASE_URL + '/api/resetpassword/confirm', {
      token: token,
      newPassword: newPassword
    })
      .then(response => {
        openSnackbar("Password has been reset successfully", "success"); // Show success snackbar on successful reset
      })
      .catch(error => {
        openSnackbar(error,"error"); // Show error snackbar on failure
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
        backgroundColor: "#f0f4f8",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 4,
          width: "500px",
          borderRadius: "10px",
          backgroundColor: "#87CEFA",
          height: "570px",
        }}
      >
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          align="center"
          marginBottom={5}
          sx={{ fontWeight: "bold" }}
        >
          Change Password
        </Typography>

        <Box component="form" noValidate autoComplete="off">
          <Typography
            variant="h6"
            gutterBottom
            sx={{ letterSpacing: "0.35em" }}
          >
            Email
          </Typography>

          <TextField
            fullWidth
            variant="outlined"
            disabled
            placeholder="Enter your email"
            value={email}
            InputProps={{
              style: {
                backgroundColor: "#ffffff",
                borderRadius: "5px",
              },
            }}
            sx={{ marginBottom: 2 }}
          />

          <Typography
            variant="h6"
            gutterBottom
            sx={{ letterSpacing: "0.1em" }}
          >
            Type New Password
          </Typography>

          <TextField
            fullWidth
            type="password"
            variant="outlined"
            placeholder="Enter new password"
            value={newPassword}
            onChange={handleNewPasswordChange}
            InputProps={{
              style: {
                backgroundColor: "#ffffff",
                borderRadius: "5px",
              },
            }}
            sx={{ marginBottom: 2 }}
          />

          <Typography
            variant="h6"
            gutterBottom
            sx={{ letterSpacing: "0.1em" }}
          >
            Confirm New Password
          </Typography>

          <TextField
            fullWidth
            type="password"
            variant="outlined"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            InputProps={{
              style: {
                backgroundColor: "#ffffff",
                borderRadius: "5px",
              },
            }}
            sx={{ marginBottom: 5 }}
          />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleGoClick}
            sx={{
              backgroundColor: "#007BFF",
              fontWeight: "bold",
              fontSize: "15px",
              height: "40px",
              "&:hover": {
                backgroundColor: "#0056b3",
              },
            }}
          >
            GO
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default ChangePassword;
