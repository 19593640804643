// Modified AddSchedule Component
import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Autocomplete,
  Box,
  IconButton,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DataGridPro } from "@mui/x-data-grid-pro";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import useSharedLink from "../../scenes/SharedLink";
import { themeStyles } from "../../constants";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "../../components/SnackBarProvider";
import axiosInstance from "../../components/AxiosInstance";

const validationRules = [{ label: "26 Weeks" }, { label: "Actual Weeks" }];

const scheduleTypes = [{ label: "Weekly" }, { label: "Adhoc" }];

const daysOfWeek = [
  { label: "Monday" },
  { label: "Tuesday" },
  { label: "Wednesday" },
  { label: "Thursday" },
  { label: "Friday" },
  { label: "Saturday" },
  { label: "Sunday" },
];

const procedureCodesWeekly = [
  { label: "97153" },
  { label: "97155" },
  { label: "97156" },
  { label: "97158" },
  { label: "97154" },
  { label: "H0032" },
];

const procedureCodesAdhoc = [
  { label: "97151" },
  { label: "97153" },
  { label: "97155" },
  { label: "97156" },
  { label: "97158" },
  { label: "97154" },
  { label: "H0032" },
];

const AddSchedule = () => {
  const sharedLink = useSharedLink();
  const { openSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const isEdit = location.state?.isEdit || false;
  const id = location.state?.id || null;

  const [formData, setFormData] = useState({
    clientName: null,
    insuranceName: "",
    dateOfBirth: null,
    policyNumber: "",
    procedureCode: "",
    totalUnits: "",
    authorizationNumber: null,
    authorizationStartDate: null,
    authorizationEndDate: null,
    procedureCodes: [], // State to store multiple procedure codes
  });
  const [validateAgainstAuthorization, setValidateAgainstAuthorization] =
    useState(true);
  const [validationRule, setValidationRule] = useState(validationRules[0]);
  const [clients, setClients] = useState([]);
  const [authorizations, setAuthorizations] = useState([]); // State for authorizations
  const [showScheduleFields, setShowScheduleFields] = useState(false);
  const [scheduleType, setScheduleType] = useState(null);
  const [scheduleRows, setScheduleRows] = useState([]);
  const [editRowId, setEditRowId] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [weeklyScheduleData, setWeeklyScheduleData] = useState([]);
  const [adhocScheduleData, setAdhocScheduleData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientResponse = await axiosInstance.post(
          sharedLink + "/viewallclient",
          { withCredentials: true }
        );
        const clientsData = clientResponse.data.map((client) => ({
          label: client.firstName + " " + client.lastName,
          ...client,
        }));
        setClients(clientsData);

        // EDIT MODE CODE STARTS
        if (isEdit && id) {
          const response = await axiosInstance.post(
            sharedLink + "/getclientschedules",
            { id },
            { withCredentials: true }
          );
          const editData = response.data[0];

          // Ensure these arrays exist
          editData.weeklyClientScheduleDetails =
            editData.weeklyClientScheduleDetails || [];
          editData.adhocClientScheduleDetails =
            editData.adhocClientScheduleDetails || [];

          console.log(editData.weeklyClientScheduleDetails);
          const authResponse = await axiosInstance.post(
            sharedLink + "/getpriorauthorizations",
            {
              clientId: editData.clientId,
            },
            { withCredentials: true }
          );
          const authorizationsData = authResponse.data.flatMap((auth) =>
            auth.priorAuthorizationDetails.map((detail) => ({
              label: detail.priorAuthorizationNo,
              ...detail,
            }))
          );
          setAuthorizations(authorizationsData);

          const weeklyData = editData.weeklyClientScheduleDetails.map(
            (detail) => ({
              id: detail.id,
              scheduleType: "Weekly",
              day: detail.day,
              procedureCode: detail.procedureCode,
              units: detail.units,
            })
          );

          const adhocData = editData.adhocClientScheduleDetails.map(
            (detail) => ({
              id: detail.id,
              scheduleType: "Ad-hoc",
              date: dayjs(detail.date),
              procedureCode: detail.procedureCode,
              units: detail.units,
            })
          );

          const authNo =
            editData.weeklyClientScheduleDetails.length > 0
              ? editData.weeklyClientScheduleDetails[0].authorizationNumber
              : editData.adhocClientScheduleDetails[0].authorizationNumber;
          const authObj = authorizationsData.find(
            (data) => data.label === authNo
          );
          console.log(authObj);
          console.log(authNo);
          setFormData({
            clientName: { label: editData.clientName, id: editData.clientId },
            insuranceName: editData.insuranceName,
            dateOfBirth: dayjs(editData.dateOfBirth),
            policyNumber: editData.policyNumber,
            priorAuthorizationNo: authObj.priorAuthorizationNo,

            authorizationStartDate:
              editData.weeklyClientScheduleDetails.length > 0
                ? dayjs(
                    editData.weeklyClientScheduleDetails[0]
                      .authorizationStartDate
                  )
                : dayjs(
                    editData.adhocClientScheduleDetails[0]
                      .authorizationStartDate
                  ),
            authorizationEndDate:
              editData.weeklyClientScheduleDetails.length > 0
                ? dayjs(
                    editData.weeklyClientScheduleDetails[0].authorizationEndDate
                  )
                : dayjs(
                    editData.adhocClientScheduleDetails[0].authorizationEndDate
                  ),
            procedureCodes: [], // Reset procedure codes
          });
          handleAuthorizationChange(authObj);
          setWeeklyScheduleData(weeklyData);
          setAdhocScheduleData(adhocData);
          setValidateAgainstAuthorization(editData.verificationRuleImplemented);
          setValidationRule({ label: editData.verificationRuleUsed });
        }
        // EDIT MODE CODE ENDS
      } catch (error) {
        console.error("Error fetching data:", error);
        setSnackbar({
          open: true,
          message: "Error fetching client data",
          severity: "error",
        });
      }
    };

    fetchData();
  }, [sharedLink, isEdit, id]);

  //EDIT MODE CODE ENDS:-

  const handleClientChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      clientName: newValue,
      insuranceName: newValue ? newValue.insuranceName : "",
      dateOfBirth: newValue ? dayjs(newValue.clientDateOfBirth) : null,
      policyNumber: newValue ? newValue.policyNumber : "",
    }));

    setAuthorizations([]); // Clear authorizations when client changes
  };

  //AUTO POPULATES AUTHORIZATION DETAILS ON SELECTING NUMBER

  const handleAuthorizationChange = (newValue) => {
    if (newValue) {
      const selectedAuth = newValue;
      console.log(newValue);

      setFormData((prevData) => ({
        ...prevData,
        authorizationNumber: selectedAuth.label,
        authorizationStartDate: selectedAuth.priorAuthorizationStartDate
          ? dayjs(selectedAuth.priorAuthorizationStartDate)
          : null,
        authorizationEndDate: selectedAuth.priorAuthorizationEndDate
          ? dayjs(selectedAuth.priorAuthorizationEndDate)
          : null,
        procedureCodes:
          selectedAuth.aprrovedUnits.length > 1
            ? selectedAuth.aprrovedUnits
            : [],
        procedureCode:
          selectedAuth.aprrovedUnits.length === 1
            ? selectedAuth.aprrovedUnits[0].procedureCode
            : "",
        totalUnits:
          selectedAuth.aprrovedUnits.length === 1
            ? selectedAuth.aprrovedUnits[0].unitsTotal
            : "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        authorizationNumber: null,
        authorizationStartDate: null,
        authorizationEndDate: null,
        procedureCodes: [],
        procedureCode: "",
        totalUnits: "",
      }));
    }
  };

  //AUTHORIZATION DROPDOWN FOR NUMBER

  const handleAuthorizationDropdownOpen = async () => {
    if (formData.clientName) {
      try {
        const authResponse = await axiosInstance.post(
          sharedLink + "/getpriorauthorizations",
          {
            clientId: formData.clientName.id,
          },
          { withCredentials: true }
        );
        const authorizationsData = authResponse.data.flatMap((auth) =>
          auth.priorAuthorizationDetails.map((detail) => ({
            label: detail.priorAuthorizationNo,
            ...detail,
          }))
        );
        setAuthorizations(authorizationsData);
      } catch (error) {
        console.error("Error fetching authorizations:", error);
        setSnackbar({
          open: true,
          message: "Error fetching authorization data",
          severity: "error",
        });
      }
    }
  };

  const handleInputChange = (field) => (event) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: event.target.value,
    }));
  };

  //used in date of birth field ,authorization start date ,end date.
  // const handleDateChange = (field) => (newValue) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [field]: newValue ? dayjs(newValue) : null,
  //   }));
  // };

  const handleCheckboxChange = (event) => {
    setValidateAgainstAuthorization(event.target.checked);
  };

  const handleValidationRuleChange = (event, newValue) => {
    setValidationRule(newValue);
  };

  //SCHEDULE TYPE FIELD AND DISPLAYING ROWS ACCORDING TO THE SELECTION
  const handleScheduleTypeChange = (event, newValue) => {
    setScheduleType(newValue);
    if (newValue?.label === "Weekly") {
      setScheduleRows([{ day: "", units: "", procedureCode: "" }]);
    } else if (newValue?.label === "Adhoc") {
      setScheduleRows([{ date: null, units: "", procedureCode: "" }]);
    } else {
      setScheduleRows([]);
    }
  };

  //when i select a date,day,procedure code it handles it.
  const handleScheduleRowChange = (index, field, newValue) => {
    const updatedRows = [...scheduleRows];

    if (field === "date") {
      updatedRows[index][field] = newValue ? dayjs(newValue) : null;
    } else {
      // Handle the case where newValue is null (e.g., when clearing a selected value)
      if (newValue === null) {
        updatedRows[index][field] = "";
      } else {
        updatedRows[index][field] = newValue?.label || newValue.target.value;
      }
    }
    setScheduleRows(updatedRows);
  };

  //IT HANDLES ADDING OF NEW ROW EITHER FOR WEEKLY OR ADHOC AND IT IS CALLED IN THE ADD ROW button

  const addScheduleRow = () => {
    if (scheduleRows.length < 7) {
      if (scheduleType?.label === "Weekly") {
        setScheduleRows((prevRows) => [
          ...prevRows,
          { id: scheduleRows.length, day: "", units: "", procedureCode: "" },
        ]);
      } else if (scheduleType?.label === "Adhoc") {
        setScheduleRows((prevRows) => [
          ...prevRows,
          { id: scheduleRows.length, date: null, units: "", procedureCode: "" },
        ]);
      }
    } else {
      openSnackbar("You can only add up to 7 rows.", "error");
    }
  };

  //delete schedule row for both weekly and adhoc
  const deleteScheduleRow = (index) => {
    const updatedRows = [...scheduleRows];
    updatedRows.splice(index, 1);
    setScheduleRows(updatedRows);
  };

  //deleting row  as it analyse the id of the row mane add row koira j delete icon da aye.

  const deleteScheduleDataRow = (id, type) => {
    if (type === "Weekly") {
      setWeeklyScheduleData((prevData) =>
        prevData.filter((row) => row.id !== id)
      );
    } else {
      setAdhocScheduleData((prevData) =>
        prevData.filter((row) => row.id !== id)
      );
    }
  };

  //table a dhukar pore jodi edit click kori toh row id da analyse korbo.
  const handleEditRow = (rowId, type) => {
    let row;
    if (type === "Weekly") {
      row = weeklyScheduleData.find((r) => r.id === rowId);
    } else {
      row = adhocScheduleData.find((r) => r.id === rowId);
    }

    setScheduleType({ label: type });
    setScheduleRows([row]);
    setEditRowId(rowId);
    setShowScheduleFields(true);
  };

  const toggleScheduleFields = () => {
    setShowScheduleFields((prev) => !prev);
  };

  const saveScheduleType = () => {
    if (scheduleType) {
      if (scheduleType.label === "Weekly") {
        if (editRowId) {
          // Update the existing row
          setWeeklyScheduleData((prevData) =>
            prevData.map((data) => {
              if (data.id === editRowId) {
                return {
                  ...scheduleRows[0], // Update with the first row from scheduleRows
                  id: editRowId, // Maintain the same id
                };
              }
              return data;
            })
          );
        } else {
          // Add new rows
          const newRows = scheduleRows.map((row, index) => ({
            id: weeklyScheduleData.length + index + 1, // Ensure unique IDs
            ...row,
          }));
          setWeeklyScheduleData([...weeklyScheduleData, ...newRows]);
        }
      } else if (scheduleType.label === "Adhoc") {
        if (editRowId) {
          // Update the existing row
          setAdhocScheduleData((prevData) =>
            prevData.map((data) => {
              if (data.id === editRowId) {
                return {
                  ...scheduleRows[0], // Update with the first row from scheduleRows
                  id: editRowId, // Maintain the same id
                };
              }
              return data;
            })
          );
        } else {
          // Add new rows
          const newRows = scheduleRows.map((row, index) => ({
            id: adhocScheduleData.length + index + 1, // Ensure unique IDs
            ...row,
          }));
          setAdhocScheduleData([...adhocScheduleData, ...newRows]);
        }
      }
      setScheduleType(null);
      setScheduleRows([]);
      setEditRowId(null); // Reset the edit row ID
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.clientName) {
      openSnackbar("Client Name is required", "error");
      return;
    }

    // Prepare the data for submission, only taking schedule details from the DataGrid rows
    const weeklyClientScheduleDetails = weeklyScheduleData.map((data) => ({
      authorizationNumber: formData.authorizationNumber,
      authorizationStartDate: formData.authorizationStartDate
        ? formData.authorizationStartDate.toISOString().split("T")[0]
        : null,
      authorizationEndDate: formData.authorizationEndDate
        ? formData.authorizationEndDate.toISOString().split("T")[0]
        : null,
      scheduleType: "Weekly",
      day: data.day,
      procedureCode: data.procedureCode,
      units: data.units,
    }));

    const adhocClientScheduleDetails = adhocScheduleData.map((data) => ({
      authorizationNumber: formData.authorizationNumber,
      authorizationStartDate: formData.authorizationStartDate
        ? formData.authorizationStartDate.toISOString().split("T")[0]
        : null,
      authorizationEndDate: formData.authorizationEndDate
        ? formData.authorizationEndDate.toISOString().split("T")[0]
        : null,
      scheduleType: "Ad-hoc",
      date: data.date ? data.date.toISOString().split("T")[0] : null,
      procedureCode: data.procedureCode,
      units: data.units,
    }));

    const submitData = {
      clientId: formData.clientName?.id,
      insuranceName: formData.insuranceName,
      policyNumber: formData.policyNumber,
      weeklyClientScheduleDetails:
        weeklyClientScheduleDetails.length > 0
          ? weeklyClientScheduleDetails
          : [],
      adhocClientScheduleDetails:
        adhocClientScheduleDetails.length > 0 ? adhocClientScheduleDetails : [],
      enteredBy: "admin",
      lastUpdatedBy: "admin",
      enteredTime: new Date().toISOString().split("T")[0],
      lastUpdatedTime: new Date().toISOString().split("T")[0],
      status: "Active",
      verificationRuleUsed: validationRule.label,
      verificationRuleImplemented: validateAgainstAuthorization,
    };

    // Check if only Adhoc schedule details are present and set the ID for an existing client
    if (
      adhocClientScheduleDetails.length > 0 &&
      weeklyClientScheduleDetails.length === 0
    ) {
      submitData.id = formData.clientName?.id; // Add the ID for Adhoc schedule
    }

    console.log(submitData);

    try {
      if (isEdit) {
        submitData.id = id;
        await axiosInstance.post(
          `${sharedLink}/updateclientschedule`,
          submitData,
          {
            withCredentials: true,
          }
        ); // Use the provided API endpoint for update
        openSnackbar("Schedule Updated Successfully", "success");
      } else {
        await axiosInstance.post(
          `${sharedLink}/addclientschedules`,
          submitData,
          {
            withCredentials: true,
          }
        ); // Use the provided API endpoint for creation
        openSnackbar("Schedule Added Successfully", "success");
      }

      if (submitData && !isEdit) {
        setFormData({
          clientName: null,
          insuranceName: "",
          dateOfBirth: null,
          policyNumber: "",
          procedureCode: "",
          totalUnits: "",
          authorizationNumber: null,
          authorizationStartDate: null,
          authorizationEndDate: null,
          procedureCodes: [], // Reset procedure codes
        });
        setValidateAgainstAuthorization(true);
        setValidationRule(validationRules[0]);
        setWeeklyScheduleData([]);
        setAdhocScheduleData([]);
        setShowScheduleFields(false);
      }
      // if (isEdit) {
      //   navigate("/manageSchedule"); // Navigate back to ManageSchedule after update
      // }
    } catch (error) {
      console.error("Error submitting data:", error);
      openSnackbar(error, "error");
    }
  };

  const weeklyColumns = [
    { field: "day", headerName: "Day", width: 150 },
    { field: "procedureCode", headerName: "Procedure Code", width: 150 },
    { field: "units", headerName: "Units", width: 100 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEditRow(params.row.id, "Weekly")}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => deleteScheduleDataRow(params.row.id, "Weekly")}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const adhocColumns = [
    { field: "date", headerName: "Date", width: 250 },
    { field: "procedureCode", headerName: "Procedure Code", width: 150 },
    { field: "units", headerName: "Units", width: 100 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEditRow(params.row.id, "Adhoc")}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => deleteScheduleDataRow(params.row.id, "Adhoc")}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <div>
      <Typography
        variant="h4"
        sx={{
          mb: 10,
          textAlign: "center",
          fontWeight: "bold",
          fontSize: 35,
          ...themeStyles.textField,
        }}>
        {isEdit ? "Update Client Schedule" : "Add Client Schedule"}
      </Typography>
      <form style={{ margin: "20px" }} onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
          <Autocomplete
            fullWidth
            options={clients}
            getOptionLabel={(option) => option.label}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={
                  <span>
                    Client Name<span style={{ color: "red" }}>*</span>
                  </span>
                }
                variant="outlined"
                margin="normal"
              />
            )}
            value={formData.clientName}
            onChange={handleClientChange}
            sx={{ flex: "1 1 48%", ...themeStyles.textField, mt: 2 }}
            disabled={isEdit} // Disable client name in Update page
          />

          <TextField
            label="Insurance Name"
            variant="outlined"
            value={formData.insuranceName}
            className="animated-input"
            onChange={handleInputChange("insuranceName")}
            fullWidth
            margin="normal"
            sx={{ flex: "1 1 48%", ...themeStyles.textField, mt: 3.9 }}
          />

          <DatePicker
            label="Date of Birth"
            value={formData.dateOfBirth}
            // onChange={handleDateChange("dateOfBirth")}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                margin="normal"
                disabled={Boolean(formData.clientName)}
              />
            )}
            sx={{ flex: "1 1 48%", ...themeStyles.textField, mt: 2 }}
            disabled={Boolean(formData.clientName)}
          />

          <TextField
            label="Policy Number"
            variant="outlined"
            value={formData.policyNumber}
            className="animated-input"
            onChange={handleInputChange("policyNumber")}
            fullWidth
            margin="normal"
            sx={{ flex: "1 1 48%", ...themeStyles.textField, mt: 2 }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={validateAgainstAuthorization}
                onChange={handleCheckboxChange}
                name="validateAgainstAuthorization"
                color="primary"
              />
            }
            className="animated-input"
            label="Validate Against Authorization"
            sx={{ flex: "1 1 48%", ...themeStyles.textField, mt: 2 }}
          />

          <Autocomplete
            fullWidth
            options={validationRules}
            getOptionLabel={(option) => option.label}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Validation Rule"
                variant="outlined"
                margin="normal"
              />
            )}
            value={validationRule}
            onChange={handleValidationRuleChange}
            sx={{ flex: "1 1 48%", ...themeStyles.textField }}
          />

          {isEdit ? (
            <TextField
              fullWidth
              label="Authorization Number"
              variant="outlined"
              value={formData.authorizationNumber}
              disabled
              margin="normal"
              sx={{ flex: "1 1 100%", ...themeStyles.textField, mt: 2 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : (
            <Autocomplete
              fullWidth
              options={authorizations}
              getOptionLabel={(option) => option.label}
              className="animated-input"
              onOpen={handleAuthorizationDropdownOpen}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Authorization Number"
                  variant="outlined"
                  margin="normal"
                />
              )}
              value={formData.priorAuthorizationNo}
              onChange={handleAuthorizationChange}
              sx={{ flex: "1 1 100%", ...themeStyles.textField, mt: 2 }}
            />
          )}

          {formData.procedureCodes.length === 0 && (
            <React.Fragment>
              <TextField
                label="Procedure Code"
                variant="outlined"
                value={formData.procedureCode}
                className="animated-input"
                onChange={handleInputChange("procedureCode")}
                fullWidth
                margin="normal"
                sx={{ flex: "1 1 48%", ...themeStyles.textField, mt: 2 }}
                disabled
              />

              <TextField
                label="Total Units"
                variant="outlined"
                value={formData.totalUnits}
                className="animated-input"
                onChange={handleInputChange("totalUnits")}
                fullWidth
                margin="normal"
                sx={{ flex: "1 1 48%", ...themeStyles.textField, mt: 2 }}
                disabled
              />
            </React.Fragment>
          )}

          {formData.procedureCodes.map((procedure, index) => (
            <React.Fragment key={index}>
              <TextField
                label="Procedure Code"
                variant="outlined"
                value={procedure.procedureCode}
                className="animated-input"
                fullWidth
                margin="normal"
                sx={{ flex: "1 1 48%", ...themeStyles.textField, mt: 2 }}
                disabled
              />

              <TextField
                label="Total Units"
                variant="outlined"
                value={procedure.unitsTotal}
                className="animated-input"
                fullWidth
                margin="normal"
                sx={{ flex: "1 1 48%", ...themeStyles.textField, mt: 2 }}
                disabled
              />
            </React.Fragment>
          ))}

          <DatePicker
            label="Authorization Start Date"
            value={formData.authorizationStartDate}
            // onChange={handleDateChange("authorizationStartDate")}
            className="animated-input"
            renderInput={(params) => (
              <TextField {...params} fullWidth margin="normal" />
            )}
            sx={{ flex: "1 1 48%", ...themeStyles.textField, mt: 2 }}
            disabled
          />

          <DatePicker
            label="Authorization End Date"
            value={formData.authorizationEndDate}
            // onChange={handleDateChange("authorizationEndDate")}
            minDate={formData.authorizationStartDate}
            className="animated-input"
            renderInput={(params) => (
              <TextField {...params} fullWidth margin="normal" />
            )}
            sx={{ flex: "1 1 48%", ...themeStyles.textField, mt: 2 }}
            disabled
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 4 }}>
          <Button
            type="button"
            variant="contained"
            className="animated-input"
            onClick={toggleScheduleFields}
            sx={{
              backgroundColor: "#3A57E8",
              color: "white",
              fontWeight: "bold",
              borderRadius: "20px",
              padding: "8px 16px",
              "&:hover": {
                backgroundColor: "#0059b2",
              },
            }}>
            {showScheduleFields ? "Hide Scheduler" : "Add Scheduler"}
          </Button>
        </Box>

        {showScheduleFields && (
          <Box sx={{ mt: 4, display: "flex", flexWrap: "wrap", gap: "16px" }}>
            <Autocomplete
              fullWidth
              options={scheduleTypes.filter(
                (type) =>
                  !weeklyScheduleData.find(
                    (data) => data.type === type.label
                  ) || type.label === "Adhoc"
              )}
              getOptionLabel={(option) => option.label}
              className="animated-input"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Schedule Type"
                  variant="outlined"
                  margin="normal"
                />
              )}
              value={scheduleType}
              onChange={handleScheduleTypeChange}
              sx={{ flex: "1 1 100%", ...themeStyles.textField }}
            />

            {scheduleRows.map((row, index) => (
              <React.Fragment key={index}>
                {scheduleType?.label === "Weekly" ? (
                  <>
                    <Autocomplete
                      fullWidth
                      options={daysOfWeek}
                      getOptionLabel={(option) => option.label}
                      className="animated-input"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Day"
                          variant="outlined"
                          margin="normal"
                        />
                      )}
                      value={{ label: row.day }}
                      onChange={(event, newValue) =>
                        handleScheduleRowChange(index, "day", newValue)
                      }
                      sx={{ flex: "1 1 25%", ...themeStyles.textField }}
                    />

                    <Autocomplete
                      fullWidth
                      options={procedureCodesWeekly}
                      getOptionLabel={(option) => option.label}
                      className="animated-input"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="animated-input"
                          fullWidth
                          label="Procedure Code"
                          variant="outlined"
                          margin="normal"
                        />
                      )}
                      value={{ label: row.procedureCode }}
                      onChange={(event, newValue) =>
                        handleScheduleRowChange(
                          index,
                          "procedureCode",
                          newValue
                        )
                      }
                      sx={{ flex: "1 1 25%", ...themeStyles.textField }}
                    />

                    <TextField
                      label="Units"
                      variant="outlined"
                      type="number"
                      value={row.units}
                      className="animated-input"
                      onChange={(event) =>
                        handleScheduleRowChange(index, "units", event)
                      }
                      fullWidth
                      margin="normal"
                      sx={{
                        flex: "1 1 25%",
                        ...themeStyles.textField,
                        mt: scheduleType?.label === "Weekly" ? 2 : 4,
                      }}
                      inputProps={{ min: 1 }}
                    />

                    {index > 0 && (
                      <IconButton
                        onClick={() => deleteScheduleRow(index)}
                        sx={{ mt: 3 }}>
                        <DeleteIcon sx={{ fontSize: "2rem" }} />
                      </IconButton>
                    )}
                  </>
                ) : (
                  <>
                    <DatePicker
                      label="Date"
                      value={row.date}
                      onChange={(newValue) =>
                        handleScheduleRowChange(index, "date", newValue)
                      }
                      className="animated-input"
                      renderInput={(params) => (
                        <TextField {...params} fullWidth margin="normal" />
                      )}
                      sx={{ flex: "1 1 30%", ...themeStyles.textField, mt: 4 }}
                      maxDate={dayjs()}
                    />

                    <Autocomplete
                      fullWidth
                      options={procedureCodesAdhoc}
                      getOptionLabel={(option) => option.label}
                      className="animated-input"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Procedure Code"
                          variant="outlined"
                          margin="normal"
                        />
                      )}
                      value={{ label: row.procedureCode }}
                      onChange={(event, newValue) =>
                        handleScheduleRowChange(
                          index,
                          "procedureCode",
                          newValue
                        )
                      }
                      sx={{ flex: "1 1 30%", ...themeStyles.textField, mt: 2 }}
                    />

                    <TextField
                      label="Units"
                      variant="outlined"
                      type="number"
                      value={row.units}
                      className="animated-input"
                      onChange={(event) =>
                        handleScheduleRowChange(index, "units", event)
                      }
                      fullWidth
                      margin="normal"
                      sx={{
                        flex: "1 1 30%",
                        ...themeStyles.textField,
                        mt: scheduleType?.label === "Weekly" ? 2 : 4,
                      }}
                      inputProps={{ min: 1 }}
                    />

                    {index > 0 && (
                      <IconButton
                        onClick={() => deleteScheduleRow(index)}
                        sx={{ mt: 3 }}>
                        <DeleteIcon sx={{ fontSize: "2rem" }} />
                      </IconButton>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
          </Box>
        )}

        {scheduleType && showScheduleFields && (
          <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2 }}>
            <Button
              type="button"
              variant="contained"
              className="animated-input"
              onClick={addScheduleRow}
              startIcon={<AddCircleOutlineIcon />}
              sx={{
                backgroundColor: "#3A57E8",
                color: "white",
                fontWeight: "bold",
                borderRadius: "20px",
                padding: "8px 16px",
                "&:hover": {
                  backgroundColor: "#0059b2",
                },
              }}>
              Add Row
            </Button>
            <Button
              type="button"
              variant="contained"
              className="animated-input"
              onClick={saveScheduleType}
              sx={{
                backgroundColor: "#3A57E8",
                color: "white",
                fontWeight: "bold",
                borderRadius: "20px",
                padding: "8px 16px",
                ml: 2,
                "&:hover": {
                  backgroundColor: "#0059b2",
                },
              }}>
              Save Schedule
            </Button>
          </Box>
        )}

        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 10 }}>
          <Box sx={{ width: "45%", ...themeStyles.textField, height: "450px" }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Weekly Schedule
            </Typography>
            <DataGridPro
              rows={weeklyScheduleData.map((data, index) => ({
                id: data.id, // Ensure unique ID
                day: data.day,
                procedureCode: data.procedureCode,
                units: data.units,
              }))}
              columns={weeklyColumns}
              pageSize={5}
              className="animated-input"
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#3A57E8",
                  color: "white",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
                backgroundColor: "#3A57E8",
                color: "white",
                borderTop: "1px solid #fff",
              }}>
              <Typography variant="subtitle1">
                Total Rows: {weeklyScheduleData.length}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ width: "45%", ...themeStyles.textField }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Adhoc Schedule
            </Typography>
            <DataGridPro
              rows={adhocScheduleData.map((data, index) => ({
                id: data.id, // Ensure unique ID
                date: data.date,
                procedureCode: data.procedureCode,
                units: data.units,
              }))}
              columns={adhocColumns}
              pageSize={5}
              className="animated-input"
              sx={{
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#3A57E8",
                  color: "white",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
                backgroundColor: "#3A57E8",
                color: "white",
                borderTop: "1px solid #fff",
              }}>
              <Typography variant="subtitle1">
                Total Rows: {adhocScheduleData.length}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 10, gap: 2 }}>
          {isEdit && (
            <Button
              type="button"
              variant="contained"
              className="animated-input"
              onClick={() => navigate("/manageSchedule")}
              sx={{
                backgroundColor: "#3A57E8",
                color: "white",
                fontWeight: "bold",
                borderRadius: "20px",
                padding: "12px 24px",
                fontSize: "16px",
                height: "48px",
                mt: 10,
                "&:hover": {
                  backgroundColor: "#0059b2",
                },
              }}>
              GO BACK
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            className="animated-input"
            sx={{
              backgroundColor: "#3A57E8",
              color: "white",
              fontWeight: "bold",
              borderRadius: "20px",
              padding: "12px 24px",
              fontSize: "16px",
              height: "48px",
              mt: 10,
              "&:hover": {
                backgroundColor: "#0059b2",
              },
            }}>
            {isEdit ? "UPDATE" : "Submit"}
          </Button>
        </Box>
      </form>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddSchedule;
