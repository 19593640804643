import React, { useEffect, useState } from "react";
import { useTrail, animated } from "react-spring";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../Loader";
import { usePermissions } from "../../Phase3/CheckPermission";
import {
  SESSION_NOTE_97151,
  SESSION_NOTE_97153,
  SESSION_NOTE_97155,
  SESSION_NOTE_97156,
  SESSION_NOTE_H0032,
} from "../../constants";

const ChooseSessionNotePage = () => {
  const { checkPermission } = usePermissions();

  const sessionNotes = [];
  if (checkPermission(SESSION_NOTE_97151, "c"))
    sessionNotes.push({ id: "97151", path: "/sessionNote97151" });
  if (checkPermission(SESSION_NOTE_97153, "c"))
    sessionNotes.push({ id: "97153", path: "/sessionNote" });
  if (checkPermission(SESSION_NOTE_97156, "c"))
    sessionNotes.push({ id: "97156", path: "/sessionNote97156" });
  if (checkPermission(SESSION_NOTE_97155, "c"))
    sessionNotes.push({ id: "97155", path: "/sessionNote97155" });
  if (checkPermission(SESSION_NOTE_H0032, "c"))
    sessionNotes.push({ id: "H0032", path: "/sessionNoteH0032" });
  const navigate = useNavigate();
  const trail = useTrail(sessionNotes.length, {
    from: { opacity: 0, transform: "scale(0)" },
    to: { opacity: 1, transform: "scale(1)" },
    config: { tension: 170, friction: 26 },
    delay: 1000, // Delay before the animation starts
  });

  return (
    <div className="staff-background" style={{ height: 1000 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "100px",
        }}>
        <h1
          style={{
            textAlign: "center",
            marginBottom: "40px",
            fontWeight: "bold",
          }}>
          Session Notes
        </h1>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}>
          {trail.map((props, index) => (
            <animated.div
              key={sessionNotes[index].id}
              style={{
                ...props,
                margin: 20,
                cursor: "pointer",
                boxShadow: "0px 5px 15px rgba(0,0,0,0.2)",
              }}
              onClick={() => navigate(sessionNotes[index].path)}>
              <div
                style={{
                  padding: "20px 40px",
                  background: "#3A57E8",
                  fontSize: "20px",
                  borderRadius: "8px",
                  color: "white",
                }}>
                {sessionNotes[index].id}
              </div>
            </animated.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChooseSessionNotePage;
