import {
  FETCH_EVENTS,
  ADD_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  FETCH_EVENT_BY_ID,
  EVENT_FAILURE,
} from "../../Actions/actionTypes";

const initialState = {
  eventsByStaff: {}, // Store events keyed by staff ID
  selectedEvent: null,
  selectedStaffs: [],
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENTS:
      const { staffIds, events } = action.payload;
      console.log(staffIds, "staffid");
      console.log(events, "events");
      const updatedEventsByStaff = { ...state.eventsByStaff };

      staffIds.forEach((staffId) => {
        updatedEventsByStaff[staffId.id] = events.filter(
          (event) => String(event.staffId) === String(staffId.id)
        );
      });

      return {
        ...state,
        eventsByStaff: updatedEventsByStaff,
        selectedStaffs: staffIds,
      };

    case ADD_EVENT:
      const newEvent = action.payload;
      const staffIdToAdd = String(newEvent.staffId);

      // Print the previous state
      console.log("Previous state:", state);

      const updatedState = {
        ...state,
        eventsByStaff: {
          ...state.eventsByStaff,
          [staffIdToAdd]: [
            ...(state.eventsByStaff[staffIdToAdd] || []),
            newEvent,
          ],
        },
      };

      // Print the new state
      console.log("New state:", updatedState);

      return updatedState;

    case UPDATE_EVENT:
      const eventToUpdate = action.payload[0];
      const staffIdToUpdate = String(eventToUpdate.staffId);

      return {
        ...state,
        eventsByStaff: {
          ...state.eventsByStaff,
          [staffIdToUpdate]: state.eventsByStaff[staffIdToUpdate].map((event) =>
            String(event.id) === String(eventToUpdate.id)
              ? eventToUpdate
              : event
          ),
        },
      };

    case FETCH_EVENT_BY_ID:
      let foundEvent = null;
      Object.keys(state.eventsByStaff).forEach((staffId) => {
        const event = state.eventsByStaff[staffId].find(
          (event) => event.id === action.payload
        );
        if (event) {
          foundEvent = event;
        }
      });
      return {
        ...state,
        selectedEvent: foundEvent,
      };

    case DELETE_EVENT:
      const eventIdToDelete = action.payload;
      const updatedEventsByStaffAfterDelete = {};

      Object.keys(state.eventsByStaff).forEach((staffId) => {
        updatedEventsByStaffAfterDelete[staffId] = state.eventsByStaff[
          staffId
        ].filter((event) => event.id !== eventIdToDelete);
      });

      return {
        ...state,
        eventsByStaff: updatedEventsByStaffAfterDelete,
      };

    case EVENT_FAILURE:
      return {
        ...state,
        selectedEvent: null,
        eventsByStaff: {},
      };

    default:
      return state;
  }
};

export default eventReducer;
