import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  TextField,
  Typography,
  Autocomplete,
  Box,
  IconButton,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DataGridPro } from "@mui/x-data-grid-pro";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import axiosInstance from "../../components/AxiosInstance";
import dayjs from "dayjs";
import useSharedLink from "../../scenes/SharedLink";
import AddPriorAuthorization from "./AddPriorAuthorization";
import { themeStyles } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import isEqual from "lodash.isequal";
import { useSnackbar } from "../../components/SnackBarProvider";
const AuthorizationClient = ({ onSubmit }) => {
  const sharedLink = useSharedLink();
  const { openSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const isEdit = location.state?.isEdit || false;
  const id = location.state?.id || null;

  const initialFormData = {
    clientName: null,
    startDate: null,
    endDate: null,
    insuranceName: "",
    insurancePhoneNumber: "",
    policyNumber: "",
    groupName: "",
    rate: "",
    outOfNetworkRateType: null,
    status: { label: "Active" }, // Default status to Active
    singleCaseAgreement: { label: "False" },

    authorizationRequiredForAssessment: { label: "False" },
    authorizationRequiredServiceRequest: { label: "False" },
    eligibleForSocialSkill: { label: "False" },
    authorizationType: null,
    authorizationNumber: "",
    authorizationActive: null,
    procedureCode: [],
    unit: "",
    currentAuthorizationState: null,
    stopMonitoring: false,
  };

  const status = [
    { label: "Active" },
    { label: "Inactive" },
    { label: "None" },
  ];

  const SingleCaseAgreement = [{ label: "True" }, { label: "False" }];

  const AuthorizationRequiredForAssessment = [
    { label: "True" },
    { label: "False" },
  ];

  const AuthorizationRequiredServiceRequest = [
    { label: "True" },
    { label: "False" },
  ];

  const EligibleForSocialSkill = [{ label: "True" }, { label: "False" }];

  const outOfNetworkRateTypes = [
    { label: "RNC" },
    { label: "Medicare" },
    { label: "Other" },
    { label: "NA" },
  ];

  const setMidnight = (date) => dayjs(date);

  const [formData, setFormData] = useState({
    clientName: null,
    startDate: null,
    endDate: null,
    insuranceName: "",
    insurancePhoneNumber: "",
    policyNumber: "",
    groupName: "",
    rate: "",
    outOfNetworkRateType: null,
    status: { label: "Active" }, // Default status to Active
    singleCaseAgreement: null,

    authorizationRequiredForAssessment: null,
    authorizationRequiredServiceRequest: null,
    eligibleForSocialSkill: null,
    authorizationType: null,
    authorizationNumber: "",
    authorizationActive: null,
    procedureCode: [],
    unit: "",
    currentAuthorizationState: null,
    stopMonitoring: false,
  });

  const [priorAuthorizationDates, setPriorAuthorizationDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [clients, setClients] = useState([]);
  const [rows, setRows] = useState([]);
  const [showAddAuthorization, setShowAddAuthorization] = useState(false);
  const [additionalFields, setAdditionalFields] = useState([
    { id: 1, procedure: null, unit: "" },
  ]);
  const [editRowId, setEditRowId] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [fieldsEditable, setFieldsEditable] = useState(true);
  //Navigation to manage page after updation
  const [isUpdated, setIsUpdated] = useState(false); // Add this state
  const [showInactive, setShowInactive] = useState(false);
  const [allRows, setAllRows] = useState([]);
  const [rowFieldsEditable, setRowFieldsEditable] = useState(true);

  // Snackbar state for notifications
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Dialog state for unsaved changes confirmation
  const [initialState, setInitialState] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] =
    useState(false);

  //prior authorization button funtionality
  const [tempFormData, setTempFormData] = useState(null); // State to store temporary data
  const [tempAdditionalFields, setTempAdditionalFields] = useState(null); // State to store temporary additional fields
  const [tempPriorAuthorizationDates, setTempPriorAuthorizationDates] =
    useState(null); // State to store temporary dates
  const [isAuthorizationVisible, setIsAuthorizationVisible] = useState(false); // State to control visibility

  //tick wala unnecessary funtion.
  useEffect(() => {
    if (showInactive) {
      setRows(allRows);
    } else {
      setRows(allRows?.filter((row) => row.authorizationActive === "Active"));
    }
  }, [showInactive, allRows]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientResponse = await axiosInstance .post(sharedLink + "/viewallclient", {
          withCredentials: true,
        });
        const clientsData = clientResponse.data
          .filter((client) => client.status === "Active") // Filter to include only active clients
          .map((client) => ({
            label: client.firstName + " " + client.lastName,
            ...client,
          }));
        console.log("Active Clients:", clientsData); // Log the active clients data
        setClients(clientsData);

        if (isEdit && id) {
          const response = await axiosInstance .post(
            sharedLink + "/getpriorauthorizations",
            { id }
          );
          const editData = response.data[0];

          console.log("Fetched data:", editData); // Debugging: log the fetched data

          const formattedRows = editData.priorAuthorizationDetails.map(
            (detail) => ({
              id: detail.id,
              authorizationType: detail.priorAuthorizationType,
              startDate: dayjs(detail.priorAuthorizationStartDate.split("T")[0]),
              endDate: dayjs(detail.priorAuthorizationEndDate.split("T")[0]),
              authorizationNumber: detail.priorAuthorizationNo,
              // authorizationStatus: detail.priorAuthorizationStatus,
              authorizationActive:
                detail.isAuthorizationActive === "true" ? "Active" : "Inactive",
              procedureCode: detail.aprrovedUnits
                .map((unit) => unit.procedureCode)
                .join(", "),
              unit: detail.aprrovedUnits
                .map((unit) => unit.unitsTotal)
                .join(", "),
              currentAuthorizationState: detail.currentAuthorizationState,
              stopMonitoring: detail.stopMonitoring == "true", // Ensure this field is correctly set
            })
          );

          //unneccesary checkbox ka baccha
          setAllRows(formattedRows);
          setRows(
            formattedRows.filter((row) => row.authorizationActive === "Active")
          );

          const initialData = {
            clientName: { label: editData.clientName, id: editData.clientId },
            startDate: dayjs(editData.clientDateOfBirth),
            endDate: null,
            insuranceName: editData.insuranceName,
            insurancePhoneNumber: editData.insurancePhoneNumber,
            policyNumber: editData.policyNumber,
            groupName: editData.groupName,
            rate: editData.oonRate,
            outOfNetworkRateType: { label: editData.oonRateType },
            status: { label: editData.status },
            singleCaseAgreement: {
              label:
                editData.isSingleCaseAgreement === "true" ? "True" : "False",
            },

            // Added to ensure correct interpretation of these fields
            authorizationRequiredForAssessment: {
              label:
                editData.isAuthorizationRequiredForAssessment === "true"
                  ? "True"
                  : "False",
            },
            authorizationRequiredServiceRequest: {
              label:
                editData.isAuthorizationRequiredServiceRequest === "true"
                  ? "True"
                  : "False",
            },
            eligibleForSocialSkill: {
              label:
                editData.isEligibleForSocialSkill === "true" ? "True" : "False",
            },
            authorizationType: { label: editData.authorizationType },
            authorizationNumber: editData.authorizationNumber,
            authorizationActive: {
              label: editData.authorizationActive === "true" ? "True" : "False", //as string
            },
            procedureCode: editData.procedureCode
              ? editData.procedureCode
                  .split(", ")
                  .map((code) => ({ label: code }))
              : [],
            unit: editData.unit,
            currentAuthorizationState: {
              label: editData.currentAuthorizationState,
            },
            stopMonitoring: editData.stopMonitoring === "true", // Ensure this field is correctly set
            priorAuthorizationStopMonitoring: false,
          };

          setFormData(initialData);
          setInitialState(JSON.parse(JSON.stringify(initialData))); // Set the initial state here
          setPriorAuthorizationDates({
            startDate: dayjs(editData.priorAuthorizationStartDate),
            endDate: dayjs(editData.priorAuthorizationEndDate),
          });
          setAdditionalFields(
            editData.procedureCode
              ? editData.procedureCode.split(", ").map((code, index) => ({
                  id: index + 1,
                  procedure: { label: code },
                  unit: editData.unit ? editData.unit.split(", ")[index] : "",
                }))
              : [{ id: 1, procedure: null, unit: "" }]
          );

          setFieldsEditable(editData.status !== "Inactive");
        } else {
          const initialData = {
            clientName: null,
            startDate: null,
            endDate: null,
            insuranceName: "",
            policyNumber: "",
            groupName: "",
            rate: "",
            outOfNetworkRateType: null,
            status: { label: "Active" }, // Default status to Active

            //remove label and write null if backend fix the null issue
            singleCaseAgreement: { label: "False" },

            authorizationRequiredForAssessment: { label: "False" },
            authorizationRequiredServiceRequest: { label: "False" },
            eligibleForSocialSkill: { label: "False" },
            authorizationType: null,
            authorizationNumber: "",
            authorizationActive: null,
            procedureCode: [],
            unit: "",
            currentAuthorizationState: null,
            insurancePhoneNumber: "",
            stopMonitoring: false,
            priorAuthorizationStopMonitoring: false,
          };

          setFormData(initialData);
          setInitialState(JSON.parse(JSON.stringify(initialData))); // Set the initial state here
          setPriorAuthorizationDates({
            startDate: null,
            endDate: null,
          });
          setAdditionalFields([{ id: 1, procedure: null, unit: "" }]);
          setRows([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [sharedLink, isEdit, id]);

  //handle submit funtion

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.insuranceName) {
      setSnackbar({
        open: true,
        message: "Insurance Name is required",
        severity: "error",
      });
      return;
    }

    if (!formData.rate) {
      setSnackbar({
        open: true,
        message: "Out-of-network-rate is required",
        severity: "error",
      });
      return;
    }

    if (!formData.policyNumber) {
      setSnackbar({
        open: true,
        message: "Policy Number is required",
        severity: "error",
      });
      return;
    }

    const clientInfo = {
      clientId: formData.clientName?.id || null,
      insuranceName: formData.insuranceName,
      policyNumber: formData.policyNumber,
      groupName: formData.groupName,
      insurancePhoneNumber: formData.insurancePhoneNumber,
      oonRate: formData.rate,
      oonRateType: formData.outOfNetworkRateType?.label || "",
      status: formData.authorizationStatus?.label || "",
      isSingleCaseAgreement:
        formData.singleCaseAgreement?.label === "True"
          ? true
          : formData.singleCaseAgreement?.label === "False"
          ? false
          : null,

      isAuthorizationRequiredForAssessment:
        formData.authorizationRequiredForAssessment?.label === "True"
          ? true
          : formData.authorizationRequiredForAssessment?.label === "False"
          ? false
          : null,
      isAuthorizationRequiredServiceRequest:
        formData.authorizationRequiredServiceRequest?.label === "True"
          ? true
          : formData.authorizationRequiredServiceRequest?.label === "False"
          ? false
          : null,
      isEligibleForSocialSkill:
        formData.eligibleForSocialSkill?.label === "True"
          ? true
          : formData.eligibleForSocialSkill?.label === "False"
          ? false
          : null,
      stopMonitoring: formData.stopMonitoring, // Ensure this field is included
    };

    if (isEdit) {
      clientInfo.id = id;
    }
    console.log(allRows);
    //commented the authorization status
    const priorAuthorizations = allRows.map((row) => ({
      priorAuthorizationType: row.authorizationType || "",
      priorAuthorizationStartDate:
        dayjs(row.startDate).format("YYYY-MM-DD") || "",
      priorAuthorizationEndDate: dayjs(row.endDate).format("YYYY-MM-DD") || "",
      priorAuthorizationNo: row.authorizationNumber || "",
      // priorAuthorizationStatus: row.authorizationStatus || "",
      //code responsible for Stop Monitoring.
      isAuthorizationActive: row.authorizationActive == "Active" ? true : false,

      aprrovedUnits: row.procedureCode
        ? row.procedureCode.split(", ").map((code, index) => ({
            procedureCode: code,
            unitsTotal: parseInt(row.unit.split(", ")[index], 10) || 0,
          }))
        : [],
      currentAuthorizationState: row.currentAuthorizationState || "",
      stopMonitoring: row.stopMonitoring, // Ensure this field is included
    }));

    try {
      const combinedData = {
        ...clientInfo,
        priorAuthorizationDetails: priorAuthorizations,
        status: formData.status.label,
        lastUpdatedBy: localStorage.getItem("username"),
        enteredBy: localStorage.getItem("username"),
      };

      await axiosInstance .post(sharedLink + "/addpriorauthorizations", combinedData, {
        withCredentials: true,
      });
      console.log(isEdit ? "Data updated:" : "Data added:", combinedData);

      setSnackbar({
        open: true,
        message: isEdit
          ? "Authorization Updated Successfully"
          : "Authorization Created Successfully",
        severity: "success",
      });

      // Clear form and rows for new client
      if (!isEdit) {
        setFormData({
          clientName: null,
          startDate: null,
          endDate: null,
          insuranceName: "",
          policyNumber: "",
          groupName: "",
          rate: "",
          outOfNetworkRateType: null,
          status: { label: "Active" }, // Default status to Active
          singleCaseAgreement: { label: "False" },

          authorizationRequiredForAssessment: { label: "False" },
          authorizationRequiredServiceRequest: { label: "False" },
          eligibleForSocialSkill: { label: "False" },
          authorizationType: null,
          authorizationNumber: "",
          authorizationActive: null,
          procedureCode: [],
          unit: "",
          currentAuthorizationState: null,
          insurancePhoneNumber: "",
          stopMonitoring: false,
        });
        setRows([]);
        setAllRows([]);
        setPriorAuthorizationDates({
          startDate: null,
          endDate: null,
        });
        setAdditionalFields([{ id: 1, procedure: null, unit: "" }]);
      }

      //update is happening
      if (isEdit) {
        setIsUpdated(true); // Set the update flag
        setUnsavedChanges(false); // Reset unsaved changes
        setInitialState(JSON.parse(JSON.stringify(formData))); // Update initial state to current form data
      }

      if (combinedData && !isEdit) {
        setRows([]);
        setFormData({
          clientName: null,
          startDate: null,
          endDate: null,
          insuranceName: "",
          policyNumber: "",
          groupName: "",
          rate: "",
          outOfNetworkRateType: null,
          status: { label: "Active" }, // Default status to Active
          singleCaseAgreement: { label: "False" },
          serviceAuthorizationActive: null,
          socialSkillAuthorizationActive: null,
          authorizationRequiredForAssessment: { label: "False" },
          authorizationRequiredServiceRequest: { label: "False" },
          eligibleForSocialSkill: { label: "False" },
          authorizationType: null,
          authorizationNumber: "",
          authorizationActive: null,
          procedureCode: [],
          unit: "",
          currentAuthorizationState: null,
          insurancePhoneNumber: "",
          stopMonitoring: false,
        });
        setPriorAuthorizationDates({
          startDate: null,
          endDate: null,
        });
        setAdditionalFields([{ id: 1, procedure: null, unit: "" }]);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      setSnackbar({
        open: true,
        message: "Error in Creating Authorization",
        severity: "error",
      });
    }
  };

  const handleClientChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      clientName: newValue,
      startDate: newValue ? dayjs(newValue.dateOfBirth) : null,
      groupName: newValue ? newValue.groupName : "",
      insuranceName: newValue ? newValue.insuranceName : "",
      policyNumber: newValue ? newValue.policyNumber : "",
    }));
  };

  const handleInputChange = (field) => (event, newValue) => {
    if (field === "status" && newValue?.label === "Active") {
      setFieldsEditable(true);
    } else if (field === "status" && newValue?.label === "Inactive") {
      setFieldsEditable(false);
      setAllRows((rows) =>
        rows.map((row) => ({
          ...row,
          authorizationActive: "Inactive",
        }))
      );
      setFormData((prevData) => ({
        ...prevData,
        authorizationActive: { label: "Inactive" },
      }));
      setRowFieldsEditable(false);
    }
    setFormData((prevData) => ({
      ...prevData,
      [field]: newValue !== undefined ? newValue : event.target.value,
    }));
  };

  //validation for out-of-network-rate

  // Function to handle percentage input validation
  const handleRateChange = (event) => {
    const { value } = event.target;
    if (/^\d{0,3}%?$/.test(value)) {
      setFormData((prevData) => ({
        ...prevData,
        rate: value,
      }));
    }
  };

  const handleCheckboxChange = (field) => (event) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: event.target.checked,
    }));
  };

  // Update the handlePhoneNumberChange function with simple validation
  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;

    // Check if the input is a digit and has a maximum length of 10
    if (/^\d{0,10}$/.test(value)) {
      setFormData((prevData) => ({
        ...prevData,
        insurancePhoneNumber: value,
      }));
    }
  };
  //new toggle funtion so that whenever i click prior authorization new fields are generated and saved to new row.
  const toggleShowAddAuthorization = () => {
    setFormData((prevData) => ({
      ...prevData,
      authorizationType: null,
      authorizationNumber: "",
      authorizationActive: null,
      currentAuthorizationState: null,
      priorAuthorizationStopMonitoring: false,
    }));

    setPriorAuthorizationDates({
      startDate: null,
      endDate: null,
    });

    setAdditionalFields([{ id: 1, procedure: null, unit: "" }]);
    setShowAddAuthorization(true); // Always show the fields when the button is clicked
    setIsAuthorizationVisible(true); // Ensure the fields are visible
    setEditRowId(null);
  };

  //toggle visibility hide icon funtion:-

  const toggleAuthorizationVisibility = () => {
    if (isAuthorizationVisible) {
      // When hiding, store the current data
      setTempFormData(formData);
      setTempAdditionalFields(additionalFields);
      setTempPriorAuthorizationDates(priorAuthorizationDates);
    } else {
      // When showing, restore the stored data
      if (tempFormData) setFormData(tempFormData);
      if (tempAdditionalFields) setAdditionalFields(tempAdditionalFields);
      if (tempPriorAuthorizationDates)
        setPriorAuthorizationDates(tempPriorAuthorizationDates);
    }
    setIsAuthorizationVisible((prev) => !prev); // Toggle visibility
  };

  //Save Prior Authorization
  const handleSavePriorAuthorization = () => {
    if (!formData.authorizationType) {
      setSnackbar({
        open: true,
        message: "Authorization Type is required",
        severity: "error",
      });
      return; // Added return to prevent further execution if there's an error
    }
    if (!formData.authorizationNumber) {
      setSnackbar({
        open: true,
        message: "Authorization Number is required",
        severity: "error",
      });
      return; // Added return to prevent further execution if there's an error
    }

    if (!formData.authorizationActive) {
      setSnackbar({
        open: true,
        message: "Authorization status is required",
        severity: "error",
      });
      return; // Added return to prevent further execution if there's an error
    }

    if (!formData.currentAuthorizationState) {
      setSnackbar({
        open: true,
        message: "Current Authorization State is required",
        severity: "error",
      });
      return; // Added return to prevent further execution if there's an error
    }
    if (additionalFields.some((field) => !field.procedure)) {
      setSnackbar({
        open: true,
        message: "Procedure Code is required",
        severity: "error",
      });
      return;
    }

    if (additionalFields.some((field) => !field.unit)) {
      setSnackbar({
        open: true,
        message: "Unit is required",
        severity: "error",
      });
      return;
    }

    if (!priorAuthorizationDates.startDate) {
      setSnackbar({
        open: true,
        message: "Start Date is required",
        severity: "error",
      });
      return;
    }

    if (!priorAuthorizationDates.endDate) {
      setSnackbar({
        open: true,
        message: "End Date is required",
        severity: "error",
        setIsAuthorizationVisible,
      });
      return;
    }

    const newRow = {
      id: editRowId || rows.length + 1,
      authorizationType: formData.authorizationType?.label || "",
      authorizationNumber: formData.authorizationNumber || "",
      // authorizationStatus: formData.authorizationStatus?.label || "",
      authorizationActive: formData.authorizationActive?.label || "",
      procedureCode: additionalFields
        .map((field) => field.procedure?.label || "")
        .join(", "),
      unit: additionalFields.map((field) => field.unit).join(", "),
      currentAuthorizationState:
        formData.currentAuthorizationState?.label || "",
      startDate: setMidnight(priorAuthorizationDates.startDate).toISOString(), // Ensure date is set to midnight
      endDate: setMidnight(priorAuthorizationDates.endDate).toISOString(), // Ensure date is set to midnight
      stopMonitoring: formData.priorAuthorizationStopMonitoring, // Ensure this field is included
    };
    console.log(formData);

    if (editRowId) {
      setAllRows((prevRows) =>
        prevRows.map((row) => (row.id === editRowId ? newRow : row))
      );
      setEditRowId(null);
    } else {
      setAllRows([...allRows, newRow]);
    }

    setFormData({
      ...formData,
      authorizationType: null,
      authorizationNumber: "",
      authorizationActive: null,
      currentAuthorizationState: null,
      priorAuthorizationStopMonitoring: false,
    });

    setPriorAuthorizationDates({
      startDate: null,
      endDate: null,
    });

    setAdditionalFields([{ id: 1, procedure: null, unit: "" }]);

    // Hide the authorization fields after saving
    setShowAddAuthorization(false); // Add this line to hide the fields
    console.log(priorAuthorizationDates.startDate);
    console.log(priorAuthorizationDates.endDate);
  };

  const handleEditRow = (rowId) => {
    const row = rows.find((row) => row.id === rowId);
    console.log(row);
    if (row.authorizationActive == "Active") setRowFieldsEditable(true);
    else setRowFieldsEditable(false);
    setFormData({
      ...formData,
      clientName: formData.clientName,
      startDate: row.startDate ? dayjs(row.startDate) : null,
      endDate: row.endDate ? dayjs(row.endDate) : null,
      insuranceName: formData.insuranceName,
      policyNumber: formData.policyNumber,
      groupName: formData.groupName,
      rate: formData.rate,
      outOfNetworkRateType: formData.outOfNetworkRateType,
      authorizationStatus: { label: row.authorizationStatus },
      singleCaseAgreement: formData.singleCaseAgreement,

      // Added to ensure correct interpretation of these fields
      authorizationRequiredForAssessment:
        formData.authorizationRequiredForAssessment,
      authorizationRequiredServiceRequest:
        formData.authorizationRequiredServiceRequest,
      eligibleForSocialSkill: formData.eligibleForSocialSkill,
      authorizationType: { label: row.authorizationType },
      authorizationNumber: row.authorizationNumber,
      authorizationActive: { label: row.authorizationActive },
      procedureCode: row.procedureCode
        ? row.procedureCode.split(", ").map((code) => ({ label: code }))
        : [],
      unit: row.unit,
      currentAuthorizationState: { label: row.currentAuthorizationState },
      insurancePhoneNumber: formData.insurancePhoneNumber,
      priorAuthorizationStopMonitoring: row.stopMonitoring, // Ensure this field is correctly set
    });
    setPriorAuthorizationDates({
      startDate: row.startDate ? dayjs(row.startDate) : null,
      endDate: row.endDate ? dayjs(row.endDate) : null,
    });
    setAdditionalFields(
      row.procedureCode
        ? row.procedureCode.split(", ").map((code, index) => ({
            id: index + 1,
            procedure: { label: code },
            unit: row.unit.split(", ")[index],
          }))
        : [{ id: 1, procedure: null, unit: "" }]
    );
    setEditRowId(rowId);
    setShowAddAuthorization(true);
    setIsAuthorizationVisible(true);
  };

  const handleDeleteRow = (rowId) => {
    setAllRows((prevRows) =>
      prevRows.map((r) =>
        r.id === rowId
          ? { ...r, authorizationActive: "Inactive", stopMonitoring: false }
          : r
      )
    );
    //comment it if you want to show the inactive row on deletion before reloading and without checkbox
    //  setAllRows((prevRows) =>
    //    prevRows.map((r) => (r.id === id ? { ...r, status: "Inactive" } : r))
    //  );
  };

  const checkForUnsavedChanges = useCallback(() => {
    if (initialState) {
      const currentState = JSON.parse(JSON.stringify(formData));
      setUnsavedChanges(!isEqual(initialState, currentState));
    }
  }, [formData, initialState]);

  useEffect(() => {
    checkForUnsavedChanges();
  }, [formData, checkForUnsavedChanges]);

  const handleGoBack = () => {
    if (unsavedChanges) {
      setUnsavedChangesDialogOpen(true);
    } else {
      navigate("/managePriorAuthorization");
    }
  };

  const handleCloseUnsavedChangesDialog = (confirm) => {
    setUnsavedChangesDialogOpen(false);
    if (confirm) {
      navigate("/managePriorAuthorization");
    }
  };

  //inactive jahdasdasbd

  const handleShowInactiveChange = (event) => {
    setShowInactive(event.target.checked);
  };

  const columns = [
    {
      field: "authorizationType",
      headerName: "Authorization Type",
      width: 250,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 150,
      valueFormatter: ({ value }) => dayjs(value).format("YYYY-MM-DD"), // Format the date correctly
    },

    {
      field: "endDate",
      headerName: "End Date",
      width: 150,
      valueFormatter: ({ value }) => dayjs(value).format("YYYY-MM-DD"), // Format the date correctly
    },
    // {
    //   field: "authorizationStatus",
    //   headerName: "Authorization Status",
    //   width: 150,
    // },
    {
      field: "authorizationNumber",
      headerName: "Authorization Number",
      width: 250,
    },
    {
      field: "authorizationActive",
      headerName: "Status",
      width: 250,
    },
    { field: "procedureCode", headerName: "Procedure Code", width: 300 },
    { field: "unit", headerName: "Units", width: 300 },
    {
      field: "currentAuthorizationState",
      headerName: "Current Authorization State",
      width: 200,
    },

    {
      field: "stopMonitoring",
      headerName: "Monitoring Status",
      width: 200,
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEditRow(params.row.id)}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteRow(params.row.id)}
            disabled={params.row.authorizationActive == "Inactive"}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <div>
      <Typography
        variant="h4"
        sx={{
          mb: 10,
          textAlign: "center",
          fontWeight: "bold",
          fontSize: 35,
          ...themeStyles.textField,
        }}
      >
        {isEdit ? "Update Authorization" : "Add Authorization"}
      </Typography>
      <form style={{ margin: "20px" }} onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-start",
              mt: 2,
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={formData.stopMonitoring}
                  onChange={handleCheckboxChange("stopMonitoring")}
                  disabled={!fieldsEditable}
                />
              }
              label={
                <span style={{ fontWeight: "bold", fontSize: 18 }}>
                  {" "}
                  Stop Monitoring
                </span>
              }
              className="animated-input"
            />
          </Box>

          <Autocomplete
            fullWidth
            options={clients}
            getOptionLabel={(option) => option.label}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={
                  <span>
                    Client Name<span style={{ color: "red" }}>*</span>
                  </span>
                }
                variant="outlined"
                margin="normal"
              />
            )}
            value={formData.clientName}
            onChange={handleClientChange}
            sx={{ flex: "1 1 48%", ...themeStyles.textField }}
            disabled={isEdit} // Disable client name in Update page
          />

          <DatePicker
            label={
              <span>
                Date of Birth<span style={{ color: "red" }}>*</span>
              </span>
            }
            value={formData.startDate}
            onChange={(newValue) =>
              setFormData((prevData) => ({ ...prevData, startDate: newValue }))
            }
            className="animated-input"
            renderInput={(params) => (
              <TextField {...params} fullWidth margin="normal" disabled /> // Disable the DatePicker input field
            )}
            sx={{ flex: "1 1 48%", ...themeStyles.textField, mt: 2 }}
            disabled={isEdit || !!formData.clientName}
          />

          <TextField
            label="Group Name"
            variant="outlined"
            value={formData.groupName}
            className="animated-input"
            onChange={handleInputChange("groupName")}
            fullWidth
            margin="normal"
            sx={{
              ...themeStyles.textField,
              flex: "1 1 48%",
            }}
            disabled={!fieldsEditable}
          />

          <TextField
            label={
              <span>
                Insurance Name<span style={{ color: "red" }}>*</span>
              </span>
            }
            variant="outlined"
            value={formData.insuranceName}
            className="animated-input"
            onChange={handleInputChange("insuranceName")}
            fullWidth
            margin="normal"
            sx={{
              ...themeStyles.textField,
              flex: "1 1 48%",
            }}
            disabled={!fieldsEditable}
          />

          <TextField
            label="Insurance Phone Number"
            variant="outlined"
            value={formData.insurancePhoneNumber}
            className="animated-input"
            onChange={handlePhoneNumberChange}
            fullWidth
            margin="normal"
            sx={{
              ...themeStyles.textField,
              flex: "1 1 48%",
            }}
            disabled={!fieldsEditable}
          />

          <TextField
            label={
              <span>
                Policy Number<span style={{ color: "red" }}>*</span>
              </span>
            }
            variant="outlined"
            value={formData.policyNumber}
            className="animated-input"
            onChange={handleInputChange("policyNumber")}
            fullWidth
            margin="normal"
            sx={{
              ...themeStyles.textField,
              flex: "1 1 48%",
            }}
            disabled={!fieldsEditable}
          />

          <TextField
            label={
              <span>
                Out-of-network-rate<span style={{ color: "red" }}>*</span>
              </span>
            }
            variant="outlined"
            value={formData.rate}
            className="animated-input"
            onChange={handleRateChange} // Updated to use the new validation function
            fullWidth
            margin="normal"
            sx={{
              ...themeStyles.textField,
              flex: "1 1 48%",
            }}
            disabled={!fieldsEditable}
          />

          <Autocomplete
            fullWidth
            options={outOfNetworkRateTypes}
            getOptionLabel={(option) => option.label}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Out-of-Network-Rate-Types"
                variant="outlined"
                margin="normal"
              />
            )}
            value={formData.outOfNetworkRateType}
            onChange={handleInputChange("outOfNetworkRateType")}
            sx={{ ...themeStyles.textField, flex: "1 1 48%" }}
            disabled={!fieldsEditable}
          />

          <Autocomplete
            fullWidth
            options={status}
            getOptionLabel={(option) => option.label}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Status"
                variant="outlined"
                margin="normal"
              />
            )}
            value={formData.status}
            onChange={handleInputChange("status")}
            sx={{ ...themeStyles.textField, flex: "1 1 48%" }}
          />

          <Autocomplete
            fullWidth
            options={SingleCaseAgreement}
            getOptionLabel={(option) => option.label}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Single Case Agreement"
                variant="outlined"
                margin="normal"
              />
            )}
            value={formData.singleCaseAgreement}
            onChange={handleInputChange("singleCaseAgreement")}
            sx={{ ...themeStyles.textField, flex: "1 1 48%" }}
            disabled={!fieldsEditable}
          />

          <Autocomplete
            fullWidth
            options={AuthorizationRequiredForAssessment}
            getOptionLabel={(option) => option.label}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Authorization Required For Assessment"
                variant="outlined"
                margin="normal"
              />
            )}
            value={formData.authorizationRequiredForAssessment}
            onChange={handleInputChange("authorizationRequiredForAssessment")}
            sx={{ ...themeStyles.textField, flex: "1 1 48%" }}
            disabled={!fieldsEditable}
          />

          <Autocomplete
            fullWidth
            options={AuthorizationRequiredServiceRequest}
            getOptionLabel={(option) => option.label}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Authorization Required Service Request"
                variant="outlined"
                margin="normal"
              />
            )}
            value={formData.authorizationRequiredServiceRequest}
            onChange={handleInputChange("authorizationRequiredServiceRequest")}
            sx={{ ...themeStyles.textField, flex: "1 1 48%" }}
            disabled={!fieldsEditable}
          />

          <Autocomplete
            fullWidth
            options={EligibleForSocialSkill}
            getOptionLabel={(option) => option.label}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Eligible For Social Skill"
                variant="outlined"
                margin="normal"
              />
            )}
            value={formData.eligibleForSocialSkill}
            onChange={handleInputChange("eligibleForSocialSkill")}
            sx={{ ...themeStyles.textField, mb: 5 }}
            disabled={!fieldsEditable}
          />
        </Box>

        <Button
          type="button"
          variant="contained"
          className="animated-input"
          onClick={toggleShowAddAuthorization}
          startIcon={<AddCircleOutlineIcon />}
          sx={{
            backgroundColor: "#3A57E8",
            color: "white",
            fontWeight: "bold",
            borderRadius: "20px",
            padding: "8px 16px",
            mb: 5,
            marginTop: "20px",
            "&:hover": {
              backgroundColor: "#0059b2",
            },
          }}
          disabled={!fieldsEditable}
        >
          Prior Authorization
        </Button>

        <IconButton
          sx={{ marginBottom: "20px", marginLeft: "20px", fontSize: "" }}
          onClick={toggleAuthorizationVisibility}
        >
          {isAuthorizationVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>

        {isAuthorizationVisible && showAddAuthorization && (
          <Box sx={{ mb: 5 }} key={allRows}>
            <AddPriorAuthorization
              formData={formData}
              setFormData={setFormData}
              additionalFields={additionalFields}
              setAdditionalFields={setAdditionalFields}
              handleSavePriorAuthorization={handleSavePriorAuthorization}
              priorAuthorizationDates={priorAuthorizationDates}
              setPriorAuthorizationDates={setPriorAuthorizationDates}
              rowFieldsEditable={rowFieldsEditable}
              fieldsEditable={fieldsEditable}
              setRowFieldsEditable={setRowFieldsEditable}
            />
          </Box>
        )}

        <Box
          sx={{
            width: "80%",
            mt: 2,
            ml: 18,
            mr: 20,
            height: "calc(60vh - 160px)",
            ...themeStyles.textField,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={showInactive}
                onChange={handleShowInactiveChange}
              />
            }
            label="Show Inactive Authorization"
            sx={{ mb: 2, className: "animated-input" }}
          />

          <DataGridPro
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            className="animated-input"
            hideFooter
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#3A57E8",
                color: "white",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "nowrap",
                fontWeight: "bold",
              },
            }}
          />

          {/* Footer Box */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px 20px",
              backgroundColor: "#3A57E8",
              color: "white",
              borderTop: "1px solid #fff",
            }}
          >
            <Typography variant="subtitle1">
              Total Rows: {rows.length}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 15, gap: 2 }}
        >
          {isEdit && (
            <Button
              type="button"
              variant="contained"
              className="animated-input"
              onClick={handleGoBack}
              sx={{
                backgroundColor: "#3A57E8",
                color: "white",
                fontWeight: "bold",
                borderRadius: "20px",
                padding: "12px 24px",
                fontSize: "16px",
                height: "48px",
                "&:hover": {
                  backgroundColor: "#0059b2",
                },
              }}
            >
              GO BACK
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            className="animated-input"
            sx={{
              backgroundColor: "#3A57E8",
              color: "white",
              fontWeight: "bold",
              borderRadius: "20px",
              padding: "12px 24px",
              fontSize: "16px",
              height: "48px",
              "&:hover": {
                backgroundColor: "#0059b2",
              },
            }}
          >
            {isEdit ? "UPDATE" : "Submit"}
          </Button>
        </Box>
      </form>

      {/* Snackbar component for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Unsaved changes dialog */}
      <Dialog
        open={unsavedChangesDialogOpen}
        onClose={() => handleCloseUnsavedChangesDialog(false)}
      >
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have unsaved changes. Are you sure you want to leave this page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseUnsavedChangesDialog(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleCloseUnsavedChangesDialog(true)}
            color="primary"
          >
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AuthorizationClient;
