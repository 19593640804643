import {
  Box,
  Button,
  TextField,
  Snackbar,
  Autocomplete,
  InputAdornment,
  Tooltip,
  IconButton,
  MenuItem,
} from "@mui/material";
import axiosInstance from "../../../components/AxiosInstance";
import dayjs from "dayjs";
import InfoIcon from "@mui/icons-material/Info";
import React, { useState, useEffect, useRef } from "react";
import { Formik, FieldArray } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import useSharedLink from "../../../scenes/SharedLink";
import LoadingIndicator from "../../../scenes/Loader";
import { useSnackbar } from "../../../components/SnackBarProvider";
import StyledTextArea from "../../../components/StyledTextArea";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { useNavigate, useLocation } from "react-router-dom";
import {
  MASTERY_TYPES,
  PROMPTS,
  DIRECTIONS,
  themeStyles,
  buttonStyle,
  STATUS,
  ROOT_FOLDER,
} from "../../../constants";

const AddGoal = ({ mode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();

  const queryParams = new URLSearchParams(location.search);
  const goalId = queryParams.get("id");
  const from = queryParams.get("from");
  const sharedLink = useSharedLink();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [goalTypes, setGoalTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const types = ["Percentage", "Frequency", "Duration", "Task Analysis"];
  const isUpdateMode = mode === "update";
  const defaultValues = {
    goalName: "",
    programType: "Percentage",
    goalDescription: "",
    goalStatus: "Active",
    noOfAttempts: "",
    prompt: "Independent",
    masteryCriteria: "",
    masteryDirection: "",
    masteryTargetDate: dayjs(),
    masteryType: "",
    masteryDuration: "",
    goaltype: null,
    trialList: [{ trialName: "", prompt: "Independent" }],
    lastUpdatedBy: localStorage.getItem("username"),
    enteredBy: localStorage.getItem("username"),
  };
  const [initialValues, setInitialValues] = useState(defaultValues);

  useEffect(() => {
    setInitialValues(defaultValues);
    const fetchGoalTypes = async () => {
      try {
        const res = await axiosInstance.post(sharedLink + "/viewgoalbankarea", { withCredentials: true });
        const extractGoalTypes = (node, result = []) => {
          if (
            node.goalBankAreaName !== ROOT_FOLDER &&
            node.goalBankAreaStatus == "Active"
          ) {
            result.push({
              goalareaname: node.goalBankAreaName,
              goalareaid: node.id.toString(),
            });
          }
          node.children?.forEach((child) => extractGoalTypes(child, result));
          return result;
        };
        const data = res?.data?.data;
        if (data) {
          const extractedGoalTypes = extractGoalTypes(data);
          setGoalTypes(extractedGoalTypes);
        }
      } catch (error) {
        openSnackbar(error?.response?.data, "error");
      }
    };

    const fetchGoal = async () => {
      try {
        const res = await axiosInstance.post(sharedLink + "/viewgoals", { id: goalId }, { withCredentials: true });
        if (res.status >= 200 && res.status < 300) {
          const goal = res.data[0];
          const formattedGoal = {
            goalName: goal.goalName,
            goalDescription: goal.goalDescription,
            goalStatus: goal.goalStatus,
            programType: goal.programType,
            masteryType: goal.masteryType,
            goaltype: {
              goalareaname: goal.goalAreaName,
              goalareaid: goal.goalAreaId,
            },
            masteryDirection: goal.masteryDirection,
            masteryTargetDate: dayjs(goal.masteryTargetDate),
            noOfAttempts: goal.noOfAttempts,
            masteryCriteria: goal.masteryCriteria,
            masteryDuration: goal.masteryDuration,
            trialList: goal.trialList || [],
            prompt: goal.prompt,
            enteredBy: goal.enteredBy,
            lastUpdatedBy: localStorage.getItem("username"),
          };
          setInitialValues(formattedGoal);
        }
      } catch (error) {
        openSnackbar(error?.response?.data, "error");
      }
    };

    setLoading(true);
    fetchGoalTypes();
    if (isUpdateMode) fetchGoal();
    setLoading(false);
  }, [goalId, sharedLink, isUpdateMode, openSnackbar]);

  const handleFormSubmit = async (values, { resetForm }) => {
    console.log("inside form submit", values);
    openSnackbar("Please wait while we are saving goal", "info");

    let modifiedPayload = {
      goalName: values.goalName,
      goalDescription: values.goalDescription,
      goalStatus: values.goalStatus,
      goalAreaId: values.goaltype.goalareaid,
      goalAreaName: values.goaltype.goalareaname,
      masteryCriteria: values.masteryCriteria,
      masteryDirection: values.masteryDirection,

      masteryDuration: values.masteryDuration,
      masteryType: values.masteryType,
      masteryTargetDate: dayjs(values.masteryTargetDate).format("YYYY-MM-DD"),
      programType: values.programType,
      noOfAttempts: values.noOfAttempts,
      enteredBy: localStorage.getItem("username"),
      lastUpdatedBy: localStorage.getItem("username"),
    };

    if (values.programType === "Task Analysis") {
      modifiedPayload.trialList = JSON.stringify(values.trialList);
    } else if (values.programType === "Percentage") {
      modifiedPayload.prompt = values.prompt;
    }
    console.log(modifiedPayload);
    try {
      if (isUpdateMode) {
        await axiosInstance.post(sharedLink + "/updategoal", {
          ...modifiedPayload,
          lastUpdatedTime: dayjs(),
          id: parseInt(goalId),
        }, { withCredentials: true });
        openSnackbar("Updated Successfully", "success");
      } else {
        axiosInstance
          .post(sharedLink + "/addgoal", modifiedPayload)
          .then((res) => {
            if (res.status == 200) {
              resetForm();
              openSnackbar(
                "Goal is added to the goal bank Successfully",
                "success"
              );
            }
          })
          .catch((err) => {
            console.log(err);
            openSnackbar(err, "error");
          });
      }
    } catch (error) {
      console.error("Error submitting form", error);
      openSnackbar(error?.response?.data, "error");
    }
  };

  if (loading) return <LoadingIndicator />;

  return (
    <Box m="20px">
      <Header title={isUpdateMode ? "Modify Goal" : "Add Goal"} subtitle="" />
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
        enableReinitialize>
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": {
                  gridColumn: isNonMobile ? undefined : "span 4",
                },
              }}>
              <Autocomplete
                fullWidth
                options={goalTypes}
                getOptionLabel={(option) => option.goalareaname}
                value={values.goaltype}
                onChange={(event, newValue) => {
                  setFieldValue("goaltype", newValue != null ? newValue : {});
                }}
                onBlur={handleBlur}
                autoHighlight
                isOptionEqualToValue={(option, value) =>
                  option.goalareaid === value.goalareaid
                }
                disabled={values.goalStatus == "Inactive"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Goal Area"
                    variant="filled"
                    required
                    inputProps={{
                      ...params.inputProps,
                      "aria-label": "Select Goal type",
                    }}
                    error={touched.goaltype && Boolean(errors.goaltype)}
                    helperText={touched.goaltype && errors.goaltype}
                  />
                )}
                sx={{ gridColumn: "span 2", ...themeStyles.textField }}
              />
              <Autocomplete
                fullWidth
                options={types}
                autoHighlight
                value={values.programType}
                onChange={(event, newValue) => {
                  setFieldValue(
                    "programType",
                    newValue !== null ? newValue : ""
                  );
                }}
                disabled={values.goalStatus == "Inactive"}
                onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Program Type"
                    variant="filled"
                    required
                    inputProps={{
                      ...params.inputProps,
                      "aria-label": "Select type",
                    }}
                    error={touched.programType && Boolean(errors.programType)}
                    helperText={touched.programType && errors.programType}
                  />
                )}
                sx={{ gridColumn: "span 2", ...themeStyles.textField }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Goal "
                required
                disabled={values.goalStatus == "Inactive"}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.goalName}
                name="goalName"
                maxLength={120}
                error={touched.goalName && Boolean(errors.goalName)}
                helperText={touched.goalName && errors.goalName}
                sx={{ gridColumn: "span 2", ...themeStyles.textField }}
              />
              <StyledTextArea
                label="Goal Description"
                name="goalDescription"
                value={values.goalDescription}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={values.goalStatus == "Inactive"}
                error={
                  touched.goalDescription && Boolean(errors.goalDescription)
                }
                helperText={touched.goalDescription && errors.goalDescription}
                maxLength={500}
              />
              <TextField
                fullWidth
                variant="filled"
                type="number"
                label="Number of attempts"
                onBlur={handleBlur}
                disabled={values.goalStatus == "Inactive"}
                required
                onChange={handleChange}
                value={values.noOfAttempts}
                name="noOfAttempts"
                inputProps={{ min: "0" }}
                error={touched.noOfAttempts && Boolean(errors.noOfAttempts)}
                helperText={touched.noOfAttempts && errors.noOfAttempts}
                sx={{ gridColumn: "span 2", ...themeStyles.textField }}
              />
              {isUpdateMode && (
                <Autocomplete
                  fullWidth
                  options={STATUS}
                  className="animated-input"
                  autoHighlight
                  value={values.goalStatus}
                  onChange={(event, newValue) =>
                    setFieldValue("goalStatus", newValue)
                  }
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="filled"
                      required
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select status",
                      }}
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
              )}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Mastery Target Date"
                  value={values.masteryTargetDate}
                  onChange={(newValue) => {
                    if (dayjs.isDayjs(newValue) && newValue.isValid()) {
                      setFieldValue("masteryTargetDate", newValue);
                    } else {
                      console.error("Invalid date object:", newValue);
                    }
                  }}
                  disabled={values.goalStatus == "Inactive"}
                  minDate={dayjs()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      required
                      error={
                        touched.masteryTargetDate &&
                        Boolean(errors.masteryTargetDate)
                      }
                      helperText={
                        touched.masteryTargetDate && errors.masteryTargetDate
                      }
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
              </LocalizationProvider>
              <Autocomplete
                fullWidth
                options={MASTERY_TYPES}
                autoHighlight
                value={values.masteryType}
                onChange={(event, newValue) => {
                  setFieldValue(
                    "masteryType",
                    newValue !== null ? newValue : ""
                  );
                }}
                disabled={values.goalStatus == "Inactive"}
                onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Mastery Type"
                    variant="filled"
                    required
                    inputProps={{
                      ...params.inputProps,
                      "aria-label": "Select mastery type",
                    }}
                    error={touched.masteryType && Boolean(errors.masteryType)}
                    helperText={touched.masteryType && errors.masteryType}
                  />
                )}
                sx={{ gridColumn: "span 2", ...themeStyles.textField }}
              />
              <Autocomplete
                fullWidth
                options={DIRECTIONS}
                autoHighlight
                value={values.masteryDirection}
                onChange={(event, newValue) => {
                  setFieldValue(
                    "masteryDirection",
                    newValue !== null ? newValue : ""
                  );
                }}
                disabled={values.goalStatus == "Inactive"}
                onBlur={handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Mastery Direction"
                    variant="filled"
                    required
                    inputProps={{
                      ...params.inputProps,
                      "aria-label": "Select mastery direction",
                    }}
                    error={
                      touched.masteryDirection &&
                      Boolean(errors.masteryDirection)
                    }
                    helperText={
                      touched.masteryDirection && errors.masteryDirection
                    }
                  />
                )}
                sx={{ gridColumn: "span 2", ...themeStyles.textField }}
              />
              {values.programType === "Percentage" && (
                <>
                  <Autocomplete
                    fullWidth
                    options={PROMPTS}
                    autoHighlight
                    value={values.prompt}
                    onChange={(event, newValue) => {
                      setFieldValue(
                        "prompt",
                        newValue !== null ? newValue : ""
                      );
                    }}
                    disabled={values.goalStatus == "Inactive"}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Prompt"
                        variant="filled"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select prompt",
                        }}
                        error={touched.prompt && Boolean(errors.prompt)}
                        helperText={touched.prompt && errors.prompt}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Mastery Criteria, %"
                    onBlur={handleBlur}
                    disabled={values.goalStatus == "Inactive"}
                    onChange={handleChange}
                    value={values.masteryCriteria}
                    inputProps={{ min: "0" }}
                    name="masteryCriteria"
                    error={
                      touched.masteryCriteria && Boolean(errors.masteryCriteria)
                    }
                    helperText={
                      touched.masteryCriteria && errors.masteryCriteria
                    }
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Mastery Duration"
                    required={isUpdateMode}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.masteryDuration}
                    name="masteryDuration"
                    inputProps={{ min: "0" }}
                    disabled={values.goalStatus == "Inactive"}
                    error={
                      touched.masteryDuration && Boolean(errors.masteryDuration)
                    }
                    helperText={
                      touched.masteryDuration && errors.masteryDuration
                    }
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </>
              )}
              {values.programType === "Frequency" && (
                <>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Mastery Criteria,times"
                    onBlur={handleBlur}
                    required
                    onChange={handleChange}
                    value={values.masteryCriteria}
                    inputProps={{ min: "0" }}
                    name="masteryCriteria"
                    disabled={values.goalStatus == "Inactive"}
                    error={
                      touched.masteryCriteria && Boolean(errors.masteryCriteria)
                    }
                    helperText={
                      touched.masteryCriteria && errors.masteryCriteria
                    }
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Mastery Duration"
                    onBlur={handleBlur}
                    required={isUpdateMode}
                    onChange={handleChange}
                    value={values.masteryDuration}
                    name="masteryDuration"
                    inputProps={{ min: "0" }}
                    disabled={values.goalStatus == "Inactive"}
                    error={
                      touched.masteryDuration && Boolean(errors.masteryDuration)
                    }
                    helperText={
                      touched.masteryDuration && errors.masteryDuration
                    }
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </>
              )}
              {values.programType === "Duration" && (
                <>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Mastery Criteria,seconds"
                    onBlur={handleBlur}
                    required
                    onChange={handleChange}
                    value={values.masteryCriteria}
                    inputProps={{ min: "0" }}
                    name="masteryCriteria"
                    disabled={values.goalStatus == "Inactive"}
                    error={
                      touched.masteryCriteria && Boolean(errors.masteryCriteria)
                    }
                    helperText={
                      touched.masteryCriteria && errors.masteryCriteria
                    }
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Mastery Duration"
                    onBlur={handleBlur}
                    required={isUpdateMode}
                    onChange={handleChange}
                    value={values.masteryDuration}
                    name="masteryDuration"
                    disabled={values.goalStatus == "Inactive"}
                    inputProps={{ min: "0" }}
                    error={
                      touched.masteryDuration && Boolean(errors.masteryDuration)
                    }
                    helperText={
                      touched.masteryDuration && errors.masteryDuration
                    }
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </>
              )}
              {values.programType === "Task Analysis" && (
                <>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Mastery Criteria,%"
                    onBlur={handleBlur}
                    required
                    onChange={handleChange}
                    disabled={values.goalStatus == "Inactive"}
                    value={values.masteryCriteria}
                    name="masteryCriteria"
                    inputProps={{ min: "0" }}
                    error={
                      touched.masteryCriteria && Boolean(errors.masteryCriteria)
                    }
                    helperText={
                      touched.masteryCriteria && errors.masteryCriteria
                    }
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Mastery Duration"
                    required={isUpdateMode}
                    disabled={values.goalStatus == "Inactive"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.masteryDuration}
                    name="masteryDuration"
                    inputProps={{ min: "0" }}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                  <FieldArray name="trialList">
                    {({ form, push, remove }) => (
                      <Box
                        display="grid"
                        gap="30px"
                        sx={{ ...themeStyles.textField, gridColumn: "span 4" }}
                        gridTemplateColumns="1fr 1fr 2fr auto">
                        {values.trialList?.map((option, index) => (
                          <React.Fragment key={index}>
                            <StyledTextArea
                              fullWidth
                              variant="filled"
                              name={`trialList.${index}.trialName`}
                              label="Trial Name*"
                              value={option.trialName}
                              onChange={handleChange}
                              error={
                                touched.trialList?.[index]?.trialName &&
                                Boolean(errors.trialList?.[index]?.trialName)
                              }
                              helperText={
                                touched.trialList?.[index]?.trialName &&
                                errors.trialList?.[index]?.trialName
                              }
                              disabled={values.goalStatus == "Inactive"}
                              maxLength={256}
                              multiline
                              minRows={3}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Tooltip title="Max length - 256 characters">
                                      <IconButton>
                                        <InfoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <TextField
                              select
                              fullWidth
                              variant="filled"
                              name={`trialList.${index}.prompt`}
                              label="Prompt *"
                              disabled={values.goalStatus == "Inactive"}
                              value={option.prompt}
                              error={
                                touched.trialList?.[index]?.prompt &&
                                Boolean(errors.trialList?.[index]?.prompt)
                              }
                              helperText={
                                touched.trialList?.[index]?.prompt &&
                                errors.trialList?.[index]?.prompt
                              }
                              onChange={handleChange}>
                              {PROMPTS.map((promptOption, promptIndex) => (
                                <MenuItem
                                  key={promptIndex}
                                  value={promptOption}>
                                  {promptOption}
                                </MenuItem>
                              ))}
                            </TextField>
                            <IconButton
                              onClick={() => remove(index)}
                              sx={{
                                boxShadow: "none",
                                "&:hover": {
                                  boxShadow: "none",
                                  backgroundColor: "transparent",
                                },
                              }}>
                              <DeleteIcon />
                            </IconButton>
                          </React.Fragment>
                        ))}
                        <Button
                          type="button"
                          className="animated-input"
                          variant="contained"
                          color="secondary"
                          disabled={values.goalStatus == "Inactive"}
                          onClick={() => push({ trialName: "", prompt: "" })}
                          sx={{
                            ...buttonStyle,
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                          }}>
                          Add Option
                        </Button>
                      </Box>
                    )}
                  </FieldArray>
                </>
              )}
            </Box>

            <Box display="flex" justifyContent="end" mt="20px">
              {isUpdateMode && (
                <Button
                  variant="contained"
                  className="animated-input"
                  onClick={() => navigate("/managegoal/folder/" + from)}
                  sx={{ ...buttonStyle }}>
                  Go Back
                </Button>
              )}
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                className="animated-input"
                disabled={values.goalStatus == "Inactive"}
                sx={{ ...buttonStyle }}>
                {isUpdateMode ? "Update Goal" : "Add Goal"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  goalName: yup.string().required("Goal name is required"),
  programType: yup.string().required("Program type is required"),
  // goalDescription: yup.string().required("Goal description is required"),
  noOfAttempts: yup
    .number()
    .positive("Number of attempts must be a positive number")
    .integer("Number of attempts must be an integer")
    .nullable(),
  masteryTargetDate: yup
    .date()
    .required("Mastery Target Date is required")
    .nullable(),
  masteryCriteria: yup.string().nullable(),
  masteryDirection: yup.string().required("Mastery direction is required"),
  masteryType: yup.string().required("Mastery type is required"),
  goaltype: yup
    .object()
    .nullable()
    .shape({
      goalareaname: yup.string().required("Goal area is required"),
    }),
  // trialList: yup
  //   .array()
  //   .of(
  //     yup.object().shape({
  //       trialName: yup.string().required("Trial name is required"),
  //       prompt: yup.string().required("Prompt is required"),
  //     })
  //   )
  //   .when("programType", {
  //     is: "Task Analysis",
  //     then: yup.array().min(1, "At least one trial is required"),
  //     otherwise: yup.array().nullable(),
  //   })
  //   .nullable(),
  // enteredBy: yup.string().required("Entered by field is required"),
  // lastUpdatedBy: yup.string().required("Last updated by field is required"),
});

export default AddGoal;
