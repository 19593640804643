import axios from 'axios';

// Set default base URL
// axios.defaults.baseURL = 'https://lucidsoftgen.azurewebsites.net'; // Dev Server API base URL
axios.defaults.baseURL = 'https://lucidnotes.azurewebsites.net'; // Client Server API base URL

// Set default withCredentials to true
axios.defaults.withCredentials = true;

// Export axios instance
export default axios;
