import React, { useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Button,
  Grid,
  Stack,
  useMediaQuery,
  Autocomplete,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import GoalHnadler from "../Goal(ProgramBook)";

import { themeStyles, buttonStyle } from "../../../constants";
import StyledTextArea from "../../../components/StyledTextArea";
// AddProgramBookGoal Component
const AddProgramBookGoal = ({
  iid,
  onRemove,
  toggleVisibility,
  visible,
  updateProgramBookGoal,
  InitialShortTermObjectiveName,
  InitialShortTermObjectiveDesc,
  InitialGoals,
  type,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  console.log("objective name", InitialShortTermObjectiveName);
  console.log("desc ", InitialShortTermObjectiveDesc);
  console.log("templates ", InitialGoals);
  const [shortTermObjectiveName, setShortTermObjectiveName] = useState(
    InitialShortTermObjectiveName
  );
  const [goalTemplates, setGoalTemplates] = useState([]);
  const [shortTermObjectiveDescription, setShortTermObjectiveDescription] =
    useState(InitialShortTermObjectiveDesc);
  const [goals, setGoals] = useState(InitialGoals);
  console.log("Goals", goals);

  const handleGoalsChange = (goal) => {
    updateProgramBookGoal(
      iid,
      shortTermObjectiveName,
      shortTermObjectiveDescription,
      goal
    );
    console.log("abey under add programm", goal);
  };
  const handleGoalNameChange = (event) => {
    setShortTermObjectiveName(event.target.value);
    updateProgramBookGoal(
      iid,
      event.target.value,
      shortTermObjectiveDescription,
      goals
    );
  };
  const handleGoalDescriptionChange = (event) => {
    setShortTermObjectiveDescription(event.target.value);
    updateProgramBookGoal(
      iid,
      shortTermObjectiveName,
      event.target.value,
      goals
    );
  };

  return (
    <Box marginLeft={2}>
      <Box display="flex" alignItems="end">
        <IconButton onClick={() => toggleVisibility(iid)} size="large">
          {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
        <IconButton onClick={() => onRemove(iid)} size="large">
          <DeleteOutlineIcon />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="start">
        {visible && (
          <Stack spacing={2} flexGrow={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5.8}>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Short Term Objective Name"
                  name={`shortTermObjectiveName-${iid}`}
                  onChange={handleGoalNameChange}
                  value={shortTermObjectiveName}
                  sx={{ ...themeStyles.textField }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6.2}>
                <StyledTextArea
                  fullWidth
                  multiline
                  rows={3}
                  variant="filled"
                  label="Short Term Objective Description"
                  onChange={handleGoalDescriptionChange}
                  value={shortTermObjectiveDescription}
                  name={`shortTermObjectiveDescription-${iid}`}
                  sx={{ ...themeStyles.textField }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <GoalHnadler
                  setGoals={setGoals}
                  goals={goals}
                  type={type}
                  handleGoalChange={handleGoalsChange}
                />
              </Grid>
            </Grid>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

// AddProgramBookGoalHandler Component
const AddProgramBookGoalHandler = ({
  programBookGoals,
  setProgramBookGoals,
  handleGoalChange,
  type,
}) => {
  const addProgramBookGoal = () => {
    setProgramBookGoals([
      ...programBookGoals,
      { iid: programBookGoals.length, visible: true },
    ]);
  };

  const removeProgramBookGoal = (iid) => {
    setProgramBookGoals(programBookGoals.filter((goal) => goal.iid !== iid));
    handleGoalChange(programBookGoals.filter((goal) => goal.iid !== iid));
  };
  const updateProgramBookGoal = (iid, goalName, goalDescription, goals) => {
    const res = programBookGoals.map((goal) => {
      if (goal.iid == iid) {
        return {
          ...goal,
          shortTermObjectiveName: goalName,
          shortTermObjectiveDescription: goalDescription,
          shortTermObjectiveStatus: "Active",
          goalTemplates: goals,
        };
      }

      return goal;
    });

    setProgramBookGoals(res);
    console.log(programBookGoals, "Goal inside program book goal handler");
    handleGoalChange(res);
  };

  const toggleVisibility = (iid) => {
    setProgramBookGoals(
      programBookGoals.map((goal) =>
        goal.iid === iid ? { ...goal, visible: !goal.visible } : goal
      )
    );
    handleGoalChange(
      programBookGoals.map((goal) =>
        goal.iid === iid ? { ...goal, visible: !goal.visible } : goal
      )
    );
  };

  return (
    <Box>
      <Button
        startIcon={<AddCircleOutlineIcon />}
        variant="contained"
        color="primary"
        onClick={addProgramBookGoal}
        sx={{ ...buttonStyle, marginBottom: "25px" }}>
        Short Term Objective
      </Button>
      {programBookGoals?.map((goal, index) => (
        <AddProgramBookGoal
          key={index}
          iid={index}
          InitialShortTermObjectiveDesc={goal.shortTermObjectiveDescription}
          InitialShortTermObjectiveName={goal.shortTermObjectiveName}
          InitialGoals={goal.goalTemplates ? goal.goalTemplates : []}
          onRemove={removeProgramBookGoal}
          toggleVisibility={toggleVisibility}
          visible={goal.visible}
          updateProgramBookGoal={updateProgramBookGoal}
          type={type}
        />
      ))}
    </Box>
  );
};

export default AddProgramBookGoalHandler;
