import { useTheme, Checkbox, FormControlLabel, Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import useSharedLink from "../SharedLink";
import axiosInstance from "../../components/AxiosInstance";
import { useState, useEffect } from "react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import LoadingIndicator from "../Loader";
import { useSnackbar } from "../../components/SnackBarProvider"; // Import SnackbarProvider

const roles = ["Admin", "Staff", "Supervisor", "Biller", "Other"];
const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

const randomRole = () => {
  return roles[Math.floor(Math.random() * roles.length)];
};

const disableFutureDates = (date) => {
  return date > new Date();
};

const initialRows = [
  {
    id: Math.floor(Math.random() * 1000),
    name: "John Doe",
    age: 25,
    joinDate: new Date(),
    role: randomRole(),
  },
];

export default function ManageClient() {
  const { openSnackbar } = useSnackbar(); // Access the SnackbarProvider
  const theme = useTheme();
  const sharedLink = useSharedLink();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(false);
  const [infocontent, setInfoContent] = useState("");
  const [facilities, setFacilities] = useState([]);
  const [assignedUsers, setAssignedUsers] = useState([]);

  const [allusers, setAllusers] = useState([]);
  const [includeInactive, setIncludeInactive] = useState(false);
  const navigate = useNavigate();
  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };

  const dateTimeFormatter = (value, locale = "en-US") => {
    if (!value) return "";

    const date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    const timeFormatter = new Intl.DateTimeFormat(locale, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formattedTime = timeFormatter.format(date);

    return `${formattedDate} ${formattedTime}`;
  };

  useEffect(() => {
    async function fetchData(retryDelay = 2000, retryCount = 0) {
      const maxRetries = 5;

      try {
        const res = await axiosInstance.post(sharedLink + "/viewallclient", { withCredentials: true });
        console.log(res.data);

        const formattedRows = res.data.map((row) => {
          const combinedAssignedUsers =
            Array.isArray(row.assignedUserName) &&
            Array.isArray(row.assignedUser)
              ? row.assignedUserName
                  .map((name, index) => {
                    const userId = row.assignedUser[index] || "unknown";
                    return `${name} (${userId})`;
                  })
                  .join(", ")
              : "";

          return {
            ...row,
            facilityName: `${row.facilityName} (${row.facilityId})`,
            assignedUser: combinedAssignedUsers,
          };
        });
        const activeUsers = formattedRows.filter(
          (row) => row.status === "Active"
        );
        setRows(activeUsers);
        setAllusers(formattedRows);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);

        openSnackbar("Error fetching client data.", "error");
        setLoading(false);
      }

      try {
        const res = await axiosInstance.post(sharedLink + "/viewallfacility", { withCredentials: true });
        console.log(res.data);
        const fomattedfacility = res.data.map(
          (row) => row.facilityName + " (" + row.id + ")"
        );
        setFacilities(fomattedfacility);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching facility data:", error);

        openSnackbar("Error fetching facility data.", "error");
        setLoading(false);
      }

      try {
        const res = await axiosInstance.post(sharedLink + "/viewallstaff", {}, { withCredentials: true });
        console.log(res.data);
        const formatted = res.data.map(
          (row) => `${row.firstName} ${row.lastName} (${row.id})`
        );
        setAssignedUsers(formatted);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching staff data:", error);

        openSnackbar("Error fetching staff data.", "error");
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  const handleRowEditStop = (params, event) => {
    console.log("inside stop");
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    navigate(`/updateClient?Id=${id}`);
  };

  const handleSaveClick = (id) => () => {
    console.log("inside save");
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => async () => {
    console.log("inside delete");

    if (window.confirm("Sure want to delete?")) {
      const idToDelete = { id: id };
      openSnackbar("Deleting Information...", "info");

      try {
        const response = await axiosInstance.post(
          sharedLink + "/removeclient",
          idToDelete , { withCredentials: true }
        );
        if (response.status === 200) {
          if (includeInactive) {
            setRows((prevRows) =>
              prevRows.map((row) =>
                row.id === id ? { ...row, status: "Inactive" } : row
              )
            );
          } else {
            setRows((prevRows) => prevRows.filter((row) => row.id !== id));
          }
          setAllusers((prevAllusers) =>
            prevAllusers.map((row) =>
              row.id === id ? { ...row, status: "Inactive" } : row
            )
          );
          openSnackbar("Information deleted.", "success");
        } else {
          openSnackbar("Failed to delete.", "error");
          console.error("Failed to delete item");
        }
      } catch (error) {
        openSnackbar("Error deleting item.", "error");
        console.error("Error while deleting item:", error);
      }
    }
  };

  const handleActivate = (id) => () => {
    console.log("inside activate");
    if (window.confirm("Sure want to activate?")) {
      openSnackbar("Activating Information...", "info");

      axiosInstance
        .post(sharedLink + "/updateclient", {
          id: id,
          status: "Active",
        })
        .then((res) => {
          if (res.status === 200) {
            setRows(
              rows.map((row) =>
                row.id === id ? { ...row, status: "Active" } : row
              )
            );
            openSnackbar("Information activated.", "success");
          } else {
            openSnackbar("Failed to activate.", "error");
            console.error("Failed to activate item");
          }
        })
        .catch((error) => {
          openSnackbar("Error activating item.", "error");
          console.error("Error while activating item:", error);
        });
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    console.log("Inside processing", newRow);

    const phoneNumberValidation = /^\d{10}$/.test(newRow.phoneNumber);
    if (!phoneNumberValidation) {
      openSnackbar("Phone Number must be a 10-digit number.", "error");
      return null;
    }
    const previousRow = rows.find((row) => row.id === newRow.id);

    const updatedRow = {
      ...newRow,
      isNew: false,
      lastUpdatedTime:
        new Date().toDateString() + ", " + new Date().toLocaleTimeString(),
      lastUpdatedBy: localStorage.getItem("username"),
      facilityId: newRow.facilityName.split("(")[1].split(")")[0],
    };
    openSnackbar("Updating Information...", "info");

    axiosInstance
      .post(sharedLink + "/updateclient", {
        ...newRow,
        lastupdatedby: localStorage.getItem("username"),
        lastupdatedtime:
          new Date().toLocaleDateString() +
          ", " +
          new Date().toLocaleTimeString(),
        facilityName: newRow.facilityName.split("(")[0],
        facilityId: newRow.facilityName.split("(")[1].split(")")[0],
      })
      .then((res) => {
        if (res.status === 200) {
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          openSnackbar("Information Updated.", "success");
          return updatedRow;
        } else {
          openSnackbar("Failed to update.", "error");
          console.error("Failed to update item");
        }
      })
      .catch((error) => {
        openSnackbar("Error updating item.", "error");
        console.error("Error while updating item:", error);
      });

    return previousRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const isCellEditable = (params) => {
    if (params.row.status.toLowerCase() === "active") return true;
    if (
      params.row.status.toLowerCase() === "inactive" &&
      params.field === "status"
    ) {
      return true;
    }
    return false;
  };

  const handleCheckboxChange = (event) => {
    setIncludeInactive(event.target.checked);
    if (event.target.checked) {
      setRows(allusers);
    } else {
      setRows(allusers.filter((row) => row.status === "Active"));
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "Client Id",
      editable: false,
      type: "number",
      hideable: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      editable: false,
      width: 200,
    },
    {
      field: "dateOfBirth",
      headerName: "Date of Birth",
      editable: false,
      type: "date",
      valueGetter: ({ row }) =>
        row.dateOfBirth ? new Date(row.dateOfBirth) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      field: "country",
      headerName: "Country",
      editable: false,
      type: "singleSelect",
      valueOptions: ["United States"],
    },
    {
      field: "state",
      headerName: "State",
      type: "singleSelect",
      valueOptions: states,
      editable: false,
    },
    {
      field: "zipCode",
      headerName: "Zip Code",
      editable: false,
    },
    {
      field: "city",
      headerName: "City",
      editable: false,
    },
    {
      field: "streetAddress",
      headerName: "Street Address",
      editable: false,
    },
    {
      field: "gender",
      headerName: "Gender",
      editable: false,
      type: "singleSelect",
      valueOptions: ["Prefer not to say", "Male", "Female"],
    },
    {
      field: "lastUpdatedBy",
      headerName: "Last Updated By",
      width: 100,
      editable: false,
    },
    {
      field: "lastUpdatedTime",
      headerName: "Last Updated Time",
      width: 200,
      type: "dateTime",
      editable: false,
      valueGetter: ({ row }) =>
        row.lastUpdatedTime
          ? new Date(row.lastUpdatedTime).toISOString()
          : null,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },
    {
      field: "enteredBy",
      headerName: "Entered By",
      width: 100,
      editable: false,
    },
    {
      field: "enteredTime",
      headerName: "Entered Time",
      width: 200,
      type: "dateTime",
      editable: false,
      valueGetter: ({ row }) =>
        row.enteredTime ? new Date(row.enteredTime).toISOString() : null,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },
    {
      field: "assignedUser",
      headerName: "Assigned User",
      width: 200,
      editable: false,
      type: "text",
    },
    {
      field: "dateOfAdmission",
      headerName: "Date of Admission",
      type: "date",
      valueGetter: ({ row }) =>
        row.dateOfAdmission ? new Date(row.dateOfAdmission) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      field: "dateOfDischarge",
      headerName: "Date of Discharge",
      type: "date",
      valueGetter: ({ row }) =>
        row.dateOfDischarge ? new Date(row.dateOfDischarge) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      field: "diagnosisCode",
      headerName: "Diagnosis Code",
      type: "text",
      editable: false,
    },
    {
      field: "initialComment",
      headerName: "Initial Comment",
      type: "text",
      editable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 200,
      editable: false,
    },
    {
      field: "policyEffectiveDate",
      headerName: "Policy Effective Date",
      type: "date",
      editable: false,
      width: 200,
      valueGetter: ({ row }) =>
        row.policyEffectiveDate ? new Date(row.policyEffectiveDate) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      field: "insuranceName",
      headerName: "Insurance Name",
      width: 200,
      editable: false,
    },
    {
      field: "status",
      type: "singleSelect",
      headerName: "Status",
      editable: false,
    },
    {
      field: "groupName",
      headerName: "Group Name",
      editable: false,
    },
    {
      field: "maritalStatus",
      headerName: "Marital Status",
      editable: false,
    },
    {
      field: "facilityName",
      headerName: "Facility Name",
      editable: false,
    },
    {
      field: "facilityId",
      headerName: "Facility ID",
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ row }) => {
        const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              title="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(row.id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              title="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(row.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            title="Edit"
            className="textPrimary"
            onClick={handleEditClick(row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            title="Delete"
            onClick={handleDeleteClick(row.id)}
            color="inherit"
            disabled={row.status.toLowerCase() === "inactive"}
          />,
        ];
      },
    },
  ];

  if (loading) {
    return <LoadingIndicator />;
  } else {
    return (
      <div className="staff-background">
        <Box m="20px">
          <Header title="Manage Clients" subtitle="" />
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.grey[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#3A57E8",
                borderBottom: "white",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "#3A57E8",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.grey[200]} !important`,
              },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeInactive}
                  onChange={handleCheckboxChange}
                  name="includeInactive"
                />
              }
              label="Include Inactive Clients"
            />

            <DataGridPro
              initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
                columns: {
                  columnVisibilityModel: {
                    id: false,
                    facilityId: false,
                    lastUpdatedBy: false,
                    lastUpdatedTime: false,
                    clientId: false,
                    enteredTime: false,
                    enteredBy: false,
                  },
                },
              }}
              sx={{
                boxShadow: 5,
                border: 5,
                borderColor: "primary.light",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold !important",
                },
                "& .MuiDataGrid-footerContainer": {
                  color: "white",
                  fontWeight: "bold !important",
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              rows={rows}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              slotProps={{
                toolbar: { setRows, setRowModesModel },
              }}
              isCellEditable={false}
              onRowDoubleClick={(params) => {
                handleEditClick(params.row.id)();
              }}
            />
          </Box>
        </Box>
      </div>
    );
  }
}
