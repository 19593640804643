import React, { useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Stack,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import LongTermGoalHandler from "../Level2";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { themeStyles, buttonStyle } from "../../../constants";
import StyledTextArea from "../../../components/StyledTextArea";

const Domain = ({
  id,
  iid,
  onRemove,
  visible,
  toggleVisibility,
  updateDomain,
  InitialDomainName,
  InitialDomainDescription,
  InitialLongTermGoals,
  updateStatus,
  status,
  parentStatus,
  type,
}) => {
  console.log("Domain -> InitialLongTermGoals", InitialLongTermGoals);
  console.log("Domain -> InitialDomainName", InitialDomainName);
  console.log("Domain -> InitialDomainDescription", InitialDomainDescription);
  console.log("Domain -> visible", visible);
  console.log("Domain -> parent", parentStatus);
  console.log("Domaain type", type);

  const [longTermGoals, setLongTermGoals] = useState(InitialLongTermGoals);

  const [domainName, setDomainName] = useState(InitialDomainName);
  const [domainDescription, setDomainDescription] = useState(
    InitialDomainDescription
  );
  const [totalAreas, setTotalAreas] = useState(InitialLongTermGoals);
  const [isActive, setIsActive] = useState(status == "Active"); // State to manage activation status

  const handleDomainNameChange = (event) => {
    setDomainName(event.target.value);
    updateDomain(iid, event.target.value, domainDescription, longTermGoals);
  };

  const handleDomainDescriptionChange = (event) => {
    setDomainDescription(event.target.value);
    updateDomain(iid, domainName, event.target.value, longTermGoals);
  };
  const handleLongtermGoalChange = (res) => {
    console.log("res in level 1", res);
    console.log("previously", longTermGoals);
    updateDomain(iid, domainName, domainDescription, res);
    setLongTermGoals(res);
  };

  const toggleActivationStatus = () => {
    if (!parentStatus) {
      alert("Please make the parent area active");
      return;
    }

    updateStatus(iid, isActive ? "Inactive" : "Active", longTermGoals);
    setIsActive((prev) => !prev);
  };
  const handleActivation = (data) => {
    console.log("handleActivation at level 1", data);
    updateStatus(iid, !isActive ? "Inactive" : "Active", data);
  };

  return (
    <Box margin={2}>
      <Box
        margin={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => toggleVisibility(iid)} size="large">
            {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>

          <Box display="flex" alignItems="center" mr={2}>
            {id == null ? (
              <>
                <IconButton onClick={() => onRemove(iid)} size="large">
                  <DeleteOutlineIcon disabled={!parentStatus} />
                </IconButton>
              </>
            ) : (
              <>
                {" "}
                <IconButton
                  onClick={toggleActivationStatus}
                  size="large"
                  disabled={!parentStatus}>
                  {isActive && parentStatus ? (
                    <RemoveCircleOutlineIcon />
                  ) : (
                    <CheckCircleOutlineIcon />
                  )}
                </IconButton>
                <Typography variant="body1">
                  {isActive && parentStatus ? "Deactivate" : "Activate"}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="start">
        <Stack spacing={2} flexGrow={1}>
          {visible && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5.8}>
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Strategy Name"
                    onChange={handleDomainNameChange}
                    name={`strategyName-${iid}`}
                    value={domainName}
                    disabled={!isActive || !parentStatus}
                    required
                    sx={{ ...themeStyles.textField }}
                    inputProps={{ maxLength: 120 }}
                  />
                </Grid>
                <Grid item xs={12} md={6.2}>
                  <StyledTextArea
                    fullWidth
                    variant="filled"
                    multiline
                    rows={3}
                    label="Strategy Description"
                    onChange={handleDomainDescriptionChange}
                    name={`strategyDescription-${iid}`}
                    value={domainDescription}
                    sx={{ ...themeStyles.textField }}
                    disabled={!isActive || !parentStatus}
                    inputProps={{
                      maxLength: 512,
                    }}
                  />
                </Grid>
              </Grid>
              <LongTermGoalHandler
                longTermGoals={longTermGoals}
                setLongTermGoals={setLongTermGoals}
                handleLongtermGoalChange={handleLongtermGoalChange}
                handleActivation={handleActivation}
                parentStatus={parentStatus && isActive}
                type={type}
              />
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
};
export default Domain;
