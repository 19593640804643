import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Grid,
  Divider,
  IconButton,
  Autocomplete,
  TextField,
  ListItem,
  ListItemText,
  List,
  Popover,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useDispatch, useSelector } from "react-redux";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import EventPopup from "./PopUp"; // Import the PopUp component for the dialog box
import axios from "../../axiosConfig"; // Import axios to handle API calls
import useSharedLink from "../../scenes/SharedLink"; // Import useSharedLink
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { fetchEvents } from "../../Redux/ActionCreators/CalenderActionCreator";
import {
  CALENDAR,
  CREATE,
  DELETE,
  READ,
  themeStyles,
  UPDATE,
} from "../../constants";
import dayjs from "dayjs";
import { usePermissions } from "../CheckPermission";

const CalendarDates = () => {
  const dispatch = useDispatch();
  const today = new Date();
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverEvents, setPopoverEvents] = useState([]);
  const { giveClients, giveStaff, checkPermission } = usePermissions();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth(); // Month index starts from 0 (January) to 11 (December)
  const currentDate = today.getDate();
  const [newEvent, setNewEvent] = useState(null); // State for new event

  // Initialize state with current year, month, and date
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth);
  const [selectedDate, setSelectedDate] = useState(dayjs()); // Initialize as null
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [openDialog, setOpenDialog] = useState(false); // State to control dialog open/close
  const [selectedClients, setSelectedClients] = useState([]); // State for selected clients
  const [selectedStaff, setSelectedStaff] = useState([]);
  // State for clients
  const clients = giveClients(CALENDAR, READ) || [];
  const staff = giveStaff(CALENDAR, READ) || [];
  console.log(clients, " ", staff);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const events = useSelector((state) => state?.calender?.eventsByStaff) || {}; // Get the selected event from Redu
  const selectedStaffFromReducer =
    useSelector((state) => state?.calender?.selectedStaffs) || [];
  console.log("events fetched", events);
  useEffect(() => {
    console.log(
      selectedStaff,
      "selected staff selected client",
      selectedClients
    );
    // Dispatch fetchEvents whenever selectedClients or selectedStaff changes
    dispatch(
      fetchEvents(selectedClients || [], selectedStaff || [], selectedDate)
    );
  }, [selectedClients, selectedStaff, selectedDate]);
  // useEffect(() => {
  //   // Fetch clients data from the API
  //   const fetchClients = async () => {
  //     try {
  //       const response = await axios.post(`${sharedLink}/viewallclient`, {
  //         withCredentials: true,
  //       });
  //       const clientsData = response.data
  //         .filter((client) => client.status === "Active") // Filter to include only active clients
  //         .map((client) => ({
  //           label: client.firstName + " " + client.lastName,
  //           ...client,
  //         }));
  //       setClients(clientsData); // Set the clients state with the data from API
  //     } catch (error) {
  //       console.error("Error fetching clients:", error);
  //     }
  //   };

  //   // Fetch staff data from the API
  //   const fetchStaff = async () => {
  //     try {
  //       const response = await axios.post(`${sharedLink}/viewallstaff`, {
  //         withCredentials: true,
  //       });
  //       const staffData = response.data
  //         .filter((staff) => staff.status === "Active") // Filter to include only active staff
  //         .map((staff) => ({
  //           label: staff.firstName + " " + staff.lastName,
  //           ...staff,
  //         }));
  //       setStaff(staffData); // Set the staff state with the data from API
  //     } catch (error) {
  //       console.error("Error fetching staff:", error);
  //     }
  //   };

  //   fetchClients(); // Fetch clients data on component mount
  //   fetchStaff(); // Fetch staff data on component mount
  // }, [sharedLink]); // Dependency array includes sharedLink

  const handleDateClick = (selectedDay) => {
    // Use Day.js to manage the date, then convert it to a native Date when needed
    const currentday = selectedDay.toDate(); // Convert Day.js object to native Date object

    const newMonth = currentday.getMonth();
    const newYear = currentday.getFullYear();

    // Keep selectedDate as a Day.js object for compatibility with DateCalendar
    setSelectedDate(selectedDay);

    // Set newEvent dates as native Date objects
    setNewEvent({
      ...newEvent,
      startDate: currentday, // Update startDate with the selected date
      endDate: currentday, // Update endDate with the selected date (or modify as per your logic)
    });

    setMonth(newMonth);
    setYear(newYear);
  };

  // Inside the CalendarDates component

  // Add a function to handle month/year change from DateCalendar
  const handleCalendarMonthChange = (date) => {
    const newMonth = date.$M; // Day.js object has the month as a zero-based index
    const newYear = date.$y;

    setMonth(newMonth); // Update the month state
    setYear(newYear); // Update the year state
    // setSelectedDate(null); // Reset selected date
    setSelectedMonth(newMonth); // Reset selected month
    setSelectedYear(newYear); // Reset selected year
  };

  const handleClientChange = (event, value) => {
    // Ensure that value is not null or undefined
    if (value) {
      setSelectedClients([value]);
    } else {
      setSelectedClients([]);
    }
  };

  const handleStaffChange = (event, value) => {
    setSelectedStaff(value);
  };
  const handleTimeSlotClick = (hour, staff) => {
    const startTime = new Date(year, month, today.getDate(), hour);
    const endTime = new Date(startTime.getTime() + 30 * 60 * 1000); // 30 minutes later
    setNewEvent({
      startTime,
      endTime,
      staff,
      selectedDate,
    });
    if (checkPermission(CALENDAR, CREATE)) {
      setSelectedEvent(null); // Clear the selected event

      setOpenDialog(true);
    }
  };
  const handleMoreClick = (event, hourEvents) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);

    setPopoverEvents(hourEvents); // Store the events that will be shown in the popover
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const renderTimeLabels = () => {
    const labels = [];
    for (let hour = 8; hour < 32; hour++) {
      const displayHour = hour > 24 ? hour - 24 : hour;
      const period = displayHour >= 12 ? "PM" : "AM";
      const formattedHour = displayHour > 12 ? displayHour - 12 : displayHour;
      const label = `${formattedHour === 0 ? 12 : formattedHour} ${period}`;

      labels.push(
        <Box
          key={hour}
          sx={{
            display: "flex",
            alignItems: "center",
            height: "60px", // Match the height of the time slots
            // borderBottom: "1px solid #ddd",
            justifyContent: "center",
          }}>
          <Typography variant="body2" color="textSecondary">
            {label}
          </Typography>
        </Box>
      );
    }
    return labels;
  };
  const renderTimeSlotsForStaff = (staffEvents, staff) => {
    console.log("staff events", staffEvents);
    const slots = [];
    const maxVisibleEvents = 3;
    const minEventHeight = 50;
    const hourHeight = 60;
    const colorPalette = [
      "#007bff",
      "#28a745",
      "#ffc107",
      "#17a2b8",
      "#ff5733",
      "#9c27b0",
    ];

    for (let hour = 8; hour < 31; hour++) {
      const actualHour = hour > 24 ? hour - 24 : hour; // Adjust for times past midnight

      const hourEvents = staffEvents.filter((event) => {
        const eventStartHour = new Date(
          `${event.startDate} ${event.startTime}`
        ).getHours();
        return eventStartHour === actualHour;
      });

      slots.push(
        <Grid
          container
          key={hour}
          sx={{
            position: "relative",
            height: `${hourHeight}px`,
            borderBottom: "1px solid #ddd",
            cursor: "pointer",
          }}
          onClick={() => handleTimeSlotClick(hour, staff)}>
          <Grid item xs={12} sx={{ position: "relative", height: "100%" }}>
            {hourEvents.slice(0, maxVisibleEvents).map((event, index) => {
              const eventStart = new Date(
                `${event.startDate} ${event.startTime}`
              );
              const eventEnd = new Date(`${event.endDate} ${event.endTime}`);
              const eventDuration =
                (eventEnd.getTime() - eventStart.getTime()) / (60 * 1000);
              const overlapCount = hourEvents.length;
              const widthPercentage =
                100 / Math.min(overlapCount, maxVisibleEvents);
              const eventHeight = Math.max(
                (eventDuration / 60) * (hourHeight / 60) * 100,
                minEventHeight
              );
              const eventColor =
                colorPalette[(hour + index) % colorPalette.length];

              return (
                <Box
                  key={`${event.id}-${index}`}
                  sx={{
                    position: "absolute",
                    top: `${(eventStart.getMinutes() / 60) * 100}%`,
                    left: `${index * widthPercentage}%`,
                    width: `${widthPercentage}%`,
                    height: `${eventHeight}%`,
                    backgroundColor: eventColor,
                    borderRadius: 1,
                    padding: "5px",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    zIndex: 1,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedEvent(event);
                    setOpenDialog(true);
                  }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      lineHeight: 1.3,
                    }}>
                    {event.title || "(No title)"}, {event.startTime}
                  </Typography>
                </Box>
              );
            })}

            {hourEvents.length > maxVisibleEvents && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "2px",
                  right: "2px",
                  zIndex: 2,
                }}>
                <IconButton
                  size="small"
                  sx={{
                    color: "#fff",
                    backgroundColor: "#0008",
                    marginBottom: "22px",
                    padding: "2px",
                    "&:hover": {
                      backgroundColor: "#000c",
                    },
                  }}
                  onClick={(e) => handleMoreClick(e, hourEvents)}>
                  <MoreHorizIcon />
                </IconButton>
              </Box>
            )}
          </Grid>
        </Grid>
      );
    }

    // Add a gap below 7 AM
    slots.push(
      <Grid
        container
        key="gap-below-7am"
        sx={{
          position: "relative",
          height: "100px", // Adjust the height as needed for your gap
          borderBottom: "none",
          cursor: "pointer",
          // backgroundColor: "#f0f0f0", // Optional: Light grey background to make the gap noticeable
        }}
        onClick={() => {
          setNewEvent({
            startTime: new Date(year, month, today.getDate(), 7, 0),
            endTime: new Date(year, month, today.getDate(), 7, 30),
            staff,
            selectedDate,
          });
          setSelectedEvent(null);
          setOpenDialog(true);
        }}>
        <Grid item xs={12} sx={{ position: "relative", height: "100%" }}></Grid>
      </Grid>
    );

    return slots;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        // border:"2px solid red",
        height: "calc( 100vh - 90px )",
        overflow: "hidden", // Prevents the outer scrollbar from appearing
      }}>
      {/* Left-side Calendar and Autocompletes */}

      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: { xs: "100%", md: "20%" },
          height: "100vh",
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "white",
          // border:"2px solid red",
          overflowY: "auto", // Adds a vertical scrollbar when content overflows
        }}>
        {/* Calendar grid and Autocompletes */}
        <Grid container spacing={1} sx={{ maxWidth: "20vw" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              showDaysOutsideCurrentMonth
              className="animated-input"
              onChange={handleDateClick}
              fixedWeekNumber={6}
              onMonthChange={handleCalendarMonthChange}
              sx={{
                ".MuiDayCalendar-weekDayLabel": {
                  color: "grey",
                  fontWeight: "bold",
                },
                ".MuiPickersCalendarHeader-label": {
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  textShadow: "1px 2px 2px rgba(0, 0, 0, 0.4)",
                },
                ".MuiPickersDay-today": {
                  backgroundColor: "#1a73e8",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#1a73e8",
                  },
                },
                ".MuiPickersDay-root.Mui-selected:not(.MuiPickersDay-today)": {
                  backgroundColor: "#90caf9",
                  color: "#1a73e8",
                  "&:hover": {
                    backgroundColor: "#bbdefb",
                  },
                },
                ".MuiPickersDay-root.Mui-selected.MuiPickersDay-today": {
                  backgroundColor: "#1a73e8",
                  color: "#fff",
                },
              }}
            />
          </LocalizationProvider>
          <Autocomplete
            // multiple
            options={clients}
            onChange={handleClientChange}
            getOptionLabel={(option) =>
              option.firstName + " " + option.lastName
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Client"
                className="animated-input"
                variant="outlined"
                margin="dense"
              />
            )}
            sx={{
              marginBottom: "5px",
              width: "85%",
              marginLeft: 1.5,
              marginTop: 2,
            }}
          />
          <Autocomplete
            multiple
            options={staff}
            getOptionLabel={(option) =>
              option.firstName + " " + option.lastName
            }
            onChange={handleStaffChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Staff"
                className="animated-input"
                variant="outlined"
                margin="dense"
              />
            )}
            sx={{
              marginBottom: 15,
              width: "85%",
              marginLeft: 1.5,
              marginTop: 1,

              // maxHeight:"200px"
            }}
          />
        </Grid>
      </Box>

      {/* Main scrollable content (Time labels and Staff columns) */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          // Staff container will scroll horizontally
          overflowX: "auto",
          height: "100%",
        }}>
        {/* Time Labels container (will only scroll vertically) */}
        <Box
          sx={{
            width: "60px",
            position: "sticky", // Keeps time labels in view during vertical scroll
            left: 0, // Pin to the left side
            zIndex: 4,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#f5f5f5",
            overflowY: "auto", // Only vertical scrolling
            height: "207vh", // Ensures it covers the entire height of the staff columns
            // border:"2px solid red",
            marginTop: "6px",
          }}>
          {renderTimeLabels()} {/* Function to render time labels */}
        </Box>

        {/* Staff Columns container */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexGrow: 1,
            height: "207vh", // Adjust height to ensure it covers the full height of the container
          }}>
          {(selectedStaff.length === 0 && selectedClients.length > 0
            ? selectedStaffFromReducer
            : selectedStaff
          ).map((staffMember) => (
            <Box
              key={staffMember.id}
              sx={{
                minWidth: { lg: "33.33%", sm: "50%", xs: "100%" },
                flexGrow:
                  selectedStaff.length > 1 ? 1 / selectedStaff.length : 1,
                borderRight: "1px solid #ddd",
                padding: 1,
                height: "100%", // Extend the height to match the full height of the container
              }}>
              <Box
                sx={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: 3,
                  borderBottom: "1px solid #ddd",
                }}>
                <Typography
                  variant="h6"
                  sx={{ textAlign: "center", marginBottom: 1 }}>
                  {staffMember.firstName} {staffMember.lastName}
                </Typography>
              </Box>

              {/* Ensure the slots fill the full height */}
              <Box
                sx={{
                  flexGrow: 1, // Make sure the content inside stretches to fill the height
                  overflowY: "visible", // Prevent additional scrollbars within the time slots
                  // position: "relative", // Ensure the event boxes are positioned correctly
                  zIndex: 1, // Lower zIndex for events compared to the staff name
                }}>
                {renderTimeSlotsForStaff(
                  events[staffMember.id] || [],
                  staffMember
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Popover for more events */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        <List>
          {popoverEvents.map((event, index) => (
            <ListItem
              button
              key={index}
              onClick={() => {
                setSelectedEvent(event);
                setOpenDialog(true);
                handlePopoverClose(); // Close the popover
              }}>
              <ListItemText
                primary={event.title || "(No title)"}
                secondary={event.startTime}
              />
            </ListItem>
          ))}
        </List>
      </Popover>

      {/* Event Popup */}
      {(!selectedEvent
        ? checkPermission(CALENDAR, CREATE)
        : checkPermission(CALENDAR, READ)) && (
        <Box key={openDialog}>
          <EventPopup
            updatePermission={
              !selectedEvent
                ? checkPermission(CALENDAR, CREATE)
                : checkPermission(CALENDAR, UPDATE)
            }
            deletePermission={checkPermission(CALENDAR, DELETE)}
            staffids={selectedStaff}
            clientids={selectedClients}
            open={openDialog}
            selectedEvent={selectedEvent}
            onClose={() => setOpenDialog(false)}
            event={newEvent}
            selectedDate={selectedDate}
          />
        </Box>
      )}
    </Box>
  );
};

export default CalendarDates;
