import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { DataGridPro } from "@mui/x-data-grid-pro";

import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import axiosInstance from "../../components/AxiosInstance";
import { useState, useEffect } from "react";
import { Alert } from "@mui/material";
import LoadingIndicator from "../Loader";
import useSharedLink from "../SharedLink";
const FacilityInfo = () => {
  const sharedLink = useSharedLink();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertDanger, setAlertDanger] = useState(false);
  const [alertContentDanger, setAlertContentDanger] = useState("");
  const handleClose = () => {
    setAlertDanger(false);
  };
  useEffect(() => {
    async function fetchData(retryDelay = 2000, retryCount = 0) {
      const maxRetries = 5; // Define the maximum number of retries

      try {
        axiosInstance
          .post(sharedLink + "/viewallfacility")
          .then((res) => {
            if (res.status === 200) {
              console.log("hey are you", res);
              setRow(res.data);
              setLoading(false);
            } else {
              setAlertContentDanger(
                "Failed to fetch data after multiple attempts."
              );
              setAlertDanger(true);
              setLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setAlertContentDanger(
              "Failed to fetch data after multiple attempts."
            );
            setAlertDanger(true);
            setLoading(false);
          });
      } catch (error) {
        console.error("Error fetching data:", error);

        if (retryCount < maxRetries) {
          console.log(
            `Retrying in ${retryDelay / 1000} seconds... (${
              retryCount + 1
            }/${maxRetries})`
          );
          setTimeout(() => fetchData(retryDelay, retryCount + 1), retryDelay);
        } else {
          console.error("Max retries reached. Aborting fetch.");
          setAlertContentDanger(
            "Failed to fetch data after multiple attempts."
          );
          setAlertDanger(true);
          setLoading(false);
        }
      }
    }

    fetchData();
  }, []);

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };

  // Helper function to format the time into "HH:MM AM/PM" (if needed)
  const dateTimeFormatter = (value, locale = "en-US") => {
    if (!value) return "";

    const date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() is zero-based
    const day = date.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    const timeFormatter = new Intl.DateTimeFormat(locale, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // true for AM/PM notation
    });

    const formattedTime = timeFormatter.format(date);

    return `${formattedDate} ${formattedTime}`;
  };

  const columns = [
    { field: "id", width: 150, headerName: "Id" },
    { field: "orgId", width: 150, headerName: "Org Id" },
    { field: "facilityName", width: 150, headerName: "Facility Name" },
    { field: "facilityNPI", width: 150, headerName: "Facility Npi" },
    { field: "facilityTIN", width: 150, headerName: "Facility Tin" },
    { field: "facilityType", width: 150, headerName: "Facility Type" },

    {
      field: "startDate",
      width: 150,
      headerName: "Start Date",
      valueGetter: ({ row }) =>
        row.startDate ? new Date(row.startDate) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      field: "expirationDate",
      width: 150,
      headerName: "Expiration Date",
      valueGetter: ({ row }) =>
        row.expirationDate ? new Date(row.expirationDate) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },
    { field: "licenseNo", width: 150, headerName: "License No" },

    {
      field: "licExpDate",
      width: 150,
      headerName: "License Exp Date",
      valueGetter: ({ row }) =>
        row.licExpDate ? new Date(row.licExpDate) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },
    { field: "country", width: 150, headerName: "Country" },
    { field: "state", width: 150, headerName: "State" },

    { field: "city", width: 150, headerName: "City" },
    { field: "address", width: 150, headerName: "Address" },

    { field: "zipCode", width: 150, headerName: "Zip Code" },
    { field: "phoneNumber", width: 150, headerName: "Phone Number" },
    { field: "enteredBy", width: 150, headerName: "Entered By" },

    { field: "lastUpdatedBy", width: 150, headerName: "Last Updated By" },
    {
      field: "lastUpdatedTime",
      headerName: "Last Updated Time",
      width: 200,
      type: "dateTime",
      editable: false,
      valueGetter: ({ row }) =>
        row.lastUpdatedTime
          ? new Date(row.lastUpdatedTime).toISOString()
          : null,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },

    {
      field: "enteredBy",
      headerName: "Entered By",
      width: 100,
      editable: false,
    },

    {
      field: "enteredTime",
      headerName: "Entered Time",
      width: 200,
      type: "dateTime",
      editable: false,
      valueGetter: ({ row }) =>
        row.enteredTime ? new Date(row.enteredTime).toISOString() : null,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },
  ];
  if (loading) {
    return <LoadingIndicator />;
  } else
    return (
       <div  className="staff-background">
      <Box m="20px">
        {alertDanger && (
          <Alert severity="error" onClose={handleClose}>
            {alertContentDanger}
          </Alert>
        )}
        <Header title="View Facilities" subtitle="" />
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
               "& .name-column--cell": {
              color: colors.grey[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor:"#3A57E8",
              borderBottom: "white",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#3A57E8",
            },
            "& .MuiCheckbox-root": {
              color: `${colors.grey[200]} !important`,
            },
          }}>
          <DataGridPro
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  id: false,
                  orgId: false,
                  lastUpdatedBy: false,
                  lastUpdatedTime: false,
                  enteredTime: false,
                  enteredBy: false,
                },
              },
            }}
            sx={{
              boxShadow: 5,
              border: 5,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
               "& .MuiDataGrid-columnHeaderTitle": {
                color:"white",
                fontWeight: "bold !important",
              },
               "& .MuiDataGrid-footerContainer": {
                color:"white",
                fontWeight: "bold !important",
              },
            }}
            pageSizeOptions={[25, 50, 100]}
            rows={row}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
          />
          {/* <InitialFilters row={row} /> */}
        </Box>
      </Box>
      </div>
    );
};

export default FacilityInfo;
