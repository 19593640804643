import {
  Box,
  Button,
  Typography,
  useTheme,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  MenuItem,
  Select,
  TableRow,
  FormControl,
  TextField,
  Paper,
  Alert,
  Autocomplete,
} from "@mui/material";
import { usePermissions } from "../../Phase3/CheckPermission";
import IconButton from "@mui/material/IconButton";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { CircularProgress } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import GroupIcon from "@mui/icons-material/Group";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import NotesIcon from "@mui/icons-material/Notes";
import { tokens } from "../../theme";
import { useEffect, useState } from "react";
import "./index.css";
import axiosInstance from "../../components/AxiosInstance";
import Topbar from "../global/Topbar";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../Loader";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  buttonStyle,
  DASHBOARD,
  DATA_ENTRY_PAGE,
  READ,
  RB_BT,
} from "../../constants";
import useSharedLink from "../SharedLink";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Modal from "@mui/material/Modal";
import GoalsLineGraphs from "../../Phase2/GoalLineGraph"; // Adjust the import path as necessary
import { modalStyle, themeStyles } from "../../constants";
import { useSnackbar } from "../../components/SnackBarProvider";
import { useDispatch, useSelector } from "react-redux";
import { fetchClients } from "../../Redux/ActionCreators/ClientActionCreator";
import { fetchStaff } from "../../Redux/ActionCreators/StaffActionCreator";

export function PatientsTable({ clients }) {
  const [dates, setDates] = useState({});
  const [isGraphModalOpen, setGraphModalOpen] = useState(false);
  const [clientId, setClientId] = useState();
  const [selectedClient, setSelectedClient] = useState();

  const { openSnackbar } = useSnackbar();
  const handleGraphModalOpen = () => setGraphModalOpen(true);
  const handleGraphModalClose = () => setGraphModalOpen(false);
  const navigate = useNavigate();

  const handleClientSelect = (newValue) => {
    setSelectedClient(newValue);
    setClientId(newValue?.id);
  };

  const handleAddDataClick = (patient) => {
    console.log(patient);
    if (!patient) {
      openSnackbar("Please select a client before adding data.", "error");
    } else {
      navigate("/tree", {
        state: {
          clientId: patient.id,
          owner: patient.owner,
          clientName: patient.firstName + " " + patient.lastName,

          gender: patient.gender,
          dob: patient.dateOfBirth,
        },
      });
    }
  };

  const handleViewResults = (patientId) => {
    if (!patientId) {
      openSnackbar(
        "Please select a client to see the program results.",
        "error"
      );
      return;
    }
    setClientId(patientId);
    handleGraphModalOpen();
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Modal open={isGraphModalOpen} onClose={handleGraphModalClose}>
          <Box sx={{ ...modalStyle, width: 1000, height: 700 }}>
            <GoalsLineGraphs
              clientId={clientId}
              onClose={handleGraphModalClose}
            />
          </Box>
        </Modal>
        <TableContainer component={Paper} style={{ maxHeight: 500 }}>
          <Table sx={{ minWidth: 1000 }} aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    width: "40%",
                    backgroundColor: "#3A57E8",
                    color: "white",
                  }}>
                  Client Name
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    backgroundColor: "#3A57E8",
                    color: "white",
                  }}>
                  Gender
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    backgroundColor: "#3A57E8",
                    color: "white",
                  }}>
                  Status
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    backgroundColor: "#3A57E8",
                    color: "white",
                  }}>
                  Date of Birth
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    backgroundColor: "#3A57E8",
                    color: "white",
                  }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: "40%" }}>
                  <Autocomplete
                    options={clients || []}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName} (${option.email})`
                    }
                    autoHighlight
                    value={selectedClient}
                    onChange={(event, newValue) => handleClientSelect(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client Name"
                        variant="filled"
                        required
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => {
                                  if (selectedClient) {
                                    handleAddDataClick(selectedClient);
                                  } else {
                                    openSnackbar(
                                      "Please select a client before proceeding.",
                                      "error"
                                    );
                                  }
                                }}
                                edge="end"
                                sx={{ color: "blue" }} // You can specify any CSS-compatible color value here
                              >
                                <ArrowRightIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                    sx={{ width: "100%", ...themeStyles.textField }}
                  />
                </TableCell>
                <TableCell align="center">{selectedClient?.gender}</TableCell>
                <TableCell align="center">{selectedClient?.status}</TableCell>
                <TableCell align="center" style={{ width: "20%" }}>
                  {selectedClient?.dateOfBirth}
                </TableCell>
                <TableCell align="center">
                  <Box sx={{ display: "flex", flexWrap: "nowrap", gap: 1 }}>
                    <Button
                      style={{ ...buttonStyle }}
                      onClick={() => handleAddDataClick(selectedClient)}>
                      Data Entry
                    </Button>
                    <Button
                      style={{ ...buttonStyle }}
                      onClick={() => handleViewResults(selectedClient?.id)}>
                      Program Results
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </LocalizationProvider>
    </>
  );
}

const Dashboard = () => {
  const { checkPermission, giveClients, giveStaff } = usePermissions();

  const [visibleNotes, setVisibleNotes] = useState(5);
  const [pagesize, setPagesize] = useState(5);
  const [currentPage, setCurrentPage] = useState(0);
  const patientStatusData = [{ name: "Jan", uv: 4000, pv: 2400 }];
  const [selectedClient, setSelectedClient] = useState("");

  const theme = useTheme();
  const sharedLink = useSharedLink();
  const colors = tokens(theme.palette.mode);
  const [sessionNotes, setSessionNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [countAppointments, setCountAppointments] = useState(0);
  const [totalSessionNotes, setTotalSessionNotes] = useState(0);
  const navigate = useNavigate();
  const clients = giveClients(DATA_ENTRY_PAGE, READ);

  const staffs = giveStaff(DASHBOARD, READ);

  console.log(staffs, " clients", clients);

  const loadMoreNotes = () => {
    setVisibleNotes((prevVisibleNotes) => prevVisibleNotes + 10);
    const nextpage = currentPage + 1;
    setCurrentPage(nextpage);
    fetchSessionNote(nextpage);
  };

  useEffect(() => {
    fetchSessionNote(0);
  }, []);
  const fetchSessionNote = async (page) => {
    let payload = {
      // procedureCode: "97153",
      page: page.toString(),
      size: pagesize.toString(),
      sessionstatus: "Pending Review",
      staffid: localStorage.getItem("userId"),
    };
    if (localStorage.getItem("role") === RB_BT) {
      payload.sessionstatus = "Draft";
    }

    try {
      const sessionNotesResponse = await axiosInstance.post(
        sharedLink + "/getsessionnote",
        payload,
        { withCredentials: true }
      );

      if (sessionNotesResponse.status === 200) {
        const sessionData = sessionNotesResponse.data.content;
        console.log(sessionData, "recieved");
        if (Array.isArray(sessionData) && sessionData.length) {
          setSessionNotes((prevNotes) => [...prevNotes, ...sessionData]);
        }
        setTotalSessionNotes(sessionNotesResponse?.data?.totalElements);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClientChange = (event) => {
    const selectedClientId = event.target.value;
    setSelectedClient(selectedClientId);
  };

  if (loading) return <LoadingIndicator />;
  else
    return (
      <div>
        <Box m="10px">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <AssessmentIcon color="primary" />
                <Typography variant="h5">
                  Appointments: {countAppointments}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <GroupIcon color="primary" />
                <Typography variant="h5">
                  Clients: {(clients || []).length}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <PermContactCalendarIcon color="primary" />
                <Typography variant="h5">
                  Staff: {(staffs || []).length}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{
                  p: 2,
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <NotesIcon color="primary" />
                <Typography variant="h5">
                  Session Notes: {totalSessionNotes}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  position: "relative",
                }}>
                <Typography variant="h5" gutterBottom>
                  Patient Status
                </Typography>

                <FormControl sx={{ position: "absolute", top: 8, right: 16 }}>
                  <Select
                    value={selectedClient}
                    onChange={handleClientChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    size="small">
                    <MenuItem value="" disabled>
                      Select Client
                    </MenuItem>
                    {clients?.map((client) => (
                      <MenuItem key={client.id} value={client.id}>
                        {client.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={patientStatusData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="uv"
                      stroke="#8884d8"
                      strokeWidth={2}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>

            {checkPermission(DATA_ENTRY_PAGE, "r") && (
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <PatientsTable clients={clients || []} />
                </Paper>
              </Grid>
            )}

            <Grid item xs={12}>
              <Paper sx={{ p: 2 }}>
                <Typography
                  color="textSecondary"
                  fontWeight="600"
                  sx={{ letterSpacing: "1px", textTransform: "uppercase" }}
                  gutterBottom>
                  Recent Session Notes [
                  {localStorage.getItem("role") == RB_BT
                    ? "Draft"
                    : "Pending Review"}
                  ]
                </Typography>
                <TableContainer
                  component={Paper}
                  style={{ maxHeight: 300, overflowY: "auto" }}>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                    stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            backgroundColor: "#3A57E8",
                            color: "white",
                          }}>
                          Staff Name
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            backgroundColor: "#3A57E8",
                            color: "white",
                          }}>
                          Client Name
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            backgroundColor: "#3A57E8",
                            color: "white",
                          }}>
                          Session Date
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            backgroundColor: "#3A57E8",
                            color: "white",
                          }}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sessionNotes
                        .slice(0, visibleNotes)
                        .map((transaction) => (
                          <TableRow key={transaction?.sessionid}>
                            <TableCell align="center">
                              {transaction?.staffFirstName}{" "}
                              {transaction?.staffLastName}
                            </TableCell>
                            <TableCell align="center">
                              {transaction?.clientFirstName}{" "}
                              {transaction?.clientLastName}
                            </TableCell>
                            <TableCell align="center">
                              {dayjs(transaction?.sessionDate).format(
                                "YYYY-MM-DD"
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                variant="contained"
                                style={{ ...buttonStyle, marginLeft: "10px" }}
                                onClick={() =>
                                  navigate(
                                    `/updateSessionNote${transaction.procedureCode}?sessionId=${transaction.sessionId}`
                                  )
                                }>
                                Session Note
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {sessionNotes.length < totalSessionNotes && (
                  <Box display="flex" justifyContent="center" mt={2}>
                    <Button
                      onClick={loadMoreNotes}
                      variant="contained"
                      color="primary">
                      {loading ? <CircularProgress size={24} /> : "Load More"}
                    </Button>
                  </Box>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
};

export default Dashboard;
