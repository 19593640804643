import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const ListViewPage = ({
  userFolders,
  handleDelete,
  handleEdit,
  handleMoveTo,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentFolder, setCurrentFolder] = useState(null);
  const navigate = useNavigate();

  const handleClick = (event, folder) => {
    setAnchorEl(event.currentTarget);
    setCurrentFolder(folder);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDoubleClick = (folderId) => {
    navigate(`/manageGoal/folder/${folderId}`); // Navigate to the folder's page on double click
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: "auto", // Adjust width as needed
        margin: "0 16px", // Adds margin to the left and right
        border: "1px solid black", // Dark black border
        backgroundColor: "transparent", // Transparent background
      }}
    >
      <Table aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#3A57E8" }}>
          <TableRow>
            <TableCell sx={{ borderBottom: "2px solid " }}>
              <Typography variant="h6" sx={{ color: "white" }}>
                Name
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "2px solid " }}>
              <Typography variant="h6" sx={{ color: "white" }}>
                Description
              </Typography>
            </TableCell>
            <TableCell sx={{ borderBottom: "2px solid " }}>
              <Typography variant="h6" sx={{ color: "white" }}>
                Status
              </Typography>
            </TableCell>
            <TableCell align="right" sx={{ borderBottom: "2px solid " }}>
              <Typography variant="h6" sx={{ color: "white" }}>
                Action
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userFolders.map((folder) => (
            <TableRow
              key={folder.name}
              onDoubleClick={() => handleDoubleClick(folder.docId)} // Add double-click event handler
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                borderBottom: "2px solid black",
              }} // Bold lines for separation
            >
              <TableCell component="th" scope="row">
                {folder.data.name}
              </TableCell>
              <TableCell>{folder.data.description}</TableCell>
              <TableCell>{folder.data.status}</TableCell>
              <TableCell align="right">
                {/* <IconButton onClick={(e) => handleClick(e, folder)}>
                  <MoreVertIcon />
                </IconButton> */}

                <IconButton onClick={() => handleEdit(folder.docId)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(folder.docId)}>
                  <DeleteIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && currentFolder === folder}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => handleEdit(folder.docId)}>
                    Move To
                  </MenuItem>
                  <MenuItem onClick={() => handleDelete(folder.docId)}>
                    Delete
                  </MenuItem>
                  <MenuItem onClick={() => handleEdit(folder.docId)}>
                    Edit
                  </MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListViewPage;
