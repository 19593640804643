import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch } from "react-redux";
import { updateFolder } from "../../../../Redux/ActionCreators/FileFolderActionCreator/filefoldersActionCreators.js";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { themeStyles, buttonStyle } from "../../../../constants.jsx";
import { useSnackbar } from "../../../../components/SnackBarProvider.jsx";
const goalStatusOptions = ["Active", "Inactive"]; // Replace this with your actual options

const GoalAreaModal = ({ isOpen, onClose, folderDetails }) => {
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const [folderName, setFolderName] = useState("");
  const [folderDescription, setFolderDescription] = useState("");
  const [goalStatus, setGoalStatus] = useState("");
  const [enteredBy, setEnteredBy] = useState("");
  const [parentFolderId, setParentFolderId] = useState("");
  const [path, setPath] = useState("");

  useEffect(() => {
    console.log("folderDetails", folderDetails);
    setFolderName(folderDetails.data.name);
    setFolderDescription(folderDetails.data.description);
    setGoalStatus(folderDetails.data.status);
    setEnteredBy(folderDetails.data.enteredBy);
    setParentFolderId(folderDetails.data.parent);
    setPath(folderDetails.data.path);
  }, [folderDetails]);

  const handleSubmit = () => {
    // Handle the submit action
    // docId, name, description, status, enteredBy, parent, pat
    dispatch(
      updateFolder(
        folderDetails.docId,
        folderName,
        folderDescription,
        goalStatus,
        enteredBy,

        parentFolderId,
        path,
        openSnackbar
      )
    );

    console.log(folderName, folderDescription, goalStatus);
    //onClose(); // Close the modal
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        Goal Area Details
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Folder Name"
          type="text"
          disabled={goalStatus == "Inactive"}
          fullWidth
          variant="filled"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
          sx={{ ...themeStyles.textField }}
        />
        <TextField
          margin="dense"
          label="Folder Description"
          type="text"
          fullWidth
          disabled={goalStatus == "Inactive"}
          variant="filled"
          value={folderDescription}
          onChange={(e) => setFolderDescription(e.target.value)}
          sx={{ ...themeStyles.textField }}
        />
        <Autocomplete
          fullWidth
          options={goalStatusOptions}
          autoHighlight
          getOptionLabel={(option) => option}
          value={goalStatus}
          onChange={(event, newValue) => {
            setGoalStatus(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Goal Status"
              margin="dense"
              variant="filled"
              required
              inputProps={{
                ...params.inputProps,
                "aria-label": "Select goal status",
              }}
              sx={{ ...themeStyles.textField }}
            />
          )}
          sx={{ gridColumn: "span 2", ...themeStyles.textField }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{ ...buttonStyle }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} sx={{ ...buttonStyle }}>
          Save{" "}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GoalAreaModal;
