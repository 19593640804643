import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Button, Box } from "@mui/material";
import GoalItem from "./GoalItem";
import { themeStyles, buttonStyle, DATA_ENTRY_PAGE } from "../../constants";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import { useTheme, useMediaQuery, Grid } from "@mui/material";

const GoalDataCollectionManager = ({
  nodes,
  clientId,
  clientName,
  dataCollectionType,
  sessionId,
  owner,
}) => {
  // Initialize selectedDate with sessionDate
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [goalDataReady, setGoalDataReady] = useState(false);
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null); // default to sessionDate or today
  const [rerenderKey, setRerenderKey] = useState(0); // Replaced goclick with rerenderKey for clarity
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleGoalChange = (event, newValue) => {
    setSelectedGoal(newValue);
    setGoalDataReady(false);
    setRerenderKey(rerenderKey + 1);
  };

  const handleGoClick = () => {
    if (selectedGoal && selectedDate) {
      setGoalDataReady(true);
      setRerenderKey((prevKey) => prevKey + 1); // Increment rerenderKey to force re-render
    }
  };

  // This useEffect is unnecessary unless you have more logic to include in it
  // useEffect(() => {}, [selectedDate, selectedGoal]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        width: "100%",
        mt: 2,
        mb: 2,
      }}>
      <Grid container spacing={2} sx={{ width: "80%" }}>
        <Grid item xs={12} sm={4}>
          <Autocomplete
            options={nodes}
            getOptionLabel={(option) =>
              `${option.goalName} (${option.goalAreaName})` || "Unnamed Goal"
            }
            onChange={handleGoalChange}
            renderInput={(params) => (
              <TextField {...params} label="Select Goal" variant="filled" />
            )}
            sx={{ width: "100%", ...themeStyles.textField }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            type="date"
            label="Select Date"
            value={selectedDate}
            onChange={(newValue) => {
              setSelectedDate(newValue);
              setRerenderKey(rerenderKey + 1);
            }}
            disableFuture
            sx={{ width: "100%", ...themeStyles.textField }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            onClick={handleGoClick}
            disabled={!selectedGoal || !selectedDate}
            sx={{ width: "100%", ...buttonStyle }}>
            Go
          </Button>
        </Grid>
      </Grid>
      {goalDataReady && selectedGoal && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
            mt: 2,
          }}>
          <GoalItem
            key={rerenderKey}
            goal={selectedGoal}
            clientId={clientId}
            clientName={clientName}
            goals={nodes}
            dataCollectionType={dataCollectionType}
            sessionId={sessionId}
            sessionDate={selectedDate}
            owner={owner}
          />
        </Box>
      )}
    </Box>
  );
};

export default GoalDataCollectionManager;
