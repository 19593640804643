// SampleModal.js
import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function SampleModal({ open, handleClose, pdfUrl }) {
  // Responsive styles with media queries
  const responsiveStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "100%", sm: "80%", md: "80%", lg: "80%" }, // Adjust based on screen size
    height: "70%", // Auto height for better responsiveness
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflow: "auto", // Adjust overflow for better scrolling on small devices
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={responsiveStyle}>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
        <iframe
          src={pdfUrl}
          style={{ width: "100%", height: "85%", border: "none" }}
          title="PDF"
          loading="lazy" // Improves performance on devices with less processing power
        />
      </Box>
    </Modal>
  );
}

export default SampleModal;
