import { useTheme } from "@mui/material";
import Alert from "@mui/material/Alert";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { DataGridPro } from "@mui/x-data-grid-pro";
import axiosInstance from "../../components/AxiosInstance";
import { useState, useEffect } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import useSharedLink from "../SharedLink";
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from "@mui/x-data-grid-generator";
import { useContext } from "react";
import { AuthContext } from "../SessionManagement/authContext";
import LoadingIndicator from "../Loader";
import { useSnackbar } from "../../components/SnackBarProvider"; // Import SnackbarProvider

const roles = ["Admin", "Staff", "Supervisor", "Biller", "Other"];

const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
const randomRole = () => {
  return randomArrayItem(roles);
};

const initialRows = [
  {
    id: randomId(),
    name: randomTraderName(),
    age: 25,
    joinDate: randomCreatedDate(),
    role: randomRole(),
  },
];

export default function ManageFacility() {
  const { openSnackbar } = useSnackbar(); // Access the SnackbarProvider
  const sharedLink = useSharedLink();
  const { state } = useContext(AuthContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("userdetails oi", state.username, " role", state.role);
    async function fetchData(retryDelay = 2000, retryCount = 0) {
      const maxRetries = 5; // Define the maximum number of retries

      try {
        const res = await axiosInstance.post(sharedLink + "/viewallfacility" , { withCredentials: true });
        console.log(res.data);
        setRows(res.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);

        if (retryCount < maxRetries) {
          console.log(
            `Retrying in ${retryDelay / 1000} seconds... (${
              retryCount + 1
            }/${maxRetries})`
          );
          setTimeout(() => fetchData(retryDelay, retryCount + 1), retryDelay);
        } else {
          console.error("Max retries reached. Aborting fetch.");
          openSnackbar(
            "Failed to fetch data after multiple attempts.",
            "error"
          );
          setLoading(false);
        }
      }
    }

    fetchData();
  }, []);

  const handleRowEditStop = (params, event) => {
    console.log("inside stop");
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };

  // Helper function to format the time into "HH:MM AM/PM" (if needed)
  const dateTimeFormatter = (value, locale = "en-US") => {
    if (!value) return "";

    const date = new Date(value);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() is zero-based
    const day = date.getDate();
    const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;

    const timeFormatter = new Intl.DateTimeFormat(locale, {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // true for AM/PM notation
    });

    const formattedTime = timeFormatter.format(date);

    return `${formattedDate} ${formattedTime}`;
  };

  const handleEditClick = (id) => () => {
    navigate(`/updateFacility?Id=${id}`);
  };

  const handleSaveClick = (id) => () => {
    console.log("inside save");
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    console.log("inside delete");

    if (window.confirm("Sure want to delete?")) {
      const idToDelete = { id: id };
      openSnackbar("Please wait while we are deleting", "info");

      axiosInstance
        .post(sharedLink + "/removefacility", idToDelete)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            console.log("success");
            setRows((prevRows) => prevRows.filter((row) => row.id !== id));
            openSnackbar("Information deleted.", "success");
          } else {
            openSnackbar("Failed to delete.", "error");
            console.error("Failed to delete item");
          }
        })
        .catch((error) => {
          openSnackbar("Failed to delete.", "error");
          console.error("Error while deleting item:", error);
        });
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const phoneNumberValidation = /^\d{10}$/.test(newRow.phoneNumber);
    if (!phoneNumberValidation) {
      openSnackbar("Phone Number must be a 10-digit number.", "error");
      return null; // Return null to indicate that the process should not continue
    }
    console.log("Inside processing", newRow);
    console.log("Inside processing", new Date().toLocaleTimeString());
    const previousRow = rows.find((row) => row.id === newRow.id);

    const updatedRow = {
      ...newRow,
      isNew: false,
      lastUpdatedBy: localStorage.getItem("username"),
      lastUpdatedTime:
        new Date().toDateString() + ", " + new Date().toLocaleTimeString(),
    };
    console.log("Inside update", updatedRow);
    openSnackbar("Please wait while we are updating", "info");

    axiosInstance
      .post(sharedLink + "/updatefacility", {
        ...newRow,
        lastUpdatedTime: new Date().toLocaleTimeString(),
        lastUpdatedBy: localStorage.getItem("username"),
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          console.log("success");
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          openSnackbar("Information Updated.", "success");
          return updatedRow;
        } else {
          openSnackbar("Failed to update.", "error");
          console.error("Failed to update item");
        }
      })
      .catch((error) => {
        openSnackbar("Failed to update.", "error");
      });

    return previousRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      editable: false,
      hide: true,
      width: 150,
    },
    {
      field: "orgId",
      headerName: "Organization ID",
      editable: false,
      width: 150,
    },
    {
      field: "facilityName",
      headerName: "Facility Name",
      type: "text",
      width: 250,
      editable: false,
    },
    {
      field: "facilityNPI",
      headerName: "Facility NPI",
      editable: false,
      width: 150,
    },
    {
      field: "facilityTIN",
      headerName: "Facility TIN",
      editable: false,
      width: 150,
      hide: false,
    },
    {
      field: "facilityType",
      headerName: "Facility Type",
      editable: true,
      width: 150,
      hide: false,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      editable: true,
      width: 150,
      type: "date",
      valueGetter: ({ row }) =>
        row.startDate ? new Date(row.startDate) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      field: "expirationDate",
      headerName: "Expiration Date",
      editable: false,
      type: "date",
      valueGetter: ({ row }) =>
        row.expirationDate ? new Date(row.expirationDate) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      field: "licenseNo",
      headerName: "License Number",
      width: 200,
      editable: false,
    },
    {
      field: "licExpDate",
      headerName: "License Expiration Date",
      type: "date",
      editable: false,
      width: 200,
      valueGetter: ({ row }) =>
        row.licExpDate ? new Date(row.licExpDate) : null,
      valueFormatter: ({ value }) => formatDate(value),
    },
    {
      field: "country",
      headerName: "Country",
      editable: false,
      width: 150,
    },
    {
      field: "state",
      headerName: "State",
      valueOptions: states,
      editable: false,
      width: 150,
      type: "singleSelect",
    },
    {
      field: "city",
      headerName: "City",
      width: 150,
      editable: false,
    },
    {
      field: "address",
      headerName: "Address",
      width: 150,
      editable: false,
    },
    {
      field: "zipCode",
      headerName: "Zip Code",
      width: 150,
      editable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      editable: false,
      width: 150,
    },
    {
      field: "lastUpdatedBy",
      headerName: "Last Updated By",
      width: 150,
      editable: false,
    },
    {
      field: "lastUpdatedTime",
      headerName: "Last Updated Time",
      width: 200,
      type: "dateTime",
      editable: false,
      valueGetter: ({ row }) =>
        row.lastUpdatedTime
          ? new Date(row.lastUpdatedTime).toISOString()
          : null,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },
    {
      field: "enteredBy",
      headerName: "Entered By",
      width: 100,
      editable: false,
    },
    {
      field: "enteredTime",
      headerName: "Entered Time",
      width: 200,
      type: "dateTime",
      editable: false,
      valueGetter: ({ row }) =>
        row.enteredTime ? new Date(row.enteredTime).toISOString() : null,
      valueFormatter: ({ value }) => dateTimeFormatter(value),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              title="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              title="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            title="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            title="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  if (loading) {
    return <LoadingIndicator />;
  } else {
    return (
      <div className="staff-background">
        <Box m="20px">
          <Header title="Manage Facility" subtitle="" />
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.grey[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#3A57E8",
                borderBottom: "white",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "#3A57E8",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.grey[200]} !important`,
              },
            }}
          >
            <DataGridPro
              initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
                columns: {
                  columnVisibilityModel: {
                    // Hide columns status and traderName, the other columns will remain visible
                    id: false,
                    orgId: false,
                    lastUpdatedBy: false,
                    lastUpdatedTime: false,
                    enteredTime: false,
                    enteredBy: false,
                  },
                },
              }}
              sx={{
                boxShadow: 5,
                border: 5,
                borderColor: "primary.light",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold !important",
                },
                "& .MuiDataGrid-footerContainer": {
                  color: "white",
                  fontWeight: "bold !important",
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              rows={rows}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              slotProps={{
                toolbar: { setRows, setRowModesModel },
              }}
              isCellEditable={false}
              onCellDoubleClick={(params, event) => {
                event.preventDefault();
              }}
              onRowDoubleClick={(params) => {
                // Call the handleEditClick function or directly navigate
                handleEditClick(params.row.id)();
              }}
            />
          </Box>
        </Box>
      </div>
    );
  }
}
