import axiosInstance from "../../../components/AxiosInstance";

import {
  FETCH_CLIENTS,
  ADD_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  FETCH_CLIENTS_FOR_STAFF,
} from "../../Actions/actionTypes";
import { API_BASE_URL } from "../../../constants";

// Action Creators
const fetchClientsSuccessForStaff = (clients) => ({
  type: FETCH_CLIENTS_FOR_STAFF,
  payload: clients,
});
const fetchClientsSuccess = (clients) => ({
  type: FETCH_CLIENTS,
  payload: clients,
});

const addClientSuccess = (client) => ({
  type: ADD_CLIENT,
  payload: client,
});

const updateClientSuccess = (client) => ({
  type: UPDATE_CLIENT,
  payload: client,
});

const deleteClientSuccess = (clientId) => ({
  type: DELETE_CLIENT,
  payload: clientId,
});

// Thunk Action Creators for async API calls using axiosInstance
export const fetchClients = () => {
  return async (dispatch) => {
    try {
      const userId = localStorage.getItem("userId");
      const userRole = localStorage.getItem("role");

      const allClientsRequest = axiosInstance.post(
        API_BASE_URL + "/api/viewallclient",
        { withCredentials: true }
      );
      const ownClientsRequest = axiosInstance.post(
        API_BASE_URL + "/api/viewclient",
        {
          staffId: userId,
        },
        { withCredentials: true }
      );
      let assignedClientsRequest;

      // If userId is 1, call a different API, it's for admin
      if (userRole === "admin" && userId === 1) {
        assignedClientsRequest = axiosInstance.post(
          API_BASE_URL + "/api/viewallclient",
          { withCredentials: true }
        );
      } else {
        assignedClientsRequest = axiosInstance.post(
          API_BASE_URL + "/api/roles/findclientsunderstaff",
          { id: userId },
          { withCredentials: true }
        );
      }

      const [allClientsResponse, ownClientsResponse, assignedClientsResponse] =
        await Promise.all([
          allClientsRequest,
          ownClientsRequest,
          assignedClientsRequest,
        ]);

      const clients = {
        allClients: allClientsResponse.data,
        ownClients: ownClientsResponse.data,
        assignedClients: assignedClientsResponse.data,
      };

      dispatch(fetchClientsSuccess(clients));
    } catch (error) {
      console.error("Failed to fetch clients:", error);
    }
  };
};

export const addOrUpdateClient = (payload, mode) => {
  return async (dispatch) => {
    try {
      const url = mode === "add" ? "/api/clients/add" : "/api/clients/update";
      const response = await axiosInstance.post(API_BASE_URL + url, payload);
      if (response.status === 200) {
        if (mode === "add") {
          dispatch(addClientSuccess(response.data));
        } else {
          dispatch(updateClientSuccess(response.data));
        }
        return null; // Indicating success
      }
    } catch (error) {
      console.error("Error during the request:", error);
      return error.message; // Return error message
    }
  };
};

export const deleteClient = (clientId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.delete(
        `${API_BASE_URL}/api/clients/${clientId}`,
        { withCredentials: true }
      );
      if (response.status === 200) {
        dispatch(deleteClientSuccess(clientId));
      }
    } catch (error) {
      console.error("Error deleting client:", error);
    }
  };
};
export const fetchClientsForStaff = (userId) => {
  return async (dispatch) => {
    try {
      const ownClientsRequest = axiosInstance.post(
        API_BASE_URL + "/api/viewclient",
        {
          staffId: userId,
        },
        { withCredentials: true }
      );

      const [ownClientsResponse] = await Promise.all([ownClientsRequest]);

      dispatch(fetchClientsSuccessForStaff(ownClientsResponse?.data));
    } catch (error) {
      console.error("Failed to fetch clients:", error);
    }
  };
};
