import { FETCH_ROLES } from "../../Actions/actionTypes";

const initialState = [];

const rolesReducer = (state = initialState, action) => {
  console.log(action.payload, "roles reducer");
  switch (action.type) {
    case FETCH_ROLES:
      return action.payload;
    default:
      return state;
  }
};

export default rolesReducer;
