import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
} from "@mui/material";

const EventUpdatePopUp = ({
  open,
  onClose,
  onConfirm,
  onOptionChange,
  action = "update",
}) => {
  const [selectedOption, setSelectedOption] = useState("thisEvent");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    onOptionChange(event.target.value);
  };

  const handleSubmit = () => {
    onConfirm(selectedOption);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Please Choose an Action</DialogTitle>
      <DialogContent sx={{ minWidth: "500px" }}>
        <DialogContentText>{action} Recurring Event</DialogContentText>
        <RadioGroup
          aria-label="action"
          name="action"
          value={selectedOption}
          onChange={handleChange}>
          <FormControlLabel
            value="thisEvent"
            control={<Radio />}
            label="This Event"
          />
          <FormControlLabel
            value="thisAndFollowingEvents"
            control={<Radio />}
            label="This and Following Event"
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            backgroundColor: "#f44336", // Red background
            color: "white", // White text color
            "&:hover": {
              backgroundColor: "#d32f2f", // Darker red on hover
            },
          }}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          sx={{
            backgroundColor: "#2196f3", // Blue background
            color: "white", // White text color
            "&:hover": {
              backgroundColor: "#1976d2", // Darker blue on hover
            },
          }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventUpdatePopUp;
