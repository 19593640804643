import React, { useState } from "react";
import { SvgIcon } from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import PopUp from "../PopUp"; // Import the PopUp component

// Custom Add Icon with Google colors
const GoogleAddIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" fill="none" />
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" fill="red" />
    <path d="M5 13h6v6h-6v-6zm0-6h6v6H5v-6zm8 0h6v6h-6v-6z" fill="yellow" />
    <path d="M11 19h6v-6h-6v6zm0-8h6v-6h-6v6z" fill="blue" />
    <path d="M11 11h6v-6h-6v6zm-8 2h6v6H5v-6zm0-8h6v6H5v-6z" fill="green" />
  </SvgIcon>
);

// Styled Create button
const CreateButton = styled(Button)(({ theme }) => ({
  borderRadius: "50px",
  textTransform: "none",
  boxShadow: theme.shadows[1],
  backgroundColor: "#fff",
  color: "#3c4043",
  padding: theme.spacing(1, 2.5), // Adjust padding
  fontSize: "14px", // Adjust font size
  margin: "10px 0", // Ensure it has margin to avoid cut-off
  display: "flex",
  alignItems: "center",
  "&:hover": {
    backgroundColor: "#f1f3f4",
  },
  "& .MuiButton-startIcon": {
    marginRight: theme.spacing(1),
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1.5rem", // Adjust icon size
  },
}));


const GoogleAddButton = () => {
  const [openDialog, setOpenDialog] = useState(false); // State to control the dialog

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <CreateButton
        startIcon={<GoogleAddIcon />}
        onClick={handleClickOpen}
      >
        Create Event
      </CreateButton>

      {/* Render the PopUp component to handle the dialog */}
      <PopUp open={openDialog} onClose={handleClose} />
    </>
  );
};

export default GoogleAddButton;
