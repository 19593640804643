import React, { useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Button,
  Stack,
  useMediaQuery,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ShortTermGoalHandler from "../Level3";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { themeStyles, buttonStyle } from "../../../constants";
import StyledTextArea from "../../../components/StyledTextArea";

const LongTermGoal = ({
  iid,
  id,
  onRemove,
  toggleVisibility,
  visible,
  status,
  updateLongTermGoal,
  InitialLongTermGoalName,
  InitialLongTermGoalDescription,
  InitialShortTermGoals,
  updateStatus,
  parentStatus,
  type,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [shortTermGoals, setShortTermGoals] = useState(InitialShortTermGoals);

  const toggleActivationStatus = () => {
    if (!parentStatus) {
      alert("Please make the parent area active");
      return;
    }
    updateStatus(iid, !isActive ? "Active" : "Inactive", shortTermGoals);
    setIsActive((prev) => !prev);
  };

  const [longTermGoalName, setLongTermGoalName] = useState(
    InitialLongTermGoalName
  );
  const [longTermGoalDescription, setLongTermGoalDescription] = useState(
    InitialLongTermGoalDescription
  );
  const [isActive, setIsActive] = useState(status === "Active");

  const handleActivation = (data) => {
    updateStatus(iid, isActive ? "Active" : "Inactive", data);
  };
  const handleLongTermGoalNameChange = (event) => {
    setLongTermGoalName(event.target.value);
    updateLongTermGoal(
      iid,
      event.target.value,
      longTermGoalDescription,
      shortTermGoals
    );
  };

  const handleLongTermGoalDescriptionChange = (event) => {
    setLongTermGoalDescription(event.target.value);
    updateLongTermGoal(
      iid,
      longTermGoalName,
      event.target.value,
      shortTermGoals
    );
  };
  const handleShortTermGoalChange = (res) => {
    console.log("level 2", res);
    setShortTermGoals(res);
    updateLongTermGoal(iid, longTermGoalName, longTermGoalDescription, res);
  };

  return (
    <Box marginLeft={2}>
      <Box
        margin={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => toggleVisibility(iid)} size="large">
            {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>

          {id == null ? (
            <IconButton onClick={() => onRemove(iid)} size="large">
              <DeleteOutlineIcon disabled={!parentStatus} />
            </IconButton>
          ) : (
            <Box display="flex" alignItems="center" mr={2}>
              <IconButton
                onClick={toggleActivationStatus}
                size="large"
                disabled={!parentStatus}>
                {isActive && parentStatus ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <CheckCircleOutlineIcon />
                )}
              </IconButton>
              <Typography variant="body1">
                {isActive && parentStatus ? "Deactivate" : "Activate"}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="start">
        <Stack spacing={2} flexGrow={1}>
          {visible && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5.8}>
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Development Area Name"
                    onChange={handleLongTermGoalNameChange}
                    name={`developmentAreaName-${iid}`}
                    value={longTermGoalName}
                    disabled={!isActive || !parentStatus}
                    sx={{ ...themeStyles.textField }}
                    required
                    inputProps={{
                      maxLength: 120,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6.2}>
                  <StyledTextArea
                    fullWidth
                    variant="filled"
                    label="Development Area Description"
                    multiline
                    onChange={handleLongTermGoalDescriptionChange}
                    rows={3}
                    name={`developmentAreaDescription-${iid}`}
                    value={longTermGoalDescription}
                    disabled={!isActive || !parentStatus}
                    sx={{ ...themeStyles.textField }}
                    maxLength={512}
                  />
                </Grid>
              </Grid>
              <ShortTermGoalHandler
                shortTermGoals={shortTermGoals}
                setShortTermGoals={setShortTermGoals}
                handleShortTermGoalChange={handleShortTermGoalChange}
                handleActivation={handleActivation}
                parentStatus={parentStatus && isActive}
                type={type}
              />
            </>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

const LongTermGoalHandler = ({
  longTermGoals,
  setLongTermGoals,
  handleLongtermGoalChange,
  visible,
  handleActivation,
  parentStatus,
  type,
}) => {
  const [activeAreas, setActiveAreas] = useState(
    longTermGoals.filter((val) => val.devlopmentalAreaStatus === "Active")
  );

  const [showInactiveAreas, setShowInactiveAreas] = useState(false);
  const len = longTermGoals.length;

  const addLongTermGoal = () => {
    if (!parentStatus) {
      alert("Please make the parent area active");
      return;
    }
    setLongTermGoals([
      ...longTermGoals,
      {
        iid: len,
        visible: true,
        treatmentPlanObjectiveTemplates: [],
        devlopmentalAreaName: "",
        devlopmentalAreaDescription: "",
        devlopmentalAreaStatus: "Active",
      },
    ]);
    setActiveAreas([
      ...activeAreas,
      {
        iid: len,
        visible: true,
        treatmentPlanObjectiveTemplates: [],
        devlopmentalAreaName: "",
        devlopmentalAreaDescription: "",
        devlopmentalAreaStatus: "Active",
      },
    ]);
  };

  const removeLongTermGoal = (iid) => {
    setActiveAreas(activeAreas.filter((goal) => goal.iid !== iid));
    const updated = longTermGoals.filter((goal) => goal.iid !== iid);
    setLongTermGoals(updated);
    handleLongtermGoalChange(updated);
  };

  const updateLongTermGoal = (
    iid,
    longTermGoalName,
    longTermGoalDescription,
    shortTermGoals
  ) => {
    console.log(longTermGoals, "long term rpevious level2");
    const res = longTermGoals.map((goal) => {
      if (goal.iid === iid) {
        return {
          ...goal,
          devlopmentalAreaName: longTermGoalName,
          devlopmentalAreaDescription: longTermGoalDescription,
          treatmentPlanObjectiveTemplates: shortTermGoals,
        };
      }
      return goal;
    });
    console.log("res now level 2", res);
    setLongTermGoals(res);
    if (showInactiveAreas) {
      setActiveAreas(res);
    } else {
      setActiveAreas(
        res?.filter((val) => val.devlopmentalAreaStatus === "Active")
      );
    }
    handleLongtermGoalChange(res);
  };

  const toggleVisibility = (iid) => {
    setActiveAreas(
      activeAreas.map((goal) =>
        goal.iid === iid ? { ...goal, visible: !goal.visible } : goal
      )
    );
    const updatedLongTerm = longTermGoals.map((goal) =>
      goal.iid === iid ? { ...goal, visible: !goal.visible } : goal
    );
    setLongTermGoals(updatedLongTerm);
    handleLongtermGoalChange(updatedLongTerm);
  };

  const onShowInactiveAreasChange = (isChecked) => {
    setShowInactiveAreas(isChecked);
    if (isChecked) {
      setActiveAreas(longTermGoals);
    } else {
      setActiveAreas(
        longTermGoals?.filter((val) => val.devlopmentalAreaStatus === "Active")
      );
    }
  };

  const updateStatus = (iid, status, data) => {
    const res = longTermGoals.map((goal) => {
      if (goal.iid === iid) {
        return {
          ...goal,
          devlopmentalAreaStatus: status,
          treatmentPlanObjectiveTemplates: data,
        };
      }
      return goal;
    });
    console.log("res in level2 ");
    setLongTermGoals(res);
    handleLongtermGoalChange(res);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          color="primary"
          onClick={addLongTermGoal}
          sx={{ ...buttonStyle }}>
          Development Area
        </Button>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event) =>
                onShowInactiveAreasChange(event.target.checked)
              }
              name="showInactiveAreas"
              color="primary"
            />
          }
          label={<Typography variant="body1">Show Inactive</Typography>}
          style={{ marginBottom: 0 }}
        />
      </Box>
      <Box key={activeAreas}>
        {" "}
        {activeAreas?.map((goal) => (
          <LongTermGoal
            key={goal.iid}
            id={goal.id ? goal.id : null}
            iid={goal.iid}
            InitialLongTermGoalName={goal.devlopmentalAreaName}
            InitialLongTermGoalDescription={goal.devlopmentalAreaDescription}
            InitialShortTermGoals={goal.treatmentPlanObjectiveTemplates}
            onRemove={removeLongTermGoal}
            toggleVisibility={toggleVisibility}
            visible={goal.visible}
            updateLongTermGoal={updateLongTermGoal}
            status={goal.devlopmentalAreaStatus}
            updateStatus={updateStatus}
            parentStatus={parentStatus}
            type={type}
          />
        ))}
      </Box>
    </Box>
  );
};

export default LongTermGoalHandler;
