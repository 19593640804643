import React, { useEffect, useState, useMemo } from "react";
import { TreeView, TreeItem } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Typography, Snackbar, Alert } from "@mui/material";
import { useLocation } from "react-router-dom";
import useSharedLink from "../../scenes/SharedLink";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WcIcon from "@mui/icons-material/Wc";
import CakeIcon from "@mui/icons-material/Cake";
import {
  useTheme,
  useMediaQuery,
  Avatar,
  Box,
  Button,
  Divider,
} from "@mui/material";
import axiosInstance from "../../components/AxiosInstance";
import LoadingIndicator from "../../scenes/Loader";
import GoalDataCollectionManager from "../GoalDataCollection/GoalDataCollectionManager";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { useSnackbar } from "../../components/SnackBarProvider";
import { buttonStyle, PROGRAM_BOOK } from "../../constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { usePermissions } from "../../Phase3/CheckPermission";
const CircleWithInitial = React.memo(({ label, color, nodes, isExpanded }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          backgroundColor: color,
          color: "#fff",
          width: "48px", // Keep width and height the same for a square shape
          height: "48px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          marginRight: "8px",
          borderRadius: "4px", // Optional: add for slight rounding, remove for a perfect square
        }}>
        <span
          style={{
            position: "absolute",
            top: "10%",
            fontSize: "1.25rem",
            fontWeight: "bold",
          }}>
          {nodes.initial}
        </span>
        {isExpanded ? (
          <ExpandMoreIcon style={{ position: "absolute", bottom: "10%" }} />
        ) : (
          <ChevronRightIcon style={{ position: "absolute", bottom: "10%" }} />
        )}
      </div>
      <Typography
        variant="body1"
        style={{
          marginLeft: "8px",
          color: "white",
          backgroundColor: getColor(nodes.level),
          padding: "8px",
          borderRadius: "4px",
          border: "1px solid black",
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "auto", // Adjust height to auto to accommodate GoalItem
        }}>
        {label}
      </Typography>
    </div>
  );
});

const renderTree = (
  nodes,
  expanded,
  clientId,
  clientName,
  dataCollectionType,
  sessionId,
  owner
) => {
  // Check if the node level is 5, then handle it differently

  return (
    <TreeItem
      key={nodes.id}
      nodeId={String(nodes.id)}
      style={{ margin: "20px" }}
      label={
        <div>
          <CircleWithInitial
            label={nodes.label}
            color={getColor(nodes.level)}
            isExpanded={expanded?.includes(String(nodes.id))}
            nodes={nodes}
          />
        </div>
      }>
      {nodes.level !== 4 ? (
        Array.isArray(nodes.children) &&
        nodes.children.map((node) =>
          renderTree(
            node,
            expanded,
            clientId,
            clientName,
            dataCollectionType,
            sessionId,
            owner
          )
        )
      ) : (
        <GoalDataCollectionManager
          nodes={nodes.children}
          clientId={clientId}
          clientName={clientName}
          dataCollectionType={dataCollectionType}
          sessionId={sessionId}
          owner={owner}
        />
      )}
    </TreeItem>
  );
};

const getColor = (id) => {
  // Example of customizing based on the ID, you can adjust logic as needed
  const colors = [
    "#2196f3",
    "#4caf50",
    "#ff9800",
    "#9c27b0",
    "#f44336",
    "#3f51b5",
  ];
  return colors[id % colors.length];
};

const transformDataToTree = (data) => {
  // Ensure data is defined before accessing its properties
  if (!data) return null;

  const generateUniqueId = (prefix, id) => `${prefix}-${id}`;

  return {
    id: generateUniqueId("PB", data.id), // Program Book
    initial: "PB",
    label: data.programBookName,
    level: 0,
    children:
      data.dataStrategyList?.map((strategy) => ({
        initial: "DS",
        id: generateUniqueId("DS", strategy.id), // Data Strategy
        level: 1,
        label: strategy.strategyName,
        children:
          strategy.developmentalAreaList?.map((area) => ({
            id: generateUniqueId("DA", area.id), // Developmental Area
            initial: "DA",
            level: 2,
            label: area.devlopmentalAreaName,
            children:
              area.treatmentPlanObjectiveList?.map((objective) => ({
                id: generateUniqueId("TO", objective.id), // Treatment Objective
                initial: "TO",
                level: 3,
                label: objective.treatmentplanObjectiveName,
                children:
                  objective.shortTermObjective?.map((sto) => ({
                    id: generateUniqueId("STO", sto.id), // Short Term Objective
                    level: 4,
                    initial: "SO",
                    label: sto.shortTermObjectiveName,
                    children:
                      sto.goals?.map((goal) => ({
                        ...goal,
                        initial: "GO",
                        level: 5,
                        id: generateUniqueId("GO", goal.id), // Goal
                        goalId: goal.id,
                        label: goal.goalName,
                      })) || [],
                  })) || [],
              })) || [],
          })) || [],
      })) || [],
  };
};
const ClientDetails = React.memo(({ clientName, gender, dob }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      backgroundColor: "#607d8b",
      padding: "16px",
      borderRadius: "8px",
      width: "100%",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    }}>
    <Avatar
      sx={{
        width: 56,
        height: 56,
        backgroundColor: "#455a64",
        marginRight: "16px",
      }}
    />
    <Box sx={{ flex: 1, textAlign: "center" }}>
      <Typography variant="h3" sx={{ color: "white", marginBottom: "4px" }}>
        Client Details
      </Typography>
      <Divider sx={{ backgroundColor: "white", marginBottom: "8px" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          color: "white",
          fontSize: "1.2rem",
        }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AccountCircleIcon sx={{ marginRight: "4px" }} />
          <Typography variant="body1">Name: {clientName}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <WcIcon sx={{ marginRight: "4px" }} />
          <Typography variant="body1">Gender: {gender}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CakeIcon sx={{ marginRight: "4px" }} />
          <Typography variant="body1">DOB: {dob}</Typography>
        </Box>
      </Box>
    </Box>
    <Avatar
      sx={{
        width: 56,
        height: 56,
        backgroundColor: "#78909c",
      }}
    />
  </Box>
));

export default function CustomizedTreeView() {
  const { checkPermission } = usePermissions();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const sharedLink = useSharedLink();
  const navigate = useNavigate();
  const location = useLocation();
  const [treeData, setTreeData] = React.useState(null);
  const queryParams = new URLSearchParams(location.search);
  const [expanded, setExpanded] = React.useState([]);
  const [dataCollectionType, setDataCollectionType] = useState("BaseLine");
  const [sessionId, setSessionId] = useState("");
  const { clientId, owner, clientName, gender, dob } = location.state || {};
  const [loading, setLoading] = useState(true);

  const { openSnackbar } = useSnackbar();

  useEffect(() => {
    const expandAllNodes = (node, expandedIds = []) => {
      expandedIds.push(node.id);
      if (node.children) {
        node.children.forEach((child) => expandAllNodes(child, expandedIds));
      }
      return expandedIds;
    };
    try {
      axiosInstance
        .post(sharedLink + "/getprogrambooks", { clientId: clientId })
        .then((res) => {
          console.log(res.data[0]);
          const data = res.data[0];
          if (!data) {
            if (checkPermission(PROGRAM_BOOK, "c"))
              openSnackbar(
                "There is no programBook associated with this client \n, Redirecting to Add programBook Page !",
                "info"
              );
            else
              openSnackbar(
                "There is no programBook associated with this client.  \n Please contact support to assign !",
                "error"
              );

            if (checkPermission(PROGRAM_BOOK, "c"))
              setTimeout(() => navigate("/addProgramBook"), 1000);
            return;
          }
          const transformedData = transformDataToTree(data);
          setTreeData(transformedData);

          setExpanded(expandAllNodes(transformedData));
          console.log("data for client", data);

          // setLoading(false);
          console.log(treeData);
        })
        .catch((err) => {
          console.log(err);

          //  navigate("/dashboard");
        })
        .finally(() => setLoading(false));
    } catch (e) {
      console.log(e);
    }
  }, [clientId]);

  if (loading) return <LoadingIndicator />;
  else
    return (
      <>
        <Header title="Data Collection" subtitle="" className="headerStyle" />
        <ClientDetails clientName={clientName} gender={gender} dob={dob} />
        <TreeView
          expanded={expanded}
          onNodeToggle={(event, nodeIds) => setExpanded(nodeIds)}
          sx={{
            flexGrow: 1,
            maxWidth: "100%", // Use full width
            overflowX: "auto",
            padding: 0, // Remove padding from the TreeView
            "& .MuiPaper-root": {
              // This targets the Paper component within the TreeView
              boxShadow: "none",
            },
            ".MuiTreeItem-root": {
              "&:before": {
                content: "none", // Remove pseudo-element lines if they are causing the space
              },
            },
            ".MuiTreeItem-content": {
              margin: 0, // Remove margin from the TreeItem content
              "&:hover": {
                backgroundColor: "transparent", // Correct property name to "backgroundColor"
              },
            },
            ".MuiTreeItem-group": {
              marginLeft: 0, // Remove margin from the nested group items if it's set
            },
            "& .Mui-selected, & .Mui-selected:hover": {
              // Corrected selector for targeting selected items
              boxShadow: "none !important",
              backgroundColor: "transparent !important", // Remove background color change on selection if necessary
            },
          }}>
          {treeData &&
            renderTree(
              treeData,
              expanded,
              clientId,
              clientName,
              dataCollectionType,
              sessionId,
              owner
            )}
        </TreeView>
        <Box
          sx={{
            mt: "auto", // Pushes the button to the bottom
            width: "100%", // Takes the full width
            display: "flex",
            justifyContent: "flex-end", // Aligns button to the right
            paddingRight: 2, // Adds padding to the right for spacing
          }}>
          <Button
            onClick={() => {
              navigate("/dashboard");
            }}
            color="secondary"
            variant="contained"
            sx={{ ...buttonStyle, marginTop: "3px" }}>
            Go Back
          </Button>
        </Box>
      </>
    );
}
