import React, { useState } from "react";
import {
  Box,
  TextField,
  IconButton,
  Button,
  Stack,
  useMediaQuery,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import GoalHnadler from "../Level5";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { themeStyles, buttonStyle } from "../../../constants";
import StyledTextArea from "../../../components/StyledTextArea";

// AddProgramBookGoal Component
const AddProgramBookGoal = ({
  id,
  iid,
  onRemove,
  toggleVisibility,
  visible,
  updateProgramBookGoal,
  InitialShortTermObjectiveName,
  InitialShortTermObjectiveDesc,
  InitialGoals,
  updateStatus,
  status,
  parentStatus,
  type,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [shortTermObjectiveName, setShortTermObjectiveName] = useState(
    InitialShortTermObjectiveName
  );
  const [isActive, setIsActive] = useState(status !== "Inactive");
  const [goalTemplates, setGoalTemplates] = useState([]);
  const [shortTermObjectiveDescription, setShortTermObjectiveDescription] =
    useState(InitialShortTermObjectiveDesc);
  const [goals, setGoals] = useState(InitialGoals);

  const handleActivation = (goal) => {
    updateStatus(
      iid,
      shortTermObjectiveName,
      shortTermObjectiveDescription,
      status ? "Active" : "Inactive",
      goal
    );
  };

  const handleGoalsChange = (goal) => {
    console.log(iid, " level 4 ", goal);
    updateProgramBookGoal(
      iid,
      shortTermObjectiveName,
      shortTermObjectiveDescription,
      goal
    );
    setGoals(goal);
  };

  const handleGoalNameChange = (event) => {
    setShortTermObjectiveName(event.target.value);

    updateProgramBookGoal(
      iid,
      event.target.value,
      shortTermObjectiveDescription,
      goals
    );
  };

  const handleGoalDescriptionChange = (event) => {
    setShortTermObjectiveDescription(event.target.value);
    updateProgramBookGoal(
      iid,
      shortTermObjectiveName,
      event.target.value,
      goals
    );
  };

  const toggleActivationStatus = () => {
    if (!parentStatus) {
      alert("Please make the parent area active");
      return;
    }
    updateStatus(
      iid,
      shortTermObjectiveName,
      shortTermObjectiveDescription,
      !isActive ? "Active" : "Inactive",
      goals
    );

    setIsActive((prev) => !prev);
  };

  return (
    <Box marginLeft={2}>
      <Box
        margin={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Box display="flex" alignItems="center">
          <IconButton onClick={() => toggleVisibility(iid)} size="large">
            {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
          {!id ? (
            <IconButton onClick={() => onRemove(iid)} size="large">
              <DeleteOutlineIcon disabled={!parentStatus} />
            </IconButton>
          ) : (
            <Box display="flex" alignItems="center" mr={2}>
              <IconButton
                onClick={toggleActivationStatus}
                size="large"
                disabled={!parentStatus}>
                {isActive && parentStatus ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <CheckCircleOutlineIcon />
                )}
              </IconButton>
              <Typography variant="body1">
                {isActive && parentStatus ? "Deactivate" : "Activate"}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="start">
        {visible && (
          <Stack spacing={2} flexGrow={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5.8}>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Short Term Objective Name"
                  name={`shortTermObjectiveName-${iid}`}
                  onChange={handleGoalNameChange}
                  value={shortTermObjectiveName}
                  sx={{ ...themeStyles.textField }}
                  disabled={!isActive || !parentStatus}
                  required
                  inputProps={{
                    maxLength: 120,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6.2}>
                <StyledTextArea
                  fullWidth
                  multiline
                  rows={3}
                  variant="filled"
                  label="Short Term Objective Description"
                  onChange={handleGoalDescriptionChange}
                  value={shortTermObjectiveDescription}
                  name={`shortTermObjectiveDescription-${iid}`}
                  disabled={!isActive || !parentStatus}
                  sx={{ ...themeStyles.textField }}
                  inputProps={{
                    maxLength: 512,
                  }}
                />
              </Grid>
              <Grid xs={12} md={12}>
                <GoalHnadler
                  setGoals={setGoals}
                  goals={goals}
                  handleGoalChange={handleGoalsChange}
                  handleActivation={handleActivation}
                  type={type}
                  parentStatus={parentStatus && isActive}
                />
              </Grid>
            </Grid>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

// AddProgramBookGoalHandler Component
const AddProgramBookGoalHandler = ({
  programBookGoals,
  setProgramBookGoals,
  handleGoalChange,
  handleActivation,
  parentStatus,
  type,
}) => {
  const [activeAreas, setActiveAreas] = useState(
    programBookGoals?.filter((val) => val.shortTermObjectiveStatus === "Active")
  );

  const [showInactiveAreas, setShowInactiveAreas] = useState(false);

  const onShowInactiveAreasChange = (isChecked) => {
    console.log(programBookGoals, " ", activeAreas);
    setShowInactiveAreas(isChecked);
    if (isChecked) {
      setActiveAreas(programBookGoals);
    } else {
      setActiveAreas(
        programBookGoals?.filter(
          (val) => val.shortTermObjectiveStatus === "Active"
        )
      );
    }
    console.log(programBookGoals, " ", activeAreas);
  };

  const addProgramBookGoal = () => {
    if (!parentStatus) {
      alert("Please make the parent area active");
      return;
    }
    const len = programBookGoals.length;
    setProgramBookGoals([
      ...programBookGoals,
      {
        iid: len,
        visible: true,
        shortTermObjectiveName: "",
        shortTermObjectiveDescription: "",
        shortTermObjectiveStatus: "Active",
        goalTemplates: [],
      },
    ]);
    setActiveAreas([
      ...activeAreas,
      {
        iid: len,
        visible: true,
        shortTermObjectiveName: "",
        shortTermObjectiveDescription: "",
        shortTermObjectiveStatus: "Active",
        goalTemplates: [],
      },
    ]);
  };

  const removeProgramBookGoal = (iid) => {
    setActiveAreas(activeAreas.filter((goal) => goal.iid !== iid));
    const updatedGoals = programBookGoals.filter((goal) => goal.iid !== iid);
    setProgramBookGoals(updatedGoals);
    handleGoalChange(updatedGoals);
  };

  const updateProgramBookGoal = (iid, goalName, goalDescription, goals) => {
    const res = programBookGoals.map((goall) => {
      if (goall.iid === iid) {
        console.log("inside goal iid", goall.iid, " iid=", iid);
        return {
          ...goall,
          shortTermObjectiveName: goalName,
          shortTermObjectiveDescription: goalDescription,
          goalTemplates: goals,
        };
      }

      return goall;
    });

    setProgramBookGoals(res);
    if (showInactiveAreas) {
      setActiveAreas(res);
    } else {
      setActiveAreas(
        res?.filter((val) => val.shortTermObjectiveStatus === "Active")
      );
    }
    console.log("previous level 4", programBookGoals);
    console.log("now level 4", res);
    handleGoalChange(res);
  };

  const updateStatus = (iid, name, desc, status, data) => {
    const res = programBookGoals.map((goal) => {
      if (goal.iid === iid) {
        return {
          ...goal,
          shortTermObjectiveName: name,
          shortTermObjectiveDescription: desc,
          shortTermObjectiveStatus: status,
          goalTemplates: data,
        };
      }
      return goal;
    });
    handleGoalChange(res);
  };

  const toggleVisibility = (iid) => {
    setActiveAreas(
      activeAreas.map((goal) =>
        goal.iid === iid ? { ...goal, visible: !goal.visible } : goal
      )
    );
    handleGoalChange(
      programBookGoals.map((goal) =>
        goal.iid === iid ? { ...goal, visible: !goal.visible } : goal
      )
    );
  };

  return (
    <Box>
      <Box
        margin={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center">
        <Button
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          color="primary"
          onClick={addProgramBookGoal}
          sx={{ ...buttonStyle }}>
          Short Term Objective
        </Button>
        <Box flexGrow={1} display="flex" justifyContent="flex-end">
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) =>
                  onShowInactiveAreasChange(event.target.checked)
                }
                name="showInactiveAreas"
                color="primary"
              />
            }
            label={<Typography variant="body1">Show Inactive</Typography>}
            style={{ marginBottom: 0, marginRight: 0 }} // Adjust as needed for alignment
          />
        </Box>
      </Box>
      <Box key={activeAreas}>
        {activeAreas?.map((goal, index) => (
          <AddProgramBookGoal
            key={index}
            iid={goal.iid}
            id={goal.id}
            InitialShortTermObjectiveDesc={goal.shortTermObjectiveDescription}
            InitialShortTermObjectiveName={goal.shortTermObjectiveName}
            InitialGoals={goal.goalTemplates ? goal.goalTemplates : []}
            onRemove={removeProgramBookGoal}
            toggleVisibility={toggleVisibility}
            visible={goal.visible}
            updateProgramBookGoal={updateProgramBookGoal}
            status={goal.shortTermObjectiveStatus}
            updateStatus={updateStatus}
            parentStatus={parentStatus}
            type={type}
          />
        ))}
      </Box>
    </Box>
  );
};

export default AddProgramBookGoalHandler;
